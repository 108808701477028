import request from '@jz/request';

export function getFlvVideoImg(data) {
    return request.post('/rajax/site_h.jsp?cmd=getWafNotCk_getFlvVideoImg', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getLinkFlvDom(data) {
    return request.post('/rajax/flv_h.jsp?cmd=getWafNotCk_getLinkFlvDom', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getModuleFlvDom(data) {
    return request.post('/rajax/flv_h.jsp?cmd=getWafNotCk_getModuleFlvDom', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getProductVideoRqueset(data) {
    return request
        .post('/rajax/productModule_h.jsp?cmd=getWafNotCk_getProductVideo', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getFlvData(data) {
    return request.post('/rajax/site_h.jsp?cmd=getWafNotCk_getFlv', { data }).then(({ data = {} }) => {
        return data;
    });
}
