var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.iconType == 2
    ? _c("div", { staticClass: "top_svg_custom top_svg_in_custom" }, [
        _vm._v("置顶"),
      ])
    : _c(
        "svg",
        { staticClass: "top_svg top_svg_in_custom", class: _vm.topSvgClass },
        [_c("use", { attrs: { "xlink:href": `#icon_top${_vm.iconType}` } })]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }