
import Counter from '../../common/Counter/index.vue';
import { mapGetters, mapState } from 'vuex';
import { setUrlArgToCookieAndJump } from '@/site/shared/cookie/index.js';

export default {
    components: {
        Counter,
    },
    props: {
        list: {
            type: Array,
            required: true,
        },
        canModify: {
            type: Boolean,
            default: true,
        },
        unitInfo: {
            required: true,
            type: Object,
        },
    },
    computed: {
        ...mapGetters(['isMobi']),
        ...mapState(['LS']),
    },
    render() {
        const LS = this.LS;

        const addUnit = (price) => {
            const { siteCurrencyRight, currencySymbol } = this.unitInfo || {};
            if (siteCurrencyRight) {
                return `${price}${currencySymbol}`;
            }
            return `${currencySymbol}${price}`;
        };

        const goProductDetail = (url, pdReqArgs) => {
            setUrlArgToCookieAndJump(url, pdReqArgs, { blank: true });
        };
        const getImgNode = (item) => (
            <img
                class="shopping_item_img"
                src={item.pdImgPath}
                onClick={() => goProductDetail(item.jumpUrl, item.pdReqArgs)}
            />
        );
        const getNameNode = (item) => (
            <div class="shopping_item_text" onClick={() => goProductDetail(item.jumpUrl, item.pdReqArgs)}>
                {item.pdName}
            </div>
        );
        const getPriceNode = (item) => <div class="shopping_item_price">{addUnit(item.priceStr)}</div>;
        const getCountNode = (item) => (
            <div class="shopping_item_count">
                {this.canModify ? <Counter v-model={item.amount} size="small" /> : `x${item.amount}`}
            </div>
        );

        const getItemNodeInPc = (item) => {
            return (
                <div class="shopping_item" key={item.pdId}>
                    <div class="shopping_item_name">
                        {getImgNode(item)}
                        {getNameNode(item)}
                    </div>
                    {getPriceNode(item)}
                    {getCountNode(item)}
                    <div class="shopping_item_subtotal">{addUnit((item.price * item.amount).toFixed(2))}</div>
                </div>
            );
        };

        const getItemNodeInMobi = (item) => {
            return (
                <div class="shopping_item_mobi" key={item.pdId}>
                    <div class="shopping_item_left">{getImgNode(item)}</div>
                    <div class="shopping_item_right">
                        {getNameNode(item)}
                        <div class="shopping_item_right_bottom">
                            {getPriceNode(item)}
                            {getCountNode(item)}
                        </div>
                    </div>
                </div>
            );
        };
        const wrapClass = ['shopping_list_wrap', !this.canModify ? 'shopping_list_wrap--notModify' : ''];
        return (
            <div class={wrapClass}>
                {!this.isMobi && (
                    <div class="shopping_header">
                        <div class="shopping_header_name">{LS.orderListItemPdName}</div>
                        <div class="shopping_header_price">{LS.orderListItemPdPrice}</div>
                        <div class="shopping_header_count">{LS.orderListItemPdAmount}</div>
                        <div class="shopping_header_subtotal">{LS.orderDetailItemTotal}</div>
                    </div>
                )}
                <div class="shopping_body">
                    {this.list.map((item) => (this.isMobi ? getItemNodeInMobi(item) : getItemNodeInPc(item)))}
                </div>
            </div>
        );
    },
};
