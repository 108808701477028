export const getRichAnimation = function (id) {
    var $module = Fai.top.$('#module' + id),
        domSeqList = [],
        $dom;

    $dom = $module.find('.rich_img_wrap');
    !!$dom.length && domSeqList.push(Array.from($dom));

    $dom = $module.find('.rich_article_content_wrap');
    !!$dom.length && domSeqList.push(Array.from($dom));
    return domSeqList;
};
