import { getComputedBg } from '@/components/utils.js';

const getMaskData = (effects, options) => {
    const { mask } = effects;
    if (!mask) {
        return {};
    }

    const style = getComputedBg(mask.bg, undefined, { manageV2: true });

    const icon = getIconCss(mask.icon);

    return {
        showType: mask.t,
        bgStyle: style,
        iconStyle: icon,
        type: effects.mt,
        ...options,
    };
};

function getIconCss(icon) {
    if (icon.y === 0 || !icon.p || !icon.f) {
        return '';
    }
    return `background-image: url(${icon.p});background-size: contain;background-repeat: no-repeat;background-position: center;`;
}

/**
 *
 * @function name - getImgComponentOption
 * @description 传入模块数据和配置返回图片组件需要的图片特效数据
 * @param module  {String} - 图片地址
 * @param options.imgId {String} - 换图特效，多图类型的模块需要传递图片的id来查找到具体换的图片，不传则直接拿commProp.pa.hfPath.
 * @param options.title {String} - 遮罩标题
 * @param options.desc {String} - 遮罩描述
 * @param options.titleStyle {String} - 遮罩标题样式
 * @param options.descStyle {String} - 遮罩描述样式
 * @param options.contentWrapStyle {String} - 遮罩容器样式
 * @param options.disabledBg {Boolean} - 是否禁用背景色
 * @param options.showType {Number} - 显式自定遮罩显示类型，不用数据的
 * @return  {Object} - 图片组件需要的配置
 */
export const getImgComponentOption = (module = {}, options = {}) => {
    const { imgId, title, desc, titleStyle, descStyle, contentWrapStyle, disabledBg = false, showType } = options;
    if (!Object.keys(module).length) {
        return {};
    }
    let effects = module.commProp?.pa || {};
    let optional = {};
    if (effects.b?.y) {
        optional.border = {
            size: effects.b?.bw,
            color: effects.b?.bc,
        };
    } else {
        if (typeof window !== 'undefined') {
            optional.border = {
                color: window._store.state.responsiveInfo.themeColor, //主题色
            };
        }
    }
    if (!disabledBg && effects.bg?.y) {
        optional.bgColor = effects.bg?.c;
    }
    let switchImg = '';
    if (imgId) {
        switchImg = effects.hfList?.find((item) => item.id == imgId)?.hfPath;
    } else {
        switchImg = effects.hfPath;
    }
    const effectsList = [
        {
            type: effects.t,
            mode: effects.st,
            dir: effects.r - 1,
            switchImg,
            ...optional,
        },
    ];
    if ([1, 2, 3].includes(effects.mt)) {
        effectsList.push({
            type: 5,
            mask: getMaskData(effects, {
                title,
                desc,
                titleStyle,
                descStyle,
                contentWrapStyle,
                ...(typeof showType !== 'undefined' ? { showType } : {}),
            }),
        });
    }
    return effectsList;
};
