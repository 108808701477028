import { Comm } from '../../utils';
const { getTextAlignStyle, getStyleDataByDevice, extend, getWrapStyle, getComputedTextColor } = Comm;
import { getImgComponentOption } from '@/components/modules/shared/imgEffect.js';
import { getComputedBg } from '@/components/utils.js';
import imgEffectTypeMixin from './imgEffectTypeMixin';

export default {
    mixins: [imgEffectTypeMixin],
    computed: {
        descStyle() {
            if (this.isAllMaskStyle3) {
                return 1;
            }
            if ([2, 3].includes(this.module.commProp.pa.mt)) {
                // 遮罩类型的图片特效，全部走文字样式0逻辑
                return 0;
            }
            return this.isMobi ? this.module.privatePattern.mobi.pds : this.module.privatePattern.pc.pds;
        },
        titleStyle() {
            const style = {};

            let fontStyle = {};
            let textColorStyle = {};

            const pcData = this.module.privatePattern.pc.pf;
            const mobiData = this.module.privatePattern.mobi.pf;

            let font = pcData;

            let isCustom = this.module.privatePattern.pc.cmdd === 2;

            if (this.isMobi) {
                font = mobiData;
                isCustom = this.module.privatePattern.mobi.cmdd === 2;
                // mobi选择默认，默认为pc的，font-size不继承
                if (this.module.privatePattern.mobi.cmdd === 1) {
                    font = Object.assign({}, pcData, { s: '' });
                    isCustom = this.module.privatePattern.pc.cmdd === 2;
                }
            }

            let isAllMaskStyle3 = false;
            if (!this.isMobi) {
                // 电脑视图
                if (this.isAllMaskStyle3) {
                    font = this.module.commProp.pa.pf;
                    isCustom = this.module.commProp.pa.pf.y === 2;
                    isAllMaskStyle3 = true;
                }
            } else {
                // 手机视图
                if (this.isHalfMaskStyle2 || this.isAllMaskStyle2 || this.isAllMaskStyle3) {
                    if (this.module.privatePattern.mobi.cmdd === 1) {
                        font = Object.assign({}, this.module.commProp.pa.pf);
                        isCustom = this.module.commProp.pa.pf.y === 2;
                        delete font.s;
                    }
                }
            }

            if (isCustom) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);

            if (this.descStyle === 1 && !isAllMaskStyle3) {
                const wrapData = getStyleDataByDevice({
                    pcData: this.module.privatePattern.pc.pl,
                    mobiData: this.module.privatePattern.mobi.pl,
                    isMobi: this.isMobi,
                });
                extend(style, getWrapStyle(wrapData));
            }

            return style;
        },
        titleDesc() {
            const style = {};
            let font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.d2f,
                mobiData: this.module.privatePattern.mobi.d2f,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });

            let fontStyle = {};
            let textColorStyle = {};

            if (font.y === 2) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);

            if (this.descStyle === 1) {
                const wrapData = getStyleDataByDevice({
                    pcData: this.module.privatePattern.pc.pl,
                    mobiData: this.module.privatePattern.mobi.pl,
                    isMobi: this.isMobi,
                });
                extend(style, getWrapStyle(wrapData));
            }

            return style;
        },
        descWrapStyle() {
            let descAlign = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.da,
                mobiData: this.module.privatePattern.mobi.da,
                isMobi: this.isMobi,
            });
            let bg = {};
            if (this.isMobi) {
                if (this.isHalfMaskStyle2 || this.isAllMaskStyle2 || (this.isMagnifier && this.descStyle === 0)) {
                    bg = getComputedBg(this.module.commProp.pa.mask.bg, undefined, { manageV2: true });
                }

                if (this.isHalfMaskStyle2 || this.isAllMaskStyle2 || this.isAllMaskStyle3) {
                    // mobi选择默认就选pc的
                    if (this.module.privatePattern.mobi.da === 0) {
                        descAlign = this.module.commProp.pa.da;
                    }
                }
            } else {
                if (this.isAllMaskStyle3) {
                    descAlign = this.module.commProp.pa.da;
                }
            }

            const _style = {
                ...getTextAlignStyle(descAlign),
                ...bg,
            };
            return _style;
        },
        titleStyleInMask() {
            return this.generateFontStyle(this.module.commProp.pa.pf);
        },
        descStyleInMask() {
            return this.generateFontStyle(this.module.commProp.pa.d2f);
        },
        wrapStyleInMask() {
            return getTextAlignStyle(this.module.commProp.pa.da);
        },
        logEventConfig() {
            return {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-列表多图',
                    jz_version: this.jzVersion,
                },
            };
        },
        previewSrcList() {
            const previewSrcList = [];
            this.photoList.forEach((value) => {
                const previewSrcItem = {};
                previewSrcItem.src = value.psrc;
                previewSrcItem.title = value.desc;
                previewSrcItem.description = value.desc2;
                previewSrcList.push(previewSrcItem);
            });
            return previewSrcList;
        },
    },
    methods: {
        generateFontStyle(font) {
            const style = {};

            if (!font) {
                return style;
            }

            let fontStyle = {};
            let textColorStyle = {};

            if (font.y === 2) {
                fontStyle = {
                    ...fontStyle,
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
        getImgComponentEffect(photoItem) {
            if (this.isMobi) {
                return { type: 0 };
            }

            if (
                this.module.commProp.pa.t === 0 &&
                (this.module.commProp.pa.mt === 0 || typeof this.module.commProp.pa.mt === 'undefined')
            ) {
                return { type: 0 };
            }

            let options = {};

            // 在遮罩里面的样式交由通用组件处理
            if (this.descStyle === 0 || this.isAllMaskStyle3) {
                options = {
                    title: photoItem.desc,
                    desc: photoItem.desc2,
                    titleStyle: this.titleStyleInMask,
                    descStyle: this.descStyleInMask,
                    contentWrapStyle: this.wrapStyleInMask,
                };
            }

            // 放大镜兼容数据，还是用旧的文本设置
            if (this.module.commProp.pa.mt === 1) {
                options.descStyle = this.titleDesc;
                options.titleStyle = this.titleStyle;

                // 文字样式0，只有切入特效
                if (this.descStyle === 0) {
                    options.showType = 0;
                }

                // 标题隐藏
                if (this.module.privatePattern.pc.cmdd === 0) {
                    delete options.title;
                    delete options.titleStyle;
                }

                // 描述隐藏
                if (this.module.privatePattern.pc.d2f.y === 1) {
                    delete options.desc;
                    delete options.descStyle;
                }
            } else {
                // 全遮罩特效，样式3，描述要在遮罩内显示，所以要传进去给 imgEffect 组件
                if (this.isAllMaskStyle3) {
                    delete options.title;
                    delete options.titleStyle;
                }

                // 隐藏不传标题
                if (this.module.commProp.pa.pf.y === 1) {
                    delete options.title;
                    delete options.titleStyle;
                }

                // 隐藏不传描述
                if (this.module.commProp.pa.d2f.y === 1) {
                    delete options.desc;
                    delete options.descStyle;
                }
            }

            return this.getImgComponentOption(this.module, {
                imgId: photoItem.pid,
                ...options,
                disabledBg: true,
            });
        },
        getImgComponentOption,
    },
};
