import '../monitor/index';
import { Monitor, MonitorDef } from '@/monitor/index.js';
/**
 *	需要最先加载，给后续js方法使用。
 *	原先是从页面直出的，为SEO优化改成用script标签加载。
 **/
(function (FUN) {
    var list = []; // 存储注册的需要调用的方法

    /**
     * @function 函数转换接口，用于判断函数是否存在Site中，有则调用，无则不调用
     * @version {create} 2015-11-30
     * @description
     *		用途：只设计用于延迟加载
     *		示例：Site.mytext.init(45, false);
     *		调用：Site.run({"name":"mytext.init"}, 45, false);
     *				或 Site.run({"base":window, "name":"Site.mytext.init"}, 45, false);
     *		如果不知道参数的个数，不能直接写，可以用apply的方式调用当前方法
     *		示例:  Site.run.apply(window, [ {"name":"mytext.init"}, 45, false ]);
     **/

    FUN.run = function () {
        if (arguments.length < 1) {
            throw new Error('jzUtils.run 参数错误');
        }

        // eslint-disable-next-line prefer-rest-params
        var name = arguments[0].name, // 函数名 0位为Object类型，方便做扩展
            // eslint-disable-next-line prefer-rest-params
            callMethod = arguments[0].callMethod || false, // 注册当函数可执行时，调用该函数, true:注册; false:不注册
            // eslint-disable-next-line prefer-rest-params
            prompt = arguments[0].prompt || false, // 是否显示提示语(当函数未能执行的时候)
            // eslint-disable-next-line prefer-rest-params
            promptMsg = arguments[0].promptMsg || '功能还在加载中，请稍候', // 函数未能执行提示语
            // eslint-disable-next-line prefer-rest-params
            base = arguments[0].base || (window.Fai && Fai.top.Site) || top.Site || window, // 基准对象，函数查找的起点
            // eslint-disable-next-line prefer-rest-params
            args = Array.prototype.slice.call(arguments), // 参数列表
            funcArgs = args.slice(1), // 函数的参数列表
            callbackFunc = {}, // 临时存放需要回调的函数
            result; // 检测结果

        result = checkMethod(name, base);
        if (result.success) {
            callMethod = false;
            try {
                result.func.apply(result.func, funcArgs); // apply调整函数的指针指向
            } catch (e) {
                window.console && console.log(e);
                // 对这个地方进行一个统计，后续确定是否要删掉这个try catch，因为会影响到异常监控
                Monitor.logAlarmMonitor(MonitorDef.AlarmMonitorId.JZ_UTILS);
            }
        } else {
            if (prompt) {
                window.Fai && Fai.ing(promptMsg, true);
            }
        }

        //将注册的函数缓存起来
        if (callMethod) {
            callbackFunc.name = name;
            callbackFunc.base = base;
            callbackFunc.args = funcArgs;
            list.push(callbackFunc);
        }
    };

    /**
     * @function 触发函数接口，调用已提前注册的函数
     * @param {object} option -- 需要调用的相关参数
     * @description
     *		用途：只设计用于延迟加载
     *		另外，调用trigger方法的前提是，该方法所在js已经加载并解析完毕
     *		不管触发成功与否，都会清除list中对应的项
     **/
    FUN.trigger = function (option) {
        if (typeof option !== 'object') {
            throw new Error('jzUtils.trigger 参数错误');
        }

        var funcName = option.name || '', // 函数名
            base = option.base || (window.Fai && Fai.top.Site) || top.Site || window, // 基准对象，函数查找的起点
            newList = [], // 用于更新list
            result, // 检测结果
            i, // 遍历list
            param, // 临时存储list[i]
            keepSave = option.keepSave || false; //是否缓存，true就会在有无函数的执行的时候都缓存起来

        if (funcName.length < 1) {
            return;
        }

        // 遍历list，执行对应的函数，并将其从缓存池list中删除
        for (i = 0; i < list.length; i++) {
            param = list[i];
            if (param.name == funcName) {
                result = checkMethod(funcName, base);
                if (result.success) {
                    try {
                        result.func.apply(result.func, param.args);
                    } catch (e) {
                        window.console && console.log(e);
                    }
                }
                if (keepSave) {
                    newList.push(param);
                }
            } else {
                newList.push(param);
            }
        }

        list = newList;
    };

    /**
     * @function {private} 检测方法是否可用
     * @param {string} funcName -- 方法名***.***.***
     * @param {object} base -- 方法所依附的对象
     **/
    function checkMethod(funcName, base) {
        var methodList = funcName.split('.'), // 方法名list
            readyFunc = base, // 检测合格的函数部分
            result = {
                success: true,
                func() {},
            }, // 返回的检测结果
            methodName, // 单个方法名
            i;

        for (i = 0; i < methodList.length; i++) {
            methodName = methodList[i];
            if (methodName in readyFunc) {
                readyFunc = readyFunc[methodName];
            } else {
                result.success = false;
                return result;
            }
        }

        result.func = readyFunc;
        return result;
    }
})(window.oldJzUtils || (window.oldJzUtils = {}));

//监控过滤，过滤掉大部分的外部域名链接报错
window.FAI_HAWK_EYE &&
    (window.FAI_HAWK_EYE.js_err_filter = function () {
        if (!window._store.state.manageMode && Fai.isIE6()) {
            return false;
        }
        // eslint-disable-next-line prefer-rest-params
        var event = arguments[4];
        if (event && event.innerError) {
            // site2.jsp.inc内部js报错
            return true;
        }
        // eslint-disable-next-line prefer-rest-params
        var url = arguments[1]; // js 文件类型报错。
        if (
            url &&
            ['1.ss.faisys.com/js', 'fe.faisys.com', 'jzfe.faisys.com'].some(function (item) {
                // ['1.ss.faisys.com/js', 'fe.faisys.com'] 【业务代码， 公共组件代码】
                // jzfe.faisys.com 建站公共库
                return url.indexOf(item) > -1;
            })
        ) {
            return true;
        }
        return false;
    });

//性能监控过滤，所有正常的页面访问都必须要有_cliid的cookie,没有的话就默认是刷出来的，
//理论上来说这行代码对已经存在的代码没鸟用，但对于新复制我们网站的访问还是有用的。
//还是下掉， 这个cookie在客户域名的时候是httpOnly，导致document.cookie拿不到
//window.FAI_HAWK_EYE && (document.cookie.indexOf("_cliid") === -1) && (window.FAI_HAWK_EYE.per_filter = true);

var _faiAjax = (function () {
    //for regexp
    var r = /\?/;
    var _o = {
        type: 'get',
        url: '',
        data: '',
        error() {},
        success() {},
    };
    var _sendRequest = function (o) {
        var xmlhttp = null;
        //init option code
        o.type = o.type || _o.type;
        o.url = o.url || _o.url;
        o.data = o.data || _o.data;
        o.error = o.error || _o.error;
        o.success = o.success || _o.success;
        if (window.XMLHttpRequest) {
            // code for IE7+, Firefox, Chrome, Opera, Safari
            xmlhttp = new XMLHttpRequest();
        } else {
            // code for IE6, IE5
            const { ActiveXObject } = window;
            xmlhttp = new ActiveXObject('Microsoft.XMLHTTP');
        }
        //the instructions param takes the form of an eval statement
        if (o.type != 'post') {
            o.url += (r.test(o.url) ? '&' : '?') + o.data;
            xmlhttp.open('GET', o.url, true);
            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                    o.success(xmlhttp.responseText);
                } else if (o.error) {
                    o.error();
                }
            };
            xmlhttp.send();
        } else {
            xmlhttp.open('POST', o.url, true);
            //Send the proper header information along with the request
            xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=UTF-8');
            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                    o.success(xmlhttp.responseText);
                } else {
                    o.error();
                }
            };
            xmlhttp.send(o.data);
        }
    };
    return {
        ajax(option) {
            try {
                //此次调用的错误不让抛出给window。防止函数重入
                _sendRequest(option);
            } catch (e) {
                //alert(e);
            }
        },
    };
})();

//site2.jsp.inc中迁移过来的

(function (FUN) {
    FUN.siteLinkFilter = function () {
        //console.log('siteLinkFilter');
        /* 模板网站访客态链接跳转 */
        document.addEventListener(
            'click',
            function (event) {
                // eslint-disable-next-line guard-for-in
                for (var i in event.path) {
                    var node = event.path[i];
                    if (node.nodeName === 'A') {
                        //点击A标签
                        var href = node.getAttribute('href');
                        if (!href) return;
                        if (!(href.indexOf('http:') > -1 || href.indexOf('https:') > -1 || href.indexOf('//') == 0)) {
                            // fix , 导航设置了有下级菜单时不可点击时，禁止跳转
                            if (!!node.classList.value && node.classList.value.includes('nav_cannotClick')) {
                                return;
                            }
                            //模板网站访客态点击链接时取消新页面打开（站内链接）
                            event.preventDefault();
                            location.href = href;
                        }
                    }
                }
            },
            false
        );
    };
})(window.Pre || (window.Pre = {}));

(function (FUN) {
    FUN.disableScriptTips = function () {
        //console.log('disableScriptTips');
        setTimeout(function () {
            var disableScriptTips = document.getElementById('disableScriptTips');
            var loadingBar = document.getElementById('fk-topbar-loading');
            if (loadingBar) {
                loadingBar.style.display = 'block';
            }

            if (disableScriptTips) {
                _faiAjax.ajax({
                    type: 'GET',
                    url:
                        Site.addRequestPrefix({
                            newPath: '/ajax',
                            oldPath: 'ajax',
                        }) + '/log_h.jsp?cmd=wafNotCk_dog&dogId=200057&dogSrc=%5B1%2C2%5D',
                });
            }
        }, 10000);
    };
})(window.Pre || (window.Pre = {}));

(function (FUN) {
    FUN.autoLoginMemberSign = function (lcid) {
        //console.log('autoLoginMemberSign');
        var sign = $.cookie('loginMemberSign' + lcid, { path: '/' });
        if (sign != null && sign != 'undefined') {
            $.ajax({
                type: 'post',
                url:
                    Site.addRequestPrefix({
                        newPath: '/ajax',
                        oldPath: 'ajax',
                    }) + '/member_h.jsp?cmd=getWafNotCk_autoLoginMember',
                data: 'sign=' + sign,
                success(result) {
                    result = jQuery.parseJSON(result);
                    if (result.success) {
                        window.location.reload();
                    }
                },
            });
        }
    };
})(window.Pre || (window.Pre = {}));

//错误上报
(function (FUN) {
    FUN.errorReport = function () {
        //console.log('errorReport');
        var _jsErrCahche = [];
        var cacheOnError = window.onerror;
        window.onerror = function (sMsg, sUrl, sLine, colno, event) {
            if (typeof Site == 'undefined') {
                alert('您的网页未加载完成，请尝试按“CTRL+功能键F5”重新加载。');
            }
            if (sLine < 1 || typeof sMsg != 'string' || sMsg.length < 1) {
                return;
            }

            var log = 'Error:' + sMsg + ';Line:' + sLine + ';Url:' + sUrl;
            // eslint-disable-next-line no-useless-concat
            var alertLog = 'Error:' + sMsg + '\n' + 'Line:' + sLine + '\n' + 'Url:' + sUrl + '\n';
            var encodeUrl = function (url) {
                return typeof url === 'undefined' ? '' : encodeURIComponent(url);
            };

            var ajax = true;
            var obj = { m: sMsg, u: sUrl, l: sLine };
            for (var i = 0; i < _jsErrCahche.length; i++) {
                if (_jsErrCahche[i].m == obj.m && _jsErrCahche[i].u == obj.u && _jsErrCahche[i].l == obj.l) {
                    ajax = false;
                    break;
                }
            }

            if (ajax) {
                _jsErrCahche.push(obj);
                _faiAjax.ajax({
                    type: 'post',
                    url:
                        Site.addRequestPrefix({
                            newPath: '/ajax',
                            oldPath: 'ajax',
                        }) + '/logJsErr_h.jsp?cmd=wafNotCk_jsErr',
                    data: 'msg=' + encodeUrl(log),
                });
            }
            if (Fai.top._devMode) {
                alert(alertLog);
            }
            if (typeof cacheOnError == 'function') {
                cacheOnError(sMsg, sUrl, sLine, colno, event);
            }
        };
    };
})(window.Pre || (window.Pre = {}));

(function (FUN) {
    FUN.logMallEnterFdp = function () {};
})(window.Pre || (window.Pre = {}));

window._faiAjax = _faiAjax;
