import request from '@jz/request';
import { Message } from '@shared/manage/componMessage/index.js';
/**
 * @class DataLazyload
 * @description 产品，文章，图册 数据按需加载
 * @kind Root
 * @param url {String}
 */
export const DataLazyload = class DataLazyload {
    constructor(url) {
        this.data = [];
        this.primaryKey = 'id';
        this.url = url;
        this.requireType = 'post';
        this.loadDelay = 300;
        this._init = true;
        this._timer = null;
        setTimeout(() => (this._init = false), 0); //已加载数据

        //已加载数据
        this._loadedList = {
            [this.primaryKey]: [],
        }; //请求中数据

        //请求中数据
        this._requireList = {
            [this.primaryKey]: [],
        }; //等待请求数据

        //等待请求数据
        this._waitRequireList = {
            [this.primaryKey]: [],
        }; //待刷新列表,用作刷新时使用

        //待刷新列表,用作刷新时使用
        this._waitRefreshList = {
            [this.primaryKey]: [],
        };
    }

    get() {
        //获取数据
        return this.data;
    }

    load(loadList = [], key = this.primaryKey) {
        this._require(key, loadList);

        return this.data;
    }

    addItem(item) {
        const id = item[this.primaryKey];
        const primaryLoadedList = this._loadedList[this.primaryKey];

        if (primaryLoadedList.includes(id)) {
            return;
        } //主键加入主键列表中,并通过主键列表避免数据重复

        //主键加入主键列表中,并通过主键列表避免数据重复
        primaryLoadedList.push(item[this.primaryKey]); //检查待刷新列表

        //检查待刷新列表
        if (this._waitRefreshList[this.primaryKey].includes(id)) {
            //正在等待刷新
            this._waitRefreshList[this.primaryKey] = this._waitRefreshList[this.primaryKey].filter(
                (item) => item != id
            );

            for (let i = 0; i < this.data.length; i++) {
                const o = this.data[i];

                if (o[this.primaryKey] === id) {
                    //需要刷新的那一项
                    Object.assign(o, item);
                    return;
                }
            }
        }

        window._store.dispatch('manage/pauseDataWatch');
        this.data.push(item);
    }

    refreshItem(id) {
        this._loadedList[this.primaryKey] = this._loadedList[this.primaryKey].filter((item) => item != id);

        this._waitRefreshList[this.primaryKey].push(id);

        this._require(this.primaryKey, [id], true); //刷新的时候马上执行待请求列表清空任务,会比较快一点
    }

    errMsg(msg) {
        Message.warning({
            message: msg || '系统错误，请刷新后重试',
            autoHide: false,
        });
    }

    _require(key, loadList, nowRequire = false) {
        if (!this._loadedList[key]) {
            this._loadedList[key] = [];
        }

        if (!this._requireList[key]) {
            this._requireList[key] = [];
        }

        if (!this._waitRequireList[key]) {
            this._waitRequireList[key] = [];
        }

        const loadedList = this._loadedList[key],
            requireList = this._requireList[key],
            waitRequireList = this._waitRequireList[key]; //从未请求并且未在等待队列中,则加入本次请求列表

        //从未请求并且未在等待队列中,则加入本次请求列表
        loadList = loadList.filter(
            (item) => !(loadedList.includes(item) || requireList.includes(item) || waitRequireList.includes(item))
        ); //加入等待队列

        //加入等待队列
        this._waitRequireList[key] = waitRequireList.concat(loadList);

        if (!loadList.length) {
            return;
        }

        const doRequire = () => {
            //清空等待列表
            for (const [key, loadList] of Object.entries(this._waitRequireList)) {
                if (!loadList.length) continue;

                this._doRequire(key, loadList);
            }

            this._waitRequireList = {};
        };

        if (nowRequire) {
            doRequire();
        } else {
            const loadDelay = this._init ? 0 : this.loadDelay; //初始化的时候不需要延迟

            //初始化的时候不需要延迟
            clearTimeout(this._timer);
            this._timer = setTimeout(doRequire, loadDelay);
        }
    }

    _doRequire(key, loadList) {
        return new Promise((resolve, reject) => {
            request?.[this.requireType]?.(this.url, {
                data: this.genData(key, loadList),
            })
                .then(({ data: result }) => {
                    try {
                        this.processResult(result, resolve, reject);
                    } catch (err) {
                        this.errMsg();
                        reject(err);
                    }
                })
                .catch((err) => {
                    this.errMsg('网络繁忙，请刷新后重试');
                    reject(err);
                });
            this._requireList[key] = this._requireList[key].concat(loadList);
        }).then((list) => {
            list.forEach((item) => this.addItem(item)); //加入已加载列表并从请求中列表移除

            //加入已加载列表并从请求中列表移除
            if (key != this.primaryKey) {
                //通过主键加载的已经在上面进行了添加
                this._loadedList[key] = this._loadedList[key].concat(loadList);
            }

            this._requireList[key] = this._requireList[key].filter((id) => !loadList.includes(id));
        });
    } //生成请求数据

    //生成请求数据
    genData() {
        //TODO
        return {};
    } //处理响应结果

    //处理响应结果
    processResult(result, resolve) {
        //TODO
        resolve(result);
    }
};
