var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ChildrenCSS", [
    _vm._v(
      "\n    " +
        _vm._s(_vm.firstLinkStyle) +
        "\n    " +
        _vm._s(_vm.secondLinkStyle) +
        "\n    " +
        _vm._s(_vm.thirdLinkStyle) +
        "\n\n    " +
        _vm._s(_vm.secondFontStyle) +
        "\n    " +
        _vm._s(_vm.thirdFontStyle) +
        "\n\n    " +
        _vm._s(_vm.selectBgStyle) +
        "\n    " +
        _vm._s(_vm.firstLinkHoverStyle) +
        "\n    " +
        _vm._s(_vm.secondLinkHoverStyle) +
        "\n    " +
        _vm._s(_vm.thirdLinkHoverStyle) +
        "\n\n    " +
        _vm._s(_vm.moduleRadiusStyle) +
        "\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }