import { warningMessage } from '@/site/shared/dialog/index.js';
import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

/*
	mobi专属方法
*/

/**
 * Helper function to parse the user agent.  Sets the following
 * .os.webkit
 * .os.android
 * .os.ipad
 * .os.iphone
 * .os.webos
 * .os.touchpad
 * .os.blackberry
 * .os.opera
 * .os.fennec
 * .os.ie
 * .os.ieTouch
 * .os.supportsTouch
 * .os.playbook
 * .feat.nativetouchScroll
 * @api private
 */
const detectUA = function (jm) {
    var userAgent = navigator.userAgent;
    jm.os = {};
    jm.os.MAC = userAgent.match(/Mac/) ? true : false;
    jm.os.WINDOW_DESKTOP = userAgent.match(/Windows NT/i) && !userAgent.match(/IEMobile/i) ? true : false;
    jm.os.webkit = userAgent.match(/WebKit\/([\d.]+)/) ? true : false;
    jm.os.android = userAgent.match(/(Android)\s+([\d.]+)/) || userAgent.match(/Silk-Accelerated/) ? true : false;
    jm.os.androidICS = jm.os.android && userAgent.match(/(Android)\s4/) ? true : false;
    jm.os.ipad = userAgent.match(/(iPad).*OS\s([\d_]+)/) ? true : false;
    jm.os.iphone = !jm.os.ipad && userAgent.match(/(iPhone\sOS)\s([\d_]+)/) ? true : false;
    jm.os.webos = userAgent.match(/(webOS|hpwOS)[\s/]([\d.]+)/) ? true : false;
    jm.os.touchpad = jm.os.webos && userAgent.match(/TouchPad/) ? true : false;
    jm.os.ios = jm.os.ipad || jm.os.iphone;
    jm.os.playbook = userAgent.match(/PlayBook/) ? true : false;
    jm.os.blackberry = jm.os.playbook || userAgent.match(/BlackBerry/) ? true : false;
    jm.os.blackberry10 = jm.os.blackberry && userAgent.match(/Safari\/536/) ? true : false;
    jm.os.chrome = userAgent.match(/Chrome/) ? true : false;
    jm.os.safari =
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/) ? true : false;

    var ios = iOSversion();
    jm.os.IOS_5_0 = ios[0] === 5 && ios[1] === 0 ? true : false;
    jm.os.IOS_5_1 = ios[0] === 5 && ios[1] === 1 ? true : false;
    jm.os.IOS_6_0 = ios[0] === 6 && ios[1] === 0 ? true : false;
    jm.os.IOS_6_1 = ios[0] === 6 && ios[1] === 1 ? true : false;
    jm.os.IOS_7_0 = ios[0] === 7 && ios[1] === 0 ? true : false;
    jm.os.IOS_7_1 = ios[0] === 7 && ios[1] === 1 ? true : false;
    jm.os.IOS_8_0 = ios[0] === 8 && ios[1] === 0 ? true : false;
    jm.os.IOS_8_1 = ios[0] === 8 && ios[1] === 1 ? true : false;

    var android = androidVersion();
    jm.os.ANDROID_2_3 = android[0] === 2 && android[1] === 3 ? true : false;
    jm.os.ANDROID_4_0 = android[0] === 4 && android[1] === 0 ? true : false;
    jm.os.ANDROID_4_1 = android[0] === 4 && android[1] === 1 ? true : false;
    jm.os.ANDROID_4_2 = android[0] === 4 && android[1] === 2 ? true : false;
    jm.os.ANDROID_4_3 = android[0] === 4 && android[1] === 3 ? true : false;
    jm.os.ANDROID_4_4 = android[0] === 4 && android[1] === 4 ? true : false;
    jm.os.ANDROID_5_0 = android[0] === 5 && android[1] === 0 ? true : false;

    jm.os.WXBrowser = userAgent.match(/micromessenger/i) ? true : false;
    jm.os.UCBrowser = userAgent.match(/UCBrowser/i) ? true : false;
    jm.os.MQQBrowser = userAgent.match(/MQQBrowser/i) ? true : false;
    jm.os.LieBaoFast = userAgent.match(/LieBaoFast/i) ? true : false;
    jm.os.BaiDuBrowser = userAgent.match(/baidubrowser|BIDUBrowser/i) ? true : false;
    jm.os.Sougou = userAgent.match(/Sougou/i) ? true : false;
    jm.os.B360Browser = userAgent.match(/360/) ? true : false;

    jm.os.opera = userAgent.match(/Opera/i) ? true : false;
    jm.os.fennec = userAgent.match(/fennec/i) ? true : userAgent.match(/Firefox/) ? true : false;
    jm.os.ie = (userAgent.match(/Trident/i) && userAgent.match(/rv:11/i)) || userAgent.match(/MSIE/i) ? true : false;
    jm.os.ie9 = userAgent.match(/MSIE 9.0/i) ? true : false;
    jm.os.ie10 = userAgent.match(/MSIE 10.0/i) ? true : false;
    //IE11的UA删除了之前一直使用的MSIE字段，相关资料：https://msdn.microsoft.com/en-us/library/ie/hh869301(v=vs.85).aspx
    jm.os.ie11 =
        (userAgent.match(/Trident/i) && userAgent.match(/rv:11/i)) || userAgent.match(/MSIE 11.0/i) ? true : false;
    jm.os.ieTouch = jm.os.ie && userAgent.toLowerCase().match(/touch/i) ? true : false;
    jm.os.supportsTouch =
        (window.DocumentTouch && document instanceof window.DocumentTouch) ||
        'ontouchstart' in document.documentElement;
    //features
    jm.feat = {};
    var head = document.documentElement.getElementsByTagName('head')[0];
    jm.feat.nativeTouchScroll = typeof head.style['-webkit-overflow-scrolling'] !== 'undefined' && jm.os.ios;
    jm.feat.cssPrefix = jm.os.webkit ? 'Webkit' : jm.os.fennec ? 'Moz' : jm.os.ie ? 'ms' : jm.os.opera ? 'O' : '';
    jm.feat.cssTransformStart = !jm.os.opera ? '3d(' : '(';
    jm.feat.cssTransformEnd = !jm.os.opera ? ',0)' : ')';
    if (jm.os.android && !jm.os.webkit) {
        jm.os.android = false;
    }

    function iOSversion() {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
            return v ? [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)] : [0, 0];
        }
        return [0, 0];
    }

    function androidVersion() {
        if (/Android/.test(navigator.userAgent)) {
            var v = navigator.userAgent.match(/Android (\d+).(\d+).?(\d+)?/);
            return v ? [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)] : [0, 0];
        }
        return [0, 0];
    }
};

/*
type 1:电话服务、2:信息服务
*/
const triggerServiceNumber = function (type, number) {
    function format() {
        var s = arguments[0];
        for (var i = 0; i < arguments.length - 1; i++) {
            var reg = new RegExp('%d', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    }

    if (window.Mobi.os == undefined) {
        detectUA(window.Mobi);
    }
    if (window.Mobi.os.supportsTouch) {
        if (type == 1) {
            window.location.href = 'tel:' + number;
        } else if (type == 2) {
            window.location.href = 'sms:' + number;
        }
    } else {
        if (window._store.state.manageMode) {
            Vue?.$famessage?.warning(LS.notSupportPc || '', 3, null, true);
        } else {
            if (type == 1) {
                warningMessage(format(LS.telConsultation || '', Fai.encodeHtml(number)), false, 'faisco-icons-M001095');
            } else {
                warningMessage(LS.notSupportPc || '', false, 'faisco-icons-M001095');
            }
        }
    }
};

export { detectUA, triggerServiceNumber };
