var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "column_product_list" },
    _vm._l(_vm.list, function (product) {
      return _c(
        "div",
        {
          key: product.id,
          ref: "products",
          refInFor: true,
          staticClass: "column_product",
          on: {
            click: function ($event) {
              return _vm.goPage(product.url)
            },
          },
        },
        [
          _c("div", { staticClass: "column_product_left" }, [
            _c("div", { staticClass: "product_icon" }, [
              _c("svg", { staticClass: "product_icon_svg" }, [
                _c("use", { attrs: { "xlink:href": _vm.getSvgHref(product) } }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "product_title", attrs: { title: product.name } },
              [_vm._v(_vm._s(product.name))]
            ),
          ]),
          _vm._v(" "),
          _vm._m(0, true),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column_product_right" }, [
      _c("div", { staticClass: "product_study" }, [_vm._v("立即学习")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }