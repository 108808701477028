var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comment_wrapper" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "fk-circle",
      },
      [
        _c("div", { staticClass: "fk-circle1 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle2 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle3 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle4 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle5 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle6 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle7 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle8 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle9 fk-child" }),
      ]
    ),
    _vm._v(" "),
    _vm.commentList.length > 0
      ? _c("div", { staticClass: "detail_mark_panel" }, [
          _c("div", { staticClass: "detail_mark_sacle" }, [
            _c("span", { staticClass: "mark_tip" }, [
              _vm._v(_vm._s(_vm.pd_praiseDegree)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "mark_sacle jz_theme_font_color" }, [
              _vm._v(_vm._s(_vm.markDegree)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mark_splite" }),
          _vm._v(" "),
          _c("div", { staticClass: "detail_mark_sacle_line_wrap" }, [
            _c(
              "div",
              {
                staticClass:
                  "detail_scale_block jz_theme_bg_color jz_theme_font_color",
                style: _vm.scaleBlockStyle,
              },
              [
                _c("span", { staticClass: "detail_scale_block_text" }, [
                  _vm._v(_vm._s(_vm.markDegree) + "%"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "detail_mark_sacle_line" }, [
              _c("span", {
                staticClass: "jz_theme_bg_color detail_sacle_1",
                attrs: { "data-satisfy": _vm.ls.pd_veryNotSatisfy },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "jz_theme_bg_color detail_sacle_2",
                attrs: { "data-satisfy": _vm.ls.pd_notSatisfy },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "jz_theme_bg_color detail_sacle_3",
                attrs: { "data-satisfy": _vm.ls.pd_midSatisfy },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "jz_theme_bg_color detail_sacle_4",
                attrs: { "data-satisfy": _vm.ls.pd_satisfy },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "jz_theme_bg_color detail_sacle_5",
                attrs: { "data-satisfy": _vm.ls.pd_verySatisfy },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "detail_comment_mobi" }, [
            _c("i", { staticClass: "comment_icon faisco-icons-S000261" }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.commentLoc.writeComment))]),
          ]),
        ])
      : _c("div", { staticClass: "detail_mark_panel dt_no_comment" }, [
          _c("div", { staticClass: "detail_comment_mobi" }, [
            _c("i", { staticClass: "comment_icon faisco-icons-S000261" }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.commentLoc.writeComment))]),
          ]),
        ]),
    _vm._v(" "),
    _vm.notAllowComm
      ? _c("div", { staticClass: "detail_comment_panel" }, [
          _c("span", [_vm._v(_vm._s(_vm.noticeWord))]),
        ])
      : _c(
          "div",
          { staticClass: "detail_comment_panel" },
          [
            _vm._l(_vm.commentList, function (item, i) {
              return _c("div", { key: i, staticClass: "comment_item" }, [
                _c("div", { staticClass: "flex-comment" }, [
                  _c("div", { staticClass: "comment_head_pic" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazyload2",
                          rawName: "v-lazyload2",
                          value: { id: _vm.moduleId, src: item.memberHeadPic },
                          expression:
                            "{ id: moduleId, src: item.memberHeadPic }",
                        },
                      ],
                      attrs: {
                        src: _vm.isMaterialLazyLoad ? item.memberHeadPic : null,
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "comment_content" }, [
                    _c("div", { staticClass: "comment_menber_info" }, [
                      _c("span", { staticClass: "menber_name" }, [
                        _vm._v(_vm._s(item.memberName)),
                      ]),
                      _vm._v(" "),
                      item.menberId != 0
                        ? _c("span", {
                            staticClass: "menber_level_icon",
                            class: item.iconClassName,
                            style: _vm.memberIcon(item.color),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "menber_comment_star" },
                        [
                          _c("commentFiveStar", {
                            attrs: {
                              index: 1,
                              star: item.star,
                              "creat-comment": false,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "comment_menber_text",
                      domProps: {
                        innerHTML: _vm._s(_vm.encodeHtml(item.comment)),
                      },
                    }),
                    _vm._v(" "),
                    !!item.commImgListParam.length
                      ? _c(
                          "div",
                          { staticClass: "comment_menber_pic" },
                          [
                            _vm._l(item.commImgListParam, function (list) {
                              return _c(
                                "span",
                                {
                                  key: list.index,
                                  staticClass:
                                    "menber_small_pic_wrap jz_theme_border_hover_color jz_hover",
                                  attrs: { index: list.index },
                                },
                                [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "lazyload2",
                                        rawName: "v-lazyload2",
                                        value: {
                                          id: _vm.moduleId,
                                          src: list.path,
                                        },
                                        expression:
                                          "{ id: moduleId, src: list.path }",
                                      },
                                    ],
                                    attrs: {
                                      src: _vm.isMaterialLazyLoad
                                        ? list.path
                                        : null,
                                      view: list.data,
                                    },
                                  }),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm._m(0, true),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.other.reComment
                      ? _c("div", { staticClass: "reply-from-merchant" }, [
                          _c("div", { staticClass: "reply-line" }, [
                            _c("span", { staticClass: "reply-title" }, [
                              _vm._v(_vm._s(_vm.bossReply) + "："),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "reply-time" }, [
                              _vm._v(_vm._s(item.other.reCommentDate)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "reply-content",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.encodeContent(item.other.reComment)
                              ),
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "comment_time" }, [
                    _vm._v(_vm._s(item.createDate)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-comment" }, [
                  item.other.reComment
                    ? _c("div", { staticClass: "mobi-reply-from-merchant" }, [
                        _c("div", { staticClass: "reply-line" }, [
                          _c("span", { staticClass: "reply-title" }, [
                            _vm._v(_vm._s(_vm.bossReply) + "："),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "reply-time" }, [
                            _vm._v(_vm._s(item.other.reCommentDate)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "reply-content",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.encodeContent(item.other.reComment)
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ])
            }),
            _vm._v(" "),
            _vm.commentList.length == 0
              ? _c("div", { staticClass: "comment_content_empty" }, [
                  _c("i", { staticClass: "empty_img" }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "comment_empty_text" }, [
                    _vm._v(_vm._s(_vm.hasNoComment)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.productCommentInfo.totalPage > 1
              ? _c("div", { staticClass: "comment_pagenation" }, [
                  _c(
                    "div",
                    {
                      staticClass: "pagenation jz_pagenation_wrap",
                      attrs: { id: "pagenation4" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "page_scroll jz_page_prev",
                          style:
                            _vm.productCommentInfo.curPage === 1
                              ? _vm.disableStyle
                              : _vm.allowStyle,
                          on: {
                            click: function ($event) {
                              return _vm.changePage("prev")
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm.productCommentInfo.curPage !== 1
                              ? _c("span", [_vm._v("<<")])
                              : _vm._e(),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.slidePre) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.paginationList, function (page) {
                        return _c(
                          "div",
                          {
                            key: page,
                            staticClass: "jz_page_number",
                            style:
                              _vm.productCommentInfo.curPage === page ||
                              page === "..."
                                ? _vm.disableStyle
                                : _vm.allowStyle,
                            on: {
                              click: function ($event) {
                                return _vm.getCommentInfo(page)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                class: {
                                  jz_theme_font_color:
                                    _vm.productCommentInfo.curPage === page,
                                },
                              },
                              [_vm._v(_vm._s(page))]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "jz_page_scroll jz_page_next",
                          style:
                            _vm.productCommentInfo.curPage ===
                            _vm.productCommentInfo.totalPage
                              ? _vm.disableStyle
                              : _vm.allowStyle,
                          on: {
                            click: function ($event) {
                              return _vm.changePage("next")
                            },
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.slideNext) +
                                "\n                        "
                            ),
                            _vm.productCommentInfo.curPage !==
                            _vm.productCommentInfo.totalPage
                              ? _c("span", [_vm._v(">>")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ],
          2
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "menber_pic_viewer" }, [
      _c("img", { staticClass: "pic_viewer" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }