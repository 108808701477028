var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "audioBtn",
      staticClass: "flv_audio_btn banner_video_playBtn-online",
      class: { "banner_video_playBtn-paused": _vm.isVideoMute },
      on: { click: _vm.toggleMute },
    },
    [_c("div", { staticClass: "banner_video_default", style: _vm.btnStyle })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }