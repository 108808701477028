var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "timelineHorizontalEl", staticClass: "timeline_text_horizontal" },
    [
      _c(
        "div",
        {
          staticClass: "timeline_text_horizontal_scroll",
          style: _vm.scrollContainerStyle,
        },
        [
          _c(
            "div",
            { staticClass: "timeline_text_horizontal_top" },
            _vm._l(_vm.timelineItems, function (item, index) {
              return _c(
                "div",
                { key: item.id, staticClass: "timeline_text_h_top_content" },
                [
                  !_vm.isCrossStyle ||
                  (_vm.isEvenNumber(index) && _vm.isCrossStyle)
                    ? [
                        _c("timeline-item-time", {
                          staticClass: "timeline_h_top_time",
                          attrs: { id: item.id, value: item.time },
                        }),
                      ]
                    : [
                        _c("timeline-item-title", {
                          staticClass: "timeline_text_h_item_title",
                          attrs: { id: item.id, value: item.title },
                        }),
                        _vm._v(" "),
                        _c("timeline-item-desc", {
                          staticClass:
                            "timeline_text_h_item_desc timeline_h_top_desc",
                          class: [item.title === "" ? "mt0" : ""],
                          attrs: { id: item.id, value: item.desc },
                        }),
                        _vm._v(" "),
                        _c("timeline-item-connect-content-line", {
                          staticClass:
                            "timeline_text_h_item_content_line timeline_h_top_cline",
                        }),
                      ],
                ],
                2
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "timeline_text_horizontal_center" },
            _vm._l(_vm.timelineItems, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "timeline_text_h_item_dot_wrap" },
                [
                  _c("timeline-item-line", {
                    staticClass: "timeline_text_h_item_line",
                  }),
                  _vm._v(" "),
                  _c("timeline-item-dot", {
                    staticClass: "timeline_text_h_item_dot",
                  }),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "timeline_text_horizontal_bottom" },
            _vm._l(_vm.timelineItems, function (item, index) {
              return _c(
                "div",
                { key: item.id, staticClass: "timeline_text_h_item_content" },
                [
                  !_vm.isCrossStyle ||
                  (_vm.isEvenNumber(index) && _vm.isCrossStyle)
                    ? [
                        _c("timeline-item-connect-content-line", {
                          staticClass:
                            "timeline_text_h_item_content_line timeline_h_bottom_cline",
                        }),
                        _vm._v(" "),
                        _c("timeline-item-title", {
                          staticClass: "timeline_text_h_item_title",
                          attrs: { id: item.id, value: item.title },
                        }),
                        _vm._v(" "),
                        _c("timeline-item-desc", {
                          staticClass: "timeline_text_h_item_desc",
                          class: [item.title === "" ? "mt0" : ""],
                          attrs: { id: item.id, value: item.desc },
                        }),
                      ]
                    : [
                        _c("timeline-item-time", {
                          staticClass: "timeline_text_h_bottom_title",
                          attrs: { id: item.id, value: item.time },
                        }),
                      ],
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showPrevArrow
        ? _c("timeline-arrow", {
            key: "prev",
            staticClass: "timeline_text_horizontal_prev",
            style: _vm.arrowStyle,
            attrs: { type: "prev" },
            nativeOn: {
              click: function ($event) {
                return _vm.onPrev.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showNextArrow
        ? _c("timeline-arrow", {
            key: "next",
            staticClass: "timeline_text_horizontal_next",
            style: _vm.arrowStyle,
            attrs: { type: "next" },
            nativeOn: {
              click: function ($event) {
                return _vm.onNext.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }