import { Module } from '@shared/visitor/module/index.js';

/**
 * 产品展示
 */

const productList = class ProductList extends Module {
    constructor(...args) {
        super(...args);
        this.init();
    }

    init() {
        this.initSlider();
    }

    initSlider() {
        //if(this.conf.prop0 === 1){
        //点击箭头
        const $list = this.$el.find('.exhibition_show_products'),
            that = this;
        this.$el.off('click.prev').on('click.prev', '.prev', () => {
            if ($list.is(':animated')) {
                return;
            }
            $list.animate(
                {
                    scrollLeft: $list.scrollLeft() - $list.width(),
                },
                this.initArrowState(that)
            );
            //this.initArrowState();
        });
        this.$el.off('click.next').on('click.next', '.next', () => {
            if ($list.is(':animated')) {
                return;
            }
            $list.animate(
                {
                    scrollLeft: $list.scrollLeft() + $list.width(),
                },
                this.initArrowState(that)
            );
            //this.initArrowState();
        });
        this.initArrowState()();
        //}
    }

    initArrowState(that) {
        const _this = that || this;
        return function () {
            const $content = _this.$el.find('.product_list_content'),
                $list = _this.$el.find('.exhibition_show_products'),
                $product = $list.find('.exhibition_show_product_wrap'),
                $next = $content.find('.next'),
                $prev = $content.find('.prev');
            if ($product.length <= 1) {
                return;
            }
            if ($product.eq(0).position().left >= 0) {
                // 最左端
                $prev.addClass('pd_no_scroll');
            } else {
                $prev.removeClass('pd_no_scroll');
            }

            if ($product.eq($product.length - 1).position().left <= $content.width()) {
                // 最右端
                $next.addClass('pd_no_scroll');
            } else {
                $next.removeClass('pd_no_scroll');
            }
        };
    }
};

export { productList };
