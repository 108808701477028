var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m_member_signup_item J_memberSignupCaptcha" },
    [
      _c("label", { staticClass: "m_member_signup_label" }, [
        _c(
          "div",
          {
            staticClass: "m_member_signup_text",
            attrs: { title: _vm.LS.msgBoardValidateCode },
          },
          [_vm._v(_vm._s(_vm.LS.msgBoardValidateCode))]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "m_member_signup_suffix" }, [
          _vm._v(_vm._s(_vm.conlon)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m_member_signup_control" }, [
        _c("div", { staticClass: "m_member_signup_box" }, [
          _c("input", {
            staticClass: "m_member_signup_input jz_theme_focus_border_color",
            attrs: {
              id: "memberSignupCaptcha",
              type: "text",
              placeholder:
                (_vm.LS.mobi_member_PleaseInput || "") +
                _vm.LS.msgBoardValidateCode,
              maxlength: _vm.captchaMaxlen,
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "m_member_signup_captcha J_memberSignupCaptchaImg",
            attrs: { src: _vm.validateCodeLink() },
            on: { click: _vm.captchaClick },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }