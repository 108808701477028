/**
 * 灰度开关，请及时清理
 */

export default {
    namespaced: true,
    state: () => ({}),
    mutations: {
        setGrayTestData() {},
    },
};
