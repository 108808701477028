var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "timeline_item_connect_content_line jz_theme_bg_color",
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }