var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ChildrenCSS",
    _vm._l(_vm.ssrModules, function (m) {
      return _c(_vm.getComponent(m), {
        key: m.id,
        tag: "component",
        attrs: { module: m },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }