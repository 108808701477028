import { getIsOpenRemoveUrlArg, handleSetUrlArgToCookie } from '@/site/shared/cookie/index.js';
import { device } from '@/site/shared/device/index.js';

let banner = {};
function isPcDevice() {
    return Comm.getDevice() === 'pc';
}
banner.config = {
    update() {
        banner.bannerInfo =
            (window._store.state.manageMode && window._store.state.responsiveInfo.banner) || banner.bannerInfo;
        let type = isPcDevice() ? banner.bannerInfo.pc : banner.bannerInfo.mbct,
            showTime = isPcDevice() ? banner.bannerInfo.pcst : banner.bannerInfo.mbcst;

        function getAnimateTime() {
            let type = isPcDevice() ? banner.bannerInfo.pc : banner.bannerInfo.mbct;
            return type == 1 ? banner.bannerInfo.pccs * 1000 : Comm.getDevice() == 'pc' ? 1500 : 600;
        }

        function getIntervalTime() {
            let type = isPcDevice() ? banner.bannerInfo.pc : banner.bannerInfo.mbct,
                showTime = isPcDevice() ? banner.bannerInfo.pcst : banner.bannerInfo.mbcst;
            return getAnimateTime() + (type == 1 ? showTime * 1000 : 4500);
        }

        return {
            picLen: $('.banner_pic_group:visible .banner_pic_item_wrap').length,
            scrollWidth: 100,
            animateTime: getAnimateTime,
            showTime: type == 1 ? showTime * 1000 : 4500,
            intervalTime: getIntervalTime,
            style: banner.bannerInfo.s,
            autoAnimate: type != 2,
            curIndex: 0,
            timer: 0,
        };
    },
    timer: 0,
    setTimer(val) {
        this.timer = val;
    },
    getTimer() {
        return this.timer;
    },
    clearTimer() {
        clearTimeout(this.timer);
        this.setTimer(0);
    },
    curIndex: 0,
    setCurIndex(val) {
        return (this.curIndex = val);
    },
    getCurIndex() {
        return this.curIndex;
    },
};

banner.arrowSwitch = function (info) {
    banner.bannerInfo =
        banner.bannerInfo || info || (window._store.state.responsiveInfo && window._store.state.responsiveInfo.banner);

    var config = banner.config.update(),
        scrollWidth = config.scrollWidth,
        $banner = $('.jz_banner'),
        $bannerGroup = $('.banner_pic_group:visible'),
        $bannerItem = $('.banner_pic_group:visible .banner_pic_item_wrap'),
        _shortLine = $('.pic_short_line_btn:visible'),
        curIndex = 0,
        shortLineActivity = 'pic_short_line_btn_activity',
        visibleBannerZoneStr = '.banner_pic_group:visible ';

    function nextClick() {
        var picLen = $(visibleBannerZoneStr + '.banner_pic_item_wrap').length,
            _shortLine = $('.pic_short_line_btn:visible');

        if ($bannerItem.is(':animated') || picLen <= 1) {
            return false;
        }
        curIndex = banner.config.getCurIndex();
        var to = 0;
        if (curIndex == picLen - 1) {
            // 上限
            curIndex = -1;
        }
        to = curIndex + 1;
        curIndex = to == 0 ? picLen - 1 : curIndex;
        slide(to, 1, curIndex);
        if (_shortLine.length) {
            _shortLine.eq(to).addClass(shortLineActivity).siblings().removeClass(shortLineActivity);
        }
    }

    function prevClick() {
        var picLen = $(visibleBannerZoneStr + '.banner_pic_item_wrap').length;

        if ($bannerItem.is(':animated') || picLen <= 1) {
            return false;
        }
        var to = 0;
        if (curIndex == 0) {
            // 上限
            curIndex = picLen;
        }
        to = curIndex - 1;
        curIndex = to == picLen - 1 ? 0 : curIndex;
        slide(to, -1, curIndex);
    }

    function initMobiBannerTouch() {
        $banner.off('swipeleft.l').on('swipeleft.l', '.banner_pic_group:visible', function () {
            nextClick(); // 向右划动事件
        });
        $banner.off('swiperight.r').on('swiperight.r', '.banner_pic_group:visible', function () {
            prevClick(); // 向左划动事件
        });
    }

    function setup() {
        //

        update();

        var picLen = $(visibleBannerZoneStr + '.banner_pic_item_wrap').length;

        if (picLen < 2) {
            return;
        }

        $banner.off('click.next').on('click.next', '.pic_arrow_btn_next', nextClick);
        $banner.off('click.prev').on('click.prev', '.pic_arrow_btn_prev', prevClick);
        $banner.off('click.s', '.pic_short_line_btn').on('click.s', '.pic_short_line_btn:visible', dotSilde);

        // mobi
        initMobiBannerTouch();

        if (config.autoAnimate) {
            autoPlay();
            initAutoPlayEvent();
        } else {
            kill();
        }
    }

    function update(index) {
        //初始化位置
        if (typeof index != 'undefined') {
            curIndex = banner.config.setCurIndex(index);
        } else {
            curIndex = banner.config.getCurIndex();
        }

        var picLen = $(visibleBannerZoneStr + '.banner_pic_item_wrap').length;
        if (curIndex + 1 > picLen) {
            curIndex = banner.config.setCurIndex(0);
        }

        $(visibleBannerZoneStr + '.banner_pic_item_wrap')
            .stop()
            .css('left', '-100%')
            .removeClass('banner_item_actived');
        $(visibleBannerZoneStr + '.banner_pic_' + curIndex)
            .stop()
            .css('left', '0')
            .addClass('banner_item_actived');
        $('.pic_short_line_btn:visible')
            .eq(curIndex)
            .addClass('pic_short_line_btn_activity')
            .siblings()
            .removeClass('pic_short_line_btn_activity');
    }

    function dotSilde(e) {
        if ($bannerItem.is(':animated')) return false;
        var $this = $(e.currentTarget),
            curIndex = parseInt($('.pic_short_line_btn_activity:visible').attr('index') || 0), // 自动播放时，如果再拿全局的curIndex，是0，why
            to = parseInt($this.attr('index') || 0);

        banner.config.setCurIndex(curIndex);
        slide(to, false, curIndex);
        $this.addClass(shortLineActivity).siblings().removeClass(shortLineActivity);
    }

    function slide(to, direction, currentIndex) {
        curIndex = currentIndex != undefined ? currentIndex : curIndex;
        if (curIndex == to) return;
        if ($bannerItem.is(':animated')) return false;

        direction = direction || Math.abs(to - curIndex) / (to - curIndex); // 1: backward, -1: forward

        $(function () {
            var $curBannerItem = $(visibleBannerZoneStr + '.banner_pic_' + curIndex),
                $toBannerItem = $(visibleBannerZoneStr + '.banner_pic_' + to),
                dir = direction == -1 ? '-' : '',
                allDir = direction == -1 ? '+=' : '-=';

            $(visibleBannerZoneStr + '.banner_pic_item_wrap')
                .css('left', '500%')
                .removeClass('banner_item_actived');
            $curBannerItem.css('left', '0');
            $toBannerItem.css('left', dir + scrollWidth + '%');
            $(visibleBannerZoneStr + '.banner_pic_item_wrap').animate(
                { left: allDir + scrollWidth + '%' },
                {
                    duration: config.animateTime(),
                    complete() {
                        //publishAnimation(); 新改造的动画 轮播自动播放不在手动触发
                    },
                }
            );

            curIndex = to;
            banner.config.setCurIndex(curIndex);
            $(visibleBannerZoneStr + '.banner_pic_' + to).addClass('banner_item_actived');
            if (_shortLine.length) {
                _shortLine.eq(to).addClass(shortLineActivity).siblings().removeClass(shortLineActivity);
            }
        });
    }

    var interval;

    function begin() {
        if (window._store.state.manageMode) {
            //管理态需要在某些情况下阻止自动轮播

            //编辑模块
            let moduleId;

            const editPanel = window._store.state.manage.editPanel;
            if (editPanel && editPanel.active) {
                if (editPanel.type === 'module') {
                    //编辑普通模块
                    moduleId = editPanel.id;
                } else if (typeof editPanel.data === 'object') {
                    //可能是常用模块等小面板的
                    moduleId = editPanel.data.id;
                }
            }

            if (window._store.state.manage.editPanel.moduleEditorActiveId) {
                //编辑横幅里面的文本按钮等
                moduleId = window._store.state.manage.editPanel.moduleEditorActiveId;
            }

            if (moduleId && $(`#module${moduleId}`).parents('.jz_banner').length) {
                return;
            }

            //横幅里的模块出现工具栏
            for (const { el } of Root.vm.hoverToolbar) {
                if ($(el).parents('.jz_banner').length) {
                    return;
                }
            }

            //页面弹出了弹窗
            if ($('.fk-popupWindowVT').length) {
                return;
            }

            //在拖动模块
            if ($('.ui-sortable-helper').length) {
                return;
            }

            //准备添加模块
            if ($('.jz_add_drag_placeholder').length) {
                return;
            }

            //调整模块大小和位置
            if ($('#popupBgJzOperatingProtect').length) {
                return;
            }
        }

        interval = setTimeout(nextClick, 0);
    }

    function autoPlay() {
        var timer = banner.config.getTimer();
        // 干掉上一个定时器
        banner.config.clearTimer(timer);
        timer = setInterval(function () {
            begin();
        }, config.intervalTime());
        // 记录当前计时器
        banner.config.setTimer(timer);
    }

    function initAutoPlayEvent() {
        if (config.autoAnimate) {
            $bannerGroup.off('mouseover.m').on('mouseover.m', function () {
                banner.config.clearTimer();
            });
            $bannerGroup.off('mouseout.m').on('mouseout.m', function () {
                autoPlay();
            });
        } else {
            $bannerGroup.off('mouseover.m');
            $bannerGroup.off('mouseout.m');
        }
    }

    function stop() {
        clearTimeout(interval);
    }

    function kill() {
        banner.config.clearTimer();
    }

    return {
        setup() {
            setup();
        },
        slide(to, flag, curIndex) {
            stop();
            slide(to, flag, curIndex);
        },
        prev() {
            stop();
            prevClick();
        },
        next() {
            stop();
            nextClick();
        },
        kill() {
            kill();
        },
        autoPlay() {
            autoPlay();
            initAutoPlayEvent();
        },
        update(index) {
            update(index);
        },
    };
};

banner.fixBannerOverflowHeight = function func() {
    if (
        window._store.state.fullScreen &&
        (Comm.getDevice() === 'pc' || window._store.state.responsiveInfo.pattern.rollingScreen.isMobiSync)
    )
        return; // 滚动全屏栏目不fix横幅高度
    const $banner = $('#jz_banner'),
        $picGroup = $banner.find('.banner_pic_group,.banner_flv_area'),
        $rows = $picGroup.find('.jz_web_row__content:visible'),
        initHeight = $picGroup.height();
    let maxFixHeight = 0;
    let maxRowHeight = 0;
    $rows.each(function () {
        const $col = $(this).find('.web_col_content');
        $col.css('min-height', 'auto');
        const rowHeight = $(this).height() - initHeight; //行的真正高度
        const colHeight = $col.height(); //列的高度(在从小变大的时候，列因为有min-height:100%所以会很大)
        const fixHeight = colHeight - rowHeight;
        if (fixHeight > maxFixHeight) {
            maxFixHeight = fixHeight;
        }
        if (colHeight > maxRowHeight) {
            maxRowHeight = colHeight;
        }
        $col.css('min-height', '');
    });
    if (maxFixHeight > 0) {
        $picGroup.height(maxFixHeight);
    } else {
        // 内容区高度未超出横幅高度 则去掉高度值 【自适应横幅优化】 https://www.tapd.cn/42954297/prong/stories/view/1142954297001069420
        $picGroup.css('height', '');
    }
    func._fixHeight = maxRowHeight;
    if (Root._setBannerFixHeight) {
        Root._setBannerFixHeight(maxRowHeight);
    }
};

banner.init = function () {
    const $banner = $('#jz_banner');

    (!window._store.state.fullScreen || Comm.getDevice() === 'mobi') && banner.listenFixBannerHeight();
    $banner.off('click.moduleStopPropagation').on('click.moduleStopPropagation', '.jz_module', function (e) {
        if (!e.originalEvent) return;
        for (let el of e.originalEvent.path) {
            //遍历冒泡过程
            if (el.nodeName == 'A' && el.href) {
                //如果在jz_module里找到a标签则不阻止默认行为
                break;
            }
            if (el.className.includes('jz_module')) {
                //只找到jz_module这一层，如果还没有a标签则阻止默认行为
                e.preventDefault();
                break;
            }
        }
        e.stopPropagation();
    });
};

banner.listenFixBannerHeight = function () {
    banner.fixBannerOverflowHeight();
    setTimeout(function () {
        banner.fixBannerOverflowHeight();
    }, 0);
    $(window)
        .off('resize.listenBanner')
        .on('resize.listenBanner', () => {
            banner.fixBannerOverflowHeight();
        });
    // 监听设备变化，对横幅进行调整
    if (window._store.state.manageMode) {
        banner._unWatchDevice && banner._unWatchDevice();
        banner._unWatchDevice = window._store.watch(
            (state) => state.device,
            () => {
                Vue.nextTick(() => {
                    //节点更变开始后持续fix，直至动画结束
                    //fix间隔为每秒60帧的一半（30帧）
                    const timer = setInterval(() => {
                        banner.fixBannerOverflowHeight();
                    }, (1000 / 60) * 2);
                    device.on(device.EVENTS.ON_CHANGE_AFTER_TRANSITION, () => {
                        clearTimeout(timer);
                    });
                });
            }
        );
    }
};

banner.openLink = function (url, is_blank, event, reqArgs) {
    url = Fai.decodeUrl(url);
    for (var index in event.path) {
        // 如果点击了横幅内模块的链接，则横幅链接不跳转
        if (event.path[index].tagName == 'A') {
            return;
        }
        // 找到横幅链接层截止
        if (event.path[index] == this) {
            break;
        }
    }
    const jump = () => {
        window.open(url, is_blank ? '_blank' : '_self');
    };
    if (getIsOpenRemoveUrlArg() && reqArgs) {
        handleSetUrlArgToCookie(reqArgs).then(() => jump());
    } else {
        jump();
    }
};

export { banner };
