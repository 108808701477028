import { computed, set } from 'vue';

export const usePropList = (module) => {
    if (!module.propList) {
        return {};
    }
    module.propList.forEach((prop) => {
        if (typeof prop.value === 'undefined') {
            set(prop, 'value', '');
        }
    });
    const propList = computed(() => {
        return module.propList.filter((prop) => prop.hidden === false);
    });

    const getPropType = (prop) => {
        // type: 收货项类型(姓名系统项0, 系统项地址1, 系统项手机2, 系统项邮箱3, 自定义项单行文本4, 自定义项手机号5, 自定义项邮箱6),
        return (
            {
                1: 'text',
                2: 'text',
                3: 'phone',
                4: 'mail',
                5: 'text',
                6: 'phone',
                7: 'mail',
            }[prop.type] || 'unknown'
        );
    };

    return { propList, getPropType };
};
