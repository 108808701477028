import { URIParam } from '@shared/manage/utils/index.js';

/**
 *
 * @param {String} type - 模块标志
 * @param {Object} module
 * @param {Object} options
 * {
 *  linkProp: string 链接控件的属性
 *  callback: function
 * }
 */
export function createJumpLink(type, module, options = {}) {
    let moduleLinkProp = jzUtils.digGet(module, options.linkProp);
    const callback = options.callback;
    const frameSrcUrl = 'rmanage/linkSetting.jsp?ram=' + Math.random() + '&m=1';
    let fUrl =
        frameSrcUrl +
        '&lType=' +
        (moduleLinkProp.lt || 0) +
        '&lIde=' +
        Fai.encodeUrl(moduleLinkProp.ide) +
        '&target=' +
        (moduleLinkProp.ib ? '_blank' : '') +
        '&nofollow=' +
        moduleLinkProp.nf +
        '&hideNofollow=false';

    const jumpWxAppData = jzUtils.digGet(module, 'blob0.jumpWxAppData');
    if (jumpWxAppData) {
        jumpWxAppData.isJumpWxApp = jzUtils.digGet(module, 'blob0.jumpMode', 0) === 1;
        fUrl += `&jumpWxAppData=${encodeURIComponent(JSON.stringify(jumpWxAppData))}`;
        fUrl += `&moduleType=${type}`;
    }
    top.Fai.popupWindowVersionTwo.createPopupWindow({
        title: '设置链接地址',
        width: 580,
        height: 530,
        frameScrolling: 'no',
        frameSrcUrl: fUrl,
        closeFunc(reData) {
            if (reData != null) {
                if (jumpWxAppData && reData.isJumpWxApp) {
                    // 微信小程序
                    module.blob0.jumpWxAppData = reData;
                    module.blob0.jumpMode = 1;
                    return;
                } else {
                    if (module.blob0) {
                        module.blob0.jumpMode = 0;
                    }
                }
                Vue.set(moduleLinkProp, 'ide', reData.jIde);
                Vue.set(moduleLinkProp, 'lt', reData.type);
                Vue.set(moduleLinkProp, 'ib', reData.target == '_blank');
                Vue.set(moduleLinkProp, 'url', reData.jumpUrl);
                Vue.set(moduleLinkProp, 'ln', reData.name);
                Vue.set(moduleLinkProp, 'nf', reData.nofollow);
                if (reData.isNotEmptyReqArgs) {
                    Vue.set(moduleLinkProp, 'reqArgs', reData.reqArgs);
                } else {
                    Vue.set(moduleLinkProp, 'reqArgs', '');
                }
            }
            typeof callback === 'function' && callback(reData);
        },
    });
}

export const jzJumpConverter = function (href, target, event) {
    //具有href链接类型的a标签且非可执行代码
    if (href && href.indexOf('javascript') < 0) {
        //此类为站外链接
        if ((href.indexOf('http:') > -1 || href.indexOf('https:') > -1) && target != '_blank') {
            // //站内链接且为新页面
            if (target == '_blank') {
                open(href, target);
                event && event.preventDefault();
                return;
            } //站外链接本页面跳转的话需要修改顶层href， 新页面跳转的话就不用处理

            event && event.preventDefault();
            location.href = href;
        } else {
            //站内链接
            //站内链接需要带上当前管理状态hash
            let topHash = location.hash;
            let hashIndex = href.indexOf('#');
            let topParam = URIParam.hash.parse(topHash);
            delete topParam['hash'];

            if (hashIndex !== -1) {
                //新链接存在hash
                let hashStr = href.slice(hashIndex);
                href = href.slice(0, hashIndex);
                let param = URIParam.hash.parse(hashStr);
                topParam['hash'] = JSON.stringify(param);
            }

            topHash = URIParam.hash.stringify(topParam);
            href += topHash; //站内链接且为新页面

            if (target == '_blank') {
                open(href, target);
                event && event.preventDefault();
                return;
            } //站内链接且为当前页面

            event && event.preventDefault(); //站内链接的话本页面跳转的话需要拼接好相应数据到顶层href

            var jzOrigin = location.origin;
            var rootUrl = (jzOrigin += (href.indexOf('/') == 0 ? '' : '/') + href); //三种情况

            /*
      ?jumpToSite=true
      ?jumpToSite=true&
      &jumpToSite=true
      */

            if (rootUrl.indexOf('&jumpToSite=true') > -1) {
                rootUrl = rootUrl.replace('&jumpToSite=true', '');
            }

            if (rootUrl.indexOf('?jumpToSite=true&') > -1) {
                rootUrl = rootUrl.replace('jumpToSite=true&', '');
            }

            if (rootUrl.indexOf('?jumpToSite=true') > -1) {
                rootUrl = rootUrl.replace('?jumpToSite=true', '');
            }

            location.href = rootUrl;
            event && event.preventDefault();
        }
    }
};
