import { observeAnimate } from '@/site/shared/index.js';

const throttle = function (delay, action) {
    var last = 0;
    return function () {
        var curr = new Date();
        if (curr - last > delay) {
            action.apply(this, arguments);
            last = curr;
        }
    };
};

export const carouselMixin = {
    data() {
        return {
            animateDir: 'left', // 滚动方向 从右往左 下一张
            currentPicIndex: 0, // 下一张
            prePicIndex: 0, // 上一张
            timerId: null,
            outActivePicFlag: false, // 控制选中图类名的 出场动画需动画结束后才去除
            inAnimateFlag: false, // 入场动画的一个锁
            outAnimateFlag: false, // 出场动画的一个锁
            showTimeFlag: true, // 控制展示时间 区分动画 动画走完才让走下一屏
        };
    },
    computed: {
        pcCarouselSetting() {
            return this.pcPrivatePattern.pas;
        },
        mobiCarouselSetting() {
            return this.mobiPrivatePattern.pas;
        },
        isPc() {
            return this.device == 'pc';
        },
        showTime() {
            let defaultShowTime = 4000;
            if (this.isPc) {
                return this.isAutoPlay ? this.pcPrivatePattern.show * 1000 : defaultShowTime;
            } else {
                return this.isAutoPlay
                    ? this.mobiCarouselSetting == 0
                        ? this.pcPrivatePattern.show * 1000
                        : this.mobiPrivatePattern.show * 1000
                    : defaultShowTime;
            }
        },
        speedTime() {
            let defaultSpeedTime = 0.8;
            if (this.isPc) {
                return this.isAutoPlay ? this.pcPrivatePattern.switch : defaultSpeedTime;
            } else {
                return this.isAutoPlay
                    ? this.mobiCarouselSetting == 0
                        ? this.pcPrivatePattern.switch
                        : this.mobiPrivatePattern.switch
                    : defaultSpeedTime;
            }
        },
        photoListItemStyle() {
            return { 'animation-duration': `${this.speedTime}s` };
        },
        isAutoPlay() {
            if (this.isPc) {
                return this.pcCarouselSetting == 0;
            } else {
                return this.mobiCarouselSetting == 0 ? this.pcCarouselSetting == 0 : this.mobiCarouselSetting == 1;
            }
        },
        photoListRealLen() {
            return this.newsList.length;
        },
        typeList() {
            return this.module.prop1 || [];
        },
    },
    mounted() {
        if (this.isAutoPlay) {
            this.autoPlay();
        }
        if (!this.isPc) {
            this.initSwipeCarousel();
        }
    },
    beforeDestroy() {
        this.stopAutoPlay();
    },
    watch: {
        showTime: function () {
            this.stopAutoPlay();
            this.autoPlay();
        },
        speedTime: function () {
            this.stopAutoPlay();
            this.autoPlay();
        },
        isAutoPlay: function (val) {
            this.stopAutoPlay();
            if (val) {
                this.autoPlay();
            }
        },
        device(val) {
            if (val == 'mobi') {
                this.$nextTick(() => {
                    this.initSwipeCarousel();
                });
            }
        },
        typeList() {
            this.stopAutoPlay();
            this.currentPicIndex = 0;
            this.prePicIndex = 0;
            this.$nextTick(() => {
                this.autoPlay();
            });
        },
    },
    methods: {
        autoPlay() {
            if (this.timerId || !this.isAutoPlay) return;
            this.timerId = `marquee-${this.module.id}`;
            this.resetAutoPlay();
        },
        resetAutoPlay() {
            if (!this.timerId) return;
            Fai.stopInterval(this.timerId);
            Fai.addInterval(
                this.timerId,
                () => {
                    this.showTimeFlag && this.next();
                },
                this.showTime
            );
            Fai.startInterval(this.timerId);
        },
        runHanle(time) {
            let delay = time > 0.5 ? 500 : time * 1000;
            const _this = this;
            return throttle(delay, function (dir) {
                _this.animateDir !== dir && (_this.animateDir = dir);
                _this.changePicIndex(dir);
                _this.play();
            });
        },
        next() {
            this.runHanle(this.showTime)('left');
        },
        prev() {
            this.runHanle(this.showTime)('right');
        },
        changePicIndex(dir) {
            const photoLastIndex = this.photoListRealLen - 1;
            if (dir === 'right') {
                // 上一张
                if (this.currentPicIndex > 0) {
                    this.prePicIndex = this.currentPicIndex;
                    this.currentPicIndex--;
                } else {
                    // 当前图是第一张时 上一张最后一张
                    this.prePicIndex = 0;
                    this.currentPicIndex = photoLastIndex;
                }
            } else {
                if (this.currentPicIndex < photoLastIndex) {
                    this.prePicIndex = this.currentPicIndex;
                    this.currentPicIndex++;
                } else {
                    this.currentPicIndex = 0;
                    this.prePicIndex = photoLastIndex;
                }
            }
        },
        stopAutoPlay() {
            this.timerId && Fai.stopInterval(this.timerId);
            this.timerId = null;
        },
        play() {
            let outConfig = {},
                inConfig = {};
            outConfig.page = $(this.$el).find('.photo_list li').eq(this.prePicIndex);
            outConfig.animateType = 'out';
            inConfig.page = $(this.$el).find('.photo_list li').eq(this.currentPicIndex);
            inConfig.animateType = 'in';
            this.runAnimation(inConfig);
            this.runAnimation(outConfig);
        },
        runAnimation(config) {
            const { page, animateType } = config;
            if (animateType == 'out') {
                // 进入动画 则直接显示图片
                this.outActivePicFlag = true;
            }
            const _this = this;
            if (animateType == 'out') {
                // 加上动画类
                this.outAnimateFlag = true;
            } else {
                this.inAnimateFlag = true;
            }
            const animateOptions = {
                el: page,
                startCallBack(event) {
                    $(this).data('carousel-animated', 'true'); // 标记动画开始执行
                    animateType == 'in' && (_this.showTimeFlag = false);
                    event.stopPropagation();
                    return false;
                },
                endCallBack(event) {
                    $(this).removeData('carousel-animated'); // 标记动画已经执行结束
                    if (animateType == 'out') {
                        // 出场动画 则动画结束后隐藏并移除动画类名
                        _this.outActivePicFlag = false;
                        _this.outAnimateFlag = false;
                    } else {
                        // 入场动画 移除动画类名
                        _this.inAnimateFlag = false;
                        _this.showTimeFlag = true;
                    }
                    event.stopPropagation();
                    return false;
                },
                eventDes: 'carousel',
            };
            observeAnimate(animateOptions);
        },
        photoMouseover() {
            this.stopAutoPlay();
        },
        photoMouseleave() {
            this.autoPlay();
        },
        initSwipeCarousel() {
            //左滑
            $(this.$el)
                .off('swipeleft.l')
                .on('swipeleft.l', '.photo_list', () => {
                    this.next();
                });
            //右滑
            $(this.$el)
                .off('swiperight.r')
                .on('swiperight.r', '.photo_list', () => {
                    this.prev();
                });
        },
        selectPhoto(index) {
            if (index === this.currentPicIndex) return;
            this.prePicIndex = this.currentPicIndex;
            this.currentPicIndex = index;
            if (this.currentPicIndex > this.prePicIndex) {
                this.animateDir = 'left';
            } else {
                this.animateDir = 'right';
            }
            this.play();
        },
    },
};
