const initNav = function (callback) {
    init(callback);
};

var SLIDE_DURATION = 300;

function init(callback) {
    initItem();
    initSubItem();
    initThreeItem();
    initMenu();
    initMobiNavEvent();
    initFreeLayoutEvent();
    fixSubMenuPostion();
    typeof callback !== 'undefined' && callback();
}

function initItem() {
    $('.jz_web_header')
        .off('mouseenter.jni')
        .on('mouseenter.jni', '.jz_nav_item', function (e) {
            e.stopPropagation();
            /* Stuff to do when the mouse enters the element */
            let $this = $(this),
                childSub = $this.find('.sub_nav_list');

            // 需要调整位置
            fixSubMenuPostion();

            $('.jz_nav_item').not($this).removeClass('jz_nav_item_active');
            $('.sub_nav_list').not(childSub).removeClass('sub_nav_list_active');
            $this.addClass('jz_nav_item_active');
            if (!childSub.length) {
                return;
            }
            if (childSub.is(':visible')) {
                childSub.removeClass('sub_nav_list_active');
                $('.three_nav_list_active').removeClass('three_nav_list_active');
            } else {
                childSub.addClass('sub_nav_list_active');
            }
            if ($('.sub_nav_wrap').length) {
                $('.sub_nav_wrap')
                    .css({
                        overflowY: '',
                        overflowX: '',
                        height: '',
                    })
                    .data('needScroll', null)
                    .data('height', null);
            }
            if ($('.jz_nav_layout0 .sub_nav_list_active').length) {
                // 加导航长度过长，加滚动条
                let $sub_nav_list = $('.sub_nav_list_active'),
                    windowInnerHeight = window.innerHeight,
                    subNavHeight = $sub_nav_list.height(),
                    subNavTop = $sub_nav_list.offset().top;

                if (subNavHeight + subNavTop - $(document).scrollTop() > windowInnerHeight) {
                    $sub_nav_list
                        .find('.sub_nav_wrap')
                        .css({
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: windowInnerHeight - subNavTop - 10 + $(document).scrollTop() + 'px',
                        })
                        .data('needScroll', true)
                        .data('height', subNavHeight);
                }
            }
        });

    $('.jz_web_header')
        .off('mouseleave.jnil')
        .on('mouseleave.jnil', '.jz_nav_item', function () {
            let childSub = $(this).find('.sub_nav_list');
            $(this).removeClass('jz_nav_item_active');
            $(this).find('.sub_nav_list_active').removeClass('sub_nav_list_active');
            if (!childSub.length) {
                return;
            }
        });
}

function initSubItem() {
    $('.jz_web_header')
        .off('mouseenter.snt')
        .on('mouseenter.snt', '.sub_nav_item', function (e) {
            e.stopPropagation();
            /* Stuff to do when the mouse enters the element */
            let item = $(this);
            let childThree = $(this).find('.three_nav_list');
            $('.sub_nav_item').not(item).removeClass('sub_nav_item_active');
            if ($('.three_nav_wrap').length) {
                $('.three_nav_wrap')
                    .css({
                        overflowY: '',
                        overflowX: '',
                        height: '',
                    })
                    .data('needScroll', null)
                    .data('height', null);
            }
            $('.three_nav_list_active').not(childThree).removeClass('three_nav_list_active');
            item.addClass('jz_nav_item_active');
            if (!childThree.length) {
                return;
            }
            childThree.addClass('three_nav_list_active').css('top', '');
            //fix 三级导航太长，超出了屏幕宽度。
            if (childThree.hasClass('three_nav_list-1')) {
                childThree.addClass('three_nav_list_active').css('left', '').css('width', '');
                childThree.find('.three_nav_wrap').css('flex-wrap', '');
                let { width: clientRectWidth } = childThree[0].getBoundingClientRect(),
                    documentWidth = document.documentElement.clientWidth;
                //先判断宽度是不是已经大于视口了,这个时候设置一个固定宽度用于换行.
                if (clientRectWidth > documentWidth) {
                    childThree.css('width', documentWidth);
                    childThree.find('.three_nav_wrap').css('flex-wrap', 'wrap');
                }
                setTimeout(() => {
                    let { right: clientRectRight } = childThree[0].getBoundingClientRect();
                    //超出视口了
                    if (clientRectRight > documentWidth) {
                        childThree.css('left', documentWidth - clientRectRight);
                    }
                });
            }
            if ($('.jz_nav_layout0 .three_nav_list_active').length) {
                // 加导航长度过长，加滚动条
                let $three_nav_list = $('.three_nav_list_active'),
                    windowInnerHeight = window.innerHeight,
                    threeNavHeight = $three_nav_list.height(),
                    threeNavTop;

                $three_nav_list.css('top', $(this).offset().top - $('.sub_nav_list_active').offset().top + 'px');
                threeNavTop = $three_nav_list.offset().top;

                if (threeNavHeight + threeNavTop - $(document).scrollTop() > windowInnerHeight) {
                    $three_nav_list
                        .find('.three_nav_wrap')
                        .css({
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: windowInnerHeight - threeNavTop - 10 + $(document).scrollTop() + 'px',
                        })
                        .data('needScroll', true)
                        .data('height', threeNavHeight);
                }

                $three_nav_list.find('.three_nav_before').addClass('three_nav_before--large'); // fix: 处理移动到滚动条上, 三级菜单消失, firefox选择不了三级惨淡
            }
        });

    $('.jz_web_header')
        .off('mouseleave.snl')
        .on('mouseleave.snl', '.sub_nav_item', function () {
            let item = $(this);
            let childThree = $(this).find('.three_nav_list');
            $('.sub_nav_item').not(item).removeClass('sub_nav_item_active');
            $(this).find('.three_nav_list_active').removeClass('three_nav_list_active');
            if (!childThree.length) {
                return;
            }
            childThree.removeClass('three_nav_list_active');
            childThree.find('.three_nav_before').removeClass('three_nav_before--large');
        });
}

function initThreeItem() {
    $('.jz_web_header .three_nav_item')
        .off('click.nav')
        .on('click.nav', function (event) {
            let item = $(this);
            $('.three_nav_item').not(item).removeClass('three_nav_item_active');
            item.addClass('three_nav_item_active');

            event.stopPropagation();
        });
}

function initMenu() {
    $('.mobi_nav_blocker')
        .off('click.nav')
        .on('click.nav', function (event) {
            event.stopPropagation();
            event.preventDefault();
            if (!$(event.target).is($(this))) {
                return;
            }
            $('.jz_nav_menu').trigger('click');
        });

    $('.mobi_nav_blocker')
        .off('touchstart.a')
        .on('touchstart.a', function (event) {
            // 点击遮罩层，收起导航
            event.stopPropagation();
            event.preventDefault();
            $('.jz_nav_menu').trigger('click');
        });

    $('.jz_screen_pc .jz_nav_layout1 .sub_nav_list, .jz_screen_pc .jz_nav_layout1 .three_nav_list')
        .off('mousewheel.nav1')
        .on('mousewheel.nav1', function (event) {
            // 导航样式二，鼠标在导航上，禁用滚动
            event.preventDefault();
            event.stopPropagation();
        });
    $('.jz_screen_pc .three_nav_wrap, .jz_screen_pc .sub_nav_wrap')
        .off('mousewheel.three')
        .on('mousewheel.three', function (event) {
            event.stopPropagation();
            var originalEvent = event.originalEvent;
            var $content = $(this);

            var arriveTop = $content.scrollTop() === 0;
            var arriveBottom = $content[0].scrollTop + $content[0].clientHeight === $content[0].scrollHeight;

            var isGoTop = getMouseDir(originalEvent) === true;
            var isGoBottom = getMouseDir(originalEvent) === false;

            if (arriveTop && isGoTop) {
                event.stopPropagation();
                event.preventDefault();
                return;
            } else if (arriveBottom && isGoBottom) {
                event.stopPropagation();
                event.preventDefault();
                return;
            }
        });
}

function getMouseDir(e) {
    var TOP = true;
    var BOTTOM = false;
    e = e || window.event;
    if (e.wheelDelta) {
        //判断浏览器IE，谷歌滑轮事件
        if (e.wheelDelta > 0) {
            //当滑轮向上滚动时
            //事件
            return TOP;
        }
        if (e.wheelDelta < 0) {
            //当滑轮向下滚动时
            //事件
            return BOTTOM;
        }
    } else if (e.deltaY) {
        //Firefox滑轮事件
        if (e.deltaY < 0) {
            //当滑轮向上滚动时
            //事件
            return TOP;
        }
        if (e.deltaY > 0) {
            //当滑轮向下滚动时
            //事件
            return BOTTOM;
        }
    }
    return null;
}

function initMobiNavEvent() {
    var $mobiNav = $('.mobi_nav');

    $mobiNav.off('click.nit').on('click.nit', '.nav_item_text', function (e) {
        e.stopPropagation();
        slideChidrenMenu($(this).parent(), 'slide_sub_menu', '.sub_nav_list');
    });
    $mobiNav.off('click.snit').on('click.snit', '.sub_nav_item_text', function (e) {
        e.stopPropagation();
        slideChidrenMenu($(this).parents('.sub_nav_item'), 'slide_three_menu', '.three_nav_list');
    });
    $mobiNav.off('click.mn').on('click.mn', '.icon_nav_item', function (e) {
        e.stopPropagation();
        slideChidrenMenu($(this).parent(), 'slide_sub_menu', '.sub_nav_list');
    });

    $mobiNav.off('click.sni').on('click.sni', '.icon_sub_item', function (e) {
        e.stopPropagation();
        slideChidrenMenu($(this).parent(), 'slide_three_menu', '.three_nav_list');
    });
}

function slideChidrenMenu($parent, slidingClassName, childrenClass) {
    // 收起非当前展开的下级导航栏
    $(`.${slidingClassName}`)
        .not($parent)
        .removeClass(slidingClassName)
        .find(childrenClass)
        .eq(0)
        .slideUp(SLIDE_DURATION);

    toggleClass($parent, slidingClassName);

    $parent.find(childrenClass).slideToggle(SLIDE_DURATION);
}

function initFreeLayoutEvent() {
    var $mobiNav = $('.mobi_nav .jz_nav_layout2');
    if (!$('.s_freelayout__wrap').length) {
        return;
    }
    $mobiNav.off('click.freelayout').on('click.freelayout', '.icon_nav_item,.nav_item_text', function (e) {
        e.stopPropagation();
        $(this).siblings('.s_freelayout__wrap').slideToggle();
        if ($(this).hasClass('icon_nav_item')) {
            $(this).toggleClass('icon_nav_item-active');
        } else {
            $(this).siblings('.icon_nav_item').toggleClass('icon_nav_item-active');
        }
    });
}

function fixSubMenuPostion() {
    if ($('.jz_web_header .jz_nav').length <= 0) {
        return;
    }

    var $navItem = $('.jz_web_header .jz_nav_item'),
        offset = $navItem.offset(),
        navHeight = $navItem.outerHeight(),
        subMarginTop = $('.sub_nav_before').height(),
        subLeft = 0,
        navItemWidth = 0;

    if ($('.sub_nav_before').length == 0) {
        subMarginTop = $('.jz_nav_layout0 .sub_nav_list:before').height();
    }

    $navItem.find('.sub_nav_list').each(function (index, dom) {
        var $dom = $(dom),
            hideOrShow = '';
        hideOrShow = $dom.find('li').length > 0 ? '' : 'none';
        let _subTop = offset.top + navHeight + subMarginTop - $(document).scrollTop();
        if (
            window._store.state.fullScreen &&
            window._store.state.manageMode &&
            !window._store.state.responsiveInfo.pattern.rollingScreen.fixedTop
        ) {
            let pageTop = Comm?.getPageTop();
            _subTop -= pageTop; // 滚屏栏目 不固定顶部情况下 需减去topBar的高度
        }
        $dom.css({
            top: _subTop + 'px',
            left: '0',
            display: hideOrShow,
        });
    });

    if ($('.jz_nav_wrap').hasClass('jz_nav_layout0')) {
        $navItem.find('.sub_nav_list').each(function (index, dom) {
            var $dom = $(dom),
                $parent = $dom.parent();

            subLeft = $parent.offset().left;
            navItemWidth = $parent.outerWidth();
            let nav = window._store.state.responsiveInfo.pattern.nav;
            let { ncc = {} } = nav;
            if (ncc.ta == 1) {
                $dom.addClass('left');
                $dom.css({ left: subLeft + 'px' });
                return;
            } else {
                $dom.removeClass('left');
            }
            $dom.css({ left: subLeft + navItemWidth / 2 + 'px' });
        });
    }
}

function getNavPosition() {
    if ($('.jz_web_header .jz_nav').length <= 0) {
        return;
    }
    var $nav = $('.jz_web_header .jz_nav'),
        $navList = $nav.find('.jz_nav_list'),
        $navItem = $nav.find('.jz_nav_item'),
        viewAreaWidth = $nav.width(),
        navItemLen = $navItem.length,
        $lastNavItem = $navItem.eq(navItemLen - 1),
        navWidth = 0;

    navWidth = $lastNavItem.position().left + $lastNavItem.outerWidth();

    return {
        $nav,
        $navList,
        $navItem,
        navItemLen,
        navWidth,
        viewAreaWidth,
    };
}

function isOverflowViewArea() {
    var option = getNavPosition(),
        navWidth = option.navWidth,
        viewAreaWidth = option.viewAreaWidth;

    return navWidth > viewAreaWidth;
}

function toggleClass($el, className) {
    $el.hasClass(className) ? $el.removeClass(className) : $el.addClass(className);
}

export { initNav, isOverflowViewArea, fixSubMenuPostion };
