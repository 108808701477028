var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "module", staticClass: "module_content_detail" }, [
    !_vm.module.notAllowPrint
      ? _c(
          "div",
          { staticClass: "m_msg_board", class: ["s_" + _vm.module.prop0] },
          [
            _c(
              "div",
              { staticClass: "m_form" },
              [
                _c(
                  "form-container",
                  { attrs: { list: _vm.contentList } },
                  [
                    _vm._l(_vm.contentList, function (item) {
                      return !item.hide
                        ? _c(
                            "form-item",
                            {
                              key: item.id,
                              class: _vm.formItemClasses(item),
                              attrs: { item: item, module: _vm.module },
                            },
                            [
                              _c(_vm.getFormItem(item.type), {
                                tag: "component",
                                class: { required: item.must },
                                attrs: {
                                  value: item.value,
                                  item: item,
                                  "required-icon": item.must,
                                  "item-input-style": _vm.itemInputStyle,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.inputItem(item, $event)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm.validation
                      ? _c(
                          "form-item",
                          {
                            key: "validateCode",
                            staticClass: "fixed_item msg_input_validateCode",
                            attrs: {
                              item: {
                                id: "validateCode",
                                name: _vm.loginDialogCaptcha,
                                hideToolBar: true,
                              },
                              module: _vm.module,
                            },
                          },
                          [
                            _c(_vm.getFormItem("validateCode"), {
                              tag: "component",
                              staticClass: "content",
                              attrs: {
                                value: _vm.validateCode,
                                module: _vm.module,
                                "item-input-style": _vm.itemInputStyle,
                              },
                              on: {
                                input: function ($event) {
                                  _vm.validateCode = $event
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "form_submit jz_theme_bg_color",
                    class: {
                      "not-allowed-button":
                        !_vm.manageMode &&
                        _vm.realNameAuth.allowSubmitMessage === false,
                    },
                    on: { click: _vm.submitHandler },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.module.msgBoardSubmit) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        )
      : _c("div", { staticStyle: { color: "#333", "line-height": "1.5" } }, [
          _vm._v("\n        " + _vm._s(_vm.module.notAllowText) + "\n    "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }