var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "hovermenu",
          rawName: "v-hovermenu",
          value: {
            hovermenu: _setup.hovermenu,
            args: { item: _setup.item, index: _setup.index },
          },
          expression: "{ hovermenu, args: { item, index } }",
        },
      ],
      staticClass: "accordion_item",
      class: {
        accordion_item_active: _setup.isActive,
        accordion_item_editing: _setup.isEditing,
      },
      attrs: { "item-id": _setup.itemId },
      on: { mouseenter: _setup.handleMouseenter },
    },
    [
      _c("div", {
        staticClass: "accordion_item_bg",
        style: { width: `${_setup.accordionItemMaxWidth}` },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.item.defaultShow || _setup.showInitial,
              expression: "item.defaultShow || showInitial",
            },
          ],
          staticClass: "accordion_item_pack_wrap accordion_item_initial",
          class: { accordion_item_show: _setup.showInitial },
        },
        [
          _c(_setup.Pack, {
            staticClass: "accordion_item_initial_pack accordion_item_pack",
            attrs: {
              "module-ids": _setup.item.initial.mIds,
              "module-index": _setup.moduleIndex,
              "row-id": _setup.rowId,
              name: "accordion",
            },
            on: {
              addModule: function ($event) {
                return _setup.addModule("initial")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.item.defaultShow || _setup.showHover,
              expression: "item.defaultShow || showHover",
            },
          ],
          staticClass: "accordion_item_pack_wrap accordion_item_hover",
          class: { accordion_item_show: _setup.showHover },
        },
        [
          _c(_setup.Pack, {
            staticClass: "accordion_item_hover_pack accordion_item_pack",
            attrs: {
              "module-ids": _setup.item.hover.mIds,
              "module-index": _setup.moduleIndex,
              "row-id": _setup.rowId,
              name: "accordion",
            },
            on: {
              addModule: function ($event) {
                return _setup.addModule("hover")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _setup.store.state.manageMode
        ? [
            _c("div", {
              staticClass: "manage_hover_line manage_hover_line_left",
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "manage_hover_line manage_hover_line_right",
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "manage_hover_line manage_hover_line_top",
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "manage_hover_line manage_hover_line_bottom",
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }