var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "form_item_wrap",
      class: { formitem_active: _vm.formItemIsActive },
      style: _vm.itemStyle,
    },
    [
      _c(
        "div",
        { ref: "formItem", staticClass: "form_item" },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("\n            " + _vm._s(_vm.item.name) + "\n            "),
            _vm.item.must
              ? _c("span", { staticClass: "required_icon" }, [_vm._v("*")])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.manageMode
            ? _c(
                "tooltip-component",
                {
                  ref: "tooltipResizeHandler",
                  attrs: {
                    value: _vm.resizeForTip,
                    manual: "",
                    "visible-arrow": false,
                    placement: "bottom-start",
                    effect: "flower_white",
                  },
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(
                      "\n                宽：" +
                        _vm._s(_vm.widthForTip) +
                        "\n                "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                高：" +
                        _vm._s(_vm.heightForTip) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "resize_handler",
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.resizeHandlerMouseDown.apply(null, arguments)
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }