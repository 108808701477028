var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item_type_phone" }, [
    _c("div", { staticClass: "phone_number_wrap" }, [
      _vm.requiredIcon
        ? _c("span", { staticClass: "required_icon" }, [_vm._v("*")])
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        staticClass: "input_style jz_focus jz_theme_focus_border_color",
        style: _vm.itemInputStyle,
        attrs: {
          maxlength: "11",
          onkeypress: "javascript:return Fai.isPhoneNumberKey(event);",
          placeholder: _vm.decodeHtmlStr(_vm.item.placeholder),
        },
        domProps: { value: _vm.phoneNumber },
        on: { input: _vm.inputPhoneNumber },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }