import { warningMessage, successMessage } from '@/site/shared/dialog/index.js';
import {
    checkMobileCodeGeneral,
    loginMemberByMobile,
    sendMobileLoginCode,
    sendMemberActiveMail,
    bindAcct,
    otherAdd,
    getMobileCode,
    otherLoginMember,
    checkMemberSameTimeLogin,
} from '@/api/member/index.js';
import { createDialog } from '@/site/shared/dialog/index.js';
import { context as globalContext } from '@jz/utils';
import { logDog } from '@/api/log/index.js';
/**
 * 后台根据url参数errno初始化页面时调用该函数，显示提示语
 * @param {Number} errno 提示语代码
 */
function showMemberLoginMsg(errno = 0) {
    _showLoginErrorMsg({ data: { rt: errno } });
}

const LS = globalContext.LS || {};

/**
 * 手机验证码倒计时
 * @param {Number} moduleId 模块id
 */
function _loginAcquireCodeCountdown(moduleId = 0) {
    const $acquireCode = $('#module' + moduleId).find('#acquireCode');
    $acquireCode.attr('data-disabled', 'true');

    let downTime = 60;
    const timer = window.setInterval(() => {
        downTime--;
        $acquireCode.html(Fai.format(LS.reacquireMemCode, downTime));
        if (downTime < 1) {
            $acquireCode.attr('data-disabled', 'false');
            $acquireCode.html($acquireCode.attr('data-text'));
            window.clearInterval(timer);
        }
    }, 1000);
}

/**
 * 显示验证码
 * @param {Number} moduleId 模块id
 */
function _showCaptcha(moduleId) {
    $('#module' + moduleId)
        .find('.J_memberLoginCaptcha')
        .removeClass('m_member_login_captcha_hide');
}

/**
 * 启用登录按钮
 * @param {Number} moduleId 模块id
 */
function _enableLoginBtn(moduleId = 0) {
    $('#module' + moduleId)
        .find('.J_memberLoginBtn')
        .prop('disabled', false);
    $('#module' + moduleId)
        .find('.J_memberFastLoginBtn')
        .prop('disabaled', false);
}

function _sendEmailActive({ emailName = '', acctName = '', needSuccess = true }) {
    // 重新发送激活邮件
    new Promise((resolve, reject) => {
        sendMemberActiveMail({
            memName: acctName,
            memEmail: emailName,
        })
            .then((data) => {
                data.success ? resolve(data) : reject(data);
            })
            .catch(() => {
                warningMessage(LS.js_systemError, true);
            });
    })
        .then(() => {
            if (!needSuccess) return;
            successMessage(LS.memberDialogOKorErrorMsg);
        })
        .catch((data = {}) => {
            if (data.AliaNotF) {
                warningMessage(LS.memberDialogAliasNotFound, true);
            }
        });
}

/*
 * 拿到当前页面的url参数（登录成功后要跳转的页面链接）
 */
function _getReturnUrl() {
    return Fai.getUrlParam(window.location.href, 'url');
}

/**
 * 看注册选项是否有必填项
 * @param {Array} optionsList - 注册填写的数据项
 * @return {Boolean} - 结果
 */
function _hasOtherLoginMust(optionsList) {
    return optionsList.some((o) => o.otherLoginMust);
}

// 根据登录类型，去拼凑后台需要的数据..........
function _getLoginInfoByLoginType(loginType, otherLoginInfo) {
    const info = {};
    if (loginType == 1) {
        // qq登录
        const { qqInfo = {} } = otherLoginInfo;
        info.qqOpenId = qqInfo.q_uid;
        info.headImgUrl = qqInfo.q_avator;
    } else if (loginType == 2) {
        // 微博登录
        const { sinaInfo = {} } = otherLoginInfo;
        info.sinaOpenId = sinaInfo.w_uid;
        info.headImgUrl = sinaInfo.wb_avator;
    } else if (loginType == 4 || loginType == 5) {
        // 微信登录
        const { wxInfo = {} } = otherLoginInfo;
        info.mobiWxOpenId = wxInfo.w_uid || '';
        info.headImgUrl = wxInfo.wx_avator || '';
        if (wxInfo.w_unionId) {
            info.wxAppUnionId = wxInfo.w_unionId;
        }
        if (wxInfo.w_faiOpenId) {
            info.faiOpenId = wxInfo.w_faiOpenId;
        }
    }
    return info;
}

function _handleAcctError({ rt, msg = '' }) {
    switch (rt) {
        case 1:
            return warningMessage(LS.memberOtherLgnAcctAlreadyBind, true);
        case -601:
            return warningMessage(LS.reGetMobileCode, true);
        case -8:
            return warningMessage(LS.mobileHasSigned, true);
        case -6:
            return warningMessage(LS.memberSignupRegisterExisted, true);
        case -4:
            return warningMessage(LS.memberSignupRegisterLimit, true);
        case -3:
            return warningMessage(msg == '密码不正确' ? LS.memberPwdError : LS.memberDialogNotFound, true);
        default:
            return warningMessage(LS.memberSignupRegisterError, true);
    }
}

/**
 * 其他账号绑定请求
 * @param {Object} data - 数据
 */
function _handleBindAcct(data) {
    const { loginType, info } = data;
    const ajaxInfo = {
        acct: data.acct,
        pwd: $.md5(data.pwd),
        ..._getLoginInfoByLoginType(loginType, info),
    };
    const ajaxData = {
        loginType,
        info: JSON.stringify(ajaxInfo),
    };
    bindAcct({
        ...ajaxData,
    })
        .then((result) => {
            if (result.success) {
                if (result.fromBind || result.active) {
                    _showLoginActiveEmailMsg({
                        emailName: result.mail,
                        acctName: result.memName,
                    });
                    return;
                }
                Fai.top.location.href = data.skipUrl || Site.preprocessUrl({ path: '', oldPath: '' }) + 'index.jsp';
            } else {
                _handleAcctError(result);
            }
        })
        .catch(() => {
            Fai.ing(LS.memberLoginError, true);
        });
}

/**
 * 第三方登录绑定其他账号弹窗
 * @param {Function} createPopupFn - 创建弹窗的函数
 * @param {Function} removePopupFn - 移除弹窗的函数
 * @param {Object} data - 数据
 */
function _showLoginBindOtherAcctPopup(createPopupFn, removePopupFn, data) {
    const { info: { name = '', avatar = '' } = {} } = data;
    const popupHtml = `
		<div class="bindacct_wrap">
			<div class="bindacct_avatar" style="background-image: url(${avatar})"></div>
			<div class="bindacct_name">${name}</div>
			<div class="bindacct_btn bindacct_btn-bind jz_theme_bg_color jz_theme_border_color">${LS.memberOtherLgnBindAcct}</div>
			<div class="bindacct_btn bindacct_btn-login jz_theme_border_color jz_theme_font_color">${LS.memberOtherLgnNow}</div>
		</div>
	`;
    const popupOptions = {
        width: 400,
        height: 480,
        divContent: popupHtml,
        title: LS.memberOtherLgnModeChoice,
    };
    const popupId = createPopupFn(popupOptions).getPopupWindowId();

    // eslint-disable-next-line no-use-before-define
    $('.bindacct_btn-bind').off('click.bind').on('click.bind', _showBindOtherAcct);
    $('.bindacct_btn-login')
        .off('click.login')
        .on('click.login', () => {
            const { loginType, info } = data;
            const hasOtherLoginMust = _hasOtherLoginMust(data.optionsList);
            if (hasOtherLoginMust) {
                data.popupId = popupId;
                showLoginSettingsPopup(data);
                return;
            }
            const ajaxInfo = {
                ..._getLoginInfoByLoginType(loginType, info),
            };
            const ajaxData = {
                loginNow: true,
                loginType,
                info: JSON.stringify(ajaxInfo),
            };
            otherAdd({
                ...ajaxData,
            })
                .then((result) => {
                    if (result.success) {
                        Fai.top.location.href = data.skipUrl || 'index.jsp';
                    } else {
                        _handleAcctError(result);
                    }
                })
                .catch(() => {
                    Fai.ing(LS.memberLoginError, true);
                });
        });

    // 点击绑定后的弹窗
    function _showBindOtherAcct() {
        const bindPopuphtml = `
			<div class="bindOtherAcct_wrap">
				<input class="bindOtherAcct_input bindOtherAcct_input-acct" placeholder='${LS.memberSignupRegisterAcctEmpty}'>
				<input type="password" class="bindOtherAcct_input bindOtherAcct_input-pwd" placeholder='${LS.memberDialogPleaseEnterPwd}'>
				<div class="bindacct_btn bindOtherAcct_btn-bind bindOtherAcct_btn-bind-disbaled jz_theme_bg_color jz_theme_border_color">${LS.memberOtherLgnBindNow}</div>
				<div class="bindacct_btn bindOtherAcct_btn-back jz_theme_border_color jz_theme_font_color">${LS.backingOut}</div>
			</div>
		`;
        const bindPopupOptions = {
            width: 400,
            height: 480,
            divContent: bindPopuphtml,
            title: LS.memberBindOther,
        };
        const bindPopupId = createPopupFn(bindPopupOptions).getPopupWindowId();

        const $bindBtn = $('.bindOtherAcct_btn-bind');
        let acctName = '',
            password = '';

        $bindBtn.off('click.bind').on('click.bind', function () {
            if ($(this).hasClass('bindOtherAcct_btn-bind-disbaled')) {
                return;
            }
            _handleBindAcct({
                acct: acctName,
                pwd: password,
                ...data,
            });
        });

        $('.bindOtherAcct_btn-back')
            .off('click.close')
            .on('click.close', () => removePopupFn(bindPopupId));

        $('.bindOtherAcct_input')
            .off('keyup.keyup')
            .on('keyup.keyup', function () {
                const $this = $(this);
                if ($this.hasClass('bindOtherAcct_input-acct')) {
                    acctName = $(this).val();
                } else {
                    password = $(this).val();
                }
                if (acctName === '' || password === '') {
                    $bindBtn.addClass('bindOtherAcct_btn-bind-disbaled');
                } else {
                    $bindBtn.removeClass('bindOtherAcct_btn-bind-disbaled');
                }
            });
    }
}

/**
 * 创建手机视图下的弹窗
 * @param {Object} options - 配置项
 * @returns {Object} - 弹窗对象
 */
function _createMobiPopup(options) {
    const popupId = (Math.random() * 10000).toFixed(0);
    const { divContent = '' } = options;
    const $html = $(`<div class="mobi_popup">${divContent}</div>`).appendTo('body');
    $html.attr('data-id', popupId);
    $('body').css({ position: 'fixed', width: '100%' });
    return {
        getPopupWindowId() {
            return popupId;
        },
    };
}

/**
 * 移除手机视图下的弹窗
 * @param {Strign} id - 弹窗id
 */
function _removeMobiPopup(id) {
    if (!id) return;
    if ($('.mobi_popup').length === 1) {
        $('body').css({ position: '', width: '' });
    }
    $(`.mobi_popup[data-id=${id}]`).remove();
}

function _mixinPopupAction(fn) {
    const isMobi = $('html').hasClass('jz_screen_mobi');

    // 这里为了兼容弹窗定位position: fixed... 公共组件不太公共..
    const pcCreateFn = (options) => {
        const newOptions = { ...options };
        if (typeof newOptions.popUpWinClass === 'string') {
            newOptions.popUpWinClass = `${newOptions.popUpWinClass} fk-popupWindowVT_position--fixed`;
        } else {
            newOptions.popUpWinClass = 'fk-popupWindowVT_position--fixed';
        }
        if (typeof newOptions.closeFunc === 'function') {
            const _closeFunc = newOptions.closeFunc;
            newOptions.closeFunc = function () {
                setTimeout(() => {
                    if ($('.fk-popupWindowVT').length < 2) {
                        $('html, body').css('overflow', '');
                    }
                }, 100);
                _closeFunc.apply(this, arguments);
            };
        } else {
            newOptions.closeFunc = function () {
                setTimeout(() => {
                    if ($('.fk-popupWindowVT').length < 2) {
                        $('html, body').css('overflow', '');
                    }
                }, 100);
            };
        }
        $('html, body').css('overflow', 'hidden');
        return Fai.popupWindowVersionTwo.createPopupWindow(newOptions);
    };
    const pcAction = {
        createPopupFn: pcCreateFn,
        removePopupFn: Fai.closePopupWindow,
    };
    const mobiAction = {
        createPopupFn: _createMobiPopup,
        removePopupFn: _removeMobiPopup,
    };
    const action = isMobi ? mobiAction : pcAction;
    return (...args) => {
        fn.call(this, action.createPopupFn, action.removePopupFn, ...args);
    };
}

/**
 * 第三方登录绑定其他账号弹窗
 * @param {Function} createPopupFn - 创建弹窗的函数
 * @param {Function} removePopupFn - 移除弹窗的函数
 * @param {Object} data - 数据
 */
function _showLoginSettingsPopup(createPopupFn, removePopupFn, data) {
    const { loginType, info = {}, optionsList, mobileCtNameList, signNeenMobile } = data;
    let avatar = info.avatar;
    let name = info.name;
    const html = `
		<div class="otherLoginSetting_wrap">
			<div class="otherLoginSetting_avatar" :style="{ backgroundImage: 'url(' + avatar + ')' }"></div>
			<div class="otherLoginSetting_name">{{name}}</div>
			<div class="otherLoginSetting_list">
				<div class="otherLoginSetting_item" v-for="(option, index) in optionsList" :key="index">
					<div class="otherLoginSetting_label">
						<div class="otherLoginSetting_label_text">{{option.name}}：</div>
					</div>
					<div
						class="otherLoginSetting_content"
						:class="{ 'otherLoginSetting_content-require': option.must }"
					>
						<template v-if="option.type == 0">
							<input class="otherLoginSetting_input jz_theme_focus_border_color" :placeholder="'${
                                LS.calculation_empty_tips || '请输入'
                            } ' + option.name" v-model="option.value" :maxlength="option.maxlength || 50">

							<!-- 手机城市列表 -->
							<select class="c_select otherLoginSetting_select-ct" v-if="option.fieldKey == 'mobile'" v-model="mobileCt">
								<option v-for="c in mobileCtNameList" :value="c.ctName">{{c.ctShowName}}{{c.ctCode}}</option>
							</select>
							<!-- 验证码 -->
							<div class="otherLoginSetting_captcha" v-else-if="option.fieldKey == 'captcha'" @click="captchaKey++">
								<img class="otherLoginSetting_captcha_img" :src="'/validateCode.jsp?' + captchaKey">
							</div>
							<!-- 短信验证码 -->
							<div class="otherLoginSetting_sms" v-else-if="option.fieldKey == 'sms'" title="${
                                LS.getMobileCode || '获取验证码'
                            }" @click="getSmsCode">{{codeText}}</div>
						</template>
						<template v-else-if="option.type == 1">
							<select class="c_select otherLoginSetting_select" v-model="option.value">
                                <option value>请选择</option>
								<option v-for="(o, i) in option.propList" :value="o" :key="i">{{o}}</option>
							</select>
							<i class="c_right_arrow otherLoginSetting_arrow"></i>
						</template>
					</div>
				</div>
			</div>
			<div class="otherLoginSetting_btn-submit jz_theme_bg_color jz_theme_border_color" @click="handleSubmit">${
                LS.confirm
            }</div>
		</div>
	`;
    const popupOptions = {
        width: 470,
        height: 670,
        divContent: html,
        title: LS.memberOtherLgnAddTitle,
        popUpWinClass: 'otherLoginSetting_popup',
    };
    const popupId = createPopupFn(popupOptions).getPopupWindowId();
    new Vue({
        el: '.otherLoginSetting_wrap',
        data() {
            // 整理下数据
            const hasSMS = optionsList.find((o) => o.fieldKey == 'captcha') != null;
            if (signNeenMobile && !hasSMS) {
                optionsList.push(
                    {
                        name: LS.loginCode || '验证码',
                        fieldKey: 'captcha',
                        value: '',
                        maxlength: 6,
                        type: 0,
                        emptyTips: LS.memberInputCaptcha,
                        otherLoginMust: true,
                    },
                    {
                        name: LS.loginDialogMsgCode || '短信验证码',
                        fieldKey: 'sms',
                        value: '',
                        type: 0,
                        maxlength: 6,
                        must: true,
                        emptyTips: LS.inputMobileCode,
                        otherLoginMust: true,
                    }
                );
            }
            optionsList.forEach((option) => {
                if (option.type == 1) {
                    option.propList = option.propContent.split('\\n');
                    option.propList.forEach((item, index) => {
                        let result = item.replace(/\\x3c/g, '<');
                        result = result.replace(/\\x3e/g, '>');
                        option.propList[index] = result;
                    });
                }
                if (option.fieldKey == 'mobile') {
                    option.maxlength = 11;
                }
                option.value = '';
                option.name = option.name.replace(/\\x3c/g, '<');
                option.name = option.name.replace(/\\x3e/g, '>');
            });
            const filterOptionsList = optionsList.filter((o) => o.otherLoginMust);
            return {
                optionsList: filterOptionsList,
                name,
                avatar,
                mobileCtNameList,
                captchaKey: 0,
                mobileCt: 'ch',
                codeText: LS.getMobileCode || '获取验证码',
            };
        },
        methods: {
            getOptionValue(fieldKey) {
                if (!fieldKey) return '';
                const option = this.optionsList.find((o) => o.fieldKey === fieldKey);
                if (!option) return '';
                return option.value;
            },
            getSmsCode() {
                if (this.codeText != LS.getMobileCode) return;
                const mobile = this.getOptionValue('mobile');
                if (!Fai.isNationMobile(mobile)) {
                    warningMessage(LS.mobileNumDefRegular, true);
                    return;
                }
                const captcha = this.getOptionValue('captcha');
                if (!captcha) {
                    warningMessage(LS.memberDialogEnterCode, true);
                    return;
                }
                const mobileCt = this.mobileCt;
                new Promise((resolve, reject) => {
                    getMobileCode({
                        mobile: mobile,
                        mobileCt: mobileCt,
                        validateCode: captcha,
                    })
                        .then((data) => {
                            data.success ? resolve(data) : reject(data);
                        })
                        .catch(reject);
                })
                    .then(() => {
                        successMessage(LS.sendMobileCodeSuc, true);
                        let second = 60;
                        this.codeText = LS.reGetMsg + second;
                        const timer = window.setInterval(() => {
                            if (second == 0) {
                                this.codeText = LS.getMobileCode;
                                window.clearInterval(timer);
                                return;
                            }
                            this.codeText = LS.reGetMsg + second;
                        }, 1000);
                    })
                    .catch(({ rt, msg } = {}) => {
                        if (msg) {
                            warningMessage(msg, true);
                            return;
                        }

                        switch (rt) {
                            case -401:
                                warningMessage(LS.memberSignupRegisterCaptchaNotMatch, true);
                                this.captchaKey++;
                                break;
                            case -2:
                                warningMessage(LS.js_argsError, true);
                                break;
                            case 2:
                                warningMessage(LS.memberDialogSendMobileCodeErr, true);
                                break;
                            case -4:
                            case 8:
                                warningMessage(LS.getMobileOneMin, true);
                                break;
                            case -8:
                                warningMessage(LS.getMobileHalfHour, true);
                                break;
                            case 3:
                                warningMessage(LS.memberDialogMobileMoneyErr, true);
                                break;
                            case 9:
                                warningMessage(LS.memberDialogSendMobileCodeLimit, true);
                                break;
                            case 101:
                                warningMessage(LS.mobileSetErr, true);
                                break;
                            case -6:
                                warningMessage(LS.mobileHasSigned, true);
                                break;
                            case 23:
                                warningMessage(LS.mobileNationTplErr, true);
                                break;
                            case -301:
                                warningMessage(LS.smsRanout, true);
                                break;
                            default:
                                warningMessage(LS.getMobileRefresh, true);
                        }
                    });
            },
            handleSubmit() {
                const ajaxInfo = {
                    ..._getLoginInfoByLoginType(loginType, info),
                };
                for (const option of this.optionsList) {
                    const { value, must, name: oName, fieldKey, emptyTips } = option;
                    if (must && value === '') {
                        const tips = emptyTips || Fai.format(LS.memberSignupUserAddItemIsEmpty, oName);
                        warningMessage(tips);
                        return;
                    }
                    if (fieldKey == 'mobile') {
                        if (must && !Fai.isNationMobile(value)) {
                            warningMessage(LS.mobileNumRegular);
                            return;
                        }
                        if (!must && value !== '' && !Fai.isNationMobile(value)) {
                            warningMessage(LS.mobileNumRegular);
                            return;
                        }
                    }
                    if (must && fieldKey == 'mobile') {
                        ajaxInfo['mobileCt'] = this.mobileCt;
                    }
                    if (must && fieldKey == 'email' && !Fai.isEmail(value)) {
                        warningMessage(Fai.format(LS.memberSignupUserAddItemCorrect, oName));
                        return;
                    }
                    if (fieldKey == 'sms') {
                        ajaxInfo.messageAuthCode = value;
                    }
                    ajaxInfo[fieldKey] = String(value);
                }
                const ajaxData = {
                    loginType,
                    info: JSON.stringify(ajaxInfo),
                };
                otherAdd({
                    ...ajaxData,
                })
                    .then((result) => {
                        if (result.success) {
                            if (result.fromBind || result.active) {
                                _showLoginActiveEmailMsg({
                                    emailName: ajaxInfo.email,
                                    acctName: result.acct,
                                    onConfirm() {
                                        if (data.popupId) {
                                            removePopupFn(data.popupId);
                                        }
                                        popupId && removePopupFn(popupId);
                                    },
                                    onClose() {
                                        if (data.popupId) {
                                            removePopupFn(data.popupId);
                                        }
                                        popupId && removePopupFn(popupId);
                                    },
                                });
                                return;
                            }
                            Fai.top.location.href = data.skipUrl || 'index.jsp';
                        } else {
                            _handleAcctError(result);
                        }
                    })
                    .catch(() => {
                        warningMessage(LS.memberSignupRegisterError);
                    });
            },
        },
    });
}

/**
 * 第三方登录绑定其他账号弹窗
 * @param {Object} data - 数据
 */
function showLoginBindOtherAcctPopup(data) {
    _mixinPopupAction(_showLoginBindOtherAcctPopup)(data);
}

/**
 * 第三方登录后设置弹窗，有必填项才出现
 * @param {Object} data - 数据
 */
function showLoginSettingsPopup(data) {
    _mixinPopupAction(_showLoginSettingsPopup)(data);
}

/**
 * 等待微信弹窗数据传回来后的处理
 * @param {Object} memberLoginData - 数据
 */
function _wxLoginListener(memberLoginData) {
    if (Site.wxListener.popupClose == false) {
        return;
    }
    window.clearInterval(Site.wxListener.timer);
    Site.wxListener.popupClose = false;
    let conditionJudge = false;
    if (Site.wxListener.faiOpenIdGreen) {
        conditionJudge = Site.wxListener.w_faiOpenId != null && Site.wxListener.w_faiOpenId != '';
    } else {
        conditionJudge =
            Site.wxListener.w_uid != null &&
            Site.wxListener.w_uid != '' &&
            Site.wxListener.w_faiOpenId != null &&
            Site.wxListener.w_faiOpenId != '';
    }
    if (!conditionJudge) {
        return;
    }
    const url = memberLoginData.skipUrl || Fai.getUrlParam(Fai.top.location.href, 'url');
    const ajaxData = {};
    const optionsList = memberLoginData.regOptionList;
    const hasMobile = optionsList.findIndex((o) => o.fieldKey == 'mobile' && o.otherLoginMust) > -1;
    ajaxData.hasMobile = hasMobile;

    const hasOtherLoginMust = _hasOtherLoginMust(optionsList);
    ajaxData.otherLoginMust = hasOtherLoginMust;

    if (!hasOtherLoginMust) {
        ajaxData.avator = encodeURIComponent(Site.wxListener.wx_avator);
        ajaxData.name = Site.wxListener.wx_name;
    }

    ajaxData.faiOpenIdGreen = Site.wxListener.faiOpenIdGreen;
    ajaxData.faiOpenId = encodeURIComponent(Site.wxListener.w_faiOpenId);
    if (Site.wxListener.faiOpenIdGreen) {
        ajaxData.loginType = 5;
    } else {
        ajaxData.loginType = 4;
        ajaxData.openId = encodeURIComponent(Site.wxListener.w_uid);
        ajaxData.unionId = Site.wxListener.w_unionId;
    }
    const hasFaiOpenId = typeof Site.wxListener.w_faiOpenId != 'undefined' && Site.wxListener.w_faiOpenId;

    otherLoginAction({
        jumpUrl: url,
        ajaxData,
        optionsList,
        loginType: hasFaiOpenId ? 5 : 4,
        info: {
            avatar: Site.wxListener.wx_avator,
            name: Site.wxListener.wx_name,
            wxInfo: Site.wxListener,
        },
    });
}

/**
 * 等待qq数据传回来后的处理
 * @param {Object} memberLoginData - 数据
 */
function _qqLoginListener(memberLoginData) {
    const { regOptionList: optionsList, skipUrl } = memberLoginData;
    if (!Site.qqListener.winOpen.closed) return;
    window.clearInterval(Site.qqListener.timer);
    Site.qqListener.timer = null;

    if (Site.qqListener.q_uid == null || Site.qqListener.q_uid === '') {
        Site.qqListener.q_uid = window.localStorage.getItem('qqLoginOpenId');
        window.localStorage.removeItem('qqLoginOpenId');
    }
    if (Site.qqListener.q_name == null || Site.qqListener.q_name === '') {
        Site.qqListener.q_name = window.localStorage.getItem('qqLoginName');
        window.localStorage.removeItem('qqLoginName');
    }
    if (Site.qqListener.q_avator == null || Site.qqListener.q_avator === '') {
        Site.qqListener.q_avator = window.localStorage.getItem('qqLoginAvator');
        window.localStorage.removeItem('qqLoginAvator');
    }

    // 为什么还要用cookie？ 因为旧的edge和ie不支持localstorage通信 T_T
    if (Site.qqListener.q_uid == null || Site.qqListener.q_uid === '') {
        Site.qqListener.q_uid = $.cookie('_qqLoginOpenId');
    }
    if (Site.qqListener.q_name == null || Site.qqListener.q_name === '') {
        Site.qqListener.q_name = $.cookie('_qqLoginName');
    }
    if (Site.qqListener.q_avator == null || Site.qqListener.q_avator === '') {
        Site.qqListener.q_avator = $.cookie('_qqLoginAvator');
    }
    // 清除cookie
    ['_qqLoginOpenId', '_qqLoginName', '_qqLoginAvator'].forEach((cookieName) => {
        $.cookie(cookieName, '', { expires: -1, path: '/' });
    });

    if (!Site.qqListener.q_uid) {
        console.log('Site.qqListener.q_uid为空');
        return;
    }

    const ajaxData = {
        loginType: 1,
        openId: encodeURIComponent(Site.qqListener.q_uid),
    };
    const hasMobile = optionsList.findIndex((o) => o.fieldKey == 'mobile' && o.otherLoginMust) > -1;
    ajaxData.hasMobile = hasMobile;

    const hasOtherLoginMust = _hasOtherLoginMust(optionsList);
    ajaxData.otherLoginMust = hasOtherLoginMust;

    if (!hasOtherLoginMust) {
        ajaxData.avator = encodeURIComponent(Site.qqListener.q_avator);
        ajaxData.name = encodeURIComponent(Site.qqListener.q_name);
    }
    otherLoginAction({
        jumpUrl: skipUrl || Fai.getUrlParam(Fai.top.location.href, 'url'),
        ajaxData,
        optionsList,
        loginType: 1,
        info: {
            avatar: Site.qqListener.q_avator,
            name: Site.qqListener.q_name,
            qqInfo: Site.qqListener,
        },
    });
}

function getLoginMsg(rt) {
    const msgMap = {
        1: LS.memberInputAcct,
        2: LS.memberInputPwd,
        3: LS.memberInputCaptcha,
        4: LS.memberDialogPlzEnterMobile,
        5: LS.memberDialogEnterCode,
        6: LS.memberDialogGetCodeFirst,
        11: LS.memberLoginFirst,
        12: LS.memberLoginSignup,
        13: LS.memberLoginToView,
        14: LS.memberLoginNoPermission,
        '-3': LS.memberPwdError,
        '-301': LS.memberCaptchError,
        '-302': LS.memberAcctError,
        '-303': LS.memberNoAuth,
        '-305': LS.memberPwdError,
        '-307': LS.loginUseAcct,
        '-2003': '该账号已多次登录失败，请更换账号重试',
        '-2004': '账号/密码有误，请重新输入',
    };
    return msgMap[rt] || LS.memberLoginError;
}

function otherLoginAction({ jumpUrl = '/', ajaxData, optionsList, loginType, info }) {
    otherLoginMember({
        ...ajaxData,
    })
        .then(async (result) => {
            if (result.success) {
                $('#_TOKEN')?.attr('value') || $('title')?.after(result._TOKEN);
                await bizShared?.clueReuqestVm?.authRecordLoginOther();
                Fai.top.location.href = jumpUrl;
                return;
            }

            if (result.active) {
                // 邮箱未激活
                _showLoginActiveEmailMsg({
                    emailName: result.mail,
                    acctName: result.memName,
                });
            } else if (result.rt == -3) {
                const isBindOtherAcc = typeof result.bindOtherAcct != 'undefined' && result.bindOtherAcct;
                const data = {
                    optionsList,
                    loginType,
                    mobileCtNameList: result.mobileCtNameList,
                    signNeenMobile: result.signNeenMobile,
                    skipUrl: jumpUrl,
                    info,
                };
                if (isBindOtherAcc) {
                    showLoginBindOtherAcctPopup(data);
                } else {
                    showLoginSettingsPopup(data);
                }
            } else {
                Fai.ing(getLoginMsg(result.rt), true);
            }
        })
        .catch(() => {
            Fai.ing(LS.memberLoginError, true);
        });
}

/**
 * 第三方微信登录（手机视图）
 * @param {Object} memberLoginData - 登录数据
 */
function loginByWechatInMobi(memberLoginData) {
    const {
        mobiWxData: { aesKey, lanCode, returnUrl },
    } = memberLoginData;
    $.cookie('_wxLoginKey', aesKey, {
        expires: 1,
    });
    const url = `/${lanCode}/responsiveMobiWxLoginConfirm.jsp?url=${returnUrl}&_key=${aesKey}`;
    window.location.replace(url);
}

/**
 * 第三方微信登录
 * @param {Object} memberLoginData - 登录数据
 */
function loginByWechat(memberLoginData) {
    if (window.self !== window.top) {
        JZ.warningMessage(
            `<span>预览状态下不支持登录会员，建议${
                sessionStorage.getItem('_preview')
                    ? `<a href="${window._store.state.currentWebUrl}" style="color: red" target="_blank">通过域名访问网站</a>`
                    : `通过域名访问网站`
            }以体验功能</span>`,
            true
        );
        return;
    }
    logDog(201523, 5);
    const isMobi = $('html').hasClass('jz_screen_mobi');
    if (isMobi) {
        loginByWechatInMobi(memberLoginData);
        return;
    }
    // 微信弹窗传出来的参数
    Site.wxListener = {
        timer: null,
        winOpen: '',
        w_uid: '',
        w_faiOpenId: '',
        faiOpenIdGreen: '',
        wx_name: '',
        wx_avator: '',
        popupId: '',
        popupClose: false,
    };

    const popupOptions = {
        title: LS.memberWxLgn,
        frameSrcUrl: `/${Fai.top._curLanCode}/newSiteWX.jsp?isResponsive=true`,
        width: 400,
        height: 377,
        popUpWinClass: 'fk-popupWindowVT_position--fixed',
        closeFunc() {
            $('html, body').css('overflow', '');
            if (Site.wxListener.timer !== null && !Site.wxListener.popupClose) {
                window.clearInterval(Site.wxListener.timer);
            }
        },
    };

    if (memberLoginData.fromLoginPopup) {
        popupOptions.popUpWinZIndex = 9994; // 登录弹窗为9993
    }

    $('html, body').css('overflow', 'hidden');
    Site.wxListener.popupId = Fai.popupWindowVersionTwo.createPopupWindow(popupOptions).getPopupWindowId();

    Site.wxListener.timer = window.setInterval(_wxLoginListener, 1000, memberLoginData);
}

/**
 * 第三方微博登录（手机视图）
 * @param {Object} memberLoginData - 登录数据
 */
function loginBySinaInMobi(memberLoginData) {
    if (Fai.getUrlParam(window.location.href, '_preview') === 'true') {
        Fai.ing('功能不支持预览，可前往手机设备上体验。', true);
        return;
    }
    const {
        sinaData: { mobiAesKey, sinaReUri, appKey },
    } = memberLoginData;
    $.cookie('_wbLoginKey', mobiAesKey, {
        expires: 1,
    });
    window.location.href = `//api.weibo.com/oauth2/authorize?client_id=${appKey}&display=mobile&response_type=code&redirect_uri=${sinaReUri}/wbLogin.jsp?isRMobi=true`;
}

/**
 * 第三方微博登录
 * @param {Object} memberLoginData - 登录数据
 */
function loginBySina(memberLoginData) {
    if (window.self !== window.top) {
        JZ.warningMessage(
            `<span>预览状态下不支持登录会员，建议${
                sessionStorage.getItem('_preview')
                    ? `<a href="${window._store.state.currentWebUrl}" style="color: red" target="_blank">通过域名访问网站</a>`
                    : `通过域名访问网站`
            }以体验功能</span>`,
            true
        );
        return;
    }
    logDog(201523, 7);
    const { sinaData } = memberLoginData;
    if ($.isEmptyObject(sinaData)) return;
    const isMobi = $('html').hasClass('jz_screen_mobi');
    if (isMobi) {
        loginBySinaInMobi(memberLoginData);
        return;
    }
    Site.wbListener = {
        timer: '',
        winOpen: '',
        w_uid: '',
        loading: false,
        wb_name: '',
        wb_avator: '',
    };
    const url = `//api.weibo.com/oauth2/authorize?client_id=${sinaData.appKey}&response_type=code&redirect_uri=${sinaData.sinaReUri}/wbLogin.jsp`;
    Site.wbListener.winOpen = window.open(
        url,
        '',
        'height=525,width=585, toolbar=no, menubar=no, scrollbars=no, status=no, location=yes, resizable=yes'
    );
    if (Site.wbListener.loading) return;
    window.addEventListener(
        'message',
        (event) => {
            const data = $.parseJSON(event.data);
            Site.wbListener.w_uid = data.uid;
            Site.wbListener.wb_name = data.name;
            Site.wbListener.wb_avator = data.w_avator;
            Site.wbListener.winOpen.close();
            Site.wbListener.loading = false;
            handleSinaLogin(memberLoginData);
        },
        false
    );
    Site.wbListener.loading = true;
}

function handleSinaLogin({ regOptionList: optionsList, skipUrl }) {
    if (!(Site.wbListener.w_uid != null && Site.wbListener.w_uid != '')) {
        return;
    }
    const ajaxData = {
        loginType: 2,
        openId: encodeURIComponent(Site.wbListener.w_uid),
    };
    const hasMobile = optionsList.findIndex((o) => o.fieldKey == 'mobile' && o.otherLoginMust) > -1;
    ajaxData.hasMobile = hasMobile;

    const hasOtherLoginMust = _hasOtherLoginMust(optionsList);
    ajaxData.otherLoginMust = hasOtherLoginMust;

    if (!hasOtherLoginMust) {
        ajaxData.avator = encodeURIComponent(Site.wbListener.wb_avator);
        ajaxData.name = encodeURIComponent(Site.wbListener.wb_name);
    }

    otherLoginAction({
        jumpUrl: skipUrl || Fai.getUrlParam(Fai.top.location.href, 'url'),
        ajaxData,
        optionsList,
        loginType: 2,
        info: {
            avatar: Site.wbListener.wb_avator,
            name: Site.wbListener.wb_name,
            sinaInfo: Site.wbListener,
        },
    });
}

/**
 * 检查输入
 * @param {Number} moduleId 模块id
 * @returns {Object} bar
 * 			{Boolean} bar.valid 是否通过
 * 			{String} bar.data 登录需要的数据json格式
 */
function _checkValid(moduleId) {
    const checkResult = {
        valid: false,
        data: '',
    };

    const $module = $('#module' + moduleId);
    const acct = $.trim($module.find('.J_memberAcctInput').val());
    if (acct == null || acct == '') {
        warningMessage(LS.memberInputAcct, true);
        return checkResult;
    }
    let pwd = $module.find('.J_memberPwdInput').val();
    if (pwd == null || pwd == '') {
        warningMessage(LS.memberDialogPleaseEnterPwd, true);
        return checkResult;
    }
    const needCaptcha = $module.find('.J_memberLoginCaptcha').css('display') != 'none';
    const captcha = $module.find('.J_memberCaptchaInput').val();
    if (needCaptcha) {
        if (captcha == null || captcha == '') {
            warningMessage(LS.memberDialogEnterCode, true);
            return checkResult;
        }
    }

    pwd = $.md5(pwd);

    let autoLogin = false;
    const autoLoginBtn = $module.find('.J_memberLoginAuto');
    if (autoLoginBtn && autoLoginBtn.attr('checked')) {
        autoLogin = true;
    }

    const data = `acct=${acct}&pwd=${Fai.encodeUrl(pwd)}&captcha=${Fai.encodeUrl(captcha)}&autoLogin=${Fai.encodeUrl(
        autoLogin
    )}`;

    checkResult.data = data;
    checkResult.valid = true;
    return checkResult;
}

/**
 * 禁用登录按钮
 * @param {Number} moduleId 模块id
 */
function _disableLoginBtn(moduleId = 0) {
    $('#module' + moduleId)
        .find('.J_memberLoginBtn')
        .prop('disabled', true);
    $('#module' + moduleId)
        .find('.J_memberFastLoginBtn')
        .prop('disabaled', true);
}

/**
 * 激活邮件弹窗
 * @param {String} emailName 邮箱名
 * @param {String} acctName 账号名
 * @param {Function} onConfirm 成功回调
 * @param {Function} onClose 关闭回调
 */
function _showLoginActiveEmailMsg({ emailName = '', acctName = '', onConfirm, onClose }) {
    // 进来先发送一次邮箱，再创建弹窗
    _sendEmailActive({ emailName, acctName, needSuccess: false });
    createDialog({
        content: Fai.format(LS.memberSignupRegisterSuccess, emailName),
        confirmButtonText: LS.memberSignupConfirm,
        cancelButtonText: LS.memberSignupResend,
        onConfirm() {
            typeof onConfirm === 'function' && onConfirm();
        },
        onCancel() {
            _sendEmailActive({ emailName, acctName });
        },
        onClose() {
            typeof onClose === 'function' && onClose();
        },
        showClose: true,
    });
}

/**
 * 错误信息弹窗
 * @param {Object} data 后台返回的数据
 * @param {Number} moduleId 模块id
 */
async function _showLoginErrorMsg({ data = {}, moduleId = 0 }) {
    const { success, showTips } = await checkMemberSameTimeLogin();
    switch (data.rt) {
        case 0:
            // do nothing
            break;
        case 1:
            warningMessage(LS.memberInputAcct, true);
            break;
        case 2:
            warningMessage(LS.memberInputPwd, true);
            break;
        case 3:
            warningMessage(LS.memberInputCaptcha, true);
            break;
        case 4:
            warningMessage(LS.memberDialogPlzEnterMobile, true);
            break;
        case 5:
            warningMessage(LS.memberDialogEnterCode, true);
            break;
        case 6:
            warningMessage(LS.memberDialogGetCodeFirst, true);
            break;
        case 11:
            warningMessage(LS.memberLoginFirst, true);
            break;
        case 12:
            successMessage(LS.memberLoginSignup, true);
            break;
        case 13:
            if (success === true && showTips === true) {
                return showSimultaneousLoginPopup();
            } else {
                warningMessage(LS.memberLoginToView, true);
            }
            break;
        case 14:
            warningMessage(LS.memberLoginNoPermission, true);
            break;
        case -3:
            warningMessage(LS.memberPwdError, true);
            break;
        case -301:
            warningMessage(LS.memberCaptchError, true);
            refreshCaptcha(moduleId);
            break;
        case -302:
            warningMessage(LS.memberAcctError, true);
            break;
        case -303:
            warningMessage(LS.memberNoAuth, true);
            break;
        case -305:
            warningMessage(LS.memberPwdError, true);
            break;
        case -307:
            warningMessage(LS.loginUseAcct, true);
            break;
        case -2000:
            warningMessage(LS.loginUseAcct, true);
            break;
        case -2001:
            warningMessage(data.msg, true);
            break;
        case -2002:
            warningMessage(LS.errorNoAuth, true);
            break;
        case -2003:
            warningMessage('该账号已多次登录失败，请更换账号重试', true);
            break;
        default:
            warningMessage(LS.memberLoginError, true);
    }

    if (data.captcha) {
        _showCaptcha(moduleId);
    }
}

/**
 * 刷新验证码
 * @param {Number} moduleId 模块id
 */
function refreshCaptcha(moduleId) {
    $('#module' + moduleId)
        .find('.J_memberCaptchaImg')
        .attr('src', `/validateCode.jsp?${parseInt(Math.random() * 1000)}`);
}

/**
 * 获取手机短信验证码
 * @param {Number} moduleId 模块id
 */
function getAcquireCode(moduleId) {
    const disabled = $('#module' + moduleId)
        .find('#acquireCode')
        .attr('data-disabled');
    if (disabled == 'true') {
        return;
    }

    const acquirer = $('#module' + moduleId)
        .find('#acquirerInput')
        .val();
    if (!acquirer || !Fai.isMobile(acquirer)) {
        warningMessage(LS.mobileNumRegular, true);
        return;
    }

    new Promise((resolve, reject) => {
        sendMobileLoginCode({
            memName: acquirer,
            new: true,
        })
            .then((data) => {
                data.success ? resolve(data) : reject(data);
            })
            .catch(() => {
                warningMessage(LS.js_argsError, true);
            });
    })
        .then((data = {}) => {
            $('#module' + moduleId)
                .find('#memCodeSign')
                .val(data.mobileCodeSign);
            successMessage(LS.memberDialogSendMobileCode);
            _loginAcquireCodeCountdown(moduleId);
        })
        .catch((data = {}) => {
            if (data.argErr) {
                warningMessage(LS.memberDialogSendMobileClose, true);
            } else if (data.apiKeyNotFound) {
                warningMessage(LS.memberDialogYunPianErr, true);
            } else if (data.tplNotFound) {
                warningMessage(LS.memberDialogMobileTplErr, true);
            } else if (data.notFound) {
                warningMessage(LS.memberDialogNotFound, true);
            } else if (data.limitOne) {
                warningMessage(LS.getMobileOneMin, true);
            } else if (data.limitTwo) {
                warningMessage(LS.getMobileHalfHour, true);
            } else if (data.noMoney) {
                warningMessage(LS.memberDialogMobileMoneyErr, true);
            } else if (data.mobileErr) {
                warningMessage(LS.memberDialogSendMobileCodeErr, true);
            } else if (data.sendLimit) {
                warningMessage(LS.memberDialogSendMobileCodeLimit, true);
            } else if (data.mobileSysErr) {
                warningMessage(LS.memberDialogSendMobileSysErr, true);
            } else if (data.tplNationErr) {
                warningMessage(LS.mobileNationTplErr, true);
            } else if (data.systemErr) {
                warningMessage(LS.js_systemError, true);
            } else if (data.rt === -301) {
                warningMessage(LS.memberDialogMobileMoneyErr, true);
            } else {
                warningMessage(LS.js_argsError, true);
            }
        });
}

/**
 * 快捷登录
 * @param {Number} moduleId 模块id
 */
function memberFastLogin(moduleId, extendParam) {
    if (window.self !== window.top) {
        JZ.warningMessage(
            `<span>预览状态下不支持登录会员，建议${
                sessionStorage.getItem('_preview')
                    ? `<a href="${window._store.state.currentWebUrl}" style="color: red" target="_blank">通过域名访问网站</a>`
                    : `通过域名访问网站`
            }以体验功能</span>`,
            true
        );
        return;
    }
    _disableLoginBtn(moduleId);

    const $module = $('#module' + moduleId);
    const acquirer = $module.find('#acquirerInput').val();
    const code = $module.find('#memCodeInput').val();
    const needCaptcha = $module.find('.J_memberLoginCaptcha').css('display') != 'none';
    const memCodeSign = $module.find('#memCodeSign').val();
    const captcha = $module.find('.J_memberCaptchaInput').val();
    const autoLogin = $module.find('.J_memberLoginAuto').prop('checked');

    if (!acquirer) {
        warningMessage(LS.memberDialogPlzEnterMobile, true);
        return;
    }
    if (!code) {
        warningMessage(LS.memberDialogEnterCode, true);
        return;
    }
    if (!memCodeSign) {
        warningMessage(LS.memberDialogGetCodeFirst, true);
        return;
    }
    if (needCaptcha && !captcha) {
        warningMessage(LS.msgBoardInputValidateCode, true);
        return;
    }

    new Promise((resolve, reject) => {
        checkMobileCodeGeneral({
            memCode: code,
            memCodeSign: memCodeSign,
            acquirer: acquirer,
            mode: 'mobile',
        }).then((data) => {
            data.success ? resolve(data) : reject(data);
        });
    })
        .then((data = {}) => {
            return new Promise((resolve, reject) => {
                loginMemberByMobile({
                    memId: data.memId,
                    captcha: captcha,
                    autoLogin: autoLogin,
                })
                    .then(async (data) => {
                        _enableLoginBtn(moduleId);
                        $('#_TOKEN')?.attr('value') || $('title')?.after(data._TOKEN);
                        await bizShared?.clueReuqestVm?.authRecordLoginOther();
                        data.success ? resolve(data) : reject(data);
                    })
                    .catch(() => {
                        _enableLoginBtn(moduleId);
                        warningMessage(LS.memberInputAcct, true);
                        $module.find('.J_memberAcctInput').focus();
                    });
            });
        })
        .then(() => {
            if (extendParam.skipUrl) {
                window.location.href = extendParam.skipUrl;
            } else {
                window.location.href =
                    Fai.getUrlParam(window.location.href, 'url') ||
                    Site.preprocessUrl({ path: '', oldPath: '' }) + '/index.jsp';
            }
        })
        .catch((data = {}) => {
            if (data.notFound) {
                warningMessage(LS.memberDialogNotFound, true);
            } else if (data.rt == 1) {
                _enableLoginBtn(moduleId);
                warningMessage(LS.memberSignupRegisterCaptchaNotMatch, true);
            }

            if (data.active) {
                _showLoginActiveEmailMsg({
                    emailName: data.mail,
                    acctName: data.memName,
                });
            } else if (data.rt == -2000) {
                window.location.href = Site.preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?errno=-307';
            } else {
                _showLoginErrorMsg({
                    data,
                });
            }
        });
}

let findPwdVm = null;
/**
 * 显示找回密码面板
 */
function memberFdPwdStepOne() {
    if ($(window).width() > 768) {
        Fai.popupWindow({
            divContent: '<div id="findPwdPopup"></div>',
            title: LS.memberDialogFwdStepOneTitle,
            width: 440,
            height: 360,
            frameScrolling: false,
            popUpWinZIndex: 9032,
            left: $(window).scrollLeft() + $(window).width() / 2 - 440 / 2,
            top: $(window).scrollTop() + $(window).height() / 2 - 360 / 2,
            version: 2,
            closeFunc() {
                findPwdVm && findPwdVm.$destroy();
            },
        });
        initFindPwdPopup();
    } else {
        $('<div id="findPwdPopup"></div>').appendTo('body');
        initFindPwdPopup();
    }
}

function initFindPwdPopup() {
    findPwdVm = new Vue({
        el: '#findPwdPopup',
        components: {
            FindPwdPopup: () => import('./findPwdPopup.vue'),
        },
        methods: {
            onBack() {
                findPwdVm && findPwdVm.$destroy();
            },
        },
        template: `<FindPwdPopup @on-back="onBack" />`,
        store: window._store,
    });
}

function showSimultaneousLoginPopup() {
    const template = `
        <div class="visitor-popup-box">
            <div class="visitor-popup-content">
                <p>您的账号已在另一台设备登录，如需继续浏览请重新登录</p>
            </div>
            <button id="closePopup" class="visitor-popup-button">确 定</button>
        <div>
    `;
    const popupBox = Site.popupBox({
        width: 350,
        htmlContent: template,
        noTitle: true,
    });

    $('#closePopup').on('click', function () {
        popupBox.find('.popupBClose').trigger('click');
    });

    return popupBox;
}

/**
 * 第三方qq登录
 * @param {Object} memberLoginData - 登录数据
 */
function loginByQQ(memberLoginData) {
    if (window.self !== window.top) {
        JZ.warningMessage(
            `<span>预览状态下不支持登录会员，建议${
                sessionStorage.getItem('_preview')
                    ? `<a href="${window._store.state.currentWebUrl}" style="color: red" target="_blank">通过域名访问网站</a>`
                    : `通过域名访问网站`
            }以体验功能</span>`,
            true
        );
        return;
    }
    logDog(201523, 6);
    const { qqData } = memberLoginData;
    if ($.isEmptyObject(qqData)) return;
    const isMobi = $('html').hasClass('jz_screen_mobi');
    if (isMobi) {
        loginByQQInMobi(memberLoginData);
        return;
    }

    if (Site.qqListener && Site.qqListener.timer) {
        window.clearInterval(Site.qqListener.timer);
    }

    Site.qqListener = {
        timer: null,
        winOpen: '',
        q_uid: '',
        q_name: '',
        q_avator: '',
        loading: false,
    };
    const url = `//graph.qq.com/oauth2.0/authorize?client_id=${qqData.qqAppId}&response_type=token&scope=all&redirect_uri=${qqData.qqReUri}/qqLoginPC.jsp`;
    Site.qqListener.winOpen = window.open(
        url,
        '',
        'height=525,width=585, toolbar=no, menubar=no, scrollbars=no, status=no, location=yes, resizable=yes'
    );
    Site.qqListener.timer = window.setInterval(_qqLoginListener, 1000, memberLoginData);
}

// 处理第三方跳转回来登录
function otherLoginAfterUrlBack({ loginData, regOptionList: optionsList, skipUrl }) {
    const { avator, uid, uname, loginTypeNum } = loginData;
    const ajaxData = {};
    const hasMobile = optionsList.findIndex((o) => o.fieldKey == 'mobile' && o.otherLoginMust) > -1;
    ajaxData.hasMobile = hasMobile;

    const hasOtherLoginMust = _hasOtherLoginMust(optionsList);
    ajaxData.otherLoginMust = hasOtherLoginMust;

    if (!hasOtherLoginMust) {
        ajaxData.avator = encodeURIComponent(avator);
        ajaxData.name = uname;
    }
    ajaxData.openId = encodeURIComponent(uid);

    if (loginTypeNum == 4 || loginTypeNum == 5) {
        // 微信登录
        const { faiOpenIdGreen, unionId, faiOpenId } = loginData;
        ajaxData.faiOpenIdGreen = faiOpenIdGreen;
        ajaxData.faiOpenId = encodeURIComponent(faiOpenId);
        if (faiOpenIdGreen) {
            ajaxData.loginType = 5;
        } else {
            ajaxData.loginType = 4;
            if (typeof unionId !== 'undefined') {
                ajaxData.unionId = unionId;
            }
        }
        otherLoginAction({
            jumpUrl: skipUrl || Fai.getUrlParam(Fai.top.location.href, 'url'),
            ajaxData,
            optionsList,
            loginType: faiOpenIdGreen ? 5 : 4,
            info: {
                avatar: avator,
                name: uname,
                wxInfo: {
                    wx_avator: avator,
                    wx_name: uname,
                    w_uid: uid,
                    w_unionId: unionId,
                    w_faiOpenId: faiOpenId,
                },
            },
        });
    } else if (loginTypeNum == 2) {
        // 新浪微博登录
        ajaxData.loginType = 2;
        otherLoginAction({
            jumpUrl: skipUrl || Fai.getUrlParam(Fai.top.location.href, 'url'),
            ajaxData,
            optionsList,
            loginType: 2,
            info: {
                avatar: avator,
                name: uname,
                sinaInfo: {
                    w_uid: uid,
                    wb_name: uname,
                    wb_avator: avator,
                },
            },
        });
    } else if (loginTypeNum == 1) {
        // qq登录
        ajaxData.loginType = 1;
        otherLoginAction({
            jumpUrl: skipUrl || Fai.getUrlParam(Fai.top.location.href, 'url'),
            ajaxData,
            optionsList,
            loginType: 1,
            info: {
                avatar: avator,
                name: uname,
                qqInfo: {
                    q_uid: uid,
                    q_avator: avator,
                    q_name: uname,
                },
            },
        });
    }
}

/**
 * 第三方qq登录（手机视图）
 * @param {Object} memberLoginData - 登录数据
 */
function loginByQQInMobi(memberLoginData) {
    const {
        qqData: { mobiAesKey, qqAppId, qqReUri },
    } = memberLoginData;
    $.cookie('_qqLoginKey', mobiAesKey, {
        expires: 1,
    });
    window.location.href = `//graph.qq.com/oauth2.0/authorize?client_id=${qqAppId}&display=mobile&response_type=token&scope=all&redirect_uri=${qqReUri}/qqLogin.jsp`;
}

export {
    showMemberLoginMsg,
    _loginAcquireCodeCountdown,
    _showCaptcha,
    _enableLoginBtn,
    _sendEmailActive,
    _getReturnUrl,
    _hasOtherLoginMust,
    _getLoginInfoByLoginType,
    _handleAcctError,
    _handleBindAcct,
    _showLoginBindOtherAcctPopup,
    _createMobiPopup,
    _removeMobiPopup,
    _showLoginSettingsPopup,
    showLoginBindOtherAcctPopup,
    showLoginSettingsPopup,
    _wxLoginListener,
    _qqLoginListener,
    getLoginMsg,
    otherLoginAction,
    loginByWechatInMobi,
    loginByWechat,
    loginBySinaInMobi,
    loginBySina,
    handleSinaLogin,
    _checkValid,
    _disableLoginBtn,
    _showLoginActiveEmailMsg,
    _showLoginErrorMsg,
    refreshCaptcha,
    getAcquireCode,
    memberFastLogin,
    memberFdPwdStepOne,
    loginByQQ,
    otherLoginAfterUrlBack,
    loginByQQInMobi,
    showSimultaneousLoginPopup,
};
