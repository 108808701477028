export const pictureChangeDefaultImage = (rootVm) => {
    const tabs = rootVm.module.blob0.tabs || [];
    if (tabs.length === 0) return;

    const styleId = rootVm.module.prop2 || 0;

    const pathPrefix = `${rootVm.$store.state.resRoot}/image/rimage/module/picture_tab/`;

    tabs.forEach((tab) => {
        const setImage = (picIdKey, picPathKey, picId) => {
            if (!tab[picIdKey]) {
                rootVm.$set(tab, picPathKey, picId);
            }
        };
        const num = ((tab.id - 1) % 3) + 1;
        if ([0, 1].includes(styleId)) {
            const path = `${pathPrefix}num${num}.png`;
            setImage('picId', 'picPath', path);
            setImage('picHoverId', 'picHoverPath', path);
        }

        if (styleId === 2) {
            setImage('picId', 'picPath', `${pathPrefix}pic${num}.png`);
            setImage('picHoverId', 'picHoverPath', `${pathPrefix}pic${num}hover.png`);
        }

        if ([3, 4].includes(styleId)) {
            setImage('picId', 'picPath', `${pathPrefix}Slpic${num}.png`);
            setImage('picHoverId', 'picHoverPath', `${pathPrefix}Slpic${num}.png`);
        }
    });
};
