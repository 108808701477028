import { isBrowserEnv } from '@jz/utils';
import { Pay, PayProduct } from '@/services';
import { checkOrderFinPay } from '@/api/order';
import { onMounted, set } from 'vue';

function checkPayStatus({ orderId, retryTimes }, topResolve, topReject) {
    return new Promise((resolve, reject) => {
        if (!topResolve) {
            topResolve = resolve;
        }
        if (!topReject) {
            topReject = reject;
        }
        checkOrderFinPay({
            orderId,
        })
            .then((data) => {
                if (data.success && data.orderFinPay) {
                    topResolve(data);
                } else {
                    if (retryTimes > 0) {
                        setTimeout(() => {
                            checkPayStatus(
                                {
                                    orderId,
                                    retryTimes: retryTimes - 1,
                                },
                                topResolve,
                                topReject
                            );
                        }, 1000);
                    } else {
                        topReject(data);
                    }
                }
            })
            .catch((err) => {
                topReject(err);
            });
    });
}

/**
 * PayPal 支付成功后检查支付状态，回调没那么及时
 */
export function useCheckPaySuccess(orderInfo) {
    if (window._store.state.manageMode || !isBrowserEnv()) {
        return;
    }

    onMounted(() => {
        const href = Fai.top.window.location.href;
        const orderIdFromParam = Number(Fai.getUrlParam(href, 'orderId'));
        const success = Fai.getUrlParam(href, 'success') === '1';
        const random = Fai.getUrlParam(href, 'random');
        if (!success) {
            return;
        }

        const localRandom = window.localStorage.getItem(Pay.PAY_RESULT_RANDOM_LOCAL_STORAGE_KEY);
        if (!localRandom || localRandom != random) {
            return;
        }
        // window.localStorage.removeItem(Pay.PAY_RESULT_RANDOM_LOCAL_STORAGE_KEY);

        if (!orderInfo) {
            return;
        }

        orderInfo = orderInfo.value;

        const orderId = orderInfo.id;
        const orderStatus = orderInfo.status;

        if (orderStatus !== PayProduct.payProductStatus.WAIT_PAY || orderId !== orderIdFromParam) {
            return;
        }

        orderInfo.status = PayProduct.payProductStatus.PAY_SUCCESS;
        set(orderInfo, 'realPaySuccessFromCheck', false);

        checkPayStatus({ orderId, retryTimes: 60 }).then((data) => {
            set(orderInfo, 'realPaySuccessFromCheck', true);
            set(orderInfo, 'payMode', data.payMode);
        });
    });
}
