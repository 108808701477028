import { deepExtendClone } from '@jz/utils';
import { logJZFdp } from '@/site/shared/log/index.js';
import { warningMessage } from '@/site/shared/dialog/index.js';
export const openPopupZone = async function (popupZoneId = -1, isEdit = false) {
    try {
        const {
            data: { popupRowInfo, moduleList, popupCss = '', success, msg },
        } = await getPopupZoneInfo(popupZoneId);

        if (!success) {
            warningMessage(msg, true);
            return -1;
        }

        if (popupZoneId === -1) {
            refreshPopupList();
        }

        if (!window._store.state.manageMode) {
            appendPopupCss(popupCss);
        }

        let { id, pattern } = popupRowInfo;

        if (window._store.state.device === 'mobi' && pattern.popup.mobiShowPopup === 0) {
            return -1;
        }
        window._store.commit('setPauseDataWatch', true);
        Vue.set(window._store.state.rows, `row${id}`, popupRowInfo);

        moduleList.forEach((module) => {
            Vue.set(window._store.state.modules, `module${module.id}`, module);
        });
        window._store.commit('setPauseDataWatch', false);
        window._store.state.popupZone.rowId = null;
        Vue.nextTick(() => {
            window._store.state.popupZone.rowId = id;

            if (window._store.state.manageMode) {
                enterEditLayout();
            }
            window._store.state.popupZone.isEdit = isEdit;
        });

        logJZFdp('jz_pop_epo', {
            jz_content_terminal: '自适应',
            jz_free_text1: window._store.state.manageMode ? '管理态' : '访客态',
            jz_version: bizShared.context.jzVersion,
            jz_zsy_view: window._store.state.device === 'mobi' ? '手机视图' : '电脑视图',
        });

        return id;
    } catch (err) {
        console.error(err);
    }
};

export const delPopupZone = async function (id) {
    await jzRequest.post('/rajax/row_h.jsp', {
        data: {
            cmd: 'delWafCk_delPopupRow',
            id,
        },
    });
    Vue.$famessage.success('删除成功', 3, null, true);
    refreshPopupList(id);
};

export async function refreshPopupList(id) {
    const {
        data: { popupRowList },
    } = await jzRequest.get('/rajax/row_h.jsp', {
        params: {
            cmd: 'getWafNotCk_getPopupRowList',
        },
    });
    const $linkSetting = $('iframe[src*="linkSetting"]');
    if ($linkSetting.length) {
        $linkSetting[0].contentWindow.pageData.popupZoneList = deepExtendClone(popupRowList);
        if (popupRowList.length === 0 || $linkSetting[0].contentWindow.pageData.popupZoneId === id) {
            $linkSetting[0].contentWindow.pageData.popupZoneId = -1;
        }
    }
    window._store.commit('popupZone/setPopupList', deepExtendClone(popupRowList));
}

export const hidePopupZone = function () {
    if (
        window._store.state.manageMode &&
        window._store.getters['popupZone/isEditMode'] &&
        !confirm('您的修改尚未保存，确定要离开吗？')
    ) {
        return false;
    }
    // 退出删除掉通栏和模块
    const popupZoneRowId = window._store.state.popupZone.rowId;
    const popupZoneModuleIdList = Array.from($('.jz_popup_zone .jz_module')).map((moduleDom) =>
        $(moduleDom).attr('id')
    );

    window._store.state.popupZone.isEdit = false;

    Vue.delete(window._store.state.rows, `row${popupZoneRowId}`);

    popupZoneModuleIdList.forEach((id) => {
        Vue.delete(window._store.state.modules, `module${id}`);
    });

    if (window._store.state.manageMode) {
        exitEditLayout();
    }

    setTimeout(() => {
        window._store.state.popupZone.rowId = null;
    });

    window._store.state.popupZone.isIndexPopup = false;
};

export const closePopupZone = function () {
    hidePopupZone();
};

function changeLinkSettingZIndex(zIndex) {
    if ($('.fk-popupWindowVT').length) {
        const $linkSetting = $('.fk-popupWindowVT');
        const $linkSettingBg = $(`.jz_popup_window_bg`);
        $linkSetting.css('z-index', zIndex);
        $linkSettingBg.css('z-index', zIndex);
    }
    if (window.moduleManagePopup && window.moduleManagePopup.popupVm.zIndex) {
        window.moduleManagePopup.popupVm.zIndex = zIndex;
    }
}

function exitEditLayout() {
    //JZ.changeModuleDisableAdd(false);

    changeLinkSettingZIndex(9032);

    $('.jz_editing_layout').remove();

    if ($('#moduleManageApp').length) {
        window._store.commit('manage/funcPanel/funcPanelChange', {
            active: true,
            type: 'module',
            showAnimation: true,
        });
    }
}

function enterEditLayout() {
    window._store.commit('manage/funcPanel/funcPanelActiveChange', false);
    window._store.commit('manage/editPanel/editPanelActiveChange', false);
    Root._voidVm.$emit('v-hovermenu.removeHoverMenu');

    //JZ.changeModuleDisableAdd(true);

    changeLinkSettingZIndex(9030);
}

function appendPopupCss(popupCss) {
    if (popupCss) {
        let styleEl = null;
        if (document.getElementById('popupZoneStyle')) {
            styleEl = document.getElementById('popupZoneStyle');
            styleEl.innerText = popupCss;
        } else {
            styleEl = document.createElement('style');
            styleEl.id = 'popupZoneStyle';
            document.head.appendChild(styleEl);
            styleEl.innerText = popupCss;
        }
    }
}

async function getPopupZoneInfo(popupZoneId) {
    if (popupZoneId === -1) {
        let info = {
            type: 8,
        };
        let pattern = {
            pc: {
                pl: 0,
                pr: 0,
            },
        };
        info.pattern = JSON.stringify(pattern);

        Vue.$famessage.warning('正在添加弹窗…', 3, null, true);

        return await jzRequest.post('/rajax/row_h.jsp', {
            data: {
                cmd: 'addWafCk_addPopupRow',
                popupRowInfo: info,
            },
        });
    } else {
        return await jzRequest.post('/rajax/row_h.jsp', {
            data: {
                cmd: 'getWafNotCk_getPopupRow',
                id: popupZoneId,
            },
        });
    }
}
