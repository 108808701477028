import { getCarouselRowsEffect } from '@shared/visitor/carouselRowsEffects/index.js';

export const ANIMATION_DURATION = 400;

const setPauseDataWatch = () => {
    if (!window._store.state.manageMode) {
        return;
    }
    window._store.dispatch('manage/pauseDataWatch');
};

export const setColHoverStatus = async ({ rowId, colIndex, isHover = false }) => {
    const rowData = window._store.state.rows[`row${rowId}`];
    if (!rowData) {
        console.error(`row${rowId} 不存在`);
        return;
    }

    const isCarouselRow = window._store.getters.isCarouselRow(rowId);

    let colData;
    if (isCarouselRow) {
        const currentCarouselIndex = getCarouselRowsEffect(rowId).carouselDotIdx - 1;
        colData = rowData.pattern.pc.e.rbs[currentCarouselIndex].cols[colIndex];
    } else {
        colData = rowData.cols[colIndex];
    }

    if (!colData) {
        console.error(`row${rowId} 的 col${colIndex} 不存在`);
        return;
    }

    if (!colData.defaultShow) {
        setPauseDataWatch();
        colData.defaultShow = true;
        await jzUtils.wait(16);
    }

    if (window._store.state.manageMode) {
        setPauseDataWatch();
        if (isHover) {
            // 切换到 hover 状态时，需要先把 zIndex 提高再做动画
            Vue.set(colData, 'increaseZIndex', true);
            await Vue.nextTick();

            setPauseDataWatch();
            Vue.set(colData, 'isHovering', true);
        } else {
            // 切换到非 hover 状态时，需要先做动画，再把 zIndex 降低
            Vue.set(colData, 'isHovering', false);
            setTimeout(() => {
                setPauseDataWatch();
                Vue.set(colData, 'increaseZIndex', false);
            }, ANIMATION_DURATION);
        }

        // 修复侧边工具条
        const cssId = 'col_hover_temp_css';
        $(`<style id="${cssId}">.edit_tool_style3 { display: none !important;}</style>`).appendTo('head');
        setTimeout(() => {
            $('.edit_tool_style3').parent().remove();
            $(`#${cssId}`).remove();
            setTimeout(() => {
                const element = document.querySelector(`#rowline${rowId} .jz_web_col${colIndex}`);
                const event = new MouseEvent('mouseenter', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                });
                element.dispatchEvent(event);
            }, 16);
        }, ANIMATION_DURATION);
    } else {
        colData.isHovering = isHover;
    }
};
