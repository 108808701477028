var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "jz_empty_tips" }, [
      _c("i", { staticClass: "jz_empty_tips__img" }),
      _vm._v(" "),
      _c("div", { staticClass: "jz_empty_tips__text" }, [
        _vm._v("找不到图册了，看看其他的吧~"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }