import { mapState } from 'vuex';
export const patternMixin = {
    computed: {
        ...mapState(['manageMode', 'colId', 'device']),
        privatePattern() {
            return this.module.privatePattern[this.device];
        },
        pcPrivatePattern() {
            return this.module.privatePattern['pc'];
        },
        mobiPrivatePattern() {
            return this.module.privatePattern['mobi'];
        },
        isMobi() {
            return this.device == 'mobi';
        },
    },
};
