var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "timeline_text_horizontal_arrow jz_theme_border_color jz_theme_font_color",
      style: _setup.style,
    },
    [
      _c("svg", { class: `icon_${_setup.type}` }, [
        _c("use", { attrs: { "xlink:href": `#icon_${_setup.type}` } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }