export const getCarouselAnimation = function (id) {
    var $module = Fai.top.$('#module' + id),
        domSeqList = [],
        $dom;

    $dom = $module.find('.photo_display_list');
    !!$dom.length && domSeqList.push(Array.from($dom));

    $dom = $module.find('.photo_list_area .select_photo');
    if ($dom.length > 0) {
        $dom.each(function () {
            domSeqList.push(Array.from($(this)));
        });
    }

    return domSeqList;
};
