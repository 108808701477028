import { addSiteForm as addSiteFormRequest } from '@/api/form/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
import { usePoolGetter, usePoolMemo } from '@shared/manage/pool/index.js';
import { getSmsTemplateListMemo } from '@shared/manage/sysFaiSmsPlatform/index.js';
import { isBrowserEnv } from '@jz/utils';
import { logFdpRequest } from '@/site/shared/log/index.js';

const addSiteForm = function (name) {
    return new Promise(function (resolve, reject) {
        addSiteFormRequest({
            name,
        })
            .then((result) => {
                if (result.success) {
                    window._store.dispatch('manage/pauseDataWatch');
                    /* eslint-disable-next-line no-undef */
                    window._store.state.manage.pool.siteFormList &&
                        /* eslint-disable-next-line no-undef */
                        window._store.state.manage.pool.siteFormList.unshift(result.info);
                    resolve(result.info);
                } else {
                    Message.warning({
                        message: result.msg,
                        autoHide: false,
                    });
                    reject(result.msg);
                }
            })
            .catch((err) => {
                Message.warning({
                    message: '网络繁忙，请刷新后重试',
                    autoHide: false,
                });
                reject(err);
            });
    });
};

const editOnlineForm = function (moduleId, itemId) {
    if (window._store.state.device === 'mobi') {
        return;
    }
    if (!window._store.state.manage.auth.isFormManage) {
        Message.warning({
            message: '没有权限操作',
            autoHide: false,
        });
        return;
    }
    window._store.commit('manage/editPanel/editPanelChange', {
        type: 'onlineForm',
        data: {
            state: typeof itemId != 'undefined' ? 'edit' : 'add',
            itemId: itemId,
            moduleId: moduleId,
        },
        active: true,
    });
};

const delOnlineFormItem = function (formId, itemId, moduleId) {
    getSiteFormList()
        .then((formList) => formList.find((form) => form.id === formId))
        .then((formData) => {
            const module = Fai.top.window._store.state.modules[`module${moduleId}`];
            const isOpenPriceRule = module.allowOnlinePay && module.openPaySetting && formData.other?.payType === 1;
            const isLinkToMailNotify =
                formData.mailNotify4Client?.open && formData.mailNotify4Client?.notifyFormItemId == itemId;
            let disabledClose = false;

            if (isOpenPriceRule) {
                const priceRuleFormItemList = formData.other?.formulaData?.variableList;
                const item = priceRuleFormItemList.find((r) => r.id === itemId);

                if (item) {
                    disabledClose = true;
                }
            }

            if (isLinkToMailNotify) {
                Message.warning('当前表单项已用于邮箱通知，不支持隐藏');
                return;
            }

            if (disabledClose) {
                Message.warning('当前表单项已用于支付金额计算，不支持隐藏');
                return;
            }

            const list = formData.contentList || [];

            for (const item of list) {
                if (item.id === itemId) {
                    const hide = item.hide;
                    const logFormDeleteByJz = function (type, hide) {
                        logFdpRequest('jz_form_item_delete', {
                            jz_version: window._store.state.jzVersion,
                            jz_content_terminal: 'zsy',
                            jz_free_text_0: hide ? '隐藏' : '删除',
                            jz_free_text1:
                                [
                                    { type: 0, name: '单行文本' },
                                    { type: 2, name: '单选按钮' },
                                    { type: 3, name: '多选按钮' },
                                    { type: 6, name: '日期选项' },
                                    { type: 8, name: ' 手机号 ' },
                                    { type: 11, name: '省市县区' },
                                    { type: 4, name: '下拉选项' },
                                    { type: 12, name: '多级下拉' },
                                    { type: 1, name: '多行文本' },
                                    { type: 7, name: '文件上传' },
                                    { type: 5, name: '文本说明' },
                                    { type: 10, name: '身份验证' },
                                    { type: 9, name: '邮箱验证' },
                                ].filter((value) => type == value.type)?.[0]?.name ?? '',
                        });
                    };
                    const deleteItem = () => {
                        Vue['delete'](list, list.indexOf(item));
                        logFormDeleteByJz(item.type, false);
                    };
                    new Vue().$createDialog({
                        content: `<p style="color:#333;font-size:16px;">确定删除"${
                            item.name
                        }"？</p><div style="color:#666;font-size:14px;">将同时删除该表单项的所有数据，无法恢复。${
                            hide ? '' : '</br>隐藏表单项可保留数据，提交时不需填写。</div>'
                        }`,
                        confirmButton: {
                            text: hide ? '确定' : '隐 藏',
                        },
                        cancelButton: {
                            text: hide ? '取消' : '仍要删除',
                        },
                        onConfirm: () => {
                            if (hide) {
                                deleteItem();
                            } else {
                                logFormDeleteByJz(item.type, true);
                                item.hide = true;
                            }
                        },
                        onCancel: () => {
                            if (!hide) {
                                deleteItem();
                            }
                        },
                    });
                    return;
                }
            }

            Message.warning('未找到表单项。');
        });
};

const getSiteFormList = usePoolGetter({
    poolNamespace: 'siteFormList',
    requestUrl: isBrowserEnv()
        ? Site.addRequestPrefix({
              newPath: '/rajax/',
              oldPath: 'rajax/',
          }) + '/siteForm_h.jsp?cmd=getWafNotCk_getSiteFormList'
        : '',
    pool: () => window._store.state.manage.pool,
});
const getSiteFormListMemo = usePoolMemo({
    poolNamespace: 'siteFormList',
    pool: () => window._store.state.manage.pool,
});

/**
 * 检查手机号表单项数据是否合法
 * @param {Object} siteFormItemPhoneSvrData 手机号表单项数据
 * @param {Boolean} siteFormItemPhoneSvrData.smsVerify 手机号表单项是否开启短信验证码
 * @param {Object} opts 其他参数
 * @returns {Object} 判断信息
 */
const checkSiteFormItemPhone = (siteFormItemPhoneSvrData, opts) => {
    const { id, smsVerify, smsTemplateId } = siteFormItemPhoneSvrData;
    let result = { success: true };

    // 验证短信模板
    if (smsVerify && opts.allowedFormSms) {
        // 短信模板数据
        const smsTemplateList = getSmsTemplateListMemo();
        const smsTemplate = smsTemplateList.find(({ key }) => key == smsTemplateId);
        if (!smsTemplate) {
            result = {
                success: false,
                info: {
                    msg: '请选择短信模板',
                    siteFormItemId: id,
                },
            };
            if (!result.success) {
                return result;
            }
        }

        const { template } = smsTemplate;
        if (template.indexOf('#code#') < 0) {
            result = {
                success: false,
                info: {
                    msg: '您选择的短信模板不符合格式：【xx公司】您的验证码是：#code#。如非本人操作，请忽略本短信',
                    siteFormItemId: id,
                },
            };
            if (!result.success) {
                return result;
            }
        }
    }

    return result;
};

/**
 * 检查表单数据是否合法
 * @param {Object} siteFormSvrData 表单数据
 * @param {Array} siteFormSvrData.contentList 表单项列表
 * @param {Object} opts 其他参数
 * @returns {Object} 判断信息
 */
const checkSiteForm = (siteFormSvrData, opts) => {
    const { contentList } = siteFormSvrData;
    let result = { success: true };

    // 验证手机号项的手机验证码
    for (let i = 0; i < contentList.length; i++) {
        const siteFormItemSvrData = contentList[i];
        const { type } = siteFormItemSvrData;
        switch (type) {
            case 8:
                result = checkSiteFormItemPhone(siteFormItemSvrData, opts);
                if (!result.success) {
                    return result;
                }
                break;
        }
    }

    return result;
};

/**
 * 检查在线表单模块数据是否合法
 * @param {Object} moduleSvrData 模块数据
 * @param {Number} moduleSvrData.prop2 选中表单
 * @returns {Object} 判断信息
 */
const checkModuleOnlineForm = (moduleSvrData) => {
    const { prop2, allowedFormSms } = moduleSvrData;
    let result = {
        success: true,
    }; // 表单数据

    // 表单数据
    const siteFormList = getSiteFormListMemo();
    const siteFormSvrData = siteFormList.find(({ id }) => id == prop2); // 验证表单数据

    // 验证表单数据
    if (siteFormSvrData) {
        result = checkSiteForm(siteFormSvrData, {
            allowedFormSms,
        });

        if (!result.success) {
            return result;
        }
    }

    return result;
};

/**
 * 获取提交按钮样式
 * @param {int} style
 * @returns
 */
const getSumbitButtonStyle = function (style) {
    var themeColor = window._store.state.responsiveInfo.themeColor;

    switch (style) {
        case -1:
            return {
                br: 0,
                bb: 0,
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 0,
                    //边框宽度
                    c: themeColor,
                    //边框颜色
                    hc: themeColor, //hover边框颜色
                },
            };
        case 0:
            return {
                br: 4,
                //圆角
                bb: 0,
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 0,
                    //边框宽度
                    c: '#000',
                    //边框颜色
                    hc: '#000', //hover边框颜色
                },
            };

        case 1:
            return {
                br: 20,
                //圆角
                bb: 0,
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 1,
                    //边框宽度
                    c: themeColor,
                    //边框颜色
                    hc: themeColor, //hover边框颜色
                },
            };

        case 2:
            return {
                br: 4,
                //圆角
                bb: 0,
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 0,
                    //边框宽度
                    c: themeColor,
                    //边框颜色
                    hc: themeColor, //hover边框颜色
                },
            };

        case 3:
            return {
                br: 20,
                //圆角
                bb: 0,
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 1,
                    //边框宽度
                    c: '#cccccc',
                    //边框颜色
                    hc: themeColor, //hover边框颜色
                },
            };

        case 4:
        case 5:
            return {
                br: 0,
                //圆角
                bb: 0,
                pbd: {
                    //边框
                    y: 0,
                    //默认
                    w: 1,
                    //边框宽度
                    c: themeColor,
                    //边框颜色
                    hc: themeColor, //hover边框颜色
                },
            };
    }
};

const calcButtonStyle = function (style) {
    var styleAttr = {
        'border-radius': `${style.br}px`,
    };

    if (style.pbd.y == 1) {
        styleAttr['border-width'] = `${style.pbd.w}px`;
        styleAttr['border-color'] = style.pbd.c;
        styleAttr['border-style'] = 'solid';
    }
    return styleAttr;
};

const calcButtonHoverStyle = function (style, type) {
    var hoverStyle = {};

    if (style.pbd.y == 1) {
        hoverStyle['border-color'] = style.pbd.hc;
    }
    if (style.bb == 0) {
        if (type == 2) {
            hoverStyle.backgroundColor = Comm.burnColor(window._store.state.responsiveInfo.themeColor, 5);
        }
    }
    return hoverStyle;
};

export {
    addSiteForm,
    editOnlineForm,
    delOnlineFormItem,
    getSiteFormList,
    getSiteFormListMemo,
    checkModuleOnlineForm,
    getSumbitButtonStyle,
    calcButtonStyle,
    calcButtonHoverStyle,
};
