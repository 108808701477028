import request from '@jz/request';

export function getServerTime() {
    return request.get('/ajax/siteForm_h.jsp?cmd=getWafNotCk_getServerTime').then(({ data = {} }) => {
        return data;
    });
}

export function getEditFileItemArg() {
    return request.get('/rajax/siteForm_h.jsp?cmd=getWafNotCk_getEditFileItemArg').then(({ data = {} }) => {
        return data;
    });
}

export function getEditPhoneItemArg() {
    return request.get('/rajax/siteForm_h.jsp?cmd=getWafNotCk_getEditPhoneItemArg').then(({ data = {} }) => {
        return data;
    });
}

export function addSiteForm(data) {
    return request.post('/rajax/siteForm_h.jsp?cmd=addWafCk_addSiteForm', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function setLinkForm(data) {
    return request.post('/rajax/site_h.jsp?cmd=setWafCk_setLinkForm', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getNativePayUrl(data) {
    return request.post('/ajax/siteForm_h.jsp?cmd=getWafNotCk_getNativePayUrl', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function checkPayStatus(data) {
    return request
        .post('/ajax/siteForm_h.jsp?cmd=wafNotCk_checkSiteFormSubmitStatue', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function sendValidateCode(data) {
    return request.post('/ajax/siteForm_h.jsp?cmd=wafNotCk_sendValidateCode', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function addSubmit(data) {
    return request.post('/rajax/siteForm_h.jsp?cmd=addWafCk_addSubmit', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getOneSiteForm(data) {
    return request.post('/rajax/siteForm_h.jsp?cmd=getWafNotCk_getOneSiteForm', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function setNewFileUploadStyle(data) {
    return request
        .post('/rajax/siteForm_h.jsp?cmd=setWafCk_setNewFileUploadStyle', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getMemberDetailFormInfo(params) {
    return request
        .get('/api/guest/form/getResFormModuleInfoForMemberModifySubmit', {
            params,
        })
        .then(({ data = {} }) => data);
}

export function memberModifySubmit(data) {
    return request.post('/api/guest/form/memberModifySubmit', { data }).then(({ data = {} }) => {
        return data;
    });
}
