import { nextTick } from 'vue';
import { ANIMATION_TIME } from './constants';

/**
 * id: accordion item id
 * type: 'initial' | 'hover'
 */
export async function setAccordionItemStatus({ moduleId, id, type }) {
    if (type !== 'initial' && type !== 'hover') {
        console.error(`type 只能是 'initial' 或 'hover'`);
        return;
    }
    const moduleData = window._store.state.modules[`module${moduleId}`];
    if (!moduleData) {
        console.error(`module${moduleId} 不存在`);
        return;
    }

    const accordionList = moduleData.prop1;

    if (window._store.state.manageMode) {
        window._store.dispatch('manage/pauseDataWatch');
    }
    const index = accordionList.findIndex((item) => item.id === id);

    const item = accordionList[index];
    if (!item.defaultShow) {
        item.defaultShow = true;
        await nextTick();
    }

    if (type === 'hover') {
        moduleData.activeAccordionItemId = id;
    } else if (type === 'initial') {
        if (index === accordionList.length - 1) {
            moduleData.activeAccordionItemId = accordionList[0].id;
        } else {
            moduleData.activeAccordionItemId = accordionList[index + 1].id;
        }
    }

    // FIXME: 抽去 manage/
    if (window._store.state.manageMode) {
        const cssId = 'accordion_temp_css';
        $(`<style id="${cssId}">.edit_tool_style3 { display: none !important;}</style>`).appendTo('head');
        setTimeout(() => {
            $('.edit_tool_style3').parent().remove();
            $(`#${cssId}`).remove();
            setTimeout(() => {
                const element = document.querySelector(`#module${moduleId} .accordion_item[item-id="${id}"]`);
                const event = new MouseEvent('mouseenter', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                });
                element.dispatchEvent(event);
            }, 16);
        }, ANIMATION_TIME);
    }
}
