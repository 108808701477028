
//:is="componentInfo.compon"
import { mapGetters, mapState, mapActions } from 'vuex';

import jzWebRowEntry from './JzWebRowEntry.vue';

export default {
    name: 'jz-web-rows',
    components: {
        jzWebRowEntry,
    },
    props: ['ids', 'content', 'name'],
    provide: function () {
        return {
            websName: this.name, // 传递一个引用类型函数过去
        };
    },
    data() {
        return {
            dragHandleSelector: '',
        };
    },
    render() {
        const manageTamplate = () => {
            return (
                <DndContainer
                    ref="content"
                    class="jz_web_rows"
                    should-accept-drop={this.shouldAcceptDrop}
                    get-child-payload={this.getChildPayload}
                    drag-handle-selector={this.dragHandleSelector}
                    drop-placeholder={this.dropPlaceholder}
                    non-drag-area-selector={'*'}
                    drag-disabled={this.dragDisabled}
                    limit-indexs={this.limitIndexs}
                    on={{
                        'drag-start': this.onDragStart,
                        'drag-end': this.onDragEnd,
                        'drop-ready': this.onDropReady,
                        drop: this.onDrop,
                    }}
                >
                    <template slot="default">{contentTemplate()}</template>
                </DndContainer>
            );
        };

        const visitorTamplate = () => {
            return (
                <div ref="content" class="jz_web_rows">
                    {contentTemplate()}
                </div>
            );
        };

        const contentTemplate = () => {
            return this.curRows.map((row, index) => (
                <jz-web-row-entry
                    key={row.id}
                    row={row}
                    index={index}
                    rows-length={this.curRows.length}
                ></jz-web-row-entry>
            ));
        };
        return this.manageMode ? manageTamplate() : visitorTamplate();
    },
    computed: {
        ...mapState(['device', 'manageMode', 'responsiveInfo', 'rows']),
        ...mapGetters(['isFullScreen']),
        ...mapState('row', ['showMobiHideRow']),
        ...mapGetters(['isFirstFullSiteRow', 'isLastFullSiteRow']),
        componentInfo() {
            let needTransition = this.manageMode && !this.isFullScreen;
            return {
                compon: needTransition ? 'transition-group' : 'div',
                tag: needTransition ? 'div' : undefined,
                moveClass: needTransition ? 'jz_web_row_move_anim' : undefined,
            };
        },
        curRows() {
            let rows = [],
                ids = Array.from(this.ids),
                length = ids.length,
                i,
                rowId;
            for (i = 0; i < length; i++) {
                rowId = ids[i];
                // mobi视图隐藏通栏
                if (this.responsiveInfo.rowIds.includes(rowId) && this.rows['row' + rowId] && this.device === 'mobi') {
                    if (this.rows['row' + rowId]?.pattern.mobi.mh && !this.showMobiHideRow) continue;
                }
                if (rowId && this.rows['row' + rowId]) {
                    rows.push(this.rows['row' + rowId]);
                } else {
                    // 清除脏数据
                    var index = this.ids.indexOf(rowId);
                    if (index > -1) this.ids.splice(index, 1);
                }
            }
            return rows;
        },
        limitIndexs() {
            if (!this.curRows.length) {
                return;
            }
            const indexs = [];
            const hasFirstFullSiteRow = this.isFirstFullSiteRow(this.curRows[0].id);
            const hasLastFullSiteRow = this.isLastFullSiteRow(this.curRows[this.curRows.length - 1].id);
            hasFirstFullSiteRow && indexs.push(0);
            hasLastFullSiteRow && indexs.push(this.curRows.length);
            return indexs;
        },
        dragDisabled() {
            return !this.manageMode || this.device === 'mobi';
        },
    },
    methods: {
    },
};
