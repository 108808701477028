export default {
    computed: {
        isHalfMask() {
            // 半遮罩特效
            return this.module.commProp.pa.mt === 2;
        },
        isAllMask() {
            // 全遮罩特效
            return this.module.commProp.pa.mt === 3;
        },
        isMagnifier() {
            // 是否放大镜
            return this.module.commProp.pa.mt === 1;
        },
        isHalfMaskStyle1() {
            // 半遮罩特效，样式1，从无到有
            return this.module.commProp.pa.mt === 2 && this.module.commProp.pa.mask.t === 0;
        },
        isHalfMaskStyle2() {
            // 半遮罩特效，样式1，显示标题，描述在下面隐藏
            return this.module.commProp.pa.mt === 2 && this.module.commProp.pa.mask.t === 1;
        },
        isAllMaskStyle1() {
            // 全遮罩特效，样式1，从无到有
            return this.module.commProp.pa.mt === 3 && this.module.commProp.pa.mask.t === 0;
        },
        isAllMaskStyle2() {
            // 全遮罩特效，样式2，从无到有
            return this.module.commProp.pa.mt === 3 && this.module.commProp.pa.mask.t === 1;
        },
        isAllMaskStyle3() {
            // 全遮罩特效，样式3，标题要在下方显示
            return this.module.commProp.pa.mt === 3 && this.module.commProp.pa.mask.t === 2;
        },
    },
};
