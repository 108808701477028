var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { languages_default_item: !_vm.isMobi },
      on: { click: _vm.openLanguagePopup },
    },
    [
      !_vm.isMobi || (_vm.isMobi && !_vm.topPosition)
        ? [
            _vm.iconVisible
              ? _c("i", {
                  staticClass: "s_multi_language_icon",
                  class: "icon_" + _vm.currentLan.lanCode,
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.textVisible
              ? _c(
                  "span",
                  {
                    staticClass:
                      "s_multi_language_text s_multi_language_active_text",
                    style: _vm.createFontStyle(true),
                  },
                  [_vm._v(_vm._s(_vm.currentLan.name))]
                )
              : _vm._e(),
          ]
        : _c("mobi-icon", {
            staticClass: "s_multi_language_mobi_svg",
            style: { color: _vm.mobiIconColor },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }