var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site_search_module_wrap module_content_detail" },
    [
      _c("search-box", {
        ref: "siteSearch",
        attrs: { module: _vm.module },
        on: { onSearch: _vm.handleSearch, onMobiSearch: _vm.handleMobiSearch },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }