var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "counter_wrap",
      class: [`size_${_vm.size}`],
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "icon_wrap", on: { click: _vm.minus } },
        [_c("MinusIcon", { staticClass: "minus_icon" })],
        1
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "counter_input",
        attrs: { type: "number" },
        domProps: { value: _vm.value },
        on: {
          input: _vm.handleInput,
          blur: _vm.handleBlur,
          keydown: _vm.handleKeydown,
          paste: _vm.handlePaste,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "icon_wrap", on: { click: _vm.plus } },
        [_c("PlusIcon", { staticClass: "plus_icon" })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }