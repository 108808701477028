var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.versionOne
    ? _c(
        "div",
        {
          staticClass: "jz_share_wrap",
          on: { touchmove: _vm.preventMoveCross },
        },
        [
          _c("div", { staticClass: "jz_share_layout" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "jz_share_list" },
            [
              _vm._l(_vm.shareList, function (item, index) {
                return [
                  item.name !== "Pinterest"
                    ? _c(
                        "a",
                        {
                          key: index,
                          staticClass: "jz_share_icon",
                          class: item.iconClass,
                          attrs: {
                            hidefocus: "true",
                            title: item.name,
                            href: "javascript:;",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.shareItemClick(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "jz_share_name" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          key: "pinterest",
                          staticClass: "jz_share_icon",
                          class: item.iconClass,
                          attrs: {
                            hidefocus: "true",
                            title: item.name,
                            href: "javascript:;",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.shareItemClick(item)
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 32 32",
                              },
                            },
                            [
                              _c("title", [_vm._v(_vm._s(item.name))]),
                              _vm._v(" "),
                              _c("circle", {
                                attrs: {
                                  cx: "16",
                                  cy: "16",
                                  r: "14",
                                  transform:
                                    "translate(-1.68 30.13) rotate(-83.63)",
                                  fill: "#ee262a",
                                },
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  d: "M13.69,24.82a.85.85,0,0,0,.16-.17,8.29,8.29,0,0,0,1-2.18c.2-.8.41-1.61.63-2.47a2.45,2.45,0,0,0,1.09.92,4,4,0,0,0,3.69-.39,5.11,5.11,0,0,0,2-2.34,7.51,7.51,0,0,0,.65-4.59A5.31,5.31,0,0,0,19.5,9.54a7.22,7.22,0,0,0-7.88,1.62,5.5,5.5,0,0,0-1.23,5.93,2.76,2.76,0,0,0,1.5,1.64c.32.14.48.07.57-.27,0-.07,0-.15,0-.21a1.23,1.23,0,0,0-.19-1.3,3,3,0,0,1-.39-2.42,4.56,4.56,0,0,1,4.55-3.88,5,5,0,0,1,1.92.3,3.28,3.28,0,0,1,2.22,2.92,6.87,6.87,0,0,1-.83,4.18A2.67,2.67,0,0,1,18,19.53,1.54,1.54,0,0,1,16,18.14,4.11,4.11,0,0,1,16.23,17c.2-.77.43-1.52.61-2.29a2.15,2.15,0,0,0,0-.76,1.29,1.29,0,0,0-2.09-.9,2.51,2.51,0,0,0-1,2A4.07,4.07,0,0,0,14,16.69a.7.7,0,0,1,0,.38c-.4,1.75-.82,3.49-1.2,5.23a10,10,0,0,0-.15,1.45c0,.19,0,.38,0,.61,0,0,0,.49.25.59A.84.84,0,0,0,13.69,24.82Z",
                                  fill: "#fff",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "jz_share_name" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      ),
                ]
              }),
            ],
            2
          ),
        ]
      )
    : _vm.versionTwo
    ? _c(
        "div",
        { staticClass: "share_v2_wrap" },
        _vm._l(_vm.shareList, function (item) {
          return _c(
            "div",
            {
              key: item.name,
              staticClass: "share_item",
              on: {
                click: function ($event) {
                  return _vm.openShareLink(item)
                },
              },
            },
            [
              _c("svg", [
                _c("use", {
                  attrs: { "xlink:href": `#icon_s_${_vm.getSvg(item)}` },
                }),
              ]),
            ]
          )
        }),
        0
      )
    : _c("div", [_vm._v("版本错误")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }