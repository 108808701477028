var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "mobi_tabpack_list_arrow_wrap mobi_tabpack_list_arrow_wrap_left",
      on: { click: _vm.arrowLeftHandler },
    },
    [
      _c(
        "svg",
        {
          staticClass: "mobi_tabpack_list_arrow_left",
          attrs: { viewBox: "0 0 16 16" },
        },
        [
          _c("path", {
            attrs: {
              d: "M7.21,8.5l3.64-3.65a.48.48,0,0,0,0-.7.48.48,0,0,0-.7,0l-4,4a.48.48,0,0,0,0,.7l4,4a.5.5,0,1,0,.7-.7Z",
              fill: "currentColor",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }