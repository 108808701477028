export default {
    namespaced: true,
    state: () => ({
        isOpenFixedHeight: false,
        isOpenFixedTop: false,
        // 固定顶部
        scrollToTop: true,
        headerStyleStatus: {
            status: false,
            headerHeight: 0,
        },
        isHoverHeader: false,
        fixedHeaderHeight: 0, //fixed 顶部or导航栏高度
    }),
    mutations: {
        setIsOpenFixedHeight(state, value) {
            state.isOpenFixedHeight = value;
        },
        setIsOpenFixedTop(state, value) {
            state.isOpenFixedTop = value;
        },
        changeHeaderStyleStatus(state, { status, headerHeight }) {
            (status !== null || status !== undefined) && (state.headerStyleStatus['status'] = status);
            (headerHeight !== null || headerHeight !== undefined) &&
                (state.headerStyleStatus['headerHeight'] = headerHeight);
        },
        changeFixedHeaderHeight(state, headerHeight) {
            state.fixedHeaderHeight = headerHeight;
        },
        setScrollToTop(state, flag) {
            state.scrollToTop = flag;
        },
        setIsHoverHeader(state, value) {
            state.isHoverHeader = value;
        },
    },
    getters: {
        isHoverHeaderAndOpenHoverEffect(state, _, rootState) {
            const fullScreen = rootState.fullScreen;
            const responsiveInfo = rootState.responsiveInfo;
            const currentPattern =
                fullScreen === 0 ? responsiveInfo.pattern.header : responsiveInfo.pattern.rollingScreen;

            return currentPattern.se && state.isHoverHeader;
        },
    },
};
