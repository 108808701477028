var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module_content_detail" },
    [
      _c(_vm.renderCompon, {
        tag: "component",
        staticClass: "module_list_photos_content",
        attrs: {
          module: _vm.module,
          "photo-list": _vm.photoList,
          "module-style": _vm.style,
          pattern: _vm.privatePattern,
          "is-mobi": _vm.isMobi,
          "use-photo-group": _vm.usePhotoGroup,
          "all-photo-list": _vm.allPhotoList,
        },
      }),
      _vm._v(" "),
      _vm.flagPagination && _vm.hasPagination
        ? _c("pagination-component", {
            attrs: {
              "module-id": _vm.module.id,
              "page-count": _vm.paginationPageCount,
              "current-page": _vm.currentPage,
              "page-size": _vm.paginationPageSize,
              "style-index": _vm.paginationStyleIndex,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              currentChange: _vm.handlePaginationPageChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }