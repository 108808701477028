var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.flagPrevNext && _vm.productPrevAndNextInfo
    ? _c("div", { staticClass: "detail_pagination_wrap" }, [
        _vm.productPrevAndNextInfo
          ? _c("table", { staticStyle: { width: "100%", height: "100%" } }, [
              _c("tr", [
                _c(
                  "td",
                  {
                    attrs: { colspan: "2", align: "center", valign: "middle" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "pagenation",
                        staticStyle: { margin: "0px" },
                        attrs: { id: "singleNewspagenation4" },
                      },
                      [
                        _c("div", { staticClass: "pagePrev" }, [
                          _vm.productPrevAndNextInfo.hasPrev
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    hidefocus: "true",
                                    href: _vm.productPrevAndNextInfo
                                      .prevProductUrl,
                                    onclick:
                                      _vm.productPrevAndNextInfo
                                        .prevProductUrlCookie,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.LS.photoPrevOne)),
                                  ]),
                                ]
                              )
                            : _c("span", [_vm._v(_vm._s(_vm.LS.photoPrevOne))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "pageNext" }, [
                          _vm.productPrevAndNextInfo.hasNext
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    hidefocus: "true",
                                    href: _vm.productPrevAndNextInfo
                                      .nextProductUrl,
                                    onclick:
                                      _vm.productPrevAndNextInfo
                                        .nextProductUrlCookie,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.LS.photoNextOne)),
                                  ]),
                                ]
                              )
                            : _c("span", [_vm._v(_vm._s(_vm.LS.photoNextOne))]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }