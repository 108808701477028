var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sideNavPattern && _vm.showSlide && _vm.isPC
    ? _c(
        "div",
        {
          directives: [
            {
              name: "hovermenu",
              rawName: "v-hovermenu",
              value: _vm.hovermenu,
              expression: "hovermenu",
            },
          ],
          staticClass: "pagination_wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "pagination_container", class: _vm.paginationStyle },
            [
              _c(
                "ul",
                { staticClass: "pagination_list" },
                _vm._l(_vm.items, function (item, index) {
                  return _c(
                    "li",
                    {
                      staticClass: "pagination_item",
                      class: _vm.getClass(index),
                      on: {
                        click: function ($event) {
                          return _vm.goCurrentPage(index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.handleIdx(item)))]
                  )
                }),
                0
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }