var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item_type_date" },
    [
      _c("input", {
        ref: "startTime",
        staticClass: "input_style start_time_input",
        style: _vm.itemInputStyle,
        attrs: { readonly: "", placeholder: _vm.item.placeholder },
        domProps: { value: _vm.value.startTime },
      }),
      _vm._v(" "),
      _vm.dateSetting.type == 1
        ? [
            _c("span", { staticClass: "connect_icon" }, [_vm._v("-")]),
            _vm._v(" "),
            _c("input", {
              ref: "endTime",
              staticClass: "input_style end_time_input",
              style: _vm.itemInputStyle,
              attrs: { readonly: "", placeholder: _vm.item.placeholder },
              domProps: { value: _vm.value.endTime },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }