
export default {
    props: {
        price: {
            type: String,
            default: '',
        },
        currency: {
            type: String,
            default: '',
        },
        currencyPositionRight: {
            type: Boolean,
            default: false,
        },
    },
    render() {
        const priceEl = <span class="pay_product_price_value">{this.price}</span>;
        const coinEl = <span class="pay_product_price_coin">{this.currency}</span>;
        return (
            <div class="pay_product_price_item">
                {this.currencyPositionRight ? [priceEl, coinEl] : [coinEl, priceEl]}
            </div>
        );
    },
};
