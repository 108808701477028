import request from '@jz/request';

export function checkMemberSameTimeLogin(data) {
    return request
        .post('/ajax/login_h.jsp?cmd=wafNotCk_checkMemberSameTimeLogin', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getMemberHeadEditInfo(data) {
    return request
        .post('/rajax/member_h.jsp?cmd=getWafNotCk_getMemberHeadEditInfo', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getMemberthumbId(data) {
    return request.post('/ajax/member_h.jsp?cmd=wafNotCk_cimg', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function setMemberMsg(data) {
    return request.post('/ajax/member_h.jsp?cmd=setWafCk_set', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function setLogoutMember(data) {
    return request.post('/ajax/login_h.jsp?cmd=wafNotCk_logoutMember', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getSubmitList(data) {
    return request.post('/ajax/siteForm_h.jsp?cmd=getWafNotCk_getSubmitList', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function changeReadStatus(data) {
    return request.post('/ajax/siteForm_h.jsp?cmd=setWafCk_changeReadStatus', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function changeSubmitStatusPost(data) {
    return request.post('/ajax/siteForm_h.jsp?cmd=setWafCk_changeSubmitStatus', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function checkMobileCodeGeneral(data) {
    return request
        .post('/ajax/member_h.jsp?cmd=wafNotCk_checkMobileCodeGeneral', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function loginMemberByMobile(data) {
    return request.post('/ajax/login_h.jsp?cmd=wafNotCk_loginMemberByMobile', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function sendMobileLoginCode(data) {
    return request.post('/ajax/member_h.jsp?cmd=wafNotCk_sendMobileLoginCode', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function loginMember(data) {
    return request.post('/ajax/login_h.jsp?cmd=wafNotCk_loginMember', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function sendMemberActiveMail(data) {
    return request.post('/ajax/mail_h.jsp?cmd=wafNotCk_sendMemberActiveMail', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function bindAcct(data) {
    return request.post('/ajax/member_h.jsp?cmd=setWafCk_bindAcct', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function otherAdd(data) {
    return request.post('/ajax/member_h.jsp?cmd=addWafCk_otherAdd', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getMobileCode(data) {
    return request.post('/rajax/member_h.jsp?cmd=getWafNotCk_getMobileCode', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function otherLoginMember(data) {
    return request.post('/ajax/login_h.jsp?cmd=wafNotCk_otherLoginMember', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function addMember(data) {
    return request.post('/ajax/member_h.jsp?cmd=addWafCk_add', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function proceedAfterMail(data) {
    return request.post('/ajax/member_h.jsp?cmd=setWafCk_proceedAfterMail', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function saveMemberMsgFile(data) {
    return request.post('/ajax/memberAdm_h.jsp?cmd=setWafCk_saveMemberFile', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function sendMemberPwdEmail(data) {
    return request.post('/ajax/mail_h.jsp?cmd=wafNotCk_sendMemberPwdEmail', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function sendMemberPwdMobileCode(data) {
    return request
        .post('/ajax/member_h.jsp?cmd=wafNotCk_sendMemberPwdMobileCode', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function setMemberPwdGeneral(data) {
    return request.post('/ajax/member_h.jsp?cmd=setWafCk_setMemberPwdGeneral', { data }).then(({ data = {} }) => {
        return data;
    });
}
