/**
 * 执行html内的脚本
 * @param {String} html
 */

function executeScripts(html) {
    const container = document.createElement('div');
    container.innerHTML = html;

    const scripts = container.querySelectorAll('script');

    for (let i = 0; i < scripts.length; i++) {
        const script = document.createElement('script');
        script.type = scripts[i].type || 'text/javascript';
        if (scripts[i].src) {
            script.src = scripts[i].src;
        }
        script.innerHTML = scripts[i].innerHTML;

        document.head.appendChild(script);
        document.head.removeChild(script);
    }
}

/**
 * 插入脚本
 * @param {String} code
 * @param {HTMLElement} target
 * @param {Number} seed
 */

function insertScript(code, target, seed) {
    const scriptId = 'SCRIPT_CREATED_BY_MODULE_' + seed;
    const script = document.createElement('script');

    script.id = scriptId;
    script.type = 'text/javascript';
    script.innerHTML = code;

    target.appendChild(script);
}

/**
 * 移除脚本
 * @param {String} scriptId
 */

function removeScript(seed) {
    const scriptId = 'SCRIPT_CREATED_BY_MODULE_' + seed;
    const script = document.getElementById(scriptId);

    if (script) {
        script.parentNode.removeChild(script);
    }
}

/**
 * 重写document.write实现无阻塞加载script
 * 针对使用write方法来插入元素的第三方脚本
 * @param { Object } textarea元素
 */

var loadScript = function (html, parent) {
    try {
        const container = document.createElement('div');
        container.innerHTML = html;
        const scripts = container.querySelectorAll('script');
        if (scripts.length) {
            for (let elem of scripts) {
                if ((elem && elem.tagName && elem.tagName.toLocaleLowerCase() !== 'script') || !elem || !elem.tagName) {
                    continue;
                }
                let url = elem.src;
                if (!url) continue;
                let _parent = parent || elem.parentNode,
                    // 缓存原生的document.write
                    docWrite = document.write,
                    // 创建一个新script来加载
                    script = document.createElement('script'),
                    head = document.head || document.getElementsByTagName('head')[0] || document.documentElement;
                // 重写document.write
                document.write = function (text) {
                    _parent.innerHTML = text;
                };
                script.type = 'text/javascript';
                script.src = url;
                elem.getAttribute('id') && script.setAttribute('id', elem.getAttribute('id'));
                elem.classList.value && script.classList.add(elem.classList.value);

                script.onerror =
                    script.onload =
                    script.onreadystatechange =
                        function (e) {
                            e = e || window.event;
                            if (!script.readyState || /loaded|complete/.test(script.readyState) || e === 'error') {
                                // 恢复原生的document.write
                                document.write = docWrite;
                                setTimeout(() => {
                                    // 延时移除 避免第三方脚本报错
                                    head.removeChild(script);
                                    // 卸载事件和断开DOM的引用
                                    // 尽量避免内存泄漏
                                    head =
                                        _parent =
                                        elem =
                                        script =
                                        script.onerror =
                                        script.onload =
                                        script.onreadystatechange =
                                            null;
                                }, 50);
                            }
                        };

                // 加载script
                head.insertBefore(script, head.firstChild);
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export { loadScript, removeScript, insertScript, executeScripts };
