import { getCustomSearchData as getCustomSearchDataRequest } from '@api/customSearch';
import { Message } from '@shared/manage/componMessage/index.js';
let searched = false;
export const getCustomSearchData = () => {
    if (!window._store.state.manage.pool.customSearchData) {
        window._store.dispatch('manage/pauseDataWatch');
        Vue.set(window._store.state.manage.pool, 'customSearchData', []);
    }

    if (searched) {
        return searched;
    }

    return (searched = new Promise((resolve, reject) => {
        getCustomSearchDataRequest({
            ram: Math.random(),
        })
            .then((data) => {
                window._store.dispatch('manage/pauseDataWatch');
                if (data.success) {
                    window._store.state.manage.pool.customSearchData.splice(
                        0,
                        window._store.state.manage.pool.customSearchData.length,
                        ...data.data
                    );

                    resolve(window._store.state.manage.pool.customSearchData);
                } else {
                    reject(data.msg);
                }
            })
            .catch((err) => {
                Message.warning({
                    message: '网络繁忙，请刷新后重试',
                    autoHide: false,
                });
                reject(err);
            });
    }));
};
