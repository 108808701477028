export function renderUeMap() {
    const RICH = 1;
    const NEWS_DETAIL = 4;
    const PRODUCT_DETAIL = 9;
    const PHOTO_GROUP_DETAIL = 36;
    // 渲染包含ue地图的模块
    let ueWrapIdList = Object.values(window._store.state.modules).filter((item) => {
        return [RICH, NEWS_DETAIL, PRODUCT_DETAIL, PHOTO_GROUP_DETAIL].includes(item.style);
    });

    window.bizShared.addRenderMapWrapper(
        ueWrapIdList.map(({ id }) => {
            return `#module${id}`;
        })
    );
    window.bizShared.renderMap();
}

export function renderSingleMap(ueWrap) {
    window.bizShared.renderSingleMap(ueWrap);
}
