var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m_msg_board_comment" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "fk-circle",
      },
      [
        _c("div", { staticClass: "fk-circle1 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle2 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle3 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle4 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle5 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle6 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle7 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle8 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle9 fk-child" }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "comments_title" }, [
          _vm._v(_vm._s(_vm.LS.msgBoardAllComment)),
        ]),
        _vm._v(" "),
        _vm._l(_vm.commentList, function (item) {
          return _vm.commentList.length
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "hovermenu",
                      rawName: "v-hovermenu",
                      value: {
                        hovermenu: _vm.hovermenu,
                        args: {
                          item: item,
                          style: _vm.styleId,
                        },
                      },
                      expression:
                        "{\n                hovermenu,\n                args: {\n                    item: item,\n                    style: styleId,\n                },\n            }",
                    },
                  ],
                  key: "msg" + item.msgId,
                  staticClass: "item",
                },
                [
                  _c("div", { staticClass: "head_pic" }, [
                    _c("img", {
                      staticClass: "head_img",
                      style: _vm.headImgStyle(item),
                      attrs: { src: item.headerPic },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item_content" }, [
                    _c("div", { staticClass: "top_line" }, [
                      _c("div", { staticClass: "menber_info" }, [
                        _c("span", {
                          staticClass: "name",
                          domProps: { innerHTML: _vm._s(item.msgName) },
                        }),
                        _vm._v(" "),
                        _vm.memberIsExt(item)
                          ? _c("span", {
                              staticClass: "menber_level_icon",
                              class: item.iconClassName,
                              style: _vm.memberIcon(item.memberColor),
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.memberIsNot(item)
                          ? _c("span", { staticClass: "is_tourist" }, [
                              _vm._v("[" + _vm._s(item.tourist) + "]"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.memberIsDel(item)
                          ? _c("span", { staticClass: "member_del" }, [
                              _vm._v("[会员已删除]"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !item.isPublic && !_vm.openMsgAuto
                          ? _c("span", { staticClass: "member_check" }, [
                              _vm._v("待审核"),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "msg_time",
                          attrs: { title: item.msgTitle2 },
                        },
                        [_vm._v(_vm._s(item.msgTime))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "center_line" }, [
                      _c("div", {
                        staticClass: "msg_content",
                        domProps: { innerHTML: _vm._s(item.msgContent) },
                      }),
                    ]),
                    _vm._v(" "),
                    !!item.rspContent
                      ? _c("div", { staticClass: "bottom_line" }, [
                          _c("div", { staticClass: "reply_title" }, [
                            _vm._v(_vm._s(item.msgBoardReply)),
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "rsp_content",
                            domProps: { innerHTML: _vm._s(item.rspContent) },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e()
        }),
        _vm._v(" "),
        _vm.commentList.length == 0
          ? _c("div", { staticClass: "comment_content_empty" }, [
              _c("i", { staticClass: "empty_img" }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "comment_empty_text" }, [
                _vm._v(_vm._s(_vm.LS.msgBoardNoComment)),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _vm.commentsInfo.pageSum > 1
      ? _c("div", { staticClass: "comment_pagenation" }, [
          _c(
            "div",
            {
              staticClass: "pagenation jz_pagenation_wrap",
              attrs: { id: "pagenation4" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "page_scroll jz_page_prev",
                  style:
                    _vm.commentsInfo.curPage === 1
                      ? _vm.disableStyle
                      : _vm.allowStyle,
                  on: {
                    click: function ($event) {
                      return _vm.handlePageChange("prev")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm.commentsInfo.curPage !== 1
                      ? _c("span", [_vm._v("<<")])
                      : _vm._e(),
                    _vm._v("\n                    上一页\n                "),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.paginationList, function (page) {
                return _c(
                  "div",
                  {
                    key: page,
                    staticClass: "jz_page_number",
                    style:
                      _vm.commentsInfo.curPage === page || page === "..."
                        ? _vm.disableStyle
                        : _vm.allowStyle,
                    on: {
                      click: function ($event) {
                        return _vm.getMsgBoardComments(page)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        class: {
                          jz_theme_font_color:
                            _vm.commentsInfo.curPage === page,
                        },
                      },
                      [_vm._v(_vm._s(page))]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "jz_page_scroll jz_page_next",
                  style:
                    _vm.commentsInfo.curPage === _vm.commentsInfo.pageSum
                      ? _vm.disableStyle
                      : _vm.allowStyle,
                  on: {
                    click: function ($event) {
                      return _vm.handlePageChange("next")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n                    下一页\n                    "
                    ),
                    _vm.commentsInfo.curPage !== _vm.commentsInfo.pageSum
                      ? _c("span", [_vm._v(">>")])
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }