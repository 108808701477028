/**
 * @example
 * <div v-loading="loading"
 *      jz-loading-text="......">
 * </div>
 *
 */

var loadingComponent = {
    data() {
        return {
            text: '......',
        };
    },
    template: `
        <div class="jz_loading">
            <div class="jz_loading_center">
                <span class="jz_loading_img jz_loading_animate"></span>
                <p class="jz_loading_text">{{ text }}</p>
            </div>
        </div>
    `,
};

var Mask = window.Vue.extend(loadingComponent);

function hasClass(el, cls) {
    if (!el || !cls) return false;
    if (cls.indexOf(' ') !== -1) throw new Error('className should not contain space.');
    if (el.classList) {
        return el.classList.contains(cls);
    } else {
        return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1;
    }
}

function addClass(el, cls) {
    if (!el) return;
    var curClass = el.className;
    var classes = (cls || '').split(' ');

    for (var i = 0, j = classes.length; i < j; i++) {
        var clsName = classes[i];
        if (!clsName) continue;

        if (el.classList) {
            el.classList.add(clsName);
        } else if (!hasClass(el, clsName)) {
            curClass += ' ' + clsName;
        }
    }
    if (!el.classList) {
        el.className = curClass;
    }
}

function removeClass(el, cls) {
    if (!el || !cls) return;
    var classes = cls.split(' ');
    var curClass = ' ' + el.className + ' ';

    for (var i = 0, j = classes.length; i < j; i++) {
        var clsName = classes[i];
        if (!clsName) continue;

        if (el.classList) {
            el.classList.remove(clsName);
        } else if (hasClass(el, clsName)) {
            curClass = curClass.replace(' ' + clsName + ' ', ' ');
        }
    }
    if (!el.classList) {
        /* eslint-disable-next-line no-undef */
        el.className = trim(curClass);
    }
}

export const loadingDirective = {
    bind(el, binding, vnode) {
        var vm = vnode.context;
        var textExr = el.getAttribute('jz-loading-text');

        var mask = new Mask({
            el: document.createElement('div'),
            data: {
                text: (vm && vm[textExr]) || textExr,
            },
        });

        el.instance = mask;
        el.mask = mask.$el;

        addClass(el, 'jz_loading_container');

        if (binding.value) {
            el.appendChild(el.mask);
        }
    },

    update(el, binding) {
        if (binding.oldValue !== binding.value) {
            if (binding.value) {
                el.appendChild(el.mask);
                addClass(el, 'jz_loading_container');
            } else {
                el.mask && el.removeChild(el.mask);
                removeClass(el, 'jz_loading_container');
            }
        }
    },

    unbind(el) {
        el.instance && el.instance.$destroy();
        removeClass(el, 'jz_loading_container');
    },
};
