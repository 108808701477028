var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowVideoBg
    ? _c("div", { staticClass: "jz_web_row--video-bg" }, [
        _c("video", {
          ref: "VideoWrapper",
          staticClass: "jz_web_row--video",
          attrs: {
            src: _vm.src,
            "data-original": _vm.rowBackground.vpath,
            loop: "loop",
            autoplay: "autoplay",
            muted: "muted",
            playsinline: "",
          },
          domProps: { muted: true },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }