export const getIconCombinationAnimation = function (id, order) {
    let $module = Fai.top.$('#module' + id),
        domSeqList = [],
        j = 0,
        $dom;

    $dom = $module.find('.icon_module_item');
    if ($dom.length < 0) return;
    if (order == 1) {
        // 同时
        let tmpArr = [],
            tmpTop = 0,
            $this;
        // 分组依据：根据top来定
        $.each($dom, function (index, val) {
            $this = $(val);
            if (index != 0) {
                if ($this.offset().top == tmpTop) {
                    tmpArr.push($this[0]);
                } else {
                    !!tmpArr.length && (domSeqList[j++] = tmpArr);
                    tmpArr = [$this[0]];
                }
            } else {
                tmpArr.push($this[0]);
            }
            if (index == $dom.length - 1) {
                !!tmpArr.length && (domSeqList[j++] = tmpArr);
                tmpArr = [];
            }
            tmpTop = $(val).offset().top;
        });
    } else {
        // 依次
        $dom.each(function () {
            domSeqList[j++] = Array.from($(this));
        });
    }

    return domSeqList;
};
