import request from '@jz/request';

export function frontMonitorRequest(data) {
    return request
        .post('/ajax/frontMonitor_h.jsp', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function logErr(errorMsg) {
    return request.get(`/ajax/log_h.jsp?cmd=wafNotCk_err&err=${errorMsg}`);
}
