var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search_result_cols" },
    [
      _vm.other.showResultType
        ? _c(
            "div",
            { staticClass: "search_result_title jz_theme_font_color" },
            [_vm._v(_vm._s(_vm.LS.searchResultCol))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.list, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "search_result_cols--item" },
          [
            _c(
              "a",
              {
                staticClass:
                  "result_highlight_node jz_hover jz_theme_font_hover_color",
                attrs: { target: _vm.getATarget(item), href: item.url },
              },
              [_vm._v(_vm._s(item.name))]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }