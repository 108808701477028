var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.comp, {
    tag: "component",
    attrs: { id: _vm.id, module: _vm.module },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }