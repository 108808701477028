import { getProductVideo } from '../components/modules/productDetail/productDetail.js';

import { fluidFlv, videoEndAndReplay, dialogVideo } from '../components/modules/flv/flv.js';
import { browser } from '@jz/utils';
/**
 * [checkDialogVideoIsIframe 弹层视频是否是iframe]
 * @function name checkDialogVideoIsIframe
 * @param id  {Number}    [id 视频模块id]
 *
 * @return  {String}      [return iframe得地址]
 */
const checkDialogVideoIsIframe = function (id, isLinkFlv) {
    //由于链接视频id永远是0，所以不缓存。
    if (Comm.getDevice() == 'mobi' && !isLinkFlv) {
        const iframe = $(`#bg_video_mask${id} .clearmb iframe`); // 获取iframe
        const hasIframe = iframe.length !== 0; // 弹层视频是否是iframe
        if (hasIframe) {
            iframe.attr({
                name: 'iframeContainer' + id + '',
                id: 'iframeContainer' + id + '',
            }); // 加上id
            if (typeof Fai.top.iframeSrcMap === 'undefined') {
                Fai.top.iframeSrcMap = {};
            }
            if (!Fai.top.iframeSrcMap[id]) {
                // 获取iframe得地址存起来
                Fai.top.iframeSrcMap[id] = iframe.attr('src');
            } else {
                iframe.attr('src', Fai.top.iframeSrcMap[id]);
            }
            $(`#bg_video_mask${id} .clearmb`).addClass('iframe_video');
        }
    }
};

/**
 *
 * @author  Mackie 20190911
 * @function name renderDialog
 * @description [renderDialog 渲染弹窗视频]
 * @param   {Dom}  videoHtml    [videoHtml description]
 * @param   {Number}  moduleId     [moduleId description]
 * @param   {String}  extClass     [extClass description]
 * @param   {String}  opacityHtml  [opacityHtml description]
 * @param   {String}  zIndexHtml   [zIndexHtml description]
 * @param   {String}  result       [result description]
 * @return  {undefined}               [return description]
 */

const renderDialog = function (videoHtml, moduleId, extClass, opacityHtml, zIndexHtml, result, isLinkFlv) {
    let flvDom;
    if (!result.success) {
        Fai.ing('视频弹窗打开失败，请稍候重试', false);
    }
    flvDom = result.info.sb;
    if (!videoHtml) {
        videoHtml =
            "<div id='bg_video_mask" +
            moduleId +
            '\'><div class="popupBg popupBgForWin ' +
            extClass +
            '" style=\'' +
            opacityHtml +
            zIndexHtml +
            "' >" +
            '</div>';
        videoHtml +=
            '<div class="video_dialog">' +
            "<div class='dialogVideoDiv'>" +
            "<div class='cancelDialogVideo' onclick='JZ.cancelDialogVideo(\"" +
            moduleId +
            '")\'></div>' +
            '<div class="clearmb">' +
            flvDom +
            '</div>' +
            '</div>' +
            '</div>';
        if (window._store.state.manageMode && Comm.getDevice() == 'mobi') {
            $(videoHtml).appendTo('.jz_web_container');
        } else {
            $(videoHtml).appendTo('body');
        }
        if (Comm.getDevice() == 'mobi') {
            $('.cancelDialogVideo').css({
                right: '0px',
                left: '20px',
            });
            checkDialogVideoIsIframe(moduleId, isLinkFlv);
        }
        return;
    }
    if (Comm.getDevice() == 'mobi') {
        checkDialogVideoIsIframe(moduleId, isLinkFlv);
        $('#bg_video_mask' + moduleId + '').show();
    }
};

const openLinkFlv = function (info) {
    let isLinkFlv = true,
        { flvCode, flvType } = info,
        match = new RegExp('`', 'g');

    flvCode = flvCode.replace(match, '"');
    dialogVideo(0, { flvCode, flvType, isLinkFlv });
};

const cancelDialogVideo = function (moduleId) {
    if (isNoAllowVideoJs()) {
        $('#bg_video_mask' + moduleId + '').remove();
    }
    if (Fai.top.$(`#bg_video_mask${moduleId} .dialogVideoDiv video`)[0] != undefined) {
        // 不需要每次都重新初始化video
        try {
            var videoObj = Fai.top.videojs('new_video_' + moduleId);
            videoObj.pause();
        } catch (err) {
            console.error(err);
        }
    }
    if (Fai.top.iframeSrcMap && Fai.top.iframeSrcMap[moduleId]) {
        // 地址存在 则置为空
        $('#iframeContainer' + moduleId + '').attr('src', '');
    }

    if (Comm.getDevice() == 'pc') {
        $('#bg_video_mask' + moduleId + '').remove();
    } else {
        $('#bg_video_mask' + moduleId + '').hide();
    }
};

// 手机设备访问site视频兼容，方案：初始化video标签代替flash，若不支持video，则引入js兼容
const videoCompatible = function (options) {
    var mediaUrl = options.flvPagePath,
        isMp4 = false;

    // 不用上述的判断是因为，视频地址改版后，现在是不加http前缀了。
    if (mediaUrl.toLowerCase().indexOf('.mp4') != -1) {
        isMp4 = true;
    }

    // 视频为MP4且是手机设备访问，才执行，否则就不执行
    if (!(isMp4 && !browser.isPc())) {
        return;
    }

    var hasVideo = !!document.createElement('video').canPlayType, // 判断是否支持vedio标签
        flashModuleObjId = 'mediaplayerObj' + options.moduleId, // 播放器对象
        mediaContainerId = 'online_flv_' + options.moduleId, // 播放器容器
        videoAttribute = [], // 标签属性
        videoHtml = [], // video标签容器
        videoAttr,
        videoHtmlStr,
        $mediaContainer,
        $flashModuleObj,
        $thisVedio;

    // 不兼容video标签则做兼容
    if (!hasVideo && options.mediaJsUrl.length > 0) {
        // 多个视频模块则只做一次兼容
        if (Fai.top.$('#jz-Html5VideoJs').length < 1) {
            var script = document.createElement('script'),
                docHtml = document.head || document.body;

            script.id = 'jz-Html5VideoJs';
            script.type = 'text/javascript';
            script.src = options.mediaJsUrl;
            docHtml.appendChild(script);
        }
    }

    if (options.videoType == 1) {
        flashModuleObjId = options.moduleId;
    }

    $mediaContainer = Fai.top.$('.' + mediaContainerId);
    $flashModuleObj = Fai.top.$('#' + flashModuleObjId);
    videoAttribute.push('id="' + flashModuleObjId + '"');

    // 片头图片
    if (options.flvImgUrl.length > 0) {
        videoAttribute.push('poster="' + options.flvImgUrl + '"');
    }

    videoAttribute.push('controls');
    videoAttr = videoAttribute.join(' ');

    videoHtml.push('<video ' + videoAttr + '>');
    videoHtml.push('<source src="' + mediaUrl + '">');
    videoHtml.push('抱歉，您的浏览器不能查看该视频。');
    videoHtml.push('</video>');
    videoHtmlStr = videoHtml.join('');
    if ($flashModuleObj.length > 0) {
        $flashModuleObj.remove();
        $mediaContainer.append(videoHtmlStr);
    }

    // 苹果手机访问，懒加载失效，导致视频报错 by json(id:43273)
    // 由于是苹果手机访问电脑网站出的问题，故采用最简洁的手段去处理（放弃查找手机端懒加载失效的原因）
    if (browser.isIos()) {
        $thisVedio = $mediaContainer.find('video');
        $thisVedio.length && $thisVedio.attr('src', $thisVedio.attr('data-original'));
    }
};

const iframeToEmbed = function (videoModuleId, urlId, urlDomain) {
    var iframe = $('#' + videoModuleId + ' iframe');
    var embedSrc;
    if (Fai.isIE()) {
        if (urlDomain == 'v.youku.com' || urlDomain == 'player.youku.com') {
            embedSrc = '//player.youku.com/player.php/sid/' + urlId + '/v.swf';
        } else if (urlDomain == 'v.qq.com' || urlDomain == 'static.video.qq.com') {
            embedSrc = '//static.video.qq.com/TPout.swf?vid=' + urlId + '&auto=0';
        } else {
            return false;
        }
        var embedDOM =
            "<embed class='mobiVideoOnlineIframe' src='" +
            embedSrc +
            "' allowFullScreen='true' wmode='Opaque' quality='high'  align='middle' allowScriptAccess='always' type='application/x-shockwave-flash'></embed>";
        $(embedDOM).insertBefore(iframe);
        iframe.remove();
    }
    return;
};

const videoLazyLoadBind = function (videoId, autoSize) {
    //解决video阻塞请求的问题，当视频模块太多的时候，会导致懒加载的一些js，css 都没有办法加载到
    //多以单独对懒加载模块进行处理
    Fai.top.$('#new_video_' + videoId).videolazyload({
        threshold: 180,
        container: Fai.top.window,
    });
    if (autoSize) {
        fluidFlv(videoId);
    }
};

//退出产品视频
const quitProductVideo = function (moduleId) {
    var videojsDom = Fai.top.$('#new_video_' + moduleId + '_html5_api')[0];
    videojsDom && videojsDom.pause();
    $('.videoBg').hide();
};

/**
 * [autoPromise description]
 * @author  mackie 2019-09-08
 * @param id  {Number}              [id 模块id]
 * @param autoPlayFlag  {Boolean}    [autoPlayFlag 自动播放]
 *
 *
 */

const autoPromise = function (id, autoPlayFlag) {
    return new Promise(function (reslove, reject) {
        if (!Fai.isPC()) {
            if (Fai.top.$('#new_video_' + id).length !== 0) {
                reslove({ id, autoPlayFlag });
            } else {
                reject('video is not ready');
            }
        }
    });
};

/**
 * [mobiAutoPlay 支持手机端自动播放]
 * @author  mackie 2019-09-08
 * @param  id {String}              [id 模块id]
 * @param  autoPlayFlag {Boolean}    [autoPlayFlag 自动播放]
 *
 *
 */

const mobiAutoPlay = function (id, autoPlayFlag) {
    var isWeiXin = browser.isWeChat();
    autoPromise(id, autoPlayFlag)
        .then(function ({ id, autoPlayFlag }) {
            Fai.top.videojs('#new_video_' + id).ready(function () {
                var myVideo = this;
                if (autoPlayFlag) {
                    if (!Fai.isPC() && !Fai.isAndroid()) {
                        // 排除安卓 ios端做自动播放
                        $('html').one('touchstart', function () {
                            myVideo.play();
                        });
                        if (isWeiXin) {
                            //微信浏览器自动播放
                            $(document).ready(function () {
                                audioAutoPlay(myVideo);
                            });
                        }
                    }
                }
            });
        })
        .catch(function (err) {
            console.log(err);
        });

    function audioAutoPlay(myVideo) {
        myVideo.play();
        document.addEventListener(
            'WeixinJSBridgeReady',
            function () {
                myVideo.play();
            },
            false
        );
    }
};

/**
 * 苹果手机上的(qq浏览器、百度浏览器app)，会劫持视频插件。需要用原生的播放才可以。
 */
const isNoAllowVideoJs = function () {
    const isPhone = browser.isIphone();
    if (!isPhone) {
        return false;
    }
    return browser.isQQ() || browser.isBaiDu();
};

export {
    openLinkFlv,
    dialogVideo,
    renderDialog,
    checkDialogVideoIsIframe,
    fluidFlv,
    videoEndAndReplay,
    cancelDialogVideo,
    videoCompatible,
    iframeToEmbed,
    videoLazyLoadBind,
    getProductVideo,
    quitProductVideo,
    autoPromise,
    mobiAutoPlay,
};

//video 模块懒加载
(function ($, window) {
    // 顶层window可以用
    if (Fai.top != window) {
        return;
    }
    var $window = $(window);

    $.fn.videolazyload = function (options) {
        var settings = {
            threshold: 0,
            failure_limit: 0,
            event: 'scroll',
            effect: 'show',
            container: window,
            data_attribute: 'original',
            skip_invisible: true,
            appear: null,
            load: null,
            lazyRemoveclass: '',
        };

        if (options) {
            /* Maintain BC for a couple of version. */
            if (undefined !== options.failurelimit) {
                options.failure_limit = options.failurelimit;
                delete options.failurelimit;
            }
            if (undefined !== options.effectspeed) {
                options.effect_speed = options.effectspeed;
                delete options.effectspeed;
            }

            $.extend(settings, options);
        }

        /* Fire one scroll event per scroll. Not one scroll event per image. */
        var elements = this;
        if (settings.event.indexOf('scroll') == 0) {
            //$(settings.container).unbind(settings.event).bind(settings.event, function(event) {
            var randomId = parseInt(Math.random() * 10000);
            $(settings.container).off('scroll.videolazy' + randomId);
            $(settings.container).on('scroll.videolazy' + randomId, function () {
                elements.each(function () {
                    let $this = $(this);
                    if (settings.skip_invisible && !$this.is(':visible')) {
                        return;
                    }

                    if (!$.belowthefold(this, settings) && !$.rightoffold(this, settings)) {
                        $this.trigger('appear');
                        $(settings.container).off('scroll.videolazy' + randomId);
                    }
                });
            });
        }

        this.each(function () {
            var self = this;
            var $self = $(self);

            self.loaded = false;
            /* When appear is triggered load original image. */
            $self.one('appear', function () {
                if (!this.loaded) {
                    if (settings.appear) {
                        var elements_left = elements.length;
                        settings.appear.call(self, elements_left, settings);
                    }
                    $self.attr('src', $self.data(settings.data_attribute));
                }
            });

            /* When wanted event is triggered load original image */
            /* by triggering appear.                              */
            if (settings.event.indexOf('scroll') != 0) {
                $self.bind(settings.event, function () {
                    if (!self.loaded) {
                        $self.trigger('appear');
                    }
                });
            }
        });

        /* Check if something appears when window is resized. */
        $window.bind('resize', function () {
            $(settings.container).trigger(settings.event);
        });

        /* Force initial check if images should appear. */
        $(settings.container).trigger(settings.event);

        return this;
    };

    /* Convenience methods in jQuery namespace.           */
    /* Use as  $.belowthefold(element, {threshold : 100, container : window}) */

    $.belowthefold = function (element, settings) {
        var fold;
        if (settings.container === undefined || settings.container === window) {
            fold = ('innerHeight' in window ? window.innerHeight : $window.height()) + $window.scrollTop();
        } else {
            fold = $(settings.container).offset().top + $(settings.container).height();
        }
        return fold <= $(element).offset().top - settings.threshold;
    };

    $.rightoffold = function (element, settings) {
        var fold;
        if (settings.container === undefined || settings.container === window) {
            fold = $window.width() + $window.scrollLeft();
        } else {
            fold = $(settings.container).offset().left + $(settings.container).width();
        }
        return fold <= $(element).offset().left - settings.threshold;
    };
})(jQuery, window);
