export const slideDown = ($el, height, speed, options) => {
    if (!$el.is(':animated')) {
        $el.css('height', 0);
    }

    $el.stop().animate(
        {
            height: height + 'px',
        },
        {
            speed: speed,
            complete() {
                $el.css('height', '');
            },
            ...options,
        }
    );
};

export const slideUp = ($el, speed, options) => {
    if (!$el.is(':animated')) {
        $el.css('height', 'auto');
    }
    $el.stop().animate(
        {
            height: 0,
        },
        {
            speed: speed,
            complete() {
                $el.css('height', '');
            },
            ...options,
        }
    );
};
