export const STYLE = {
    ONE: 0,
    TWO: 1,
    THREE: 2,
};

export const LAYOUT = {
    ONE: 0,
    TWO: 1,
};

export const MAX_TIMELINE_ITEM_LENGTH = 100;

export const STYLE_TWO_SCROLL_ONE_PAGE_ITEM_LENGTH = 4;

export const STYLE_THREE_SLIDE_ITEM_LENGTH = 5;

export const STYLE_THREE_SLIDE_ITEM_MOBI_LENGTH = 3;
