export const DEFAULT_OPTIONS = {
    node: null,
    callback: function () {},
    filterClassList: [],
    config: {
        childList: true,
        attributes: true,
        subtree: true,
        attributeOldValue: true,
        attributeFilter: ['class'],
    },
    throttleTime: 250,
    // 拖拽中是否触发回调
    dragingCall: false,
};

export const FILTER_CLASSES = [];
