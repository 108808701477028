var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "module_content_detail module_photo_group_content",
      class: _vm.styleClass,
      attrs: { leninrow: _vm.photosInRow },
    },
    [
      _c("div", { staticClass: "jz_scroll_wrap" }, [
        _c(
          "ul",
          { staticClass: "photo_display_area jz_scroll jz_scroll_only_x" },
          _vm._l(_vm.displayPhotoGroupList, function (photoGroup, index) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "animate",
                    rawName: "v-animate",
                    value: _vm.module,
                    expression: "module",
                  },
                ],
                key: photoGroup.id + "-" + index,
                style: _vm.cardStyle,
                attrs: { "data-id": photoGroup.id },
                on: {
                  click: function ($event) {
                    return _vm.photoSlide(index, photoGroup)
                  },
                  mouseenter: function ($event) {
                    return _vm.$set(photoGroup, "hovering", true)
                  },
                  mouseleave: function ($event) {
                    return _vm.$set(photoGroup, "hovering", false)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hovermenu",
                        rawName: "v-hovermenu",
                        value: {
                          hovermenu: _vm.hovermenu,
                          args: {
                            moduleId: _vm.module.id,
                            photoGroupId: photoGroup.id,
                          },
                        },
                        expression:
                          "{\n                        hovermenu,\n                        args: {\n                            moduleId: module.id,\n                            photoGroupId: photoGroup.id,\n                        },\n                    }",
                      },
                    ],
                    staticClass:
                      "photo_group_item jz_hover jz_theme_font_hover_color",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "photoWrapper" },
                      [
                        _c("ImageComponent", {
                          directives: [
                            {
                              name: "lazyload2",
                              rawName: "v-lazyload2",
                              value: {
                                id: _vm.module.id,
                                src: !_vm.isFixIe ? photoGroup.picPath : "",
                              },
                              expression:
                                "{\n                                id: module.id,\n                                src: !isFixIe ? photoGroup.picPath : '',\n                            }",
                            },
                          ],
                          staticClass: "photo",
                          style: _vm.isFixIe
                            ? _vm.photoFixIEStyle(photoGroup)
                            : _vm.photoStyle,
                          attrs: {
                            src: !_vm.isFixIe ? photoGroup.picPath : "",
                            webp: _vm.webpOptOpen,
                            "wrap-style": _vm.photoWrapStyle(),
                            "img-effects": _vm.isMobi
                              ? { type: 0 }
                              : _vm.imgOptsComputed,
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "num" }, [
                          _c("span", [
                            _vm._v(_vm._s(photoGroup.photoList.length)),
                          ]),
                          _c("i"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "name jz_theme_font_hover_color jz_hover",
                        style: {
                          ..._vm.nameStyle,
                          ..._vm.getHoverStyle(photoGroup),
                        },
                      },
                      [_vm._v(_vm._s(photoGroup.name))]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm.displayPhotoGroupList.length
        ? [
            _vm.style === 1
              ? [
                  _c("a", { staticClass: "arrow prev" }),
                  _vm._v(" "),
                  _c("a", { staticClass: "arrow next" }),
                ]
              : _vm._e(),
          ]
        : _vm.manageMode
        ? _c("empty-tips", {
            attrs: {
              "is-mobi": _vm.isMobi,
              name: "图册目录",
              "active-button-text": _vm.activeButtonText,
              "button-text": _vm.buttonText,
            },
            on: {
              "click-active-button": _vm.clickActiveButtonHandler,
              "click-button": _vm.clickButtonHandler,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("image-main-viewer", {
        attrs: {
          "show-mobi-viewer": _vm.showMobiViewer,
          "show-pc-viewer": _vm.showPcViewer,
          "z-index": _vm.zIndex,
          "initial-index": _vm.imageIndex,
          "on-close": _vm.closeViewer,
          "url-list": _vm.previewSrcList,
          "is-mobi": _vm.isMobi,
          "manage-mode": _vm.manageMode,
          "log-event-config": _vm.logEventConfig,
        },
      }),
      _vm._v(" "),
      _vm.flagPagination && _vm.module.prop0 == 0
        ? _c("pagination-component", {
            attrs: {
              "module-id": _vm.id,
              "page-count": _vm.paginationPageCount,
              "current-page": _vm.currentPage,
              "page-size": _vm.paginationPageSize,
              "style-index": _vm.paginationStyleIndex,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              currentChange: _vm.handlePaginationPageChange,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }