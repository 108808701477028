var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site_search_wrap", class: _vm.siteSearchWrapClasses },
    [
      _vm.styleId === 6 || _vm.styleId === 7 || _vm.styleId === 8
        ? _c("div", { staticClass: "site_search_input_wrap_wrap" }, [
            _c(
              "div",
              {
                staticClass: "site_search_input_wrap",
                class: _vm.searchInputWrapClass,
                on: { transitionend: _vm.transitionend },
              },
              [
                _c("input", {
                  ref: "siteSearchInput",
                  staticClass: "site_search_input pointer_event_none",
                  class: _vm.searchInputClasses,
                  style: _vm.inputStyle,
                  attrs: {
                    type: "text",
                    placeholder: _vm.searchTip,
                    maxlength: "100",
                  },
                  domProps: { value: _vm.keyword },
                  on: {
                    keyup: [
                      _vm.handleInputChange,
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch()
                      },
                    ],
                    change: _vm.handleInputChange,
                    focus: _vm.handleInputFocus,
                    blur: _vm.handleInputBlur,
                  },
                }),
              ]
            ),
          ])
        : _c(
            "div",
            {
              staticClass: "site_search_input_wrap",
              class: _vm.searchInputWrapClass,
            },
            [
              _c("input", {
                ref: "siteSearchInput",
                staticClass: "site_search_input pointer_event_none",
                class: _vm.searchInputClasses,
                style: _vm.inputStyle,
                attrs: {
                  type: "text",
                  placeholder: _vm.searchTip,
                  maxlength: "100",
                },
                domProps: { value: _vm.keyword },
                on: {
                  keyup: [
                    _vm.handleInputChange,
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleSearch()
                    },
                  ],
                  change: _vm.handleInputChange,
                  focus: _vm.handleInputFocus,
                  blur: _vm.handleInputBlur,
                },
              }),
            ]
          ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "site_search_btn_wrap",
          class: _vm.searchBtnClasses,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.handleSearch(true)
            },
          },
        },
        [
          [0, 1].includes(_vm.styleId)
            ? _c("span", { staticClass: "site_search_btn" }, [
                _vm._v(_vm._s(_vm.LS.searchBoxButtonText)),
              ])
            : _c("svg", [
                _c("use", { attrs: { "xlink:href": "#icon_search" } }),
              ]),
        ]
      ),
      _vm._v(" "),
      _vm.device == "mobi"
        ? _c("div", {
            staticClass: "site_search_mobi_mask",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleMobiSearch.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }