import request from '@jz/request';
import { Message } from '@shared/manage/componMessage/index.js';
import { isFunction, context } from '@jz/utils';
if (!context.__POOL__) {
    context.__POOL__ = {};
}
if (!context.__poolMap) {
    context.__poolMap = new Map();
}
const __poolMap = context.__poolMap;
const __POOL__ = context.__POOL__;

const initialize = (pool, poolNamescape) => {
    if (!pool[poolNamescape]) {
        window.Vue.set(pool, poolNamescape, []);
    }
};
const usePoolGetter = ({ requestUrl, poolNamespace, pool = __POOL__ }) => {
    return (forceRefresh = false) => {
        let promise = __poolMap.get(poolNamespace);
        // 只能初始化一次，否则需要强制刷新
        if (promise && !forceRefresh) {
            return promise;
        }

        if (isFunction(pool)) {
            pool = pool();
        }

        initialize(pool, poolNamespace);
        promise = new Promise((resolve, reject) => {
            request
                .post(requestUrl + '&ram=' + Math.random())
                .then(({ data = {} }) => {
                    window._store.dispatch('manage/pauseDataWatch');
                    const { list } = data;
                    const source = pool[poolNamespace];
                    source.splice(0, source.length, ...list);
                    resolve(source);
                })
                .catch((err) => {
                    Message.warning({
                        message: '网络繁忙，请刷新后重试',
                        autoHide: false,
                    });
                    reject(err);
                });
        });
        __poolMap.set(poolNamespace, promise);
        return promise;
    };
};

const usePoolMemo = ({ poolNamespace, pool = __POOL__ }) => {
    return () => {
        if (isFunction(pool)) {
            pool = pool();
        }
        initialize(pool, poolNamespace);
        return pool[poolNamespace];
    };
};

export { usePoolGetter, usePoolMemo };
