import { watch } from 'vue';
import { debounce } from '@jz/utils';
import { PayProduct } from '@/services';

const STORAGE_KEY = 'placeOrderData';

export const useDataStorage = (module, otherData = {}) => {
    if (typeof localStorage === 'undefined') {
        return;
    }

    const storageData = localStorage.getItem(STORAGE_KEY);

    if (storageData) {
        try {
            const { propList, itemList } = JSON.parse(storageData);
            module.propList.forEach((prop) => {
                const storageProp = propList.find((p) => p.id === prop.id);
                if (storageProp) {
                    prop.value = storageProp.value;
                }
            });
            module.itemList.forEach((item) => {
                const storageItem = itemList.find((i) => i.pdId === item.id);
                if (storageItem) {
                    if (typeof item.amount === 'number') {
                        item.amount = storageItem.amount;
                    }
                }
            });
        } catch (error) {
            console.error('Failed to parse storage data', error);
            localStorage.removeItem(STORAGE_KEY);
        }
    }

    // 详情页跳过来，读取 count
    const storeCountInfo = PayProduct.getStoreCountInfo();
    if (storeCountInfo) {
        const item = module.itemList.find((item) => item.pdId === storeCountInfo.productId);
        if (item) {
            item.amount = storeCountInfo.count;
        }
    }

    const setStorage = debounce(() => {
        const propList = module.propList.map((prop) => {
            return {
                id: prop.id,
                type: prop.type,
                value: prop.value,
            };
        });
        const itemList = module.itemList.map((item) => {
            return {
                id: item.pdId,
                amount: item.amount,
            };
        });
        localStorage.setItem(STORAGE_KEY, JSON.stringify({ propList, itemList, memberMsg: otherData.memberMsg.value }));
    }, 300);

    watch(
        () => {
            return [module.propList, module.itemList, otherData.memberMsg];
        },
        () => {
            setStorage();
        },
        { deep: true }
    );
};
