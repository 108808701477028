import { computed, set } from 'vue';

export const useProductList = (module) => {
    module.itemList?.forEach((product) => {
        if (typeof product.amount === 'undefined') {
            set(product, 'amount', 1);
        }
    });
    const productList = computed(() => module.itemList);
    const total = computed(() => {
        const t = module.itemList.reduce((acc, product) => {
            return acc + product.price * product.amount;
        }, 0);
        return t.toFixed(2);
    });
    return { productList, total };
};
