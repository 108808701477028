var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "photo-group-content-detail module_content_detail" },
    [
      _vm.emptyPhotoGroupInfo
        ? _c("empty-view")
        : [
            _vm.pc
              ? _c("pc-view", { attrs: { id: _vm.id, module: _vm.module } })
              : _c("mobi-view", { attrs: { id: _vm.id, module: _vm.module } }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }