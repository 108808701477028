var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisitMode && _vm.manageMode && _vm.isShowToolTab
    ? _c(
        "div",
        {
          staticClass: "popup_tool_tab",
          style: _vm.popupToolTabStyle,
          on: {
            mouseenter: _vm.mouseenterTool,
            mouseleave: _vm.mouseleaveTool,
          },
        },
        [
          _c("div", { staticClass: "jz_popup_zone_tool_item" }, [
            _c("div", { staticClass: "jz_popup_zone_tool_item_wrap" }, [
              _c("svg", { staticClass: "jz_popup_zone_tool_item_svg" }, [
                _c("use", { attrs: { "xlink:href": "#icon_tool_editing" } }),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "jz_popup_zone_tool_item_text",
                  on: {
                    click: function ($event) {
                      return _vm.editPopupZone(_vm.rowId)
                    },
                  },
                },
                [_vm._v("编辑")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isMobi,
                  expression: "!isMobi",
                },
              ],
              staticClass: "jz_popup_zone_tool_item",
              on: {
                mouseenter: _vm.mouseenterSwitchPopup,
                mouseleave: _vm.mouseleaveSwitchPopup,
              },
            },
            [
              _c("div", { staticClass: "jz_popup_zone_tool_item_wrap" }, [
                _c("svg", { staticClass: "jz_popup_zone_tool_item_svg" }, [
                  _c("use", {
                    attrs: { "xlink:href": "#icon_tool_switch_frame" },
                  }),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "jz_popup_zone_tool_item_text" }, [
                  _vm._v("切换弹窗"),
                ]),
                _vm._v(" "),
                _c(
                  "svg",
                  { staticClass: "jz_popup_zone_tool_item_svg_arrow" },
                  [_c("use", { attrs: { "xlink:href": "#icon_tool_arrow" } })]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "jz_popup_zone_tool_item",
              on: { click: _vm.hidePopupZone },
            },
            [
              _c("div", { staticClass: "jz_popup_zone_tool_item_wrap" }, [
                _c("svg", { staticClass: "jz_popup_zone_tool_item_svg" }, [
                  _c("use", { attrs: { "xlink:href": "#icon_tool_hide" } }),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "jz_popup_zone_tool_item_text" }, [
                  _vm._v("隐藏弹窗"),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowSwitchPopup && !_vm.isMobi,
                  expression: "isShowSwitchPopup && !isMobi",
                },
              ],
              staticClass: "jz_popup_zone_list",
              on: {
                mouseenter: _vm.mouseenterSwitchTool,
                mouseleave: _vm.mouseleaveSwitchTool,
              },
            },
            [
              _c(
                "scrollbar-component",
                { ref: "scrollbar", attrs: { "max-height": 100 } },
                _vm._l(_vm.popupList, function (popup) {
                  return _c(
                    "div",
                    {
                      key: popup.key,
                      staticClass: "jz_popup_zone_list_item",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.changePopupZone(popup.key)
                        },
                      },
                    },
                    [_vm._v(_vm._s(popup.label))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "jz_popup_zone_add",
                  on: { click: _vm.addPopupZone },
                },
                [_c("i"), _vm._v(" "), _c("span", [_vm._v("新建弹窗")])]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }