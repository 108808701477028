import request from '@jz/request';

/**
 * 获取跳转小程序的url
 * @param { Object } ajaxData - 数据
 * @returns { Promise } - 结果
 */

export function getWeixinAppScheme(ajaxData) {
    const data = {
        cmd: 'getWafNotCk_getWeixinAppScheme',
        ...ajaxData,
    };
    return request.post('rajax/site_h.jsp', { data });
}

export function getWeiXinShareData(data) {
    return request.post('/ajax/wx_h.jsp?cmd=getWafNotCk_getWXShareData', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getQzJumpAppList(data) {
    return request.post('/ajax/wx_h.jsp?cmd=getWafNotCk_getQzJumpAppList', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getQzAppAuthBtnUrl(data) {
    return request.post('/ajax/wx_h.jsp?cmd=getWafNotCk_getQzAppAuthBtnUrl', { data }).then(({ data = {} }) => {
        return data;
    });
}
