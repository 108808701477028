import { ref, onBeforeUnmount } from 'vue';
import { isBrowserEnv } from '@jz/utils';

export function calcRemain(remainTimestamp) {
    const remainDays = Math.floor(remainTimestamp / (24 * 60 * 60 * 1000));
    remainTimestamp -= remainDays * 24 * 60 * 60 * 1000;
    const remainHours = Math.floor(remainTimestamp / (60 * 60 * 1000));
    remainTimestamp -= remainHours * 60 * 60 * 1000;
    const remainMinutes = Math.floor(remainTimestamp / (60 * 1000));
    remainTimestamp -= remainMinutes * 60 * 1000;
    const remainSeconds = Math.floor(remainTimestamp / 1000);

    return { remainDays, remainHours, remainMinutes, remainSeconds };
}

export function useCountDown({ remainTimestamp }) {
    const remainDays = ref(0);
    const remainHours = ref(0);
    const remainMinutes = ref(0);
    const remainSeconds = ref(0);

    if (!remainTimestamp || !isBrowserEnv()) {
        return { error: true, remainDays, remainHours, remainMinutes, remainSeconds };
    }

    const calc = () => {
        if (remainTimestamp < 0) {
            clearTimer();
            remainDays.value = 0;
            remainHours.value = 0;
            remainMinutes.value = 0;
            remainSeconds.value = 0;
            return;
        }
        const remain = calcRemain(remainTimestamp);
        remainDays.value = remain.remainDays;
        remainHours.value = remain.remainHours;
        remainMinutes.value = remain.remainMinutes;
        remainSeconds.value = remain.remainSeconds;
        remainTimestamp -= 1000;
    };

    const timer = setInterval(calc, 1000);

    const clearTimer = () => {
        clearInterval(timer);
    };

    calc();

    onBeforeUnmount(() => {
        clearTimer();
    });

    return { error: false, remainDays, remainHours, remainMinutes, remainSeconds, clearTimer };
}
