/**
 * 反馈tips
 */
const Message = (options = {}) => {
    if (typeof options == 'string') {
        options = {
            message: options,
        };
    }

    if (typeof options.showClose == 'undefined') {
        options.showClose = true;
    }

    if (options.autoHide === false) {
        options.duration = 0;
    }

    if (typeof Vue !== 'undefined' && Vue.prototype.$message) {
        Vue.prototype.$message(options);
    } else {
        // Fai.ing(options.message, options.autoHide);
        alert(options.message);
    }
};
['success', 'warning'].forEach((type) => {
    Message[type] = (options = {}) => {
        if (typeof options == 'string') {
            options = {
                message: options,
            };
        }
        options.type = type;
        return Message(options);
    };
});

Message.close = () => {
    if (typeof Vue !== 'undefined' && Vue.prototype.$message) {
        Vue.prototype.$message.closeAll();
    } else {
        // Fai.removeAllIng();
    }
};
export { Message };
