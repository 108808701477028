var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form_edit_wrapper member_form_edit" }, [
    _c("div", { staticClass: "form_edit_container" }, [
      _c("div", { ref: "formDetailEl", attrs: { id: "formEl" } }),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "form_edit_button jz_theme_bg_color",
        class: { "letter-spacing": _vm.isCN },
        style: _vm.submitButtonStyle,
        attrs: { disabled: _vm.loading },
        on: { click: _vm.submit },
      },
      [_vm._v("\n        " + _vm._s(_vm.LS.siteFormSubmit) + "\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }