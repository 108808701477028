import { warningMessage, successMessage } from '@/site/shared/dialog/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
import { Pay, PayKnowProduct } from '@/services';
import { getKnowProductImageText, getKnowProductVideo, getKnowProductAudio } from '@/api/product/index.js';
import { asyncLoad } from '@jz/utils';

export default {
    data() {
        return {
            bought: false,
            videoUrl: '',
            initVideoShow: false,
            isShowVideo: false,
            audioUrl: '',
        };
    },
    computed: {
        knowProductModel() {
            return this.module.knowProductModel;
        },
        knowProductContentDisableCopy() {
            if (!this.knowProductModel.openKnowPayment || !this.isImageTextPayKnowProduct) {
                return false;
            }
            return this.module.knowProductModel.picTextCanCopy === 0;
        },
        isPayKnowProduct() {
            if (!this.knowProductModel.openKnowPayment) {
                return false;
            }
            return PayKnowProduct.isPayKnowProduct(this.module.productInfo);
        },
        payKnowBtnText() {
            return this.knowProductModel.knowButtonText || '购买内容';
        },
        payKnowBought() {
            return (
                this.knowProductModel.memberLogin &&
                (this.knowProductModel.hasPayKnowColumnProduct ||
                    this.knowProductModel.hasPayKnowProduct ||
                    this.bought)
            );
        },
        productPayKnowType() {
            return PayKnowProduct.getProductPayKnowType(this.productInfo);
        },
        isImageTextPayKnowProduct() {
            if (!this.isPayKnowProduct) {
                return false;
            }
            return this.productPayKnowType === PayKnowProduct.productPayKnowTypes.IMAGE_TEXT;
        },
        isVideoPayKnowProduct() {
            if (!this.isPayKnowProduct) {
                return false;
            }
            return this.productPayKnowType === PayKnowProduct.productPayKnowTypes.VIDEO;
        },
        isAudioPayKnowProduct() {
            if (!this.isPayKnowProduct) {
                return false;
            }
            return this.productPayKnowType === PayKnowProduct.productPayKnowTypes.AUDIO;
        },
        isColumnPayKnowProduct() {
            if (!this.isPayKnowProduct) {
                return false;
            }
            return this.productPayKnowType === PayKnowProduct.productPayKnowTypes.COLUMN;
        },
        audioTips() {
            if (!this.isAudioPayKnowProduct) {
                return '';
            }
            let tips = '';
            if (this.knowProductModel.trialPlayAudio) {
                tips = `试听前${this.knowProductModel.trialPlayAudioTime}分钟，购买后可收听完整内容`;
            }
            return tips;
        },
        audioNotTrialAndBought() {
            if (!this.isAudioPayKnowProduct) {
                return false;
            }
            return !this.knowProductModel.trialPlayAudio && !this.payKnowBought;
        },
    },
    mounted() {
        if (!this.isPayKnowProduct) {
            return;
        }
        if (this.isVideoPayKnowProduct) {
            this.playPayKnowVideo({ isPrefetch: true });
        }
        if (this.isAudioPayKnowProduct) {
            this.getKnowProductAudio();
        }
        this.checkUrlParamsHasPaid();
    },
    methods: {
        openColumnProductDirectory() {
            PayKnowProduct.openColumnProductDirectory({
                payColumnProductIdList: this.knowProductModel.payColumnProductIdList,
                productColUrl: this.knowProductModel.productColUrl,
                isBought: this.knowProductModel.hasPayKnowColumnProduct,
                productColId: this.knowProductModel.productColId,
            });
        },
        checkUrlParamsHasPaid() {
            if (!this.manageMode && this.knowProductModel.memberLogin && !this.payKnowBought) {
                if (Fai.getUrlParam(window.location.href, 'PayerID')) {
                    this.warningMsg('支付结果返回中，建议多刷新几次页面查看');
                }
            }
        },
        logPayKnowProduct(eventType) {
            if (typeof window !== 'object' || this.manageMode || !this.isPayKnowProduct) {
                return;
            }
            window.bizShared.context.fdpLog('jz_c_knowlegde_payment', {
                jz_free_text1: eventType,
                jz_free_text2: PayKnowProduct.productPayKnowTypesText[this.productPayKnowType],
                jz_content_terminal: window.bizShared.context.jzContentTerminal,
            });
        },
        handleTouchStart(e) {
            if (!this.knowProductContentDisableCopy) {
                return;
            }
            // 如果是图片，阻止长按弹出菜单
            if (e.target.tagName === 'IMG') {
                e.target.style.webkitUserSelect = 'none';
                e.target.style.pointerEvents = 'none';
            }
        },
        handleContextMenu(event) {
            if (!this.knowProductContentDisableCopy) {
                return;
            }
            event.preventDefault();
        },
        onVideoEnded() {
            const { trialPlayVideo } = this.knowProductModel;
            if (this.payKnowBought || !trialPlayVideo) {
                return;
            }
            PayKnowProduct.showTrialEndBuyPopup({
                title: '试看结束',
                tips: '试看已结束，如需要观看完整视频。请单独购买~',
                onBuy: () => {
                    this.handlePayKnowClick();
                },
            });
        },
        warningMsg(msg) {
            if (this.manageMode) {
                Message.warning(msg, true);
            } else {
                warningMessage(msg, true);
            }
        },
        loadHlsSources() {
            if (this.cacheHlsSources) {
                return this.cacheHlsSources;
            }
            this.cacheHlsSources = Promise.all([
                asyncLoad.loadJs(`${window._store.state.resRoot}/js/comm/video.js/hls.min.js?v=202307101206`),
                asyncLoad.loadJs(`${window._store.state.resRoot}/js/rjs/comm/video.min.js?v=202307101206`),
            ]);
            return this.cacheHlsSources;
        },
        async playPayKnowVideo({ isPrefetch = false } = {}) {
            const { trialPlayVideo, flvIsDel } = this.knowProductModel;
            const _warningMessage = (msg) => {
                if (isPrefetch) {
                    return;
                }
                return this.warningMsg(msg);
            };
            if (!isPrefetch) {
                if (this.manageMode) {
                    _warningMessage(`管理态下不支持${trialPlayVideo ? '试看' : '购买'}，请在访客态下访问。`);
                    return;
                }

                if (!this.knowProductModel.memberLogin) {
                    this.goLoginPage();
                    return;
                }
                if (flvIsDel) {
                    _warningMessage('视频资源不存在，请联系管理员');
                    return;
                }
                if (!this.payKnowBought && !trialPlayVideo) {
                    if (!this.knowProductModel.memberLogin) {
                        this.goLoginPage();
                        return;
                    }
                    _warningMessage('请先购买再观看');
                    return;
                }
            } else if (this.manageMode) {
                return;
            }
            try {
                if (this.initVideoShow && !isPrefetch) {
                    this.$refs.hslVideo.show();
                    return;
                }
                const videoData = await getKnowProductVideo({
                    moduleId: this.module.id,
                    productId: this.productInfo.id,
                });
                const { success, isDel, isMp4ToM3u8Success, isMp4, id: videoUrl } = videoData;

                if (!success) {
                    throw new Error(videoData.msg);
                }

                if (isDel) {
                    _warningMessage('视频资源不存在，请联系管理员');
                    return;
                }

                if (!isMp4ToM3u8Success) {
                    _warningMessage('视频格式有误，请联系管理员更新视频');
                    return;
                }

                if (isMp4) {
                    _warningMessage('视频加密大概需要1-5分钟，请加密完成后再播放观看');
                    return;
                }
                await this.loadHlsSources();
                this.videoUrl = videoUrl;
                this.initVideoShow = true;
                if (!isPrefetch) {
                    this.isShowVideo = true;
                }
            } catch (error) {
                console.error(error);
                _warningMessage('系统繁忙，请稍候再试');
            }
        },
        async handlePayKnowClick() {
            if (this.payKnowBought) {
                return;
            }
            if (this.manageMode) {
                this.warningMsg('管理态下不支持购买，请在访客态下访问');
                return;
            }
            if (!this.knowProductModel.memberLogin) {
                this.logPayKnowProduct('登录状态');
                this.goLoginPage();
                return;
            }

            const price = Number(this.formatPrice);
            if (price > 99999999) {
                this.warningMsg('支付金额过大，请联系管理员');
                return;
            }
            if (price === 0) {
                this.logPayKnowProduct('支付状态');
                // 0元特殊处理
                const orderData = await Pay.productSpecifiedData.getNativePayUrl({
                    productId: this.module.productInfo.id,
                });
                if (orderData.success) {
                    const payStatusData = await Pay.productSpecifiedData.checkPayStatus({
                        id: orderData.knowProductOrderId,
                    });
                    if (payStatusData.success) {
                        this.handlePaySuccess();
                    }
                }
                return;
            }

            const { payList } = await Pay.getPayList();
            const hasPaySetting = payList && payList.length > 0;
            if (!hasPaySetting) {
                this.warningMsg(LS.paymentNotOpen);
                return;
            }
            this.logPayKnowProduct('支付状态');
            Pay.goOnlinePay({
                businessTypes: Pay.businessTypes.PRODUCT,
                productId: this.module.productInfo.id,
                paySuccessCallbackInPc: () => {
                    this.handlePaySuccess();
                },
            });
        },
        handlePaySuccess() {
            this.bought = true;
            if (this.isImageTextPayKnowProduct) {
                this.refreshImageTextContent();
            } else if (this.isVideoPayKnowProduct) {
                this.refreshPage();
            } else if (this.isAudioPayKnowProduct) {
                this.refreshAudio();
            }
            successMessage('购买成功', true);
        },
        refreshAudio() {
            this.closeAudioTrialPopup?.();
            this.getKnowProductAudio();
        },
        goLoginPage() {
            window.location.href = `/${this.$store.state.curLanCode}/login.jsp?url=${encodeURIComponent(
                window.location.href
            )}`;
        },
        refreshPage() {
            setTimeout(() => {
                let jumpUrl = '';
                let { origin, pathname, search, hash } = window.location;
                try {
                    let urlSearchParam = new URLSearchParams(search);
                    // 【用户域名购买知识付费产品后页面未自动更新购买状态】https://www.tapd.cn/42954297/bugtrace/bugs/view?bug_id=1142954297001135971
                    urlSearchParam.append('random', jzUtils.createRandomId(4));
                    jumpUrl = `${origin}${pathname}?${urlSearchParam}${hash}`;
                } catch (err) {
                    jumpUrl = window.location.href;
                }
                window.location.href = jumpUrl;
            }, 1000);
        },
        async refreshImageTextContent() {
            const result = await getKnowProductImageText({
                productId: this.module.productInfo.id,
            });
            if (result.success) {
                this.$set(this.productInfo, 'encodeRemark', result.remark);
            } else {
                this.warningMsg('网络繁忙，请刷新页面');
            }
        },
        async getKnowProductAudio() {
            if (!this.knowProductModel.trialPlayAudio && !this.payKnowBought) {
                return;
            }

            const result = await getKnowProductAudio({
                moduleId: this.module.id,
                productId: this.module.productInfo.id,
            });
            if (!result.success) {
                this.warningMsg('网络繁忙，请刷新页面');
                return;
            }
            if (this.showAudioResultError(result)) {
                return;
            }
            await this.loadHlsSources();
            this.audioUrl = result.id;
            this.audioEncrypted = result.useM3u8;
            this.audioIsConvertToM3u8Success = result.isConvertToM3u8Success;
            this.audioIsDel = result.isDel;
        },
        showAudioResultError(result) {
            if (result.isDel) {
                this.warningMsg('音频资源不存在，请联系管理员');
                return true;
            }
            if (!result.isConvertToM3u8Success) {
                this.warningMsg('音频格式有误，请联系管理员更新音频');
                return true;
            }
            return false;
        },
        isAudioCanPlay() {
            if (this.manageMode) {
                this.warningMsg('管理态下不支持试听音频，请在访客态下访问');
                return false;
            }
            if (!this.knowProductModel.trialPlayAudio && !this.payKnowBought) {
                this.warningMsg('当前内容购买后才可收听');
                return false;
            }
            if (
                this.showAudioResultError({
                    isDel: this.audioIsDel,
                    isConvertToM3u8Success: this.audioIsConvertToM3u8Success,
                })
            ) {
                return false;
            }
            if (!this.audioEncrypted) {
                this.warningMsg('音频加密大概需要1-5分钟，请加密完成后再收听内容');
                this.getKnowProductAudio();
                return false;
            }
            return true;
        },
        onAudioEnded() {
            const { trialPlayAudio } = this.knowProductModel;
            if (this.payKnowBought || !trialPlayAudio) {
                return;
            }
            PayKnowProduct.showTrialEndBuyPopup({
                title: '试听结束',
                tips: '试听已结束，如需要收听完整音频请单独购买~',
                onBuy: () => {
                    this.handlePayKnowClick();
                },
            }).then(({ closePopup }) => {
                this.closeAudioTrialPopup = closePopup;
            });
        },
    },
};
