import { mapGetters, mapState } from 'vuex';
export const fullScreenMixin = {
    computed: {
        ...mapGetters(['isFullScreen']),
        ...mapState(['device', 'manageMode']),
        ...mapGetters(['info']),
        ...mapState('manage', ['jzPreviewAreaHeight', 'pageTop']),
        ...mapState('header', ['headerStyleStatus']),
        ...mapState('footer', ['footerStyleStatus']),
        ...mapState('row', ['rollingScreenRowInfo']),
        isPc() {
            return this.device == 'pc';
        },
        isMobi() {
            return this.device == 'mobi';
        },
        clientHeight() {
            return this.isPc || !this.manageMode ? '100vh' : `${this.jzPreviewAreaHeight}px`;
        },
        rollingScreen() {
            return this.info.pattern.rollingScreen;
        },
        toolBarHeight() {
            let toolBarHeight = 0;
            if (this.manageMode) {
                // 获取排除的高度 60为topBar的高度
                toolBarHeight = this.pageTop;
                if (!this.isPc && this.rollingScreen.isMobiSync) {
                    toolBarHeight = 0;
                }
            }
            return toolBarHeight;
        },
        hasBanner() {
            let independent = this.info.pattern.independent;
            let bannerPattern =
                independent.self && independent.selfBanner && independent.open ? independent.banner : this.info.banner;

            if (bannerPattern.hide) {
                return false;
            }
            if (this.isMobi && bannerPattern.mobiHide) {
                return false;
            }
            return true;
        },
        hasHeader() {
            let display = !this.info.pattern.header.display;
            if (this.isPc) {
                return display;
            } else {
                return display && !this.info.pattern.header.mobiHide;
            }
        },
        hasFooter() {
            //mobi没有独立开关
            return !this.info.pattern.footer.display;
        },
        footerHeight() {
            return this.footerStyleStatus.footerHeight;
        },

        headerHeight() {
            return this.hasHeader ? this.headerStyleStatus.headerHeight : 0;
        },
        headerLevitate() {
            return this.isFullScreen && this.rollingScreen.hftt === 1 && !this.isMobi;
        },
        isFixedTop() {
            return this.isFullScreen && this.rollingScreen.fixedTop;
        },
        hasMobiFooterNavServer() {
            return !this.info.pattern.footNav.h;
        },
        mobiFooterNavServerHeight() {
            return 51;
        },
    },
};
