var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "accordion_wrap" },
    _vm._l(_setup.accordionList, function (item, index) {
      return _c(_setup.AccordionItem, {
        key: item.id,
        attrs: {
          index: index,
          item: item,
          "is-active": _setup.activeAccordionItemId === item.id,
          "accordion-item-max-width": _setup.accordionItemMaxWidth,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }