export function initVisitorAdvertisement() {
    if (
        window._store.state.manageMode ||
        typeof window._store.state.isFreeVer === 'undefined' ||
        !window._store.state.isFreeVer
    ) {
        return;
    }

    const jumpUrl = '//support.huaweicloud.com/cloudsite/index.html';
    $('body').append(`
        <div id="aliVisitorAd" class="siteAdvertisement_box_BUser">
            <a class="siteAdvertisement_adImg J_adImgLink" target="_blank" href="${jumpUrl}">
                <img src="${window._store.state.resImageRoot}/image/advPhoto/f_visitor_BUser_ali.gif?v=201909241200" alt="免费建站" />
            </a>
            <a class="closeImg_BUser" href="javascript:void(0)"></a>
            <a class="freeJZ_BUser J_buttonLink" href="${jumpUrl}" target="_blank">
                <div class="anim"></div><span>免费建站</span>
            </a>
        </div>
    `);
    $('.closeImg_BUser').click(function () {
        $('#aliVisitorAd').remove();
    });
}
