var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    { staticClass: "site_search_text_compon jz_hover jz_theme_bg_hover_color" },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }