import MonitorDef from './MonitorDef';
import { frontMonitorRequest, logErr } from '@/api/monitor/index.js';

/*
 * 前端业务监控工具
 */
class Monitor {
    // 业务模块监控：业务执行
    // 业务执行结束后需执行结束句柄哦
    // 推荐直接使用返回的句柄进行结束统计，无法拿到句柄可使用endMonitor方法
    static startMonitor(monitorId) {
        if (!monitorId) {
            console.error('请传入 monitorId');
            return () => {};
        }

        // 记录开始时间
        const startTime = new Date();
        Monitor._monitors[monitorId] = { startTime };
        // 返回一个执行结束的函数句柄
        return (isSuccess = true, err) => this.endMonitor(monitorId, isSuccess, err);
    }

    // 业务模块监控：业务执行结束
    // 直接用 startMonitor 返回的函数句柄进行结束统计更方便哦（适用于不易拿到结束句柄的场景）
    static endMonitor(monitorId, isSuccess = true, err) {
        // 获取监控数据
        const monitor = Monitor._monitors[monitorId];
        delete Monitor._monitors[monitorId];
        // 检测监控数据是否存在
        if (!monitor || !monitor.startTime) {
            // console.error(`请先开启 monitorId：${ monitorId } 的 Monitor`);
            return;
        }

        // 数据上报
        this.logMonitor(monitorId, !isSuccess, new Date() - monitor.startTime);

        if (err) {
            this.logErr(
                'siteFrontendMonitorErr, monitorId:' + monitorId + '; href:' + location.href + '; errmsg:' + err.stack
            );
        }
    }

    // 业务模块监控（手动）
    // 支持统计业务次数，耗时，错误率
    static logMonitor(monitorId, isError, consumeTime) {
        this._sendData({
            monitorId: monitorId,
            cmd: 'wafNotCk_logMonitor',
            isError: isError,
            consumeTime: consumeTime,
            alarmMonitorId: this._getAutoAlarmMonitorId(monitorId),
        });
    }

    // 普通业务监控（手动告警监控）
    // 推荐通过配置 _AutoAlarmMonitorMap 进行自动告警监控
    // 原有的业务监控，统计业务次数，可配置告警以及实时视图
    static logAlarmMonitor(monitorId) {
        this._sendData({
            monitorId: monitorId,
            cmd: 'wafNotCk_logAlarmMonitor',
        });
    }

    // 发送 ajax 请求，把数据统计到后台
    static _sendData(data) {
        // 监控数据调试
        if (location.search.includes('monitor_log')) {
            console.log('监控上报', data);
        }

        frontMonitorRequest(data)
            .then((result) => {
                if (!result.success) {
                    console.error('监控数据上报错误', result);
                }
            })
            .catch((err) => {
                console.error('监控数据上报错误', err);
            });
    }

    //将错误信息上报到后台日志
    static logErr(str) {
        if (!str) return;
        logErr(encodeURIComponent(str));
    }

    // 获取自动告警监控时需要的告警id
    static _getAutoAlarmMonitorId(monitorId) {
        return MonitorDef._AutoAlarmMonitorMap[monitorId] || 0;
    }

    // 监控修饰器
    // 以后再扩展ES7 Decorator（目前大部分场景不适用ES7 Decorator，环境也要改）
    static monitorDecorator(func) {
        return (monitorId) =>
            function () {
                let result;
                const endMonitor = Monitor.startMonitor(monitorId);
                try {
                    result = func.apply(this, arguments);
                } catch (err) {
                    endMonitor(false, err);
                    throw err;
                }
                // 异步兼容（Promise、async function）
                if (result instanceof Promise) {
                    return result
                        .then((data) => {
                            endMonitor(true);
                            return data;
                        })
                        .catch((err) => {
                            endMonitor(false, err);
                            throw err;
                        });
                } else {
                    endMonitor(true);
                    return result;
                }
            };
    }
}

Monitor._monitors = {};

export default Monitor;
