const SERVICE_TYPE = {
    QQ: 0,
    WECHAT: 1,
    TAOBAO_WANGWANG: 2,
    ALI_WANGWANG: 3,
    SKYPE: 4,
};

const SERVICE_ICON_KEY = {
    [SERVICE_TYPE.QQ]: 'sqi',
    [SERVICE_TYPE.TAOBAO_WANGWANG]: 'stwi',
    [SERVICE_TYPE.ALI_WANGWANG]: 'sawi',
    [SERVICE_TYPE.SKYPE]: 'ski',
};

export { SERVICE_ICON_KEY, SERVICE_TYPE };
