var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("client-only", [
    _c(
      "div",
      { staticClass: "module_content_detail" },
      [
        _vm.insertLocation === 0 && _vm.allowScript && !_vm.safe_Mode
          ? [
              _vm.code
                ? _c("div", {
                    staticClass: "detachedCode",
                    domProps: { innerHTML: _vm._s(_vm.detachedCode) },
                  })
                : _vm.manageMode
                ? _c("empty-tips", {
                    attrs: {
                      "is-mobi": _vm.isMobi,
                      name: "插件代码",
                      "active-button-text": "添加代码",
                    },
                    on: { "click-active-button": _vm.onModuleEdit },
                  })
                : _vm._e(),
            ]
          : _c(
              "div",
              { staticClass: "m_plugin_code--empty" },
              [
                _vm.manageMode && !_vm.safe_Mode
                  ? [
                      _vm.allowScript
                        ? _c("alert-component", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.insertLocationText) +
                                "\n                    "
                            ),
                            _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: { click: _vm.onModuleEdit },
                              },
                              [_vm._v("修改代码")]
                            ),
                          ])
                        : _c("alert-component", { staticClass: "newSiteVis" }, [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.verProm) },
                            }),
                            _vm._v(" "),
                            !_vm.oem
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.updateUrlView,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("立即升级")]
                                )
                              : _vm._e(),
                          ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }