import { set } from 'vue';
import { ACCORDION_ITEM_MAX_LENGTH, ACCORDION_ITEM_MIN_LENGTH } from './constants';

export function initData({ module }) {
    if (!module.activeAccordionItemId) {
        set(module, 'activeAccordionItemId', module.prop0 || 0);
    }

    set(module, 'accordionItemMaxLength', ACCORDION_ITEM_MAX_LENGTH);
    set(module, 'accordionItemMinLength', ACCORDION_ITEM_MIN_LENGTH);
}
