import { ueHoverStyle } from '@/components/modules/shared/ue/index.js';
import { TableResponsive, clueReuqestVm, Clue, collectWebVitalsData, Versions } from '@jz/biz-shared';
import { wxShare } from './wx.js';
import { checkMemberSameTimeLogin } from '@/api/member/index.js';
import { getSales } from '@/api/salePromotion/index.js';
import { initWelcomePageView } from './welcomePage.js';
import { jumpToAnchor, ueHoverChangeImage } from './utils';
import { Message } from '@shared/manage/componMessage/index.js';
import { getState } from '@/site/shared/store/index.js';
import {
    bindMouseEnterHeaderEvent,
    bindScrollForFixTopStyle,
    unbindMouseEnterHeaderEvent,
} from '@/site/shared/fixTopStyle/index.js';
import { initFullScreen } from '@/components/grid/row/pureFullScreen/index.js';
import { showSimultaneousLoginPopup } from '../components/modules/memberLogin/index.js';
import { _voidVm } from '@/site/shared/vueEventBus/index.js';
import { checkUserDomainAction } from './site.js';
import { updateFullScreen } from '@/components/grid/row/pureFullScreen/index.js';
import { asyncLoad } from '@jz/utils';
import { showMemberLoginMsg } from '@/components/modules/memberLogin/index.js';
import { initVideoResourceHandler } from './mediaResourceHandler.js';
import { handleCssStylesSsrError } from '@/cssStyles/index.js';
import { topTips } from './topTips.js';
import { OperatorMutexHandler } from '@/manage/designer/hoverMutex.js';
import { renderUeMap } from './map.js';
import { FormPay } from '@/services';
import { initVisitorAdvertisement } from './visitorAdvertisement/index.js';

/**
 * --------------------------------------------------------------------------------------
 * Constants
 * --------------------------------------------------------------------------------------
 */

function preprocessBeianLink() {
    // 带有J_beian_mitt_hook的链接都得跳到备案官方域名
    window.addEventListener(
        'click',
        function (e) {
            if (e.target.className === 'J_beian_mitt_hook') {
                e.preventDefault();
                e.stopPropagation();
                window.open('//beian.miit.gov.cn/');
            }
        },
        true
    );
}

function checkFooterSupport() {}

function validateMemberSameLogin() {
    checkMemberSameTimeLogin().then((result) => {
        if (result.success === true) {
            if (result.showTips === true) {
                showSimultaneousLoginPopup();
            }
        }
    });
}

function initTopStyle() {
    let header = window._store.state.responsiveInfo.pattern.header || {};
    const isFullScreen = getState('fullScreen');
    let { fixedTop } = header;
    if (fixedTop && !isFullScreen) {
        bindScrollForFixTopStyle();
    }

    const rollingScreen = window._store.state.responsiveInfo.pattern.rollingScreen;
    const headerPattern = isFullScreen ? rollingScreen : header;
    const isSelectedFixedTop = headerPattern.fixedTop === 1;
    if (isSelectedFixedTop === true && headerPattern.se === true) {
        // 鼠标悬停显示固定样式
        bindMouseEnterHeaderEvent();
    } else {
        unbindMouseEnterHeaderEvent();
    }
}

const initPage = function () {
    initTopStyle();
    if (!window._store.state.manageMode) {
        setBodyClass();

        if (!Fai.getUrlParam(window.location.href, 'errno')) {
            validateMemberSameLogin();
        }
    }
    $(window).on('load.vitals', () => {
        collectWebVitalsData((data) => {
            jzRequest.post('/ajax/log_h.jsp', {
                data: {
                    cmd: 'wafNotCk_logFdpForWebVitals',
                    properties: {
                        ...data,
                        url: Fai.encodeUrl(window.location.href),
                    },
                },
            });
        });

        // 渲染包含ue地图的模块
        renderUeMap();
    });

    preprocessBeianLink();
    initWindowResize();
    initTableResponsiveEvent();
    checkFooterSupport();
};

let isCheckSiteHash = false;
const hasHash = () => {
    let hash = document.location.hash;
    return !!hash && !getState('fullScreen');
};
const checkSiteHash = function (_moduleId, _rowId) {
    let hash = document.location.hash,
        hashArr = [];
    if (!hash) {
        return false;
    }
    hashArr = Fai.URIParam.hash.parse(hash);
    if (hashArr.anchor) {
        let position = hashArr.anchor;
        if (_moduleId || _rowId) {
            let posArr = position.split('_'),
                [typeStr, id] = posArr,
                isRow = typeStr.indexOf('row') !== -1,
                isModule = typeStr.indexOf('module') !== -1;
            if (isCheckSiteHash) return;
            if (isRow && _rowId >= id) return;
            if (isModule && _moduleId >= id) return;
        }
        if (!isCheckSiteHash) isCheckSiteHash = true;
        if (position.indexOf('tab_') > -1) {
            let posArr = position.split('_'),
                [, id, , tabId] = posArr;
            return Site.jumpTabpack({
                moduleId: id,
                colId: window._store.state.colId,
                tabId: tabId,
            });
        }
        return true && jumpToAnchor(window._store.state.colId, position);
    }
};

const logClueSourceContent = () => {
    if (window._store.state.manageMode) return;
    // 微信静默登录上报
    window._store.state.silentLogin && clueReuqestVm?.authRecordLoginWeChat();
    switch (window._store.state.colId) {
        case 2:
            Clue?.logClueIndexSourceContent();
            break;
        default:
            break;
    }
};

const initPageLoad = function () {
    // 用来初始化 图文展示模块->文本->编辑->插入->鼠标经过换图
    if ($('.jz_module_style_1').length > 0) {
        ueHoverChangeImage($('.jz_module_style_1'));
    }
    // 用来初始化文章详情模块->文本->编辑->插入->鼠标经过换图
    if ($('.jz_module_style_4').length > 0) {
        ueHoverChangeImage($('.jz_module_style_4'));
    }
    // 检查hash（mackie）
    setTimeout(checkSiteHash);
    ueHoverStyle();
    initDocumentWheelEvent();
    if (/_newConversionGrayTest/.test(location.search) && window._store.state.manageMode) {
        // 供工具改间距逻辑 自动保存
        const isPc = Comm.getDevice === 'pc';
        const delay = isPc ? 0 : 800;
        setTimeout(() => {
            // 暂时不引入save
            Root.saveAll({
                callback: () => {
                    if (getState('fullScreen')) {
                        $('.jz_web_content .jz_web_row_line--full:not(.active_page)').hide();
                    }
                    Message.warning('自动保存成功');
                },
            });
        }, delay);
    }

    setTimeout(loadSalePromotion, 0);

    initVisitorAdvertisement();
    // 线索上报
    logClueSourceContent();

    window.bizManageShared?.trialExpiredTips();
};

const loadSalePromotion = function () {
    //oem绝对不能执行
    if (Fai.top.window._store.state.oem) {
        return;
    }
    getSales({
        _isResponsive: true,
    }).then((result) => {
        let salesParam;
        if (result.success) {
            salesParam = result.salesParam;
            if (!Fai.top.window._store.state.manageMode || salesParam.loadjsFile) {
                if (salesParam.js_url) {
                    asyncLoad.loadJs(salesParam.js_url, () => {
                        if (!IS_LOCALHOST_DEV) {
                            window.salesPromotion && window.salesPromotion.init(salesParam);
                        }
                    });
                }
            }
        } else {
            //非核心功能，保持静默通知
            console.log('获取营销数据失败(sys)...');
        }
    });
};

const windowResize = function () {};

function initWindowResize() {
    $(window)
        .off('resize.screen')
        .on('resize.screen', function () {
            if (!window._store.state.manageMode) {
                setBodyClass();
                updateFullScreen();
            }
        });
}

/**
 * @function name changeImageScraleTag
 * @description 改变图片缩放方式
 * @author mikey.chuhui
 * @date 2020-10-23
 * @param isMobi {boolean}
 */

function changeImageScraleTag(isMobi) {
    const COL_IMAGE_CONTOUR = $('.jz_col_module_image_contour');
    if (COL_IMAGE_CONTOUR.length) {
        COL_IMAGE_CONTOUR.each((index, item) => {
            isMobi && $(item).find('img').show();
            isMobi && $(item).find('div.module_image--bg').hide();
            !isMobi && $(item).find('img').hide();
            !isMobi && $(item).find('div.module_image--bg').show();
        });
    }
}

/**
 * @function name setBodyClass
 * @description 设置body 类名  设备切换
 * @date 2020-10-23
 */

function setBodyClass() {
    if (window._store.state.isOpenMResp) {
        return;
    }
    let rollingScreen = window._store.state.responsiveInfo.pattern.rollingScreen;
    const isChangeRowStyle = getState('fullScreen');
    const pageWidth = isChangeRowStyle ? 1024 : 768;
    const isIframe = window !== top;
    const isMobi = (isIframe ? window.innerWidth : window.outerWidth) <= pageWidth;
    const device = Comm.getDevice();
    if ((!isMobi && device == 'mobi') || (isMobi && device == 'pc')) {
        if (!isMobi) {
            $('html').removeClass('jz_screen_mobi').addClass('jz_screen_pc');
            if (!rollingScreen?.isMobiSync) {
                switch (getState('fullScreen')) {
                    case 0:
                        break;
                    case 1:
                        $('html').addClass('jz_full_screen_scrolling full_screen_col');
                        break;
                    case 2:
                        $('html').addClass('jz_full_screen_scrolling full_screen_row');
                        break;
                    default:
                        break;
                }
            }
        } else {
            if (!rollingScreen?.isMobiSync) {
                switch (getState('fullScreen')) {
                    case 0:
                        break;
                    case 1:
                        $('html').removeClass('jz_full_screen_scrolling full_screen_col');
                        break;
                    case 2:
                        $('html').removeClass('jz_full_screen_scrolling full_screen_row');
                        break;
                    default:
                        break;
                }
            }
            $('html').removeClass('jz_screen_pc').addClass('jz_screen_mobi');
        }
        changeImageScraleTag(isMobi);
    }
    window._store.commit('changeDevice', isMobi ? 'mobi' : 'pc');
    return Promise.resolve({
        changeDevice: (!isMobi && device == 'mobi') || (isMobi && device == 'pc'),
        isMobi,
    });
}

function initNewBridgeEvent() {
    // 百度商桥，由于移动端被我们这边强制显示，所以，这边要对几处关闭的地方做fix处理，不然关闭按钮失效
    if (window._store.state.manageMode || !!$('.jz_screen_pc').length) {
        return;
    }
    $('body').one('click.ni', '#nb_invite_tool', function (event) {
        event.preventDefault();
        /* Act on the event */
        // $('#newBridge').css('display', 'none');
    });
}

function initDocumentWheelEvent() {
    document.addEventListener(
        'mousewheel',
        (evt) => {
            if (evt.ctrlKey && window._store.state.manageMode && JZ.detectZoom) {
                JZ.detectZoom.debounce(200, JZ.detectZoom.setRatio);
            }
            if (
                !$('.sub_nav_list.sub_nav_list_active').is(':visible') &&
                !$('.jz_nav_item_active .sub_nav_list').is(':visible')
            ) {
                return;
            } else {
                const subNav = $('.sub_nav_list_active .sub_nav_wrap');
                const threeNav = $('.three_nav_list_active .three_nav_wrap');

                let mouseInSubNav = false;
                let mouseInThreeNav = false;
                if (subNav.length) {
                    const targetElement = evt.target;
                    if (subNav[0].contains(targetElement)) {
                        // console.log('鼠标在 subNav 元素内部');
                        mouseInSubNav = true;
                    }
                }

                if (threeNav.length) {
                    const targetElement = evt.target;
                    if (threeNav[0].contains(targetElement)) {
                        // console.log('鼠标在 threeNav 元素内部');
                        mouseInThreeNav = true;
                    }
                }

                const { wheelDeltaY } = evt;

                if (mouseInSubNav && !mouseInThreeNav) {
                    // 有二级导航 或者 有三级导航但鼠标在二级导航上
                    if (subNav.length && subNav.data('needScroll')) {
                        // 有滚动条的情况下，就不禁止滚动事件。
                        let scrollTop = subNav.scrollTop(),
                            childHeight = subNav.data('height'),
                            childNowHeight = subNav.height();
                        if (
                            (wheelDeltaY > 0 && scrollTop == 0) ||
                            (wheelDeltaY < 0 && scrollTop + childNowHeight >= childHeight)
                        ) {
                            // console.log('滚动到头了, 防止滚动穿透');
                        } else {
                            return;
                        }
                    }
                }

                if (mouseInThreeNav) {
                    // 鼠标在三级导航上
                    if (threeNav.length && threeNav.data('needScroll')) {
                        // 有滚动条的情况下，就不禁止滚动事件。
                        let scrollTop = threeNav.scrollTop(),
                            childHeight = threeNav.data('height'),
                            childNowHeight = threeNav.height();
                        if (
                            (wheelDeltaY > 0 && scrollTop == 0) ||
                            (wheelDeltaY < 0 && scrollTop + childNowHeight >= childHeight)
                        ) {
                            // console.log('滚动到头了, 防止滚动穿透');
                        } else {
                            return;
                        }
                    }
                }
            }
            evt = evt || window.event;
            if (evt.preventDefault) {
                // Firefox
                evt.preventDefault();
                evt.stopPropagation();
            } else {
                // IE
                evt.cancelBubble = true;
                evt.returnValue = false;
            }
            return false;
        },
        { passive: false }
    );
}

const initPageReady = () => {
    if (!window._store.state.manageMode) {
        initNewBridgeEvent();
        // ==> 检查用户域名是否被copy以及应对操作
        checkUserDomainAction();
    }

    // 初始化顶部小黄条广告
    topTips.init();
    // 初始化全屏通栏 全屏栏目才初始化
    !!getState('fullScreen') && initFullScreen();
    // 表单，检查之后跳转回来的参数
    FormPay.checkFormPayParam();
    wxShare();

    if (window._store.state.colId === 2 /** SiteColDef.Type.SYS_INDEX */ && !window._store.state.manageMode) {
        initWelcomePage(window._store.state.welcomePage);
    }

    // zoom整体网站测试
    doZoom();
    logoZoom();
};

function doZoom() {
    if (!window.location.href.includes('zoom=1')) {
        return;
    }
    if (window._store.state.manageMode || window._store.getters.isMobi) {
        return;
    }

    const _do = () => {
        const zoom = window.innerWidth / 1920;
        document.documentElement.style.zoom = zoom;
    };

    window.addEventListener('resize', function () {
        _do();
    });

    _do();
}

function logoZoom() {
    if (!window.location.href.includes('logoZoom=1')) {
        return;
    }
    if (window._store.state.manageMode || window._store.getters.isMobi) {
        return;
    }

    const navLogo = document.querySelector('.logo_wrap');

    if (!navLogo) {
        return;
    }

    const _do = () => {
        const zoom = window.innerWidth / 1920;
        navLogo.style.zoom = zoom;
    };

    window.addEventListener('resize', function () {
        _do();
    });

    _do();
}

/**
 * 触发table表格自适应的事件绑定
 * @author Jy
 * @date 2020-12-07
 */
function initTableResponsiveEvent() {
    $(window).on('load.tableresponsive', function () {
        TableResponsive.init({
            $tables: $('.module_content_detail table'),
            parentClassName: '.module_content_detail',
        });
    });
}

/**
 *
 * @param {Object} welcomePageData
 */
function initWelcomePage(welcomePageData = {}) {
    const device = Comm.getDevice();

    const isOpen = () => {
        const isMobileView = Comm.getDevice() === 'mobi';
        if (welcomePageData.open === false) return false;
        return (isMobileView && welcomePageData.mobiOpen === true) || (!isMobileView && welcomePageData.open === true);
    };

    if (welcomePageData.allowOpen === false || isOpen() === false) {
        /** @type {HTMLElement} */
        const welcomePageEl = document.querySelector('.J_welcomePage');
        if (welcomePageEl !== null) {
            welcomePageEl.classList.add('welcome_page--hidden');
        }
        return;
    }

    initWelcomePageView(welcomePageData, device);
}

/**
 * 初始化页面监听，从jsp迁移的
 */
const initPageAfterDomLoaded = function (callPrinterScripts) {
    try {
        const {
            colId,
            statistics,
            isExperienceAcct,
            openAutoPushPC,
            hasCheckLvBrowser,
            contextMenuDisabled,
            limitUv,
            limitDayUv,
            oem,
            isDemo,
        } = window._store.state;

        if (!window._store.state.manageMode) {
            // 访客态链接跳转
            document.addEventListener(
                'click',
                function (event) {
                    const path = event
                        ? typeof event.composedPath === 'function'
                            ? event.composedPath()
                            : event.path
                        : [];
                    for (var i in path) {
                        var node = path[i];
                        if (node.nodeName === 'A') {
                            // 点击A标签
                            var href = node.getAttribute('href');
                            if (!href || href.includes('javascript:')) return;

                            let search = node.search || '';
                            // 文件下载路径 xxx.png?f=xxx&v=xxxx
                            let isFileDownload = search.includes('f=') && search.includes('v=');

                            if (!isFileDownload && Fai.getUrlParam(location.href, '_igClose') === 'true') {
                                href = Fai.addUrlParamsV2(href, {
                                    _igClose: true,
                                });
                            }
                            if (
                                (window.innerWidth <= 768 || window.self != window.top) &&
                                !(href.indexOf('http:') > -1 || href.indexOf('https:') > -1)
                            ) {
                                // 手机或者内嵌iframe点击链接时取消新页面打开（站内链接）
                                event.preventDefault();
                                location.href = href;
                            } else if (
                                window.self != window.top &&
                                location.protocol === 'https:' &&
                                href.indexOf('http:') > -1
                            ) {
                                // 内嵌iframe点击链接，会检查域名是https的前提下，请求http。
                                event.preventDefault();
                                window.open(href);
                            }
                        }
                    }
                },
                false
            );

            // 走到这里说明是页面刚生成，那么此刻去判断 顶层是否有 rapidSite这个对象，并且是否展示了极速建站，来作为是否是从极速建站预览的模板的判断，从而不走这块逻辑
            if (isDemo && !window._store.state.isOemDemoSiteHost) {
                try {
                    if (!(parent.rapidSite && parent.rapidSite._vm.$store.state.openRapidSite)) {
                        parent.postMessage(
                            location.pathname + location.search + location.hash,
                            location.protocol + window._store.state.siteAdmHost
                        );
                    }
                    // eslint-disable-next-line no-empty
                } catch (error) {}
            }
        } else {
            OperatorMutexHandler.init();

            if (window._store.state.manage.mallGuidePopRef) {
                asyncLoad.loadJs(window._store.state.manage.mallGuidePopRef);
            }

            if (window._store.state.manage.isShowSiteClosePopup) {
                JZ.showVisitLimitPopup();
            }
        }

        initPage();

        if (window._store.state.manageMode) {
            Root.initPage();
            JZ.initManagePage();
        }

        $(function () {
            if (window._store.state.manageMode) {
                if (window._store.state.manage.manageFrameParam) {
                    Root.showTopManageFrame(window._store.state.manage.manageFrameParam);
                }

                Root.initPageReady();

                loadEditPanel();

                if (window._store.state.manage.auth.designMode) {
                    loadFuncPanel();
                }

                loadPopupZonePanel();
            }

            initPageReady();

            if (window._store.state.manageMode) {
                JZ.initManagePageReady();

                if (window._store.state.manage.viomid != 0) {
                    Root.showModuleManage(window._store.state.manage.viomid);
                }
            }

            var winOnload = window.onload;
            window.onload = function () {
                if (typeof winOnload == 'function') {
                    winOnload();
                }

                if (window._store.state.isOemSiteHost) {
                    $.ajax({
                        type: 'POST',
                        url: '/ajax/log_h.jsp',
                        data:
                            'cmd=wafNotCk_logJZFdpTrack&event=jz_agentyuming_restriction&properties=' +
                            $.toJSON({
                                jz_free_bool1: true,
                                jz_free_text_0: '建站',
                            }),
                    });
                }

                if (isExperienceAcct) {
                    const cssTest = () => {
                        var elem = document.createElement('x');
                        elem.style.cssText = 'pointer-events:auto';
                        return elem.style.pointerEvents === 'auto';
                    };
                    if (cssTest()) {
                        $('body').append("<svg id='GWWatermark' class='gw_watermark'><svg>");
                    }
                }

                initPageLoad();

                if (!hasCheckLvBrowser) {
                    $.ajax({
                        url: '/ajax/setCookie_h.jsp?cmd=setCheckRespLvBrowser',
                    });
                }

                /*禁用右键，同时禁目复制、剪切 (管理态开放，且访客态去掉粘贴paste 20170125)*/
                if (
                    (!window._store.state.manageMode && contextMenuDisabled) ||
                    (window._store.state.manageMode && !window._store.state.manage.auth.designMode)
                ) {
                    Site.closeRightClickTool();
                }

                if (window._store.state.manageMode && window._store.state.manage.creatNewSite) {
                    history.pushState({}, '', '?fromJzAllSite=true');
                }

                // ajax统计
                Site.total({
                    colId,
                    pdId: statistics.pdId,
                    ndId: statistics.ndId,
                    sc: statistics.sc,
                    statId: statistics.statId,
                });

                if (openAutoPushPC) {
                    Site.printBaiduPushJs();
                }

                Site.ajaxLogDog();
                //收集浏览器信息，统计dog
                Site.sendBrowerInfo(window._store.state.manageMode);

                if (!window._store.state.manageMode) {
                    Site.siteStatVisitTime(statistics.statId);
                }

                window.winBeforeunload = window.onbeforeunload;
                window.onbeforeunload = JZ.onbeforeunload;

                $(function () {
                    JZ.sectionObserver = JZ.initSectionObserver(JZ._sectionObserverData);
                    callPrinterScripts?.();
                });

                /**极速建站start */
                if (window._store.state.manageMode) {
                    const { rapidSiteCssPath, rapidSitePath, isCloneByOther, cloneDemoId, oemDemo } =
                        window._store.state.manage.rapidSite;
                    // rapidSite 在site, 暂时不能改成动态import
                    asyncLoad.loadCss(rapidSiteCssPath, () => {
                        asyncLoad.loadJs(rapidSitePath, () => {
                            window.rapidSite.initForNoTemplate();

                            if (isCloneByOther) {
                                window.rapidSite.cloneByOtherWays(cloneDemoId, oemDemo);
                            }

                            // 当从多网站的创建网站入口进来时（为非首个默认网站），跳过火箭引导页，直接进去请选择行业。
                            if (window._store.state.manage.creatNewSite && window._store.state.manageMode) {
                                window.rapidSite.init();
                                history.pushState({}, '', '?fromJzAllSite=true');
                            }
                        });
                    });
                }
                /**极速建站end */

                if (window._store.state.siteAutoBaiduPush) {
                    (function () {
                        var bp = document.createElement('script');
                        var curProtocol = window.location.protocol.split(':')[0];
                        if (curProtocol === 'https') {
                            bp.src = '//zz.bdstatic.com/linksubmit/push.js';
                        } else {
                            bp.src = '//push.zhanzhang.baidu.com/push.js';
                        }
                        var s = document.getElementsByTagName('script')[0];
                        s.parentNode.insertBefore(bp, s);
                    })();
                }
            };

            if (limitUv) {
                Site.limitUv();
            }

            if (limitDayUv) {
                Site.limitDayUv();
            }

            // 页面权限不够提示
            // if (!window._store.state.manageMode) {
            //     var errno = Fai.getUrlParam(window.location.href, 'errno');
            //     if (errno == 14) {
            //         Fai.ing(LS.memberLoginNoPermission);
            //     }
            // }

            if (window._store.state.manageMode) {
                import('@/manage/svgManage/index.js').then(() => import('@/site/svg/index.js'));
            } else {
                import('@/site/svg/index.js');
            }
            import('@/styles/datepicker.scss');

            if (isDemo || window._store.state.manageMode) {
                import('@/libs/domToImage.js');
            }

            if (window._store.state.manageMode && !oem) {
                loadHelpQuery();
            }

            if (window._store.state.manageMode && window._store.state.manage.isLogin) {
                var link = $('#adminHref').attr('href');
                var protocol = window.location.protocol;
                var url = window.location.href;
                var path = url.substr(protocol.length);
                var newLink = link + '&_preUrl=' + Fai.encodeUrl(path) + '&mall=false&_preSiteId=' + Fai.top._siteId;
                $('#adminHref').attr('href', newLink);
            }

            setTimeout(() => {
                Site.addQrCode();
            }, 500);

            checkChoiceLanguageForm();

            checkTipHttpInCode();

            if (!window._store.state.manageMode) {
                showMemberLoginMsg(parseInt(Fai.getUrlParam(window.location.href, 'errno')) || 0);
            }

            if (window._store.state.isJzCdnFlowLimit) {
                initVideoResourceHandler();
            }

            if (!window._store.state.manageMode) {
                handleCssStylesSsrError();
            }

            {
                const aboveStandardVersion = !window._store.state.oem
                    ? window._store.state.siteVer >= Versions.STD
                    : window._store.state.siteVer >= Versions.OEM_MID;
                const indexPopupId = window._store.state.responsiveInfo.pattern.indexPopup.id;
                const indexPopupOpen = window._store.state.responsiveInfo.pattern.indexPopup.open;
                if (aboveStandardVersion && colId === 2 && indexPopupOpen && indexPopupId) {
                    window._store.state.popupZone.isIndexPopup = true;
                    Fai.top.JZ.openPopupZone(indexPopupId, false);
                }
            }
        });
    } catch (e) {
        e.innerError = true;
        throw e;
    }
};

function checkTipHttpInCode() {
    const { open, type, tipData } = window._store.state.tipHttpInCode;
    if (open) {
        Site.showTopTips(type, tipData);
    }
}

function checkChoiceLanguageForm() {
    const { open, tips, url, confirmTips, cancelTips } = window._store.state.choiceLanguageForm;
    if (open) {
        Site.jumpToTargetLanguage(tips, url, confirmTips, cancelTips);
    }
}

async function loadEditPanel() {
}

async function loadFuncPanel() {
}

async function loadPopupZonePanel() {
}

async function loadHelpQuery() {
}

function initFaiHawkEyeMonitor() {
    window.FAI_HAWK_EYE &&
        (window.FAI_HAWK_EYE.js_err_filter = function () {
            var event = arguments[4];
            // site2.jsp.inc内部js报错
            if (event && event.innerError) {
                return true;
            }
            // js 文件类型报错
            var url = arguments[1];
            // ['1.ss.faisys.com/js', 'fe.faisys.com', 'jzfe.faisys.com'] 【业务代码， 公共组件代码】
            if (
                url &&
                ['1.ss.faisys.com/js', 'fe.faisys.com', 'jzfe.faisys.com'].some(function (item) {
                    return url.indexOf(item) > -1;
                })
            ) {
                return true;
            }
            return false;
        });
}

function setPopupWindowPrefix() {
    (function () {
        var lancode = Fai.top._curLanCode;
        function startsWith(str, search, pos) {
            pos = !pos || pos < 0 ? 0 : +pos;
            return str.substring(pos, pos + search.length) === search;
        }
        function formater(prefix, url) {
            if (lancode) {
                if (!startsWith(url, '/') && !startsWith(url, '../') && !startsWith(url, 'http')) {
                    if (/^((\.\/)|\/)?r?manage(_v2)?\/(.*\.jsp)?/.test(url)) {
                        var rIndex = url.indexOf('./') === 0;
                        if (rIndex) {
                            url = url.substring(2);
                        }
                        var hasCode = window.location.pathname.indexOf('/' + lancode + '/') !== -1;
                        if (hasCode) {
                            url = prefix + url;
                        } else {
                            url = '/' + url;
                        }
                    }
                }
            }
            return url;
        }
        try {
            Fai.top.Fai.popupWindowVersionTwo.setPrefix('/' + Fai.top._curLanCode + '/', formater);
            Fai.top.Fai.popupWindowVersionOne.setPrefix('/' + Fai.top._curLanCode + '/', formater);
        } catch (e) {
            console.error(e);
        }
    })();
}

export {
    _voidVm,
    initPage,
    initPageReady,
    hasHash,
    topTips,
    checkSiteHash,
    initPageLoad,
    windowResize,
    initPageAfterDomLoaded,
    initFaiHawkEyeMonitor,
    setPopupWindowPrefix,
};
