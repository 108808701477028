import { checkSaveBar } from '@shared/manage/popupChangedBodyWindow/index.js';

export const createManagePageUrl = function (initPage = 'manageFrameJZAllSiteStat', hash) {
    const addBackQueryParam = function (baseUrl) {
        return (
            Fai.addUrlParams(baseUrl, '_back=' + Fai.encodeUrl(Fai.top.window.location.href)) + (hash ? '#' + hash : '')
        );
    };

    const isDevEnv = location.href.indexOf('.dev.cc') !== -1;

    if (Fai.top._isManageFrame && !isDevEnv) {
        // return Fai.top.Site._createManagePageUrl(initPage);
    }

    let baseUrl = '//' + window._store.state.manage.jzAllSiteDomain;
    baseUrl = Fai.addUrlParams(baseUrl, 'siteId=' + window._store.state.siteId);
    baseUrl = Fai.addUrlParams(baseUrl, '_item=' + initPage);
    baseUrl = Fai.addUrlParams(baseUrl, '_isResponsive=true');

    if (window._store.state.lcid !== window._store.state.manage.defaultLcid) {
        baseUrl = Fai.addUrlParams(baseUrl, '_lcid=' + window._store.state.lcid);
    }

    if (window._store.state.manage.isLoginO) {
        baseUrl = Fai.addUrlParams(baseUrl, '__tmpaid=' + window._store.state.aid);
    }

    return addBackQueryParam(baseUrl);
};

export const showTopManageFrame = (() => {
    let _src = '';
    /*
     * 展示管理后台
     * @param {String} item 展示管理后台中的某个页面
     */

    return (item = '', hash, refresh) => {
        if (
            checkSaveBar({
                callback: jumptoFrame(item, _src, undefined, refresh),
            })
        ) {
            return;
        }

        jumptoFrame(item, _src, hash, refresh)();
    };

    function jumptoFrame(item, hash) {
        //信息查询未开通且帮助面板打开时 关闭面板 by mackie 20191009
        if (item === 'manageFrameSearchProjectAdd' && $('#fk-helpQueryOuterPanel').length > 0) {
            $('#fk-helpQueryOuterPanel').hide();
            /* eslint-disable-next-line no-undef */

            window.FAI_HELP_QUERY.vue.closePanelClick();
        }

        return function () {
            return managePageHandler(item, hash);
        };
    }
    /**
     * 设计器跳转到管理后台页面的方法
     * @param {String} initPage 默认打开的页面. 若无则为manageFrameJZAllSiteStat聚合页
     * @param {String} hash 需要带上的hash值
     */

    function managePageHandler(item = 'manageFrameJZAllSiteStat', hash) {
        const url = createManagePageUrl(item, hash);
        Fai.top.window.location.href = url;
    }
})();
