var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ChildrenCSS", [
    _vm._v(
      "\n    " +
        _vm._s(_vm.pcId) +
        " .accordion_item { width: " +
        _vm._s(_vm.itemPercentageWidth.initial) +
        "%; }\n    " +
        _vm._s("\n") +
        "\n    " +
        _vm._s(_vm.pcId) +
        " .accordion_item_active { width: " +
        _vm._s(_vm.itemPercentageWidth.hover) +
        "%; }\n\n    " +
        _vm._s(_vm.bgCss) +
        "\n    " +
        _vm._s(_vm.initialShadow) +
        "\n    " +
        _vm._s(_vm.hoverShadow) +
        "\n    " +
        _vm._s(_vm.height) +
        "\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }