var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "order_detail_form_item" }, [
    _c("div", { staticClass: "order_detail_form_item_title" }, [
      _c("span", { class: { required: _vm.required } }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "order_detail_form_item_content" }, [
      _vm.isText || _vm.isMail
        ? _c("input", {
            staticClass: "order_detail_form_input",
            attrs: { maxlength: _vm.maxLength, placeholder: _vm.placeholder },
            domProps: { value: _vm.value },
            on: { input: _vm.onInput },
          })
        : _vm.isPhone
        ? _c(
            "div",
            { staticClass: "order_detail_form_phone_wrap" },
            [
              _c("select-component", {
                staticClass: "area_code",
                class: "area_code_" + _vm.device,
                attrs: {
                  "show-search": true,
                  list: _vm.areaList,
                  value: _vm.areaCode,
                  placeholder: "区号",
                  "content-key": "areaText",
                  "input-key": _vm.device === "pc" ? "areaText" : "ctCode",
                },
                on: { input: _vm.selectAreaCode },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.phoneValue,
                    expression: "phoneValue",
                  },
                ],
                staticClass:
                  "order_detail_form_input order_detail_form_phone_input",
                attrs: {
                  maxlength: _vm.maxLength,
                  onkeypress: "return Fai.isPhoneNumberKey(event)",
                  placeholder: _vm.placeholder,
                },
                domProps: { value: _vm.phoneValue },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.phoneValue = $event.target.value
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }