var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "client-only",
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDisplayBtn,
                expression: "isDisplayBtn",
              },
              {
                name: "hovermenu",
                rawName: "v-hovermenu",
                value: _vm.hovermenu,
                expression: "hovermenu",
              },
            ],
            staticClass: "back_top_wrapper",
          },
          [
            _c(
              "div",
              {
                staticClass: "back_top_content",
                class: { m_rocket: _vm.iconType === 5 && _vm.isSystem },
                on: { click: _vm.jumpToTop },
              },
              [
                _vm.isSystem
                  ? _c("div", { staticClass: "back_top_system" }, [
                      _vm.iconType === 0
                        ? _c("div", { staticClass: "svg_0" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 48 48",
                                },
                              },
                              [
                                _c("title", [_vm._v(_vm._s(_vm.title))]),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    width: "48",
                                    height: "48",
                                    rx: "24",
                                    ry: "24",
                                    fill: _vm.btnColor,
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M31.71,26.3l-7-7a1,1,0,0,0-1.4,0l-7,7a1,1,0,0,0,1.4,1.4L24,21.39l6.31,6.31a1,1,0,0,0,1.4-1.4Z",
                                    fill: "#fff",
                                    "fill-rule": "evenodd",
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.iconType === 1
                        ? _c("div", { staticClass: "svg_0" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 48 48",
                                },
                              },
                              [
                                _c("title", [_vm._v(_vm._s(_vm.title))]),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    width: "48",
                                    height: "48",
                                    rx: "4",
                                    ry: "4",
                                    fill: _vm.btnColor,
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M33.57,27.35l-8.52-8.9a1.45,1.45,0,0,0-2.1,0l-8.52,8.9a1.63,1.63,0,0,0,0,2.2,1.45,1.45,0,0,0,2.1,0L24,21.74l7.47,7.81a1.45,1.45,0,0,0,2.1,0A1.63,1.63,0,0,0,33.57,27.35Z",
                                    fill: "#fff",
                                    "fill-rule": "evenodd",
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.iconType === 2
                        ? _c("div", { staticClass: "svg_0 svg_2" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 48 48",
                                },
                              },
                              [
                                _c("title", [_vm._v(_vm._s(_vm.title))]),
                                _vm._v(" "),
                                _c("rect", {
                                  attrs: {
                                    x: "0.5",
                                    y: "0.5",
                                    width: "47",
                                    height: "47",
                                    rx: "23.5",
                                    ry: "23.5",
                                    fill: "#fff",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1A23,23,0,1,0,47,24,23,23,0,0,0,24,1Z",
                                    fill: "#e5e5e5",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "text",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { x: "12", y: "35", fill: "#666" },
                                  },
                                  [_vm._v("TOP")]
                                ),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M30.72,18.37l-6-6.09a1,1,0,0,0-1.34,0l-6,6.09a1,1,0,0,0,0,1.35,1,1,0,0,0,1.34,0L24,14.31l5.38,5.41a1,1,0,0,0,1.34,0A1,1,0,0,0,30.72,18.37Z",
                                    fill: "#7a7c7f",
                                    "fill-rule": "evenodd",
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.iconType === 3
                        ? _c("div", { staticClass: "svg_3" }, [
                            _c("div", { staticClass: "svg_shadow_3" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 48 48",
                                  },
                                },
                                [
                                  _c("title", [_vm._v(_vm._s(_vm.title))]),
                                  _vm._v(" "),
                                  _c("rect", {
                                    attrs: {
                                      width: "48",
                                      height: "48",
                                      rx: "8",
                                      ry: "8",
                                      fill: "#fff",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M32.57,26.71l-7.52-7.52a1.48,1.48,0,0,0-2.1,0l-7.52,7.52a1.5,1.5,0,0,0,0,2.1,1.48,1.48,0,0,0,2.1,0L24,22.34l6.47,6.47a1.48,1.48,0,0,0,2.1,0A1.5,1.5,0,0,0,32.57,26.71Z",
                                      fill: "#666",
                                      "fill-rule": "evenodd",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.iconType === 4
                        ? _c("div", { staticClass: "svg_4" }, [
                            _c("div", { staticClass: "svg_shadow_4" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 48 48",
                                  },
                                },
                                [
                                  _c("title", [_vm._v(_vm._s(_vm.title))]),
                                  _vm._v(" "),
                                  _c("rect", {
                                    attrs: {
                                      width: "48",
                                      height: "48",
                                      rx: "24",
                                      ry: "24",
                                      fill: "#fff",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M24.78,18.4A1,1,0,0,0,24,18h0a1,1,0,0,0-.38.08.86.86,0,0,0-.33.23l-6.93,7a1,1,0,1,0,1.35,1.35L23,21.3V34a1,1,0,0,0,2,0V21.32l5.3,5.3a1,1,0,1,0,1.35-1.35Z",
                                      fill: "#666",
                                      "fill-rule": "evenodd",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M32,14v0a1,1,0,0,0-.94-1H17A1,1,0,0,0,16,14a0,0,0,0,0,0,0h0a1,1,0,0,0,1,1H31a1,1,0,0,0,1-1Z",
                                      fill: "#666",
                                      "fill-rule": "evenodd",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ])
                        : _vm.iconType === 5
                        ? _c("div", { staticClass: "svg_0 svg_5" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 48 56",
                                },
                              },
                              [
                                _c("title", [_vm._v(_vm._s(_vm.title))]),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M24.53,41.67H23.47a3.81,3.81,0,0,0-2.89,5.14L24,56l3.42-9.14A3.81,3.81,0,0,0,24.53,41.67Z",
                                    fill: "#ebba16",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M32.66,30.84a44.27,44.27,0,0,1-2.81,8.28H18.15a44.27,44.27,0,0,1-2.81-8.28,32.87,32.87,0,0,1,1-19.07h15.3A32.87,32.87,0,0,1,32.66,30.84Z",
                                    fill: "#e7eced",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M11,34.56,15.55,45l.65-4.56,1.95-1.3a44.27,44.27,0,0,1-2.81-8.28Z",
                                    fill: "#afb6bb",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M32.66,30.84a44.27,44.27,0,0,1-2.81,8.28l1.95,1.3L32.45,45,37,34.56Z",
                                    fill: "#afb6bb",
                                  },
                                }),
                                _vm._v(" "),
                                _c("polygon", {
                                  attrs: {
                                    points:
                                      "20.75 32.25 24 43.68 26.89 31.89 24 28.99 20.75 32.25",
                                    fill: "#afb6bb",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M31.65,11.77H16.35c.28-.76.58-1.48.9-2.17l.24-.52a32.37,32.37,0,0,1,2.83-4.81l.31-.42c.2-.28.4-.55.59-.79l.29-.36A24.94,24.94,0,0,1,23.79.24L23.94.1l.06,0,.06.05.15.14A24.94,24.94,0,0,1,26.49,2.7l.29.36c.19.24.39.51.59.79l.31.42a32.37,32.37,0,0,1,2.83,4.81l.24.52C31.07,10.29,31.37,11,31.65,11.77Z",
                                    fill: "#dd352e",
                                  },
                                }),
                                _vm._v(" "),
                                _c("ellipse", {
                                  attrs: {
                                    cx: "23.86",
                                    cy: "21.27",
                                    rx: "3.43",
                                    ry: "3.44",
                                    fill: "#366db6",
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _c("div", { staticClass: "back_top_user" }, [
                      _c("img", {
                        staticClass: "user_img",
                        attrs: {
                          src: _vm.userDefinedBtnUrl || _vm.defaultUserImg,
                        },
                      }),
                    ]),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }