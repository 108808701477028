var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    directives: [
      {
        name: "hovermenu",
        rawName: "v-hovermenu",
        value: {
          hovermenu: _vm.hovermenu,
          args: {
            moduleId: _vm.module.id,
            itemType: "title",
            itemId: _vm.id,
          },
        },
        expression:
          "{\n        hovermenu,\n        args: {\n            moduleId: module.id,\n            itemType: 'title',\n            itemId: id,\n        },\n    }",
      },
    ],
    staticClass: "timeline_item_title",
    domProps: { innerHTML: _vm._s(_vm.value) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }