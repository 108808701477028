var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      directives: [
        {
          name: "hover-status",
          rawName: "v-hover-status",
          value: _vm.isContentHover,
          expression: "isContentHover",
        },
        {
          name: "hover-style",
          rawName: "v-hover-style",
          value: _vm.buttonHoverStyle,
          expression: "buttonHoverStyle",
        },
      ],
      ref: "button",
      staticClass: "module_button_content module_content_detail",
      class: _vm.moduleClasses,
      style: _vm.buttonStyle,
      attrs: {
        href: _vm.link,
        target: _vm.linkTarget,
        rel: _vm.linkNofollow,
        onclick: _vm.jumpOnclickStr,
      },
    },
    [
      _vm.showIcon
        ? [
            _c(
              "div",
              {
                staticClass: "module_button_text_container",
                class: { "module_button_text--empty": _vm.emptyText },
              },
              [
                !_vm.isPreviewMode && _vm.render
                  ? _c("edit-compon", {
                      ref: "editor",
                      staticClass: "module_button_text",
                      style: _vm.btnIconTextStyle,
                      attrs: { "can-edit": _vm.edit },
                      on: { textBlur: _vm.handleTextBlur },
                      model: {
                        value: _vm.module.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.module, "content", $$v)
                        },
                        expression: "module.content",
                      },
                    })
                  : _vm.isPreviewMode
                  ? _c("span", {
                      staticClass: "module_button_text",
                      style: {
                        ..._vm.btnIconTextStyle,
                        ..._vm.buttonTextStyle,
                      },
                      domProps: { innerHTML: _vm._s(_vm.module.content) },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isIcon
                  ? [
                      _c("span", {
                        staticClass: "icon- module_button_icon",
                        style: _vm.btnIconStyle,
                        domProps: { innerHTML: _vm._s(_vm.bntIconPath) },
                      }),
                    ]
                  : [
                      _c("img", {
                        staticClass: "module_button_icon",
                        staticStyle: { "z-index": "3" },
                        attrs: {
                          src: _vm.btnIconImgPath,
                          width: _vm.btnIconSetting.is,
                          height: _vm.btnIconSetting.is,
                        },
                      }),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c("span", {
              staticClass: "after",
              class: _vm.afterClass,
              style: _vm.afterStyle,
            }),
          ]
        : [
            !_vm.isPreviewMode
              ? _c("edit-compon", {
                  ref: "editor",
                  staticClass: "module_button_text",
                  style: _vm.buttonTextStyle,
                  attrs: { "can-edit": _vm.edit },
                  on: { textBlur: _vm.handleTextBlur },
                  model: {
                    value: _vm.module.content,
                    callback: function ($$v) {
                      _vm.$set(_vm.module, "content", $$v)
                    },
                    expression: "module.content",
                  },
                })
              : _c("span", {
                  staticClass: "module_button_text",
                  style: _vm.buttonTextStyle,
                  domProps: { innerHTML: _vm._s(_vm.module.content) },
                }),
            _vm._v(" "),
            _c("span", {
              staticClass: "after",
              class: _vm.afterClass,
              style: _vm.afterStyle,
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }