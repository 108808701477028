var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search_result_empty_panel" }, [
    _c("div", { staticClass: "search_result_wrapper" }, [
      _c("div", { staticClass: "search_result_text" }, [
        _vm._v(_vm._s(_vm.searchResultText)),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "search_result_tips" }, [
        _vm._v(_vm._s(_vm.LS.searchResultNotFoundTips)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }