var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "sliderRef",
      class: _vm.vertical ? "slider_vertical_wrap" : "slider_wrap",
      on: { mousedown: _vm.onMousedown, touchstart: _vm.onTouchStart },
    },
    [
      _c("div", { staticClass: "slider_rail" }),
      _vm._v(" "),
      _c("div", { staticClass: "slider_track", style: _vm.trackStyle }),
      _vm._v(" "),
      _c("div", { staticClass: "slider_handler", style: _vm.handlerStyle }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }