var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comment_content_wrapper" }, [
    _vm.commentsInfo.totalSize
      ? _c("div", { staticClass: "comment_total_count" }, [
          _vm._v(
            _vm._s(_vm.ls.allComment) +
              "（" +
              _vm._s(_vm.commentsInfo.totalSize) +
              _vm._s(_vm.ls.pd_commNumUnit) +
              "）"
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "mobi_news_comment_line" }, [
      _c(
        "span",
        {
          staticClass: "mobi_news_comment_enter faisco-icons-S000261",
          attrs: { id: "mobiNewsDetailCommentEnter" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.addComment.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.ls.writeComment))]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        staticClass: "fk-circle",
      },
      [
        _c("div", { staticClass: "fk-circle1 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle2 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle3 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle4 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle5 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle6 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle7 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle8 fk-child" }),
        _vm._v(" "),
        _c("div", { staticClass: "fk-circle9 fk-child" }),
      ]
    ),
    _vm._v(" "),
    (_vm.manageMode ? true : _vm.realNameAuth.allowShowMessage)
      ? _c(
          "div",
          { staticClass: "news_comment_list" },
          [
            _vm._l(_vm.commentsInfo.list, function (c) {
              return _c(
                "div",
                { key: c.commentId, staticClass: "news_comment_item" },
                [
                  _c("div", { staticClass: "news_reader_img_wrap" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazyload2",
                          rawName: "v-lazyload2",
                          value: { id: _vm.moduleId, src: c.path },
                          expression: "{ id: moduleId, src: c.path }",
                        },
                      ],
                      staticClass: "news_reader_img",
                      attrs: { src: _vm.isMaterialLazyLoad ? c.path : null },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "news_comment_content" }, [
                    _c("div", { staticClass: "news_reader_msg" }, [
                      _c("span", { staticClass: "news_reader_name" }, [
                        _vm._v(_vm._s(_vm.decodeHtml(c.creator))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "news_reader_level",
                          class: _vm.getLevelClass(c),
                          style: _vm.getLevelStyle(c),
                        },
                        [
                          _vm._v(
                            _vm._s(
                              c.commentMemberId == 0
                                ? "[" + _vm.ls.visitor + "]"
                                : ""
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "news_comnent_text" }, [
                      _vm._v(_vm._s(_vm.decodeHtml(c.text))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "news_comment_timer" }, [
                    _vm._v(_vm._s(c.time)),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            _vm.commentsInfo.pageSum > 1
              ? _c("div", { attrs: { id: "pagenation2" } }, [
                  _c(
                    "div",
                    { staticClass: "jz_pagenation_wrap" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "jz_page_scroll jz_page_prev",
                          style:
                            _vm.commentsInfo.curPage === 1
                              ? _vm.disableStyle
                              : _vm.allowStyle,
                          on: {
                            click: function ($event) {
                              return _vm.handlePageChange("prev")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "jz_page_text" }, [
                            _vm.commentsInfo.curPage !== 1
                              ? _c("span", [_vm._v("<<")])
                              : _vm._e(),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.ls.prevPager) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.paginationList, function (page, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "jz_page_number",
                            style:
                              _vm.commentsInfo.curPage === page ||
                              page === "..."
                                ? _vm.disableStyle
                                : _vm.allowStyle,
                            on: {
                              click: function ($event) {
                                return _vm.getNewsComments(page)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "jz_page_number_text",
                                class: {
                                  jz_theme_font_color:
                                    _vm.commentsInfo.curPage === page,
                                },
                              },
                              [_vm._v(_vm._s(page))]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "jz_page_scroll jz_page_next",
                          style:
                            _vm.commentsInfo.curPage ===
                            _vm.commentsInfo.pageSum
                              ? _vm.disableStyle
                              : _vm.allowStyle,
                          on: {
                            click: function ($event) {
                              return _vm.handlePageChange("next")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "jz_page_next_text" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.ls.nextPager) +
                                "\n                        "
                            ),
                            _vm.commentsInfo.curPage !==
                            _vm.commentsInfo.pageSum
                              ? _c("span", [_vm._v(">>")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.commentsInfo.pageSize === 0
              ? _c("div", { staticClass: "no_comment_tips" }, [
                  _c("div", { staticClass: "no_comment_tips_icon" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "no_comment_tips_text" }, [
                    _vm._v("亲，还没有评论哟！"),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }