var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module_content_detail module_friendLink_content" },
    [
      !_vm.isMobi
        ? _c(
            "div",
            { staticClass: "m_friendlink_multiColumns_wrap" },
            [
              _vm._l(_vm.formattedList, function (col, i) {
                return [
                  _c(
                    "div",
                    { key: i, staticClass: "m_friendlink_multiColumns_col" },
                    _vm._l(col, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "m_friendlink_multiColumns_item",
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: item.link.url || "javascript:void(0);",
                                target: item.link.ib ? "_blank" : null,
                              },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  i !== _vm.formattedList.length - 1
                    ? _c("div", { staticClass: "m_friendlink_split_line" })
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _c(
            "div",
            _vm._l(_vm.list, function (item, i) {
              return _c(
                "div",
                { key: i, staticClass: "m_friendlink_multiColumns_item" },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: item.link.url || "javascript:void(0);",
                        target: item.link.ib ? "_blank" : null,
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ]
              )
            }),
            0
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }