export default {
    namespaced: true,
    state: () => ({
        footerStyleStatus: {
            status: false,
            footerHeight: 0,
        },
        payProductBtnFixedHeight: 0,
    }),
    mutations: {
        changeFooterStyleStatus(state, { status, footerHeight }) {
            state.footerStyleStatus['status'] = status;
            state.footerStyleStatus['footerHeight'] = footerHeight;
        },
        changePayProductBtnFixedHeight(state, payload) {
            state.payProductBtnFixedHeight = payload;
        },
    },
    getters: {
        payProductBtnFixedHeight(state, _, rootState, rootGetters) {
            if (rootState.colId !== 8 || !rootGetters.isMobi) {
                return 0;
            }
            return state.payProductBtnFixedHeight;
        },
    },
};
