var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ChildrenCSS",
    _vm._l(_vm.rows, function (row) {
      return _c("Row", { key: row.id, attrs: { row: row } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }