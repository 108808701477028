var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "VideoWrapper",
      staticClass: "flv_content",
      on: {
        mouseenter: function ($event) {
          _vm.isHoverContent = true
        },
        mouseleave: function ($event) {
          _vm.isHoverContent = false
        },
      },
    },
    [
      _vm.module.showHttpsTip
        ? _c(
            "div",
            { staticClass: "show_https_tip", on: { click: _vm.changeHttp } },
            [
              _vm._v(
                "\n        当前模块存在非https链接，建议替换为https链接。或切换为"
              ),
              _c("span", [_vm._v("http访问")]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.module.showHttpsTip
        ? _c(
            "div",
            {
              staticClass: "online_flv",
              class: _vm.flvClass,
              style: _vm.onlineFlvStyle,
              attrs: { "poster-effect": _vm.module.prop5.bge },
            },
            [
              _vm.manageMode
                ? [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.flagDisplayPopup ||
                              (_vm.isAndroid &&
                                _vm.module.prop0 == 0 &&
                                !_vm.module.isFrame),
                            expression:
                              "!flagDisplayPopup || (isAndroid && module.prop0 == 0 && !module.isFrame)",
                          },
                        ],
                        staticClass: "img_flv_div",
                        on: { click: _vm.dialogVideo },
                      },
                      [
                        _c("div", { staticClass: "flv_play_btn" }),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "lazyload2",
                              rawName: "v-lazyload2",
                              value: {
                                id: _vm.module.id,
                                src: _vm.posterImgThumb,
                              },
                              expression:
                                "{ id: module.id, src: posterImgThumb }",
                            },
                          ],
                          staticClass: "video_poster_img",
                          style: _vm.videoPosterImgStyle,
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.useVideo,
                            expression: "useVideo",
                          },
                        ],
                        staticClass: "direct_play_flv",
                      },
                      [
                        _c("video", {
                          staticClass:
                            "video-js vjs-default-skin vjs-big-play-centered video_lazy_load",
                          attrs: {
                            id: _vm.flvId,
                            "x-webkit-airplay": "allow",
                            "x5-video-player-fullscreen": "true",
                            "x5-playsinline": "",
                            playsinline: "",
                            "x5-video-player-type": "h5",
                            "webkit-playsinline": "",
                            "data-original": _vm.displayFlvUrl,
                            "data-poster": _vm.posterImg,
                            preload: "meta",
                          },
                          domProps: { muted: _vm.flagAutoStartPopup },
                        }),
                      ]
                    ),
                  ]
                : [
                    !_vm.flagDisplayPopup ||
                    (_vm.isAndroid &&
                      _vm.module.prop0 == 0 &&
                      !_vm.module.isFrame) ||
                    _vm.noAllowVideoJs
                      ? _c(
                          "div",
                          {
                            staticClass: "img_flv_div",
                            on: { click: _vm.dialogVideo },
                          },
                          [
                            _c("div", { staticClass: "flv_play_btn" }),
                            _vm._v(" "),
                            _c("div", {
                              directives: [
                                {
                                  name: "lazyload2",
                                  rawName: "v-lazyload2",
                                  value: {
                                    id: _vm.module.id,
                                    src: _vm.posterImgThumb,
                                  },
                                  expression:
                                    "{ id: module.id, src: posterImgThumb }",
                                },
                              ],
                              staticClass: "video_poster_img",
                              style: _vm.videoPosterImgStyle,
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.useVideo && !_vm.noAllowVideoJs
                      ? _c("div", { staticClass: "direct_play_flv" }, [
                          _c("video", {
                            staticClass:
                              "video-js vjs-default-skin vjs-big-play-centered video_lazy_load",
                            attrs: {
                              id: _vm.flvId,
                              "x-webkit-airplay": "allow",
                              "x5-video-player-fullscreen": "true",
                              "x5-playsinline": "",
                              playsinline: "",
                              "x5-video-player-type": "h5",
                              "webkit-playsinline": "",
                              "data-poster": _vm.posterImg,
                              "data-original": _vm.displayFlvUrl,
                              preload: "meta",
                            },
                            domProps: { muted: _vm.flagAutoStartPopup },
                          }),
                        ])
                      : _vm._e(),
                  ],
              _vm._v(" "),
              _vm.useIframe
                ? _c("iframe", {
                    staticClass: "flv_play_inframe",
                    attrs: {
                      frameborder: "0",
                      src: _vm.innerIframeUrl,
                      allowfullscreen: "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.useVideo && !_vm.useIframe && _vm.flagDisplayPopup
                ? _c("embed", {
                    attrs: {
                      id: _vm.embedClass,
                      allowscriptaccess: "true",
                      pluginspage: "http://get.adobe.com/cn/flashplayer/",
                      flashvars: "playMovie=true&auto=1",
                      allowfullscreen: "true",
                      quality: "hight",
                      src: _vm.videoNetUrl,
                      type: "application/x-shockwave-flash",
                      align: "middle",
                      wmode: "opaque",
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !!_vm.describe && !_vm.module.showHttpsTip
        ? _c("div", { staticClass: "flv_describe", style: _vm.descStyle }, [
            _c(
              "span",
              { staticClass: "flv_describe_text", style: _vm.descTextStyle },
              [_vm._v("\n            " + _vm._s(_vm.describe) + "\n        ")]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }