var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "outsideclick",
          rawName: "v-outsideclick",
          value: _vm.handlerSearchBoxOutside,
          expression: "handlerSearchBoxOutside",
        },
      ],
      staticClass: "site_search_module",
    },
    [
      _c("search-box", {
        ref: "searchBox",
        attrs: {
          keyword: _vm.value,
          "style-id": _vm.styleId,
          "style-type": _vm.styleType,
          "search-tip": _vm.searchTip,
          "input-style": _vm.inputStyle,
        },
        on: {
          onSearch: _vm.handleSearch,
          onFocus: _vm.handleInputFocus,
          onBlur: _vm.handleInputBlur,
          onChange: _vm.handleKeywordChange,
          onMobiSearch: _vm.handleMobiSearch,
        },
      }),
      _vm._v(" "),
      (!_vm.isHideHistoryRecord && _vm.historyRecordList.length) ||
      _vm.recommendKeyWordList.length
        ? _c(
            "div",
            {
              staticClass: "site_search_dropdown",
              staticStyle: { display: "none" },
              attrs: { "data-id": _vm.moduleId },
            },
            [
              _vm.recommendKeyWordList.length
                ? _c("div", { staticClass: "site_search_dropdown_item" }, [
                    _c("p", { staticClass: "site_search_dropdown_title" }, [
                      _vm._v(_vm._s(_vm.LS.searchBoxHotText)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "site_search_dropdown_content" },
                      _vm._l(
                        _vm.recommendKeyWordList,
                        function (keyword, index) {
                          return _c(
                            "text-compon",
                            {
                              key: "s" + index,
                              nativeOn: {
                                mousedown: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.jumpSearchPage(keyword)
                                },
                              },
                            },
                            [_vm._v(_vm._s(keyword))]
                          )
                        }
                      ),
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isHideHistoryRecord && _vm.historyRecordList.length
                ? _c("div", { staticClass: "site_search_dropdown_item" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "site_search_dropdown_title site_search_dropdown_clear",
                        on: {
                          mousedown: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.clearHistoryRecord.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.LS.searchBoxClearText) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "site_search_dropdown_title" }, [
                      _vm._v(_vm._s(_vm.LS.searchBoxHistoryText)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "site_search_dropdown_content" },
                      _vm._l(_vm.historyRecordList, function (keyword, index) {
                        return _c(
                          "text-compon",
                          {
                            key: "h" + index,
                            nativeOn: {
                              mousedown: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.jumpSearchPage(keyword)
                              },
                            },
                          },
                          [_vm._v(_vm._s(keyword))]
                        )
                      }),
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }