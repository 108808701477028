import { usePoolGetter, usePoolMemo } from '@shared/manage/pool/index.js';
import { isBrowserEnv } from '@jz/utils';
/**
 * 短信模板
 */
// data-source

/**
 * 短信模板
 */
// data-source
const getSmsTemplateList = usePoolGetter({
    poolNamespace: 'smsTemplateList',
    requestUrl: isBrowserEnv()
        ? Site.addRequestPrefix({
              newPath: '/rajax/',
              oldPath: 'rajax/',
          }) + 'sysFaiSmsPlatform_h.jsp?cmd=getWafNotCk_getSmsTemplateList'
        : '',
});
const getSmsTemplateListMemo = usePoolMemo({
    poolNamespace: 'smsTemplateList',
});
export { getSmsTemplateList, getSmsTemplateListMemo };
