
export default {
    namespaced: true,
    state: () => ({
        // 编辑通栏
        rowEditing: null,
        // 正在编辑的行
        rowEditingActive: null,

        // 显示mobi隐藏的通栏
        showMobiHideRow: false,

        rollingScreenRowInfo: {
            footerNavHeight: 0, //待删除
            isRunning: false, //是否正在执行动画，如果正在执行动画，下个切屏动作跳过
            curIndex: 0, //当前页面index
            animateClass: '', //用于<transition>动画的name
            isFooterPageActive: false, //底部当都一行时，是否该行被激活，虽说底部单独一行，但其不占index
            instance: {}, //对象实例
            isLastScreen: false, //是否时最后一个页面
            rowId: 0, // 当前通栏ID
        },
    }),
    mutations: {
        setRollingScreenRowInfo(
            state,
            { isRunning, curIndex, animateClass, isFooterPageActive, instance, isLastScreen, rowId }
        ) {
            isLastScreen !== undefined && (state.rollingScreenRowInfo['isLastScreen'] = isLastScreen);
            instance !== undefined && (state.rollingScreenRowInfo['instance'] = instance);
            isFooterPageActive !== undefined && (state.rollingScreenRowInfo['isFooterPageActive'] = isFooterPageActive);
            animateClass !== undefined && (state.rollingScreenRowInfo['animateClass'] = animateClass);
            curIndex !== undefined && (state.rollingScreenRowInfo['curIndex'] = curIndex);
            isRunning !== undefined && (state.rollingScreenRowInfo['isRunning'] = isRunning);

            if (rowId) {
                state.rollingScreenRowInfo.rowId = rowId;
            }
        },
        rowEditingChange(state, rowId) {
            state.rowEditing = rowId;
        },
        rowEditingActiveChange(state, rowId) {
            state.rowEditingActive = rowId;
        },
        setShowMobiHideRow(state, isShow) {
            state.showMobiHideRow = isShow;
        },
    },
    getters: {
        rowEditingList: (state, _, rootState, rootGetters) => {
            let result = [];
            return result;
        },
    },
};
