var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ChildrenCSS",
    [
      _vm.moduleStyle !== 2 && _vm.dotColor
        ? [
            _vm._v(
              "\n        " +
                _vm._s(_vm.id) +
                " .timeline_item_dot_outer, " +
                _vm._s(_vm.id) +
                " .timeline_item_dot_inner { background-color: " +
                _vm._s(_vm.dotColor) +
                "; }\n\n        " +
                _vm._s(_vm.id) +
                " .timeline_item_connect_content_line { background-color: " +
                _vm._s(_vm.dotColor) +
                "; }\n\n        " +
                _vm._s(_vm.id) +
                " .timeline_text_horizontal_arrow { color: " +
                _vm._s(_vm.dotColor) +
                "; border-color: " +
                _vm._s(_vm.dotColor) +
                "; }\n    "
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.moduleStyle === 2
        ? [
            _vm._v(
              "\n        " +
                _vm._s(_vm.timelineSDotCss) +
                "\n\n        " +
                _vm._s(_vm.timelineSDotActiveCss) +
                "\n\n        " +
                _vm._s(_vm.picSizeCss) +
                "\n\n        " +
                _vm._s(_vm.picScaleCss) +
                "\n\n        " +
                _vm._s(_vm.rulerWidth) +
                "\n    "
            ),
          ]
        : _vm._e(),
      _vm._v(
        "\n\n    " +
          _vm._s(_vm.lineColorCss) +
          "\n\n    " +
          _vm._s(_vm.timeFontCss) +
          "\n    " +
          _vm._s(_vm.timeActiveFontCss) +
          "\n\n    " +
          _vm._s(_vm.titleFontCss) +
          "\n\n    " +
          _vm._s(_vm.descFontCss) +
          "\n"
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }