import { getCommentList } from '@/api/msgBoard/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
const msgBoardCommentsFunc = function () {
    this.init.apply(this, [...arguments]);
};

msgBoardCommentsFunc.prototype = {
    init(page = 1, _this) {
        this.page = page;
        this.jumpPage = 1;
        this.isLoading = false;
        this.openMsgAuto = false;
        this.commentsInfo = {};
        this.commentList = [];
        this._this = _this;
        this.getComments();
    },
    getComments(notLoad = true) {
        // 如果是处于当前页 或者输入的页数超出总页数  或者小于一    就不需要发送请求了
        var page = this.page;
        if (
            (this.commentsInfo.curPage === page ||
                this.commentsInfo.pageSum < page ||
                page < 1 ||
                typeof page != 'number') &&
            notLoad
        ) {
            this.jumpPage = 1;
            return;
        }
        this.isLoading = true;
        getCommentList({
            m9pageno: page,
        })
            .then((comments) => {
                this.isLoading = false;
                try {
                    // 每次请求后都清空分页的页码
                    this.paginationList = [];
                    var curPage = comments.data.curPage,
                        pageSum = comments.data.pageSum;
                    var viewStart = curPage - 2 <= 0 ? 1 : curPage - 2;
                    var viewEnd = curPage + 2 >= pageSum ? pageSum : curPage + 2;

                    //判断是否应该显示首页
                    if (Math.abs(curPage - 1) > 2 && viewStart != 1) {
                        this.paginationList.push(1);
                    }

                    //判断是否应该显示首页的多页提示 '...'
                    if (viewStart > 2) {
                        this.paginationList.push('...');
                    }

                    //循环显示翻页数字
                    for (var j = viewStart; j <= viewEnd; j++) {
                        this.paginationList.push(j);
                    }

                    //判断是否应该显示末页的多页提示 '...'
                    if (viewEnd < pageSum - 1) {
                        this.paginationList.push('...');
                    }

                    //判断是否应该显示末页
                    if (Math.abs(curPage - pageSum) > 2 && pageSum != viewEnd) {
                        this.paginationList.push(pageSum);
                    }
                } catch (error) {
                    comments = {};
                }
                this.commentsInfo = comments.data || {};
                this.commentList = this.commentsInfo.commentList || [];
                this.openMsgAuto = this.commentsInfo.openMsgAuto || false;
                this.updates();
            })
            .catch(() => {
                this.isLoading = false;
                Message.warning({
                    message: '网络繁忙，请刷新后重试',
                    autoHide: false,
                });
            });
    },
    updates() {
        this._this.commentsInfo = this.commentsInfo;
        this._this.commentList = this.commentList;
        this._this.paginationList = this.paginationList;
        this._this.openMsgAuto = this.openMsgAuto;
    },
};

let msgBoardInfo = undefined;
export { msgBoardCommentsFunc, msgBoardInfo };
