
export default {
    name: 'ChildrenCSS',
    destroyed() {
        this.refreshCSS();
    },
    updated() {
        this.refreshCSS();
    },
    mounted() {
        this.refreshCSS();
    },
    methods: {
        /* 通知宿主组件样式有更新 */
        refreshCSS() {
            let $parent = this.$parent;
            while ($parent) {
                if ($parent.$options._componentTag == 'DynamicCSS') {
                    $parent.refreshCSS();
                    break;
                }
                $parent = $parent.$parent;
            }
        },
    },
    render(h) {
        return h('i', this.$slots.default);
    },
};
