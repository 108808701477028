var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.list, function (prop) {
      return _c("div", { key: prop.id, staticClass: "pay_product_param" }, [
        _c("span", {
          staticClass: "pay_product_param_title",
          domProps: { innerHTML: _vm._s(_vm.encodeHtmlStr(prop.name)) },
        }),
        _c("span", { staticClass: "pay_product_param_value" }, [
          _vm._v("：" + _vm._s(_vm.productInfo[prop.fieldKey])),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }