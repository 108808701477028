import { mapState } from 'vuex';
import { digGet } from '@jz/utils';
export const slidesshowDataMixin = {
    data() {
        return {
            showPcViewer: false,
            showMobiViewer: false,
            zIndex: 9999,
            imageIndex: 0,
        };
    },
    computed: {
        ...mapState(['manageMode']),
        photoSlides() {
            return this.module.commProp.ps;
        },
        isPhotoSlides() {
            return digGet(this.photoSlides, 'o') === 1;
        },
        noLink() {
            return digGet(this.photoSlides, 'o') !== 2;
        },
    },
    methods: {
        closeViewer() {
            this.showPcViewer = false;
            this.showMobiViewer = false;
        },
        slidesshow(index = this.imageIndex) {
            if (this.isPhotoSlides) {
                this.imageIndex = index;
                if (this.isMobi) {
                    this.showMobiViewer = true;
                } else {
                    this.showPcViewer = true;
                }
            }
        },
        cursorAuto(href = '') {
            if (href === null) {
                href = '';
            }
            return digGet(this.photoSlides, 'o') == 0 || (digGet(this.photoSlides, 'o') == 2 && !href.length);
        },
    },
};
