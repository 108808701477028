/* eslint-disable no-inner-declarations */
import { InterSectionObserApi } from '@jz/biz-shared';
/**
 * --------------------------------------------------------------------------------------
 * Constants 交叉观察器的配置数据 这里只放交叉观察器相关的内部函数跟数据
 * --------------------------------------------------------------------------------------
 */
const LAZY_IMG_DEFAULT_DATA = {
    // 懒加载监听器
    id: 0, // 懒加载的标志id 必须
    observerClass: '.jz_img_lazyload', // 需要监听的元素必须
    observerName: 'observer0', // 这里是为了方便拿这个监听器的名字 基类里给监听器的名字就是根据监听器的标志id来的
    data_attribute: 'src-original', // 同上也是为了方便 给上面得内部函数用的
    isUnobserve: true, // 执行完回调是否解除监听
};

/**
 * @function name checkObserver
 * @description 检查观察器是否存在
 * @author mikey.chuhui
 * @date 2020-09-09
 * @private
 * @param observerName {String} 观察器属性名
 * @example
 * _checkObserver("observer0")
 * // => 返回观察器对象 否则返回undefined
 * @return {Object}
 */

const _checkObserver = (observerName) => sectionObserver()?.observerList?.[`${observerName}`];

/**
 * @function name - _updateObserver
 * @description 更新观察元素
 * @author mikey.chuhui
 * @private
 * @date 2020-03-18
 * @example
 * _updateObserver({ el: 'module324', ...LAZY_IMG_DEFAULT_DATA });
 * // => 将会监听id为module324的元素的 开启图片懒加载功能
 * @param options {Object} - {el, id, observerName} el可以是dom对象也可以是dom元素id值
 */

const _updateObserver = ({ el, id, observerName }) => {
    if (typeof el === 'string') {
        el = document.getElementById(el);
    }
    if (!_checkObserver(observerName)) return;
    sectionObserver()?.updateObserver?.(el, id);
};

/**
 * @function name - initLazyloadTwo
 * @description - 图片懒加载监听函数 base JZ
 * @author mikey.chuhui
 * @date 2020-03-18
 * @example
 * initLazyloadTwo($(img)[0])
 * // => 监听$(img)[0] 懒加载
 * @param [el] {Object|Array} - dom对象或者dom对象数组
 */
const initLazyloadTwo = (el) => {
    if (Array.isArray(el)) {
        el.forEach((value) => {
            _updateObserver({
                el: value,
                ...LAZY_IMG_DEFAULT_DATA,
            });
        });
    } else {
        _updateObserver({
            el,
            ...LAZY_IMG_DEFAULT_DATA,
        });
    }
};

const sectionObserver = () => InterSectionObserApi.SectionObserverMap.get('interSectionObserver1');
export { initLazyloadTwo, LAZY_IMG_DEFAULT_DATA, _checkObserver, sectionObserver };
