(function () {
    Site.addRequestPrefix = function getRequestPrefix({ newPath, oldPath, useNewPrefix = true, useNew = false }) {
        const isOpen =
            typeof Fai !== 'undefined' && typeof Fai.top !== 'undefined' && typeof Fai.top._curLanCode !== 'undefined';
        const hasLanCode = isOpen && window.location.pathname.indexOf('/' + Fai.top._curLanCode + '/') !== -1;

        if (!useNew) {
            if (!isOpen || !useNewPrefix) {
                return oldPath;
            } else {
                if (hasLanCode) {
                    return '/' + Fai.top._curLanCode + '/' + newPath.replace(/^\/+/, '');
                } else {
                    return '/' + newPath.replace(/^\/+/, '');
                }
            }
        } else {
            if (hasLanCode) {
                return '/' + Fai.top._curLanCode + '/' + newPath.replace(/^\/+/, '');
            } else {
                return '/' + newPath.replace(/^\/+/, '');
            }
        }
    };
    if (!Site.preprocessUrl) {
        Site.preprocessUrl = function preprocessUrl({ path, oldPath, isUsePath = true, useNew = false }) {
            const isOpen =
                typeof Fai !== 'undefined' &&
                typeof Fai.top !== 'undefined' &&
                typeof Fai.top._curLanCode !== 'undefined';

            const hasLanCode = isOpen && window.location.pathname.indexOf('/' + Fai.top._curLanCode + '/') !== -1;

            if (!useNew) {
                if (!isUsePath || !isOpen) {
                    return oldPath;
                } else {
                    if (hasLanCode) {
                        return '/' + Fai.top._curLanCode + '/' + path.replace(/^\/+/, '');
                    } else {
                        return '/' + path.replace(/^\/+/, '');
                    }
                }
            } else {
                if (hasLanCode) {
                    return '/' + Fai.top._curLanCode + '/' + path.replace(/^\/+/, '');
                } else {
                    return '/' + path.replace(/^\/+/, '');
                }
            }
        };
    }
})(window.Site || (window.Site = {}));
