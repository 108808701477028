var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.pattern.h
    ? _c(
        "div",
        {
          directives: [
            {
              name: "hovermenu",
              rawName: "v-hovermenu",
              value: _vm.hovermenu,
              expression: "hovermenu",
            },
          ],
          staticClass: "s_member_login J_onlyInHeader",
          attrs: {
            margin: _vm.pattern,
            projectid: 151,
            rowid: _vm.rowid,
            "hide-right-bottom-btn": true,
            login: "",
          },
        },
        [
          _c(
            "a",
            {
              staticClass:
                "s_member_login_register jz_theme_bg_color J_project_sort_handle",
              attrs: { href: _vm.signupUrl },
            },
            [_vm._v(_vm._s(_vm.LS.js_memberReg))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "s_member_login_login J_project_sort_handle",
              attrs: { href: _vm.loginUrl },
            },
            [_vm._v(_vm._s(_vm.LS.js_memberLogin))]
          ),
          _vm._v(" "),
          _vm.manageMode
            ? _c("features", {
                attrs: { inner: "", features: _vm.innerFeatures },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }