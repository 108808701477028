var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "module_content_detail module_current_position_content",
      class: _vm.moduleClass,
    },
    [
      _c(
        "span",
        {
          staticClass: "current_position_title location_default_font_color",
          style: _vm.titleStyle,
          on: {
            mouseenter: _vm.titleMouseenterHandler,
            mouseleave: _vm.titleMouseleaveHandler,
          },
        },
        [_vm._v(_vm._s(_vm.title) + "：")]
      ),
      _vm._v(" "),
      _vm._l(_vm.location, function (item, idx) {
        return _c("location-list", {
          key: item.name + idx,
          attrs: {
            "hide-arrow": idx === _vm.location.length - 1,
            location: item,
            "is-mobi": _vm.isMobi,
            "font-style": _vm.getCurrentFontStyle(idx),
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }