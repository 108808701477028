import { mapState } from 'vuex';
import { getImgComponentOption } from '@/components/modules/shared/imgEffect.js';

export const photoSettingMixin = {
    computed: {
        ...mapState(['device', 'webpOptOpen']),
        isMobi() {
            return this.device === 'mobi';
        },
        model() {
            return this.module.privatePattern[this.device];
        },
        pcPrivatePattern() {
            return this.module.privatePattern['pc'];
        },
        curPpst() {
            const pps =
                this.model.ppst !== undefined
                    ? this.model.ppst
                    : this.model.pps === undefined
                    ? this.model.psy
                    : this.model.pps;
            const pcPps =
                this.pcPrivatePattern.ppst !== undefined
                    ? this.pcPrivatePattern.ppst
                    : this.pcPrivatePattern.pps === undefined
                    ? this.pcPrivatePattern.psy
                    : this.pcPrivatePattern.pps;
            return this.isMobi && pps == 0 ? pcPps : pps;
        },
    },
    methods: {
        getImgComponentOption,
    },
};
