var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "form_container not_sort" },
    [
      !_vm.allowEdit
        ? _vm._t("default")
        : _c(
            "draggable",
            {
              attrs: {
                list: _vm.list,
                options: _vm.dragOptions,
                move: _vm.dragOptions.checkMove,
              },
              on: { start: _vm.onSortStart, end: _vm.onSortEnd },
            },
            [_vm._t("default")],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }