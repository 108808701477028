var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "module_alert", class: _vm.tipClass }, [
    _c("span", { staticClass: "text-left" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }