var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowWebsiteTitle
    ? _c(
        "div",
        {
          directives: [
            {
              name: "hovermenu",
              rawName: "v-hovermenu",
              value: _vm.hovermenu,
              expression: "hovermenu",
            },
          ],
          ref: "jzProject",
          staticClass: "jz_project jz_website_title_wrap J_onlyInHeader",
          class: _vm.wrapClasses,
          style: _vm.wrapStyle,
          attrs: { projectid: _vm.projectid, title: "" },
        },
        [
          _c(
            "div",
            {
              ref: "websiteTitle",
              staticClass: "jz_website_title J_project_sort_handle",
              class: [
                _vm.isMobi ? "jz_mobi_website_title" : "jz_pc_website_title",
                "type_" + _vm.titleInfo.lt,
                _vm.independentMobiClass,
              ],
              style: _vm.contentStyle,
              attrs: { id: "jz_website_title" },
            },
            [
              _vm.logoVisible
                ? _c(
                    "div",
                    { staticClass: "logo_wrap", style: _vm.logoContainerStyle },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.titleInfo.ll.url,
                            target: _vm.logoTarget,
                            onclick: _vm.jumpOnclickStr,
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "logo_img",
                            class: {
                              hidden:
                                _vm.onFixedTopLogoMode || _vm.equalFixedTopLogo,
                            },
                            attrs: {
                              src: _vm.logoSrc,
                              alt: _vm.titleInfo.la,
                              title: _vm.titleInfo.la,
                            },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "logo_img",
                            class: {
                              hidden:
                                !_vm.onFixedTopLogoMode &&
                                !_vm.equalFixedTopLogo,
                            },
                            attrs: {
                              src: _vm.fixedLogoSrc,
                              alt: _vm.titleInfo.la,
                              title: _vm.titleInfo.la,
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowTitle,
                      expression: "isShowTitle",
                    },
                  ],
                  staticClass: "website_title_content",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "website_title_inner",
                      on: { click: _vm.handleTitleJump },
                    },
                    [
                      _vm.manageMode
                        ? _c("fkeditor-component", {
                            ref: "fkeditor",
                            attrs: {
                              id: _vm.projectid,
                              "edit-tip": "请输入网站标题",
                            },
                            on: { editStatus: _vm.handleEditStatus },
                            model: {
                              value: _vm.content,
                              callback: function ($$v) {
                                _vm.content = $$v
                              },
                              expression: "content",
                            },
                          })
                        : _c("SiteTitle", {
                            attrs: {
                              value: _vm.content,
                              "index-url": _vm.titleInfo.indexUrl,
                            },
                          }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.manageMode
            ? _c("features", {
                attrs: { inner: "", features: _vm.innerFeatures },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }