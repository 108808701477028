import { ref, onMounted, onUnmounted, watch } from 'vue';
import { debounce, useMapState, useMapGetters } from '@jz/utils';
import { ANIMATION_TIME } from './constants';

export function useAccordionItemMaxWidth(module) {
    const maxWidth = ref('0px');
    const { isMobi } = useMapGetters(['isMobi']);
    const setMaxWidth = () => {
        if (isMobi.value) {
            maxWidth.value = '100%';
            return;
        }
        maxWidth.value = $(`#module${module.id} .accordion_item_active`).width() + 1 + 'px';
    };

    const debounceSetMaxWidth = debounce(setMaxWidth, 100);
    let observer = null;

    onMounted(() => {
        setMaxWidth();

        observer = new ResizeObserver(() => {
            debounceSetMaxWidth();
        });

        observer.observe(document.querySelector(`#module${module.id}`));
    });

    onUnmounted(() => {
        observer.disconnect();
    });

    const { manageMode } = useMapState(['manageMode']);
    watch(
        () => {
            if (manageMode.value) {
                return JSON.stringify([module.privatePattern.pc.width, isMobi.value]);
            }
            return isMobi.value;
        },
        () => {
            debounceSetMaxWidth();
            setTimeout(() => {
                debounceSetMaxWidth();
            }, ANIMATION_TIME);
        }
    );

    return {
        accordionItemMaxWidth: maxWidth,
    };
}
