import { memberSetOrder } from '@/api/order';
import { successMessage, warningMessage } from '@/site/utils';
import { checkProductCanBuy } from '@/api/product';
import { isBrowserEnv } from '@jz/utils';

const STORAGE_COUNT_KEY = 'payProductCount';

export class PayProduct {
    static isPayProduct(productInfo, openECommerce = true) {
        return productInfo.productType === 5 && openECommerce;
    }

    static getPayProductStatus(status, LS = isBrowserEnv() ? window.LS : {}) {
        return (
            {
                [PayProduct.payProductStatus.WAIT_PAY]: LS.jzOrderFinSettle,
                [PayProduct.payProductStatus.WAIT_SEND]: LS.jzOrderFinPay,
                [PayProduct.payProductStatus.SENT]: LS.mobi_toBeReceived,
                [PayProduct.payProductStatus.FINISHED]: LS.jzOrderFinConfirm,
                [PayProduct.payProductStatus.CLOSED]: LS.jzOrderFinCancel,
            }[status] || '-'
        );
    }

    static getTotalPrice(item) {
        return (item.amount * item.price).toFixed(2);
    }

    static cancelOrder(orderId) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$modal.confirm({
                title: `${window.LS.orderRealCancelMsg}`,
                async onConfirm() {
                    const data = await memberSetOrder({
                        orderId,
                        cancelOrder: true,
                    });

                    if (data.success) {
                        successMessage(window.LS.orderCancelSuccess, true);
                        resolve();
                    } else {
                        warningMessage(window.LS.orderCancelFail, true);
                        console.log('cancelOrder error', data);
                        reject();
                    }
                },
            });
        });
    }

    static confirmReceipt(orderId) {
        return new Promise((resolve, reject) => {
            memberSetOrder({
                orderId,
                confirmOrder: true,
            }).then((data) => {
                if (data.success) {
                    successMessage(window.LS.orderSureSuccess, true);
                    window.bizShared.context.fdpLog('jz_receive', {
                        jz_content_terminal: window.bizShared.context.jzContentTerminal,
                        jz_version: window.bizShared.context.jzVersion,
                    });
                    resolve();
                } else {
                    warningMessage(window.LS.orderSureFail, true);
                    console.log('confirmReceipt error', data);
                    reject();
                }
            });
        });
    }

    static setStoreCountInfo(productId, count) {
        window.localStorage.setItem(
            STORAGE_COUNT_KEY,
            JSON.stringify({
                count,
                productId,
            })
        );
    }

    static getStoreCountInfo() {
        const payProductCountStr = window.localStorage.getItem(STORAGE_COUNT_KEY);
        if (payProductCountStr) {
            try {
                const payProductCount = JSON.parse(payProductCountStr);
                window.localStorage.removeItem(STORAGE_COUNT_KEY);
                return payProductCount;
            } catch (error) {
                console.error('Failed to parse payProductCount', error);
                window.localStorage.removeItem(STORAGE_COUNT_KEY);
            }
        }
        return null;
    }

    static checkProductCanBuy(productId) {
        return new Promise((resolve) => {
            checkProductCanBuy({ pdId: productId }).then((data) => {
                if (data.success) {
                    if (data.pdNotFound) {
                        warningMessage(window.LS.productDeleteTips);
                        resolve(false);
                    } else if (data.isNotAdd) {
                        warningMessage(window.LS.productDelistTips);
                        resolve(false);
                    } else if (!data.isjzPayProductType) {
                        warningMessage(window.LS.notCommerceProduct);
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                } else {
                    resolve(false);
                }
            });
        });
    }
}

/**
 * 收货信息类型
 */
PayProduct.payPropTypes = {
    SYSTEM_NAME: 1,
    SYSTEM_ADDRESS: 2,
    SYSTEM_PHONE: 3,
    SYSTEM_MAIL: 4,
    TEXT: 5,
    PHONE: 6,
    MAIL: 7,
};

PayProduct.payProductStatus = {
    /**
     * 待付款
     */
    WAIT_PAY: 1,
    /**
     * 待发货
     */
    WAIT_SEND: 2,
    /**
     * 待收货
     */
    SENT: 3,
    /**
     * 交易完成
     */
    FINISHED: 4,
    /**
     * 交易关闭
     */
    CLOSED: 5,

    /**
     * 临时状态，用于支付成功后检查支付状态
     */
    PAY_SUCCESS: -1,
};
