export const setVueDepUseSet = (Vue) => {
    try {
        Vue.config.depUseSet = Boolean(Set);
    } catch (e) {
        Vue.config.depUseSet = false;
    }
};

export const setVueIgnoredElements = (Vue) => {
    try {
        Vue.config.ignoredElements = ['wc-waterfall'];
    } catch (e) {
        Vue.config.ignoredElements = [];
    }
};
