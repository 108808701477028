export const hiddenOperationLine = {
    update(el) {
        if (typeof window === 'undefined' || window._store.getters.isMobi || !window._store.state.manageMode) {
            return;
        }
        $(el).hover(
            function () {
                $(`
                .f_row_padding_resizor,
                .row_handler_line,
                .f_row_padding_resizor__line,
                .col_handler_line
            `).css('pointer-events', 'none');
            },
            function () {
                $(`
                .f_row_padding_resizor,
                .row_handler_line,
                .f_row_padding_resizor__line,
                .col_handler_line
            `).css('pointer-events', '');
            }
        );
    },
};
