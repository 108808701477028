var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    _vm._g(
      _vm._b(
        {
          ref: _vm.refName,
          tag: "component",
          domProps: { innerHTML: _vm._s(_vm.renderedHtml) },
        },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }