import { encodeHtmlStr } from '@/components/utils.js';

class AddressSlide {
    constructor(moduleId, classname) {
        this.moduleId = moduleId;
        this.classname = classname;
        this.instance = null;
        this.init();
        this.events = {};
    }

    init() {
        const el = document.createElement('div');
        const id = `${this.classname}_${this.moduleId}`;

        el.id = id;
        document.getElementById('jzWebContainer').appendChild(el);

        let self = this;
        this.instance = new window.Vue({
            template: `
        <div v-if="show" class="address_wrap" :id="id">
          <div class="address_bg" @click="close"></div>
          <div class="address_panel">
            <div class="header">
              <svg class="back" @click="handleBack"><use xlink:href="#online_map_back"></use></svg>
              <div class="search" v-if="openAddressSearch">
                <input v-model="search" ref="search" class="search-input" type="text" :placeholder="inputTips">
                <svg v-if="!searchMode" class="search-icon" @click="handleSearch">
                    <use xlink:href="#online_map_search"></use>
                </svg>
                <svg v-else class="close-icon" @click="handleClear">
                    <use xlink:href="#online_map_close"></use>
                </svg>
              </div>
            </div>
            <div v-if="isEmpty && openAddressSearch" class="empty_list">
              <div class="empty_img"></div>
              <div class="empty_tips">未搜索到相关结果</div>
            </div>
            <div class="list" v-if="renderAddressList.length > 0" ref="addressList">
              <div class="address" v-for="(addr, index) in renderAddressList" :class="{'active': index == addressIndex}" @click="handleAddressClick(index)">
                  <div class="multi_icon">
                      <span class="number">{{ index + 1 }}</span>
                      <svg class="jz_theme_fill_color icon_mark">
                          <use xlink:href="#online_map_mark"></use>
                      </svg>
                  </div>
                  <div class="multi_content">
                      <div v-if="addr.da != ''" class="detail" v-html="addr.da"></div>
                      <div class="address_detail" v-html="addr.a"></div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      `,
            data() {
                return {
                    show: false,
                    id,
                    module: window._store.state.modules[`module${self.moduleId}`],
                    search: '',
                    searchMode: false,
                    addressIndex: 0,
                    recordAddress: null,
                    inputTips: LS.onlineMapInputTips || '请输入城市',
                };
            },
            computed: {
                privatePattern() {
                    return this.module.privatePattern || {};
                },
                pcPrivatePattern() {
                    return this.privatePattern.pc || {};
                },
                openAddressSearch() {
                    return this.pcPrivatePattern.pms;
                },
                multiAddress() {
                    return this.blob0.multiAddress || [];
                },
                blob0() {
                    return this.module.blob0 || {};
                },
                renderAddressList() {
                    let list = this.multiAddress.map((item) => {
                        return {
                            ...item,
                            da: encodeHtmlStr(item.da),
                        };
                    });
                    if (this.searchMode) {
                        return list.filter((item) => {
                            return this.search == '' || item.p.indexOf(this.search) != -1;
                        });
                    } else {
                        return list;
                    }
                },
                isEmpty() {
                    return this.renderAddressList.length == 0;
                },
                renderViewAddressList() {
                    let list = this.multiAddress;
                    if (this.searchMode) {
                        return list.filter((item) => {
                            return this.search == '' || item.p.indexOf(this.search) != -1;
                        });
                    } else {
                        return list;
                    }
                },
            },
            beforeDestroy() {
                $(this.$refs.search).unbind('keydown');
            },
            methods: {
                close() {
                    this.show = false;
                },
                open() {
                    this.show = true;
                    if (this.recordAddress != null) {
                        this.addressIndex = this.renderViewAddressList.findIndex((item) => {
                            return item.x == this.recordAddress.x && item.y == this.recordAddress.y;
                        });
                    }
                    this.$nextTick(() => {
                        $(this.$refs.search).keydown((e) => {
                            if (e.keyCode === 13) {
                                this.handleSearch();
                            }
                        });
                    });
                },
                handleSearch() {
                    this.searchMode = true;
                },
                handleClear() {
                    this.search = '';
                    this.searchMode = false;
                    this.recordAddress = null;
                    let fn = self.events[AddressSlide.EVENT_BACK];
                    if (typeof fn == 'function') {
                        fn(0, this.renderViewAddressList[0]);
                    }
                },
                handleBack() {
                    this.show = false;
                    this.searchMode = false;
                    this.search = '';
                },
                handleAddressClick(index) {
                    this.addressIndex = index;
                    let fn = self.events[AddressSlide.EVENT_BACK];
                    if (typeof fn == 'function') {
                        let detail = this.renderViewAddressList[index];
                        this.recordAddress = detail;
                        fn(index, detail);
                    }
                    this.handleBack();
                },
            },
        }).$mount(`#${id}`);
    }

    open() {
        this.instance && this.instance.open();
    }

    close() {
        this.instance && this.instance.close();
    }

    on(name, callback) {
        this.events[name] = callback;
    }
}

AddressSlide.EVENT_BACK = 'back';

export default AddressSlide;
