var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: [_vm.prefixCls] }, [
    _c("div", { class: [`${_vm.prefixCls}__content`] }, [
      _c(
        "div",
        {
          class: [
            `${_vm.prefixCls}__icon`,
            `${_vm.prefixCls}__icon-theme${_vm.styleType}`,
          ],
        },
        [
          (_vm.moduleStyle === 1 && [1, 2].includes(_vm.iconStyle)) ||
          (_vm.moduleStyle === 2 && [1, 2, 3, 4].includes(_vm.iconStyle))
            ? _c(
                "svg",
                {
                  class: "rn-" + _vm.moduleStyle + "-" + _vm.iconStyle,
                  style: _vm.svgIconStyle,
                },
                [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "#icon-rn-" + _vm.moduleStyle + "-" + _vm.iconStyle,
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.moduleStyle === 1 && _vm.iconStyle === 3
            ? _c("span", { staticClass: "rn-1-3", style: _vm.textIconStyle }, [
                _vm._v(_vm._s(_vm.iconText)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.moduleStyle === 1 && _vm.iconStyle === 4
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "flex",
                    "align-items": "center",
                  },
                  style: _vm.svgIconStyle,
                },
                [
                  _c("svg", { staticClass: "rn-1-4" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-rn-1-4" } }),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "rn-1-4-text" }, [
                    _vm._v(_vm._s(_vm.iconText)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.imgPath && _vm.iconStyle === 6
            ? _c("img", {
                class: [
                  `${_vm.prefixCls}__icon-img`,
                  `rn-${_vm.moduleStyle}-6`,
                ],
                style: _vm.iconWidthStyle,
                attrs: { src: _vm.imgPath },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.imgPath && _vm.iconStyle === 6
            ? _c(
                "svg",
                {
                  staticStyle: { width: "32px", height: "24px" },
                  style: _vm.svgIconStyle,
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 64 30",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M6.016 15.365L15.96 0 8.408 0 9.165 1.203 0 15.365 6.016 15.365z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M19.093 0L24.984 12.932 31 12.932 25.109 0 19.093 0z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M17.84 18.287L20.412 24.503 9.468 25.122 19.845 8.437 13.829 8.437 3.217 25.475 2.799 30 22.231 28.902 22.686 30 28.702 30 23.856 18.287 17.84 18.287z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M52.762 12.028L53.364 7.54 63.064 7.54 63.593 3.724 53.876 3.724 54.375 0 46.547 0 47.347 1.429 47.039 3.724 43.058 3.724 44.059 1 37.019 1 37.723 2.295 35.106 9.414 40.966 9.414 41.655 7.54 46.527 7.54 45.925 12.028 33.499 12.028 33 15.844 63.471 15.844 64 12.028 52.762 12.028z",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M54.111,18.435H35.269L33.865,30H59.544l1.4-11.565H54.111ZM41.165,26.183l.477-3.932H53.648l-.477,3.932Z",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { ref: "swiperWrap", class: [`${_vm.prefixCls}__text`] }, [
        !_vm.needRenderSwiper
          ? _c("div", { ref: "mySwiper", staticClass: "swiper" }, [
              _c(
                "div",
                { staticClass: "swiper-wrapper" },
                _vm._l(_vm.renderNoticeList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "swiper-slide J_swiper swiper-no-swiping",
                      class: "swiper-slide-" + _vm.moduleStyle,
                    },
                    [
                      _vm.moduleStyle === 1
                        ? _c(
                            "a",
                            {
                              key: item.id,
                              class: [`${_vm.prefixCls}__text-item`],
                              style: _vm.itemStyle,
                              attrs: { href: item.link && item.link.url },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.name) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._l(item, function (item2) {
                            return _c(
                              "a",
                              {
                                key: item2.id,
                                class: [`${_vm.prefixCls}__text-item`],
                                style: _vm.itemStyle,
                                attrs: { href: item2.link && item2.link.url },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item2.name) +
                                    "\n                        "
                                ),
                              ]
                            )
                          }),
                    ],
                    2
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }