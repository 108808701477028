var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "pay_status_wrap pay_status_success_wrap" }, [
    _c("div", { staticClass: "pay_status_left" }, [
      _c("div", { staticClass: "pay_status_icon pay_success_icon" }, [
        _c("svg", [_c("use", { attrs: { "xlink:href": "#icon_completes" } })]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pay_status_info" }, [
        _c("div", { staticClass: "pay_status_title" }, [
          _vm._v(_vm._s(_setup.LS.paySuccess)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pay_status_desc" }, [
          _vm._v(
            _vm._s(_setup.LS.payAndOrderSucees) +
              _vm._s(_setup.isShowDelayTips ? _setup.LS.orderSuceesLimit : "")
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    !_setup.isMobi
      ? _c(
          "div",
          { staticClass: "pay_success_back", on: { click: _setup.goIndex } },
          [_vm._v(_vm._s(_setup.LS.backToHomePage))]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }