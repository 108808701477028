var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item_type_select" },
    [
      _vm.requiredIcon
        ? _c("span", { staticClass: "required_icon" }, [_vm._v("*")])
        : _vm._e(),
      _vm._v(" "),
      _c("select-component", {
        attrs: {
          list: _vm.itemList,
          "is-link-to-form": _vm.isLinkToForm,
          value: _vm.currentVal,
          placeholder: _vm.item.placeholder,
          "item-input-style": _vm.itemInputStyle,
          "residue-map": _vm.getResidueMap(),
        },
        on: { input: _vm.selectItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }