var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.isLogin
    ? _c("div", { staticClass: "mall_order_detail_content" }, [
        _c("div", { staticClass: "mall_order_detail_wrap" }, [
          _c(
            "div",
            { staticClass: "mall_order_detail_info" },
            [
              _c(
                _setup.OrderDetailSection,
                {
                  staticClass: "mall_order_detail_receive",
                  attrs: { title: _setup.LS.receivingInfo },
                },
                _vm._l(_setup.propList, function (prop, index) {
                  return _c(_setup.FormItem, {
                    key: index,
                    attrs: {
                      title: prop.name,
                      type: _setup.getPropType(prop),
                      "mobile-ct-name-list": _vm.module.mobileCtNameList,
                      required: prop.required,
                      placeholder: prop.placeholder,
                    },
                    model: {
                      value: prop.value,
                      callback: function ($$v) {
                        _vm.$set(prop, "value", $$v)
                      },
                      expression: "prop.value",
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                _setup.OrderDetailSection,
                { attrs: { title: _setup.LS.orderDetailListTitle } },
                [
                  _c(_setup.ShoppingList, {
                    attrs: {
                      list: _setup.productList,
                      "unit-info": _setup.unitInfo,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.module.openMemberMsg
                ? _c(
                    _setup.OrderDetailSection,
                    { attrs: { title: _setup.LS.orderMsg } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _setup.memberMsg,
                            expression: "memberMsg",
                          },
                        ],
                        staticClass: "mall_order_detail_leave_message",
                        attrs: { maxlength: "200" },
                        domProps: { value: _setup.memberMsg },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _setup.memberMsg = $event.target.value
                          },
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mall_order_detail_total_wrap" },
            [
              _c(
                _setup.OrderDetailSection,
                {
                  staticClass: "mall_order_detail_total",
                  attrs: { title: _setup.LS.total },
                },
                [
                  _c("div", { staticClass: "mall_order_detail_total_item" }, [
                    _c("span", [_vm._v(_vm._s(_setup.LS.productSum))]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_setup.addUnit(_setup.total)))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mall_order_detail_split_line" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mall_order_detail_total_item" }, [
                    _c("span", [_vm._v(_vm._s(_setup.LS.shouldPayPrice))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mall_order_detail_price" }, [
                      _vm._v(_vm._s(_setup.addUnit(_setup.total))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mall_order_detail_submit",
                      on: { click: _setup.submitOrder },
                    },
                    [_vm._v(_vm._s(_setup.LS.mallStlSubmit))]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }