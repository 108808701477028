var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: { css: false },
      on: { enter: _vm.animEnter, leave: _vm.animLeave },
    },
    [
      _vm.value
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "dom-portal",
                  rawName: "v-dom-portal",
                  value: _vm.getDomEl,
                  expression: "getDomEl",
                },
              ],
              staticClass: "p_popup_layer",
              attrs: { ontouchmove: _vm.LayerTouchMove },
            },
            [
              _c("div", {
                ref: "mask",
                staticClass: "popup_mask",
                on: { click: _vm.onClose },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "content",
                  staticClass: "popup_content",
                  attrs: { ontouchmove: _vm.contentTouchMove },
                },
                [_vm._t("default")],
                2
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }