const factory = new Map();

export const ServiceFactory = {
    register(name, pkg = {}) {
        const { interfaceClass, impl } = pkg;
        factory.set(name, {
            interfaceClass,
            impl,
        });
    },
    get(serviceInterface, { params = [], impl } = {}) {
        if (factory.has(serviceInterface)) {
            const service = factory.get(serviceInterface);
            if (!service.impl) {
                return new (service.interfaceClass.bind(null, ...params))();
            }
            if (impl) {
                return service.impl[impl];
            } else {
                return Object.values(service.impl).map((ImplClass) => {
                    return new (ImplClass.bind(null, ...params))();
                });
            }
        }
    },
};
