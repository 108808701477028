var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ChildrenCSS",
    [
      _vm.isCarouselRowInPc
        ? [
            _vm._l(_vm.carouselListInPc, function (c, j) {
              return _vm._l(c.cols, function (col, k) {
                return _c("ColComponent", {
                  key: "c" + _vm.row.id + j + k,
                  attrs: {
                    col: col,
                    "row-id": _vm.row.id,
                    index: j,
                    "open-hover": _vm.openHover,
                    "is-carousel": true,
                  },
                })
              })
            }),
          ]
        : _vm._l(_vm.row.cols, function (col, i) {
            return _c("ColComponent", {
              key: "r" + _vm.row.id + i,
              attrs: {
                col: col,
                "row-id": _vm.row.id,
                "open-hover": _vm.openHover,
              },
            })
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }