var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item_type_0" }, [
    _c("input", {
      staticClass: "input_style jz_focus jz_theme_focus_border_color",
      attrs: {
        maxlength: "100",
        placeholder: _vm.item.placeholder,
        disabled: _vm.item.disabled,
      },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }