var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { viewBox: "0 0 24 24" } }, [
    _c("line", {
      attrs: {
        x1: "9",
        y1: "12",
        x2: "15",
        y2: "12",
        fill: "none",
        stroke: "currentColor",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }