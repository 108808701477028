var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pc-view" },
    [
      !_vm.flagName
        ? _c("h1", {
            staticClass: "title",
            style: _vm.titleStyle,
            domProps: { innerHTML: _vm._s(_vm.title) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("carousel-component", {
        attrs: {
          "photo-list": _vm.photoList,
          "des-show": _vm.descShow,
          "module-id": _vm.id,
        },
      }),
      _vm._v(" "),
      !_vm.flagDetailClose
        ? _c("html-renderer", {
            staticClass: "ue_content",
            attrs: { html: _vm.ueContent },
            on: {
              click: function ($event) {
                return _vm.bindUeSlider($event)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.flagDetailClose
        ? _c("div", { staticClass: "split-line" })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "detail-footer" }, [
        !_vm.flagPrevNext
          ? _c("div", { staticClass: "group" }, [
              !_vm.emptyPrePhotoGroup
                ? _c(
                    "a",
                    { staticClass: "pre-group", attrs: { href: _vm.preUrl } },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.prefix) },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "group-name",
                        domProps: { innerHTML: _vm._s(_vm.preName) },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.emptyNextPhotoGroup
                ? _c(
                    "a",
                    { staticClass: "next-group", attrs: { href: _vm.nextUrl } },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.suffix) },
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "group-name",
                        domProps: { innerHTML: _vm._s(_vm.nextName) },
                      }),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.flagShare
          ? _c(
              "div",
              {
                staticClass: "share jz_share_text jz_share_info",
                on: {
                  mouseenter: function ($event) {
                    return _vm.jzShareMouseenter($event)
                  },
                  mouseleave: function ($event) {
                    return _vm.jzShareMouseleave($event)
                  },
                },
              },
              [
                _c("div", { staticClass: "share-container" }, [
                  _c("svg", { attrs: { width: "16px", height: "16px" } }, [
                    _c("use", {
                      attrs: { "xlink:href": "#icon_photo_group_detail_share" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "news_share_text" }, [
                    _vm._v(_vm._s(_vm.share)),
                  ]),
                ]),
                _vm._v(" "),
                _c("jz-share-ssr-compon", {
                  attrs: {
                    list: _vm.shareList,
                    "share-param-list": _vm.shareParamList,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }