export default {
    namespaced: true,
    state: () => ({
        wxShareInfo: null,
        allowedWXShare: false,
        updateIdWXShare: 0,
        isAllowJumpWxApp: false,
    }),
    mutations: {
        setWxData(state, payload) {
            state.wxShareInfo = payload.wxShareInfo;
            state.allowedWXShare = payload.allowedWXShare;
            state.updateIdWXShare = payload.updateIdWXShare;
            state.isAllowJumpWxApp = payload.isAllowJumpWxApp;
        },
    },
};
