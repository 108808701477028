var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "second_style_content" }, [
    _c(
      "div",
      { staticClass: "second_style style_container" },
      _vm._l(_vm.iconList, function (item) {
        return _c(
          "a",
          {
            directives: [
              {
                name: "hovermenu",
                rawName: "v-hovermenu",
                value: {
                  hovermenu: _vm.hovermenu,
                  args: {
                    moduleId: _vm.module.id,
                    id: item.id,
                  },
                },
                expression:
                  "{\n                hovermenu,\n                args: {\n                    moduleId: module.id,\n                    id: item.id,\n                },\n            }",
              },
            ],
            key: item.id,
            staticClass: "icon_module_item second_style_item",
            class: _vm.iconCountClass,
            style: _vm.itemContainerStyle,
            attrs: {
              href: _vm.getLinkUrl(item.link),
              target: item.link.ib ? "_blank" : "",
              rel: item.link.nf ? "nofollow" : "",
            },
            on: {
              mouseenter: function ($event) {
                return _vm.handleItemMouseenter(item)
              },
              mouseleave: function ($event) {
                return _vm.handleItemMouseleave(item)
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "icon_item_container second_style_item_content",
                class: _vm.iconImgContainerAlignStyle,
              },
              [
                _c(
                  "div",
                  { staticClass: "second_style_item_left iconImgWrap" },
                  [_c("img", { attrs: { src: item.url } })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "second_style_item_right",
                    style: _vm.iconImgStyle(item),
                  },
                  [
                    _c("div", {
                      staticClass: "item_title",
                      style: {
                        ..._vm.iconTitleStyle,
                        ..._vm.getTitleHoverStyle(item),
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.encodeHtmlStr(item.title)),
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "item_desc",
                      style: _vm.iconDescStyle,
                      domProps: {
                        innerHTML: _vm._s(_vm.encodeHtmlStr(item.desc)),
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }