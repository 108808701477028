var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "paySelect" },
    _vm._l(_vm.payTypeList, function (payItem) {
      return _c(
        "div",
        {
          key: payItem.key,
          staticClass: "payItem",
          class: { check: _vm.value === payItem.key },
          on: {
            click: function ($event) {
              return _vm.$emit("input", payItem.key)
            },
          },
        },
        [
          _c("svg", { class: _vm.getIconClass(payItem.key) }, [
            _c("use", { attrs: { "xlink:href": _vm.getIcon(payItem.key) } }),
          ]),
          _vm._v(" "),
          _c("svg", { staticClass: "payCheckSvg jz_theme_font_color" }, [
            _c("use", { attrs: { "xlink:href": "#icon_checked2" } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "payName" }, [
            _c("div", { staticClass: "payCn" }, [
              _vm._v(_vm._s(payItem.label)),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }