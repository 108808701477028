var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "popup_login" }, [
    _c("div", {
      staticClass: "title",
      domProps: { textContent: _vm._s(_vm.title) },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content", attrs: { id: _vm.id } },
      [_c("member-login", { attrs: { module: _vm.module } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }