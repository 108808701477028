var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: { name: _vm.animateClass },
      on: { "before-enter": _vm.beforeEnter, "after-enter": _vm.afterEnter },
    },
    [
      _c("JzBannerContent", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activePage,
            expression: "activePage",
          },
        ],
        class: _vm.activePageClass,
        attrs: { "full-screen-inner-info": _vm.fullScreenInnerInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }