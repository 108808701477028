var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "module_content_detail module_rich_content",
      class: _vm.contentClass,
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "hovermenu",
              rawName: "v-hovermenu",
              value: { hovermenu: _vm.hovermenu, args: true },
              expression: "{ hovermenu, args: true }",
            },
          ],
          staticClass: "rich_img_wrap",
          style: _vm.imgsStyle,
        },
        [
          !!_vm.picLink
            ? [
                !!_vm.picLink
                  ? _c(
                      "a",
                      {
                        staticClass: "rich_Link",
                        attrs: {
                          hidefocus: "true",
                          href: _vm.picLink,
                          target: _vm.picTarget,
                          rel: _vm.nofollow,
                          onclick: _vm.picJumpOnclickStr,
                        },
                        on: { click: _vm.photoSlide },
                      },
                      [
                        _c("ImageComponent", {
                          directives: [
                            {
                              name: "lazyload2",
                              rawName: "v-lazyload2",
                              value: {
                                id: _vm.module.id,
                                src: _vm.imgSrc,
                                naturalWidth: _vm.naturalWidth,
                                naturalHeight: _vm.naturalHeight,
                              },
                              expression:
                                "{ id: module.id, src: imgSrc, naturalWidth, naturalHeight }",
                            },
                          ],
                          staticClass: "rich_major_photo",
                          attrs: {
                            src: _vm.imgSrc,
                            webp: _vm.webpOptOpen,
                            title: _vm.picDisc,
                            alt: _vm.picDisc,
                            "img-effects": _vm.isMobi
                              ? { type: 0 }
                              : _vm.imgOptsComputed,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.picLink
            ? _c("ImageComponent", {
                directives: [
                  {
                    name: "lazyload2",
                    rawName: "v-lazyload2",
                    value: {
                      id: _vm.module.id,
                      src: _vm.imgSrc,
                      naturalWidth: _vm.naturalWidth,
                      naturalHeight: _vm.naturalHeight,
                    },
                    expression:
                      "{ id: module.id, src: imgSrc, naturalWidth, naturalHeight }",
                  },
                ],
                staticClass: "rich_major_photo",
                attrs: {
                  src: _vm.imgSrc,
                  webp: _vm.webpOptOpen,
                  title: _vm.picDisc,
                  alt: _vm.picDisc,
                  "img-effects": _vm.isMobi ? { type: 0 } : _vm.imgOptsComputed,
                },
                on: { click: _vm.photoSlide },
              })
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hovermenu",
              rawName: "v-hovermenu",
              value: _vm.hovermenu,
              expression: "hovermenu",
            },
          ],
          staticClass: "rich_article_content_wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "rich_article_wrap",
              on: {
                click: function ($event) {
                  return _vm.bindUeSlider($event)
                },
              },
            },
            [
              _c("html-renderer", {
                staticClass: "rich_article_wrap_html",
                attrs: { html: _vm.onShowContent },
              }),
              _vm._v(" "),
              _vm.hasContentPage
                ? _c("pagination-component", {
                    attrs: {
                      "module-id": _vm.id,
                      "page-count": _vm.pageList.length,
                      "current-page": _vm.page,
                      "style-index": 0,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        _vm.page = $event
                      },
                      "update:current-page": function ($event) {
                        _vm.page = $event
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("image-main-viewer", {
        attrs: {
          "show-mobi-viewer": _vm.showMobiViewer,
          "show-pc-viewer": _vm.showPcViewer,
          "z-index": _vm.zIndex,
          "initial-index": _vm.imageIndex,
          "on-close": _vm.closeViewer,
          "url-list": _vm.previewSrcList,
          "is-mobi": _vm.isMobi,
          "manage-mode": _vm.manageMode,
          "log-event-config": _vm.logEventConfig,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }