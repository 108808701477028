/**
 * 创建一个提示大保存的方法，保存后执行
 * @param { Function} fn - 大保存后的函数
 * @param { Object } options - 参数
 * @returns { Function }
 */
export function createFnBeforeCheckSaveBar(fn, options = {}) {
    return function () {
        if (
            checkSaveBar({
                callback: fn,
                ...options,
            })
        ) {
            return;
        }

        fn();
    };
}

/**
 * @function name - checkSaveBar
 * @description 触发保存弹窗
 * @kind Root
 * @param options {Object}
 */
export const checkSaveBar = (options) => {
    if (window._store.state.manage.changedFlag.page) {
        popupChangedBodyWindow({
            msg: '您的网站设计已经更改，是否立即保存？',
            isCancelBtn: false,
            runCallback: false,
            saveBtnStr: '确定',
            cancelBtnStr: '取消',
            callback: (options && options.callback) || (() => {}),
            otherOptions: options,
        });
        return true;
    }

    return false;
};
/**
 * @function name - popupChangedBodyWindow
 * @description 网站设计已经更改 立即保存弹窗
 * @kind Root
 * @param options {Object}
 */
export const popupChangedBodyWindow = function (options) {
    var msg = options.msg || '您的网站设计已经更改，是否立即保存？',
        // 提示语
        otherOptions = options.otherOptions ?? {},
        // 传入的其他数据
        callback = options.callback || (() => {}),
        // 回调函数
        isCancelBtn = options.isCancelBtn || -1,
        // 是否点击大保存的取消按钮触发了当前函数
        judgeCancelBtn = false;

    if (typeof isCancelBtn != 'undefined' && isCancelBtn == 1) {
        judgeCancelBtn = true;
    }

    new Vue().$createDialog({
        content: msg,
        confirmButton: {
            text: judgeCancelBtn ? '确 定' : '立即保存',
        },
        cancelButton: {
            text: '取 消',
        },

        onConfirm() {
            if (otherOptions.beforePopup) {
                otherOptions.closeFunc = function () {
                    Fai.top.location.reload();
                };
            }

            if (judgeCancelBtn) {
                window._store.commit('manage/clearChangedFlag');

                location.reload();
            } else {
                if (otherOptions.beforePopup) {
                    Root.saveAll({
                        callback,
                        ...otherOptions,
                    });
                    setTimeout('Fai.top.location.reload()', 500); // 火狐浏览器下不延迟加载会出现系统繁忙的bug ajax异步问题
                } else {
                    Root.saveAll({
                        callback,
                        ...otherOptions,
                    });
                }
            }
        },
    });
};
