var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "jz_theme_bg_color online_service_btn",
      on: { click: _vm.handleClick },
    },
    [
      _vm.showDefaultIcon
        ? _c(
            "svg",
            {
              staticClass:
                "online_service_btn_icon online_service_btn_icon--svg",
            },
            [_c("use", { attrs: { "xlink:href": _vm.defaultSvg } })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showCustomIcon && _vm.path
        ? _c("img", {
            staticClass: "online_service_btn_icon",
            attrs: { src: _vm.path, alt: "在线客服" },
          })
        : _vm._e(),
      _vm._v("\n    " + _vm._s(_vm.text) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }