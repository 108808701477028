export const getProductListAnimation = function (id, order) {
    var $module = Fai.top.$('#module' + id),
        domSeqList = [],
        j = 0,
        pdsInRow = 4, // 一行几个产品
        $dom;

    $dom = $module.find('.exhibition_show_product');
    if ($dom.length < 0) {
        return;
    }
    if (order == 1) {
        // 同时
        let tmpArr = [];
        pdsInRow = parseInt($module.find('.exhibition_show_products').attr('productsinrow') || pdsInRow);
        $.each($dom, function (index, val) {
            tmpArr.push($(val)[0]);
            if ((index != 0 && (index + 1) % pdsInRow == 0) || index == $dom.length - 1) {
                // 每行作为一个整体push进domSeqList
                !!tmpArr.length && (domSeqList[j++] = tmpArr);
                tmpArr = [];
            }
        });
    } else {
        // 依次
        $dom.each(function () {
            domSeqList[j++] = Array.from($(this));
        });
    }

    return domSeqList;
};
