import { Module } from '@shared/visitor/module/index.js'; //图册目录模块
import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

//图册目录模块
const PhotoGroupModule = class PhotoGroupModule extends Module {
    constructor(...args) {
        super(...args);
        this.init();
    }

    init() {
        //点击图册出现幻灯片
        // this.$el.off('click.slide').on('click.slide', '.photo_display_area > li', (e) => {
        //     const photo_group_id = e.currentTarget.getAttribute('data-id');
        //     this.initSlide(photo_group_id);
        // });
        // 若为详情页则点击不进行幻灯片操作 FIX
        this.initSlider();
        this.initMarquee();
    }

    initSlider() {
        const timer_id = `marquee-${this.moduleId}`;
        Fai.stopInterval(timer_id);

        if (this.moduleStyle === 1) {
            //点击箭头
            const $list = this.$el.find('.photo_display_area');
            this.$el.off('click.prev').on('click.prev', '.prev', () => {
                $list.animate({
                    scrollLeft: $list.scrollLeft() - $list.width(),
                });
            });
            this.$el.off('click.next').on('click.next', '.next', () => {
                $list.animate({
                    scrollLeft: $list.scrollLeft() + $list.width(),
                });
            });
        }
    }

    initMarquee() {
        const timer_id = `marquee-${this.moduleId}`;
        Fai.stopInterval(timer_id);

        if (this.moduleStyle === 2) {
            //样式三为滚动样式,支持滚动
            const $list = this.$el.find('.photo_display_area');
            const speed = 1;
            let prev_scroll_left = 0;
            Fai.addInterval(
                timer_id,
                () => {
                    let scroll_left = $list.scrollLeft() + speed;

                    if (prev_scroll_left === scroll_left) {
                        //又回到最初的起点,呆呆的站在镜子前。
                        scroll_left = speed; //fix();
                    }

                    prev_scroll_left = scroll_left;
                    $list.scrollLeft(scroll_left);
                },
                1000 / 60
            );
            Fai.startInterval(timer_id);

            const fix = () => {
                if (!window._store.state.manageMode) {
                    $list.children('.placeholder').remove();
                    var rowLen = window.innerWidth <= 768 ? 2 : this.conf.rowLen;

                    if (this.conf.photoGroupList.length > rowLen) {
                        for (var i = 0; i < rowLen; i++) {
                            $list.append($list.children(`li:eq(${i})`).clone().addClass('placeholder'));
                        }
                    }
                }
            };

            fix();
            $(window).resize(fix);
        }
    }

    get moduleStyle() {
        return this.conf.style;
    }

    get photoGroupList() {
        return this.conf.photoGroupList;
    }
    /*  幻灯片 start  */

    /*  幻灯片 start  */
    initSlide(photo_group_id) {
        //图册数据
        const photoGroupList = this.photoGroupList;
        const photoGroup = photoGroupList.find((item) => item.id == photo_group_id);
        if (!photoGroup) return;
        const photoList = (this._slidePhotoList = photoGroup.photoList); //浏览结束面板

        //浏览结束面板
        const photo_group_index = photoGroupList.indexOf(photoGroup);
        const nextPhotoGroupList = photoGroupList.slice(photo_group_index + 1, photo_group_index + 7); //取后面接下来6个

        //取后面接下来6个
        const review_photo_group = nextPhotoGroupList.length === 0; //后面已经没有图册了，重新浏览

        //后面已经没有图册了，重新浏览
        review_photo_group && nextPhotoGroupList.push(photoGroupList[photoGroupList.length - 1]); //重新预览最后一个

        //重新预览最后一个
        const endPanelHtml = `
			<a class="close faisco-icons-S000118"></a>
        	<h1 class="title">${review_photo_group ? LS.revisit : LS.continueBrowsing}</h1>
        	<div class="jz_scroll_wrap">
        		<ul class="photo_group_list jz_scroll">
	        		${nextPhotoGroupList
                        .map(
                            (item) => `
	        				<li class="photo_group_item ${nextPhotoGroupList.length === 1 ? 'once_photo' : ''}" data-id="${item.id}">
			        			<div class="photo" style="background-image:url('${item.picPath}');" >
			                        <div class="num">${item.photoList.length}<i></i></div>
			                    </div>
			                    <div class="name jz_theme_font_hover_color jz_hover ">${Fai.encodeHtml(item.name)}</div>
			        		</li>
	        			`
                        )
                        .join('')}
	        	</ul>
        	</div>
		`; //图片列表

        //图片列表
        const photoListHtml = photoList
            .map((item) => `<li style="background-image: url(${item.thumb160Path})"></li>`)
            .join(''); //应用到页面中去

        //应用到页面中去
        let $slide = $('body').find('.slide_panel'); //在当前frame查找

        //在当前frame查找
        if ($slide.length === 0) {
            //新打开幻灯片
            $slide = this._createSlideBody(photoListHtml, endPanelHtml);
        } else {
            //在幻灯片内切换图册
            $slide.find('.photo_list').html(photoListHtml);
            setTimeout(function () {
                $slide.find('.end_panel').html(endPanelHtml);
            }, 300);
            $slide.find('.photo_display_area').find('.body_container').remove();
            this.hideSlideEndPanel();
        } //刷新lazyload

        //初始化到第一个
        this._selectSlide = -1;
        this.selectSlide = 0;
    }

    _createSlideBody(photoListHtml, endPanelHtml) {
        // let $ = jQuery;
        // if(window._store.state.manageMode && _state.device !== "mobi"){
        // 	$ = RootFai.top.$;
        // }
        //幻灯片主体
        let $slide = (this.$slide = $(`
			<div class="slide_panel" style="display:none;">
				<a class="close faisco-icons-S000118"></a>
				<div class="photo_display_area">
					<a class="arrow prev"></a>
                	<a class="arrow next"></a>
				</div>
                <ul class="photo_list">
                	${photoListHtml}
                </ul>
                <div class="end_panel hidden">
                	${endPanelHtml}
                </div>
			</div>
		`)); //绑定事件

        //绑定事件
        $slide
            .on('click', '.close', () => {
                //关闭
                $slide.fadeOut(() => $slide.remove());
            })
            .on('click', '.prev', () => {
                //上一张
                this.selectSlide--;
            })
            .on('click', '.next', () => {
                //下一张
                this.selectSlide++;
            })
            .on('swipeleft.l', '.photo_display_area', () => {
                //左滑
                this.selectSlide++;
            })
            .on('swiperight.r', '.photo_display_area', () => {
                //右滑
                this.selectSlide--;
            })
            .on('click', '.photo_list > li', (e) => {
                //选择图片
                this.selectSlide = $(e.currentTarget).index();
            })
            .on('click', '.end_panel .photo_group_item', (e) => {
                //切换图册
                this.initSlide(e.currentTarget.getAttribute('data-id'));
            });
        $(window).resize(() => this._resizeSlide()); //添加到页面

        //添加到页面
        if ($('#jzPreviewContent').append($slide).length <= 0) {
            //如果容器不存在则添加到body
            $('body').append($slide);
        }

        $slide.fadeIn();
        return $slide;
    }

    get selectSlide() {
        return this._selectSlide;
    }

    set selectSlide(select) {
        if (select < 0) return;
        if (this.selectSlide === select) return;

        if (select >= this._slidePhotoList.length) {
            //看完了，显示结束面板
            this.showSlideEndPanel();
            return;
        }

        this._selectSlide = select;

        this._updateSlide();
    }

    showSlideEndPanel() {
        this.$slide.find('.arrow, .photo_list, >.close, .photo_display_area').addClass('hidden');
        this.$slide.find('.end_panel').removeClass('hidden');
    }

    hideSlideEndPanel() {
        this.$slide.find('.arrow, .photo_list, >.close, .photo_display_area').removeClass('hidden');
        this.$slide.find('.end_panel').addClass('hidden');
    }

    _updateSlide() {
        const photo = this._slidePhotoList[this.selectSlide];
        if (!photo) return;
        const desc_text = photo.basic;
        const $photo = $(`
			<div class="body_container" style="display:none;">
				<div class="body ${desc_text ? 'has_text' : ''}">
					<img class="photo" src="${photo.picPath}" alt="${Fai.encodeHtml(desc_text)}"/>
					${
                        desc_text
                            ? `
						<div class="text">
							<div class="jz_scroll_wrap">
								<span class="jz_scroll">${Fai.encodeHtml(desc_text)}</span>
							</div>
						</div>
					`
                            : ''
                    }
				</div>
			</div>
		`);
        const $photoDisplayArea = this.$slide.find('.photo_display_area');
        {
            const $prevPhoto = $photoDisplayArea.find('.body_container');
            $prevPhoto.fadeOut(() => $prevPhoto.remove());
        }
        $photoDisplayArea.append($photo);

        this._resizeSlide();

        $photo.fadeIn();

        if (!this.isMobi) {
            //下方列表滚动
            const $photo = this.$slide.find('.photo_list > li').removeClass('active').eq(this.selectSlide);
            $photo.addClass('active');
            const $list = $photo.parent(),
                photo_pos = $photo.position().left,
                photo_width = $photo.outerWidth(true),
                list_width = $list.outerWidth(true);

            if (photo_pos >= list_width - photo_width * 2) {
                //右边元素超出可视区
                $list.stop().animate(
                    {
                        scrollLeft: (this.selectSlide + 2) * photo_width - list_width,
                    },
                    300
                );
            } else if (photo_pos < photo_width) {
                //左边元素超出可视区
                $list.stop().animate(
                    {
                        scrollLeft: $list.scrollLeft() + photo_pos - photo_width,
                    },
                    300
                );
            }
        }
    }

    get isMobi() {
        return $(window).width() <= 768;
    }

    _resizeSlide() {
        const photo = this._slidePhotoList[this.selectSlide];
        const $photo = this.$slide.find('.photo_display_area .body_container').last().find('.body');
        const has_text = $photo.hasClass('has_text');
        const $container = this.$slide.find('.photo_display_area');
        let photo_width = photo.width;
        let photo_height = photo.height;
        let container_width = $container.width();
        let container_height = $container.height();

        if (has_text && !this.isMobi) {
            //如果存在文本并且不是移动设备则减去文本区域
            container_height -= 100;
        }

        if (photo_width > container_width) {
            const scale_ratio = container_width / photo_width;
            photo_width *= scale_ratio;
            photo_height *= scale_ratio;
        }

        if (photo_height > container_height) {
            const scale_ratio = container_height / photo_height;
            photo_width *= scale_ratio;
            photo_height *= scale_ratio;
        }

        $photo.width(photo_width);
    }
    /*  幻灯片 end  */
};
export { PhotoGroupModule };
