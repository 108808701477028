
import { mapState, mapGetters } from 'vuex';

import { getSmsTemplateList } from '@shared/manage/sysFaiSmsPlatform/index.js';
import { PopupWin } from '@/site/shared/popupWin/index.js';
import { setUrlArgToCookieAndJump } from '@/site/shared/cookie/index.js';
let manageUse = {
    components: {},
};
import { Comm } from '../../utils';
const { getStyleDataByDevice, extend, getComputedTextColor, isNewModuleAfter20210707 } = Comm;
import { addSiteForm, editOnlineForm, getSiteFormList } from '@shared/manage/onlineForm/index.js';
import { successMessage, warningMessage } from '@/site/shared/dialog/index.js';
import { logJZFdp, logDog } from '@/site/shared/log/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
import FormContainer from './formContainer.vue';
import FormItem from './formItem.vue';
import formPayItem from './formPayItem.vue';
import ItemType_0 from './itemType0.vue';
import ItemType_1 from '../common/forms/itemType1.vue';
import ItemType_2 from '../common/forms/itemType2.vue';
import ItemType_3 from '../common/forms/itemType3.vue';
import ItemType_4 from '../common/forms/itemType4.vue';
import ItemType_5 from '../common/forms/itemType5.vue';
import ItemType_6 from '../common/forms/itemType6.vue';
import ItemType_7 from '../common/forms/itemType7.vue';
import ItemType_8 from './itemType8.vue';
import ItemType_9 from '../common/forms/itemType9.vue';
import ItemType_10 from '../common/forms/itemType10.vue';
import ItemType_11 from '../common/forms/itemType11.vue';
import ItemType_12 from '../common/forms/itemType12.vue';
import ItemType_13 from '../common/forms/itemType13.vue';
import ItemType_validateCode from '../common/forms/itemTypeValidateCode.vue';
import { genCommFontStyle, genCommFontStyleForText, submitForm } from './utils.js';
import { animationMixin } from '../animationMixin';
import { getServerTime, addSubmit, memberModifySubmit } from '@api/form';
import { updateSubmitFormStyle } from './submitFormCss.js';
import { initModuleTransformTrack } from '@/components/transformTrack.js';
import { Pay } from '@/services';
import { FORM_ITEM_TYPES, transformToUIModel } from './model.js';

import ModuleAlert from '../alert.vue';

let hasLog = false;

export default {
    name: 'OnlineForm',
    style: 21,
    components: {
        FormContainer,
        FormItem,
        formPayItem,
        ItemType_0,
        ItemType_1,
        ItemType_2,
        ItemType_3,
        ItemType_4,
        ItemType_5,
        ItemType_6,
        ItemType_7,
        ItemType_8,
        ItemType_9,
        ItemType_10,
        ItemType_11,
        ItemType_12,
        ItemType_13,
        ItemType_validateCode,
        ModuleAlert,
        ...manageUse.components,
    },
    mixins: [animationMixin],
    inject: {
        inMemberDetailEdit: {
            default: false,
        },
    },
    provide() {
        return {
            initialValues: this.initialValues,
        };
    },
    props: {
        module: {
            type: Object,
            default: () => ({}),
        },
        initialValues: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            validateCode: '',
            formItemTypeNameArr: [
                '单行文本',
                '多行文本',
                '单选按钮',
                '多选按钮',
                '下拉选项',
                '文本说明',
                '日期选项',
                '文件上传',
                '手机号',
                '邮箱验证',
                '身份验证',
                '省市县区',
                '多级下拉',
            ],
            showSubmitLimitTime: false,
            formLimitTimeTips: '',
            cleanCountDown: null,
            selectedMap: {},
            answerMap: {},
            rulePrice: '0.00',
            checkbox_active: false,
            submitResultId: -1,
        };
    },
    asyncComputed: {
        asyncFormData: {
            get() {
                // 初始化短信模板数据
                getSmsTemplateList();

                const formId = this.module.prop2;
                return getSiteFormList().then((formList) => {
                    return formList.find((formData) => formData.id === formId) || {};
                });
            },
            default: {},
            lazy: true,
        },
    },
    watch: {
        contentList() {
            if (!this.manageMode) return;
            this.initRules();
            this.initSubmitLimitTime();
            this.refreshOneResAnimation();
            this.initPriceRule();
            this.initDefaultValues();
        },
        device() {
            $(`#formProtocol`).remove();
        },
    },
    computed: {
        ...mapState([
            'device',
            'manageMode',
            'jzVersion',
            'siteId',
            'isDemo',
            'isJuly3FreeUser',
            'LS',
            'sections',
            'openMultiPdInquiry',
        ]),
        ...mapState('manage', ['editPanel']),
        ...mapGetters('popupZone', ['isVisitMode']),
        ...Comm.mapFlag(
            {
                permission: 0x1,
                validation: 0x2,
                notRepeat: 0x4,
                openOnLinePayFlag: 0x40,
                openSubmitLimitTime: 0x2000,
                isOpenFormProtocol: 0x10000,
            },
            'formData.flag'
        ),
        supportMultiple() {
            return this.module.payList.length > 1;
        },
        onlySupportWxPay() {
            return this.module.payList.length === 1 && this.module.payList[0].key === Pay.showPayMode.WECHAT;
        },
        onlySupportAliPay() {
            return this.module.payList.length === 1 && this.module.payList[0].key === Pay.showPayMode.ALIPAY;
        },
        onlySupportPaypalPay() {
            return this.module.payList.length === 1 && this.module.payList[0].key === Pay.showPayMode.PAYPAL;
        },
        onlySupportStripePay() {
            return this.module.payList.length === 1 && this.module.payList[0].key === Pay.showPayMode.STRIPE;
        },
        showPayAlert() {
            return !this.isPreviewMode && !this.module.openPaySetting;
        },
        itemInputStyle() {
            if (this.isLinkToForm) {
                return {};
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.if,
                mobiData: this.module.privatePattern.mobi.if,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
        isMultiPdInquiry() {
            return this.openMultiPdInquiry && this.module.isLinkToForm && this.module.fromBtn;
        },
        openOnlinePay() {
            const openOnlinePay = this.openOnLinePayFlag && this.module.allowOnlinePay;
            return !this.isMultiPdInquiry && openOnlinePay;
        },
        isMobi() {
            return this.device === 'mobi';
        },
        isLinkToForm() {
            return !!this.module.isLinkToForm;
        },
        formData() {
            return this.manageMode ? this.asyncFormData || {} : this.module.formData || {};
        },
        hasFormData() {
            return this.formData.id;
        },
        contentList() {
            return this.formData.contentList || [];
        },
        priceRuleData() {
            return this.formData?.other?.formulaData ?? {};
        },
        hasFormItem() {
            return this.contentList && this.contentList.length > 0;
        },
        isActiveEditorPanel() {
            if (this.isLinkToForm) {
                return false;
            }
            return (
                this.manageMode &&
                this.editPanel.active &&
                this.editPanel.type === 'onlineForm' &&
                this.editPanel.data.moduleId === this.module.id
            );
        },
        classes() {
            const classes = {};

            classes[`s_${this.module.prop0}`] = true;
            classes['form_edit_state'] = this.isActiveEditorPanel;
            if (this.isLinkToForm) {
                classes[`onlineForm${this.module.id}`] = true;
            }
            return classes;
        },
        pattern() {
            // if (this.isLinkToForm) {
            // 	return;
            // }
            return this.module.privatePattern.pc;
        },
        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },
        formTitleStyle() {
            if (!this.manageMode) {
                return;
            }
            if (this.isLinkToForm) {
                return;
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.frt,
                mobiData: this.module.privatePattern.mobi.frt,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
                isNewModule: this.isNewModuleAfter20210707,
            });
            return genCommFontStyle(font);
        },
        formTitleTextStyle() {
            if (!this.manageMode) {
                return;
            }
            if (this.isLinkToForm) {
                return;
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.frt,
                mobiData: this.module.privatePattern.mobi.frt,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
                isNewModule: this.isNewModuleAfter20210707,
            });
            return genCommFontStyleForText(font);
        },
        limitTimeTipsTextAllStyle() {
            if (this.isLinkToForm) {
                return {};
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.lttf,
                mobiData: this.module.privatePattern.mobi.lttf,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            return genCommFontStyleForText(font, {}, { customStyleKey: 1 });
        },
        limitTimeTipsTextStyle() {
            if (this.isLinkToForm) {
                return {};
            }
            const allStyle = Object.assign({}, this.limitTimeTipsTextAllStyle || {});
            delete allStyle['text-decoration'];
            return allStyle;
        },
        limitTimeTipsTextStyleForText() {
            if (this.isLinkToForm) {
                return {};
            }
            const allStyle = Object.assign({}, this.limitTimeTipsTextAllStyle || {});
            return { 'text-decoration': allStyle['text-decoration'] };
        },
        verificationCodeName() {
            return this.module.verificationCodeName;
        },
        submitText() {
            return this.module.prop1;
        },
        payTxt() {
            return this.formData.payTxt || this.LS.paymentAmount || '支付金额';
        },
        price: {
            get() {
                if (this.formData.other.payType === 1) {
                    return this.rulePrice;
                }
                return this.formData.price.toFixed(2);
            },
            set(val) {
                if (this.formData.other.payType === 1) {
                    this.rulePrice = val;
                }
            },
        },
        isOpenPriceRule() {
            return this.openOnlinePay && this.formData.other.payType === 1;
        },
        formContainerStyle() {
            const style = {};
            let showTitle = true;
            if (this.isLinkToForm) {
                showTitle = false;
            } else {
                const font = Comm.getStyleDataByDevice({
                    pcData: this.module.privatePattern.pc.frt,
                    mobiData: this.module.privatePattern.mobi.frt,
                    isMobi: this.isMobi,
                    isTextNotExtendFontSize: true,
                    isNewModule: this.isNewModuleAfter20210707,
                });
                showTitle = font.y !== 1;
            }
            if ((this.openSubmitLimitTime && this.formLimitTimeTips) || showTitle) {
                style.marginTop = '40px';
            }
            return style;
        },
        formProtocolTextStyle() {
            if (this.isLinkToForm) {
                return {};
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.pts,
                mobiData: this.module.privatePattern.mobi.pts,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
        formProtocolTitleStyle() {
            if (this.isLinkToForm) {
                return {};
            }
            const font = getStyleDataByDevice({
                pcData: this.module.privatePattern.pc.lts,
                mobiData: this.module.privatePattern.mobi.lts,
                isMobi: this.isMobi,
                isTextNotExtendFontSize: true,
            });
            var fontStyle = {},
                textColorStyle = {},
                style = {};

            if (font.y) {
                fontStyle = {
                    fontSize: font.s ? font.s + 'px' : '',
                    fontFamily: font.f,
                    fontStyle: font.i == 1 ? 'italic' : 'normal',
                    fontWeight: font.w == 1 ? 'bold' : 'normal',
                    textDecoration: font.d == 1 ? 'underline' : 'none',
                };
                textColorStyle = getComputedTextColor(font.c);
            }

            extend(style, fontStyle);
            extend(style, textColorStyle);
            return style;
        },
        formProtocolTitle() {
            return `《${this.formData.formProtocolTitle}》`;
        },
        formProtocolContent() {
            return this.formData.formProtocolContent;
        },
        formProtocolText() {
            return this.$store.state.LS.memberProtocol_labelMsg;
        },
        allowFormProtocol() {
            return this.module.allowFormProtocol;
        },
        btnPattern() {
            return this.isLinkToForm ? this.module.other?.btnPattern : this.module.privatePattern.pc;
        },
        submitFormBackgroundSetting() {
            return this.btnPattern.bbs || {};
        },
        submitFormBtnFontSetting() {
            return this.btnPattern.bf || {};
        },
        submitFormBtnStyle() {
            return this.btnPattern.bs;
        },
        oldBtnStyle() {
            return this.submitFormBtnStyle == -1;
        },
        submitFormBtnBorder() {
            return this.btnPattern.br;
        },
        submitFormBtnBackgroundType() {
            return this.btnPattern.bb;
        },
        submitBtnClassList() {
            let classList = [];
            if (!this.oldBtnStyle) {
                classList.push(`submit_btn_style_${this.submitFormBtnStyle}`);
            }
            return classList;
        },
        afterClass() {
            if (this.submitFormBtnStyle == 5) {
                return {
                    jz_theme_bg_color: true,
                };
            }

            return {};
        },
        permissionWarning() {
            if (!this.sections.logined) return '';
            if (this.module.checkFormAuth === -307) {
                return Comm.format(this.LS.memberLevelPermission, this.sections.memberName, this.module.memberLevel);
            }
            if (this.module.checkFormAuth === -308) {
                return Comm.format(this.LS.memberGroupPermission, this.sections.memberName);
            }

            return '';
        },
        isPreviewMode() {
            return !this.manageMode || this.isVisitMode;
        },
    },
    created() {
        if (!this.manageMode) {
            this.initRules();
            this.initDefaultValues();
        }
        this.initPriceRule();
    },
    mounted() {
        if (!hasLog) {
            logDog(201020, 1);
            hasLog = true;
        }

        if (this.manageMode || this.isLinkToForm) {
            // 管理态 或者 表单弹窗，样式直接前端吐出处理
            updateSubmitFormStyle(this.module);
        }

        this.initSubmitLimitTime();

        if (!this.manageMode) {
            initModuleTransformTrack(this.module.id, this.module.style, this.formData?.other?.tts, this.isLinkToForm);
        }
    },
    methods: {
        handleChangeByPriceRule(formItemId, value) {
            if (!this.isOpenPriceRule) {
                return;
            }
            if (!this.answerMap[formItemId]) {
                return;
            }
            const selectedIndex = this.answerMap[formItemId].indexOf(value);
            if (this.answerMap[formItemId][0] !== '' && selectedIndex === -1 && value !== '') {
                return;
            }
            if (value) {
                if (this.answerMap[formItemId][0] !== '') {
                    this.selectedMap[formItemId] = selectedIndex;
                } else {
                    this.selectedMap[formItemId] = formItemId;
                }
            } else {
                this.selectedMap[formItemId] = null;
            }
            const needUpdatePrice = Object.values(this.selectedMap).every((val) => val != null);
            if (!needUpdatePrice) {
                this.rulePrice = '0.00';
                return;
            }
            try {
                const { formula = '', variableList = [] } = this.priceRuleData;
                const updatedPriceExp = formula.replace(/prop(\d+)/gi, (...args) => {
                    const id = Number(args[1]);
                    const selectedIndex = this.selectedMap[id];
                    if (selectedIndex == null) {
                        throw `表单项${id}已删除`;
                    }
                    const variableItem = variableList.find((item) => item.id === id);
                    if (!variableItem || !variableItem.selectList) {
                        throw `找不到表单项${id}变量数据`;
                    }
                    if (variableItem.selectList.length === 0) {
                        const item = this.contentList.find((item) => item.id === id);
                        return typeof item.value === 'undefined' && item.numberSetting.defaultNum !== ''
                            ? item.numberSetting.defaultNum
                            : item.value;
                    } else {
                        return variableItem.selectList[selectedIndex];
                    }
                });
                const price = eval(updatedPriceExp).toFixed(2);
                if (Math.abs(price).toString() === 'Infinity' || isNaN(price)) {
                    // NaN、无穷大或无穷小的时候，不显示价格
                    this.rulePrice = '';
                } else {
                    this.rulePrice = price;
                }
            } catch (err) {
                Fai.ing(this.LS.paymentAmountCalcError || '支付金额计算有误，请联系管理员', true);
                console.error(err);
            }
        },
        initPriceRule() {
            if (!this.isOpenPriceRule) {
                return;
            }
            const hasRuleFormItem = this.priceRuleData.formula.match(/prop\d+/gi) || [];
            hasRuleFormItem.forEach((id) => {
                id = Number(id.replace('prop', '')) || 0;
                const formItem = this.contentList.find((item) => item.id === id);
                if (!formItem) {
                    return;
                }
                this.answerMap[id] = formItem.input.split('\n');
                this.selectedMap[id] =
                    formItem.type === 13 && formItem.numberSetting.defaultNum !== ''
                        ? formItem.numberSetting.defaultNum
                        : null;
            });
            if (hasRuleFormItem.length === 0) {
                let price = '0.00';
                try {
                    price = eval(this.priceRuleData.formula).toFixed(2);
                } catch (error) {
                    console.error(error);
                }
                this.rulePrice = price;
            }
        },
        async initSubmitLimitTime() {
            if (!this.formData.other) {
                return;
            }
            if (!this.openSubmitLimitTime) {
                return;
            }
            this.showSubmitLimitTime = true;
            this.cleanCountDown && this.cleanCountDown();
            const result = await getServerTime();
            const nowTime = result.timeStamp ?? 0;
            const { startTime = 0, endTime = 0 } = this.formData.other;
            const clientNowTime = new Date().getTime();
            // 如果客户端时间跟服务端时间相差不超过5s，则用客户端的时间，否则用服务端时间
            const nowTimestamp = nowTime === 0 || Math.abs(nowTime - clientNowTime) < 5000 ? clientNowTime : nowTime;
            const { cleanCountDown } = bizShared.countDownFormLimitTime({
                startTimestamp: startTime,
                endTimestamp: endTime,
                nowTimestamp,
                LS: this.LS,
                onCountDown: ({ tips }) => {
                    this.formLimitTimeTips = tips;
                },
            });
            this.cleanCountDown = cleanCountDown;
        },
        handleChangeByRule(list) {
            if (this.manageMode) window._store.dispatch('manage/pauseDataWatch');
            list.forEach((operation) => {
                const { itemId, type, flag } = operation;
                const formItem = this.contentList.find(({ id }) => id === itemId);
                if (!Array.isArray(formItem.hideListByRule)) {
                    this.$set(formItem, 'hideListByRule', []);
                }
                if (type === 'add') {
                    if (!formItem.hideListByRule.includes(flag)) {
                        formItem.hideListByRule.push(flag);
                    }
                } else {
                    const index = formItem.hideListByRule.findIndex((item) => item === flag);
                    if (index > -1) {
                        formItem.hideListByRule.splice(index, 1);
                        if (formItem.hideListByRule.length === 0) this.checkFormItemRule(formItem);
                    }
                }
            });
        },
        checkFormItemRule(formItem) {
            if (!Array.isArray(formItem.rules)) return;
            // 清空掉选项
            const clearValueMap = {
                [FORM_ITEM_TYPES.CHECKBOX]: [],
                [FORM_ITEM_TYPES.LOCATION]: {
                    province: {},
                    city: {},
                    county: {},
                },
            };
            if (this.manageMode) {
                const $formItem = this.$refs[`formItem${formItem.id}`];
                if ($formItem && $formItem[0]) {
                    this.$set($formItem[0], 'manageVal', clearValueMap[formItem.type] ?? '');
                }
            } else {
                this.$set(formItem, 'value', clearValueMap[formItem.type] ?? '');
            }
            if ([2, 4].includes(formItem.type)) {
                this.handleChangeByPriceRule(formItem.id, '');
            }
            const { rules } = formItem;
            rules.forEach((rule) => {
                rule.showList.forEach((showItem) => {
                    const showItemContent = this.contentList.find((item) => item.id === showItem.key);
                    if (!showItemContent) return;
                    const { hideListByRule } = showItemContent;
                    if (!Array.isArray(hideListByRule)) return;
                    const subItem = `${rule.contentId}_${rule.optionName}`;
                    const subItemIndex = hideListByRule.findIndex((item) => item === subItem);
                    if (subItemIndex > -1) {
                        hideListByRule.splice(subItemIndex, 1);
                        if (hideListByRule.length === 0) this.checkFormItemRule(showItemContent);
                    }
                });
            });
        },
        initRules() {
            if (!this.formData.other) return;
            const ruleList = this.formData.other.rules;
            if (ruleList.length === 0) return;
            const contentList = this.contentList;
            // 过滤失效的规则
            ruleList.forEach((rule) => {
                // 判断该表单项是否存在
                const formItemIndex = contentList.findIndex((item) => rule.contentId === item.id);
                if (formItemIndex === -1) {
                    rule.disabled = true;
                    return;
                }
                const formItem = contentList[formItemIndex];
                if (formItem.hide) {
                    rule.disabled = true;
                    return;
                }
                if (formItem.type != rule.type) {
                    rule.disabled = true;
                    return;
                }
                if (formItem.input.split('\n')[rule.selected] !== rule.optionName) {
                    rule.disabled = true;
                    return;
                }
                rule.showList.forEach(({ key }) => {
                    const showItemIndex = contentList.findIndex((item) => key === item.id);
                    if (showItemIndex === -1 || showItemIndex < formItemIndex) {
                        rule.disabled = true;
                        return;
                    }
                });
            });

            const enableRuleList = ruleList.filter((rule) => !rule.disabled);
            const hideFormItemIds = [];
            // 表单项触发规则
            const hasRuleItemIds = [];
            enableRuleList.forEach((rule) => {
                if (!hasRuleItemIds.includes(rule.contentId)) {
                    hasRuleItemIds.push(rule.contentId);
                }
                rule.showList.forEach((item) => {
                    if (!hideFormItemIds.includes(item.key)) {
                        hideFormItemIds.push(item.key);
                    }
                });
            });
            if (this.manageMode) window._store.dispatch('manage/pauseDataWatch');
            // 添加临时数据，不入库
            this.contentList.forEach((content) => {
                const formItemId = content.id;
                this.$set(content, 'hideListByRule', hideFormItemIds.includes(formItemId) ? [] : false);
                if (hasRuleItemIds.includes(formItemId)) {
                    const rules = enableRuleList.filter((rule) => rule.contentId === formItemId);
                    this.$set(content, 'rules', rules);
                } else {
                    this.$set(content, 'rules', []);
                }
            });
        },
        logFormSubmitByJz() {
            if (this.manageMode === false) {
                const formItems = this.transformFormItemText();
                logJZFdp('jz_c_form_submit', {
                    jz_content_terminal: 'zsy',
                    jz_free_text1: formItems,
                    jz_version: this.jzVersion,
                    jz_site_id: this.siteId,
                });
            }
        },
        transformFormItemText() {
            if (this.contentList.length === 0) {
                return '';
            }

            const arr = this.contentList.map((contentItem) => {
                return this.formItemTypeNameArr[contentItem.type] || '';
            });
            const dedupeArr = Array.from(new Set(arr));

            return dedupeArr.join(',');
        },
        _submit(actionLoader) {
            if (this.manageMode) {
                Message.warning('当前为管理状态，提交无效。');
                return;
            }
            // if (this.isDemo) {
            //     warningMessage('当前为“模板网站”，请先“复制网站”再进行提交。', true);
            //     return;
            // }

            if (this.isJuly3FreeUser) {
                logDog(200964, 10);
                warningMessage('免费版暂不支持此功能', true);
                return;
            }
            if (this.openOnlinePay && Number(this.price) > 99999999) {
                warningMessage(this.LS.paymentAmountOver || '支付金额过大，请联系管理员', true);
                return;
            }

            if (this.permissionWarning.length > 0) {
                warningMessage(this.permissionWarning, true);
                return;
            }

            let unfinishedPriceRule = false;
            if (this.isOpenPriceRule) {
                unfinishedPriceRule = !Object.values(this.selectedMap).every((val) => val != null);
            }
            let isAgree = true;
            if (this.isOpenFormProtocol && this.allowFormProtocol) {
                isAgree = this.checkbox_active;
            }

            const options = {
                unfinishedPriceRule,
                isAgree,
                formProtocolTitle: this.formProtocolTitle,
                actionLoader,
            };

            if (this.inMemberDetailEdit) {
                // 会员编辑表单需要增加确认框
                options.beforeSubmit = () => {
                    let confirmResolve;
                    const modalConfirmPromise = new Promise((resolve) => {
                        confirmResolve = resolve;
                    });

                    this.$modal.confirm({
                        zIndex: 10002,
                        title: this.LS.confirmEditFormResult,
                        content: this.LS.coverOldFormResult,
                        confirmButtonText: this.LS.confirm,
                        cancelButtonText: this.LS.cancel,
                        onConfirm: async () => {
                            confirmResolve && confirmResolve(true);
                        },
                        onCancel: async () => {
                            confirmResolve && confirmResolve(false);
                        },
                    });

                    return modalConfirmPromise;
                };
            }

            return submitForm(this.module, this.formData, this.contentList, this.validation, this.validateCode, options)
                .then((result) => {
                    this.logFormSubmitByJz();
                    if (this.isMultiPdInquiry) {
                        localStorage.setItem('inquiry_product_list', '[]');
                    }
                    if (this.openOnlinePay && Number(this.price) > 0) {
                        this.handleOnlinePay(result);
                    } else {
                        this.handleSubmitSuccess();
                    }
                })
                .catch((error) => {
                    this.$emit('submit-error', error);
                    if (error instanceof Error) {
                        //系统错误
                        console.error(error);
                        return;
                    }
                    //下面是提交时返回的错误
                    if (error.msg) {
                        warningMessage(error.msg, true);
                    }
                    if (error.itemId) {
                        $(this.$refs.module).find(`.formitem_${error.itemId}`).find('input, textarea').eq(0).focus();
                    }
                    switch (error.errCode) {
                        case 'needCode':
                            //显示验证码
                            this.validation = true;
                            break;
                        case -401:
                            //验证码有误，清空并刷新验证码
                            this.validateCode = '';
                            this.validation = false;
                            Vue.nextTick(() => {
                                this.validation = true;
                            });
                            break;
                        case -28:
                            //已经提交过，两秒后刷新页面
                            setTimeout(function () {
                                document.location.reload();
                            }, 2000);
                            break;
                    }
                });
        },
        submit() {
            this.submitResultId = -1;
            const actionLoader = function (params) {
                return [addSubmit, params];
            };
            return this._submit(actionLoader);
        },
        /**
         * 提供给会员编辑表单组件的 method
         */
        memberEditDetail(submitId) {
            this.submitResultId = submitId;
            const actionLoader = function (params) {
                return [
                    memberModifySubmit,
                    {
                        id: submitId,
                        ...params,
                    },
                ];
            };
            return this._submit(actionLoader);
        },
        handleOnlinePay(result) {
            Pay.goOnlinePay({
                ...result,
                businessTypes: Pay.businessTypes.FORM,
                moduleId: this.module.id,
                formId: this.formData.id,
                paySuccessCallbackInPc: () => {
                    Fai.top.$('#formPayCodeBox').remove();
                    this.handleSubmitSuccess();
                },
            });
        },
        handleSubmitSuccess() {
            let submitTips = Fai.encodeHtml(this.formData.other.submitSuccessTips);
            if (this.module.showForm && this.module.buddyId) {
                submitTips = this.module.siteOpenFormSubmitOk;
            }
            if (this.inMemberDetailEdit) {
                submitTips = this.LS.siteFormSubmitOk;
            }

            successMessage(submitTips, true);

            this.$emit('submit-success', this.submitResultId);

            if (this.inMemberDetailEdit) return; // 不需要跳转和文件下载

            //3秒后跳转
            setTimeout(() => {
                const url = this.module.prop3.url;
                const hasUrl = url && !url.includes('javascript:void(0)');
                if (hasUrl) {
                    let isFileDownload = url.includes('Site.fileDownload');
                    setUrlArgToCookieAndJump(url, this.module.prop3.reqArgs, {
                        blank: this.isLinkToForm ? this.module.other?.p?.ib : this.module.prop3.ib,
                    });
                    // 文件下载后，再刷新页面
                    isFileDownload &&
                        setTimeout(() => {
                            document.location.reload();
                        }, 1000);
                    !!PopupWin &&
                        !!PopupWin.newPopup &&
                        !!PopupWin.newPopup.newVue &&
                        PopupWin.newPopup.newVue.closePopup();
                } else {
                    document.location.reload();
                }
            }, 3000);
            //重置验证码及输入框(旧逻辑，但是这里都刷新了，重置他还有什么用呢)
        },
        clickButtonHandler() {
            this.manageMode && editModule(this.module.id);
        },
        clickActiveButtonHandler() {
            getSiteFormList()
                .then((formList) => addSiteForm(`表单${formList.length + 1}`))
                .then((newSiteForm) => {
                    this.module.prop2 = newSiteForm.id;
                    editOnlineForm(this.module.id);
                });
        },
        clickAddFormItemHandler() {
            editOnlineForm(this.module.id);
        },
        getFormItem(type) {
            return `ItemType_${type}`;
        },
        inputItem(item, val) {
            if (this.manageMode && item.type !== 13) {
                //如果是管理态则不设置这个数据，否则可能会被存到设置项的数据中
                //如果是访客态的话每一个模块拿的都不是同一个引用的数据，所以可以作为一个临时的数据储存点
                return;
            }
            if (this.manageMode && item.type === 13) {
                item.value = val;
            } else {
                Vue.set(item, 'value', val);
            }
        },
        dblClickHandler(e) {
            if (!this.manageMode) {
                return;
            }
            if (this.isActiveEditorPanel) {
                //编辑状态时双击不能出编辑模块面板
                e.stopPropagation();
            }
        },
        toggleItem() {
            this.checkbox_active = !this.checkbox_active;
        },
        async viewFormProtocol() {
            if (!this.isMobi) {
                const lancode = Fai.getLanCode();
                const url = lancode.length ? `/${lancode}/` : `/`;
                window.open(`${url}viewFormProtocol.jsp?formId=${this.formData.id}`);
            } else {
                const {
                    data: { formProtocolContent, formProtocolTitle },
                } = await jzRequest.get('/rajax/siteForm_h.jsp', {
                    params: { cmd: 'getWafNotCk_getSiteFormProtocolDetail', formId: this.formData.id },
                });
                const { default: formProtocol } = await import('./form-protocol.vue');
                $("<div id='formProtocol'></div>").appendTo('.jz_preview_area');

                new Vue({
                    components: {
                        'form-protocol': formProtocol,
                    },
                    data() {
                        return {
                            formProtocolContent,
                            formProtocolTitle,
                        };
                    },
                    methods: {
                        close() {
                            this.$destroy();
                            $(`#formProtocol`).remove();
                        },
                    },
                    template: `<form-protocol :content="formProtocolContent" :contentTitle="formProtocolTitle" :close="close"></form-protocol>`,
                }).$mount('#formProtocol');
            }
        },

        initDefaultValues() {
            const getInitialValue = (formItem) => {
                // defaultValue 是表单项默认值， initialValue 是从外部传进来值(比如会员中心编辑表单时提供的值)
                // 以 initialValue 为最终值，为空才拿表单项默认值
                const defaultValueMapping = {
                    [FORM_ITEM_TYPES.TEXT]: this.initialValues[formItem.id] ?? formItem.dtsl,
                    [FORM_ITEM_TYPES.TEXT_AREA]: this.initialValues[formItem.id] ?? formItem.dtml,
                    [FORM_ITEM_TYPES.RADIO]: this.initialValues[formItem.id] ?? formItem.dtr,
                    [FORM_ITEM_TYPES.CHECKBOX]: this.initialValues[formItem.id] ?? formItem.dtm,
                    [FORM_ITEM_TYPES.SELECT]: this.initialValues[formItem.id] ?? formItem.dtds,
                    [FORM_ITEM_TYPES.DATE]: this.initialValues[formItem.id] ?? formItem.dtd,
                };
                return defaultValueMapping[formItem.type] ?? this.initialValues[formItem.id];
            };

            this.contentList.forEach(async (formItem) => {
                const value = getInitialValue(formItem) ?? '';
                const context = {
                    formItem,
                    files: this.initialValues.files,
                };
                const uiModelValue = transformToUIModel(value, context);
                if (uiModelValue) {
                    this.$set(formItem, 'value', uiModelValue);
                }
            });
        },

        hovermenu: manageUse.hovermenu || function () {},
    },
};
