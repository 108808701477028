export const paginationMixin = {
    data() {
        return {
            listLazyLoaded: false,
        };
    },
    watch: {
        listLazyLoaded(val) {
            val && this.currentPage !== 1 && this.handlePaginationPageChange(this.currentPage);
        },
    },
    mounted() {
        this.currentPage = +Fai.getUrlParam(location.href, `m${this.id}page`) || this.getHistoryCurrentPage();
        this.currentPage !== 1 && this.handlePaginationPageChange(this.currentPage);
    },
    methods: {
        setHistoryCurrentPage() {
            const currentPage = $.cookie(this.paginationCookieName);
            if ((currentPage == null || currentPage != this.currentPage) && this.flagIsBlank) {
                let tempDate = new Date();
                tempDate.setTime(tempDate.getTime() + 12 * 60 * 60 * 1000);
                $.cookie(this.paginationCookieName, this.currentPage, {
                    expires: tempDate,
                    path: '/',
                    domain: location.host,
                });
            }
        },
        removeHistoryCurrentPage() {
            $.cookie(this.paginationCookieName, null, {
                expires: -2,
                path: '/',
                domain: location.host,
            });
        },
        getHistoryCurrentPage() {
            let currentPage =
                $.cookie(this.paginationCookieName) !== null ? parseInt($.cookie(this.paginationCookieName)) : 1;
            return this.showPagination ? currentPage : 1;
        },
        handleListLazyLoaded(flag) {
            flag && (this.listLazyLoaded = true);
        },
        async backToModuleTop() {
            await this.$nextTick();
            const $module = $(`#module${this.module.id}`);
            const moduleOffset = $module.offset();

            if (this.$store.getters.isFullScreen) {
                // 全屏通栏
                let $row = $module.closest('.jz_web_row--carousel_item');
                if ($row.length === 0) {
                    $row = $module.closest('.jz_web_row');
                }
                const rowOffset = $row.offset();
                if (moduleOffset.top - rowOffset.top < 0) {
                    $row.animate(
                        {
                            scrollTop: moduleOffset.top - rowOffset.top + $row.scrollTop(),
                        },
                        300
                    );
                }
            } else {
                // 常规栏目手机视图，因素固定顶部
                if (this.$store.getters.isMobi) {
                    let $container = null;
                    let containerOffsetTop = 0;
                    const $fixedHeader = $('#gridMobiHeader');
                    let fixedHeaderHeight = 0;
                    if ($fixedHeader.hasClass('g_mobi_header__fixed')) {
                        fixedHeaderHeight = $fixedHeader.outerHeight();
                    }
                    if (this.$store.state.manageMode) {
                        $container = $('#jzPreviewContent');
                        containerOffsetTop = $container.offset().top;
                    } else {
                        $container = $('html');
                        containerOffsetTop = $('html').scrollTop();
                        const bodyScrollTop = $('body').scrollTop();
                        // 修复安卓微信使用的是 body 滚动
                        if (containerOffsetTop === 0 && bodyScrollTop !== 0) {
                            containerOffsetTop = bodyScrollTop;
                            $container = $('body');
                        }
                    }

                    if (moduleOffset.top - containerOffsetTop - fixedHeaderHeight < 0) {
                        $container.animate(
                            {
                                scrollTop:
                                    moduleOffset.top - containerOffsetTop - fixedHeaderHeight + $container.scrollTop(),
                            },
                            300
                        );
                    }
                } else {
                    // 常规栏目电脑视图，因素: 固定顶部 + 管理态 topBar
                    let manageModeTopBarHeight = 0;
                    if (this.manageMode) {
                        manageModeTopBarHeight = $('#jzToolbar').outerHeight();
                    }
                    let fixedHeaderHeight = 0;
                    const $fixedHeader = $('.header_content');
                    if ($fixedHeader.hasClass('g_header_content__fixed')) {
                        fixedHeaderHeight = $fixedHeader.outerHeight();
                    }
                    let topHeight = manageModeTopBarHeight + fixedHeaderHeight;
                    if (moduleOffset.top - topHeight < $(window).scrollTop()) {
                        $('html').animate(
                            {
                                scrollTop: moduleOffset.top - topHeight,
                            },
                            300
                        );
                    }
                }
            }
        },
    },
    computed: {
        paginationCookieName() {
            return `module_${this.module.id}_page`;
        },
    },
};
