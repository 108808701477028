var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ChildrenCSS", [
    _vm._v("\n    " + _vm._s(_vm.contentPaddingCss) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }