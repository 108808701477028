var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.popupClass, attrs: { id: "loginPopup" } }, [
    _vm.pc
      ? _c(
          "div",
          { staticClass: "loginPopupContainer" },
          [
            _c("div", { staticClass: "close", on: { click: _vm.close } }),
            _vm._v(" "),
            _c("member-login", { attrs: { module: _vm.loginModuleData } }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "mobiLoginPopupContainer" },
          [
            _c("div", { staticClass: "header" }, [
              _c("i", {
                staticClass: "back faisco-icons-S000107",
                on: { click: _vm.close },
              }),
            ]),
            _vm._v(" "),
            _c("member-login", { attrs: { module: _vm.loginModuleData } }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }