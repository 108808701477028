var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pic_switch_btn_area clear_fix", class: _vm.btnTypeClass },
    [
      _c("a", {
        staticClass: "pic_arrow_btn pic_arrow_btn_prev",
        on: { click: _vm.prev },
      }),
      _vm._v(" "),
      _c("a", {
        staticClass: "pic_arrow_btn pic_arrow_btn_next",
        on: { click: _vm.next },
      }),
      _vm._v(" "),
      _vm._l(_vm.picData, function (item, index) {
        return !_vm.isPc || _vm.btnStyle == 0
          ? _c(
              "a",
              {
                staticClass: "pic_short_line_btn",
                class: _vm.shortLineClass(index),
                attrs: { index: index },
              },
              [_c("div", { staticClass: "pic_short_line" })]
            )
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }