var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fold_wrap" }, [
    _c(
      "div",
      {
        staticClass: "fold_title",
        on: {
          click: function ($event) {
            return _vm.onFold(!_vm.isFold)
          },
        },
      },
      [
        _vm._t("title"),
        _vm._v(" "),
        _c("MinusIcon", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isFold,
              expression: "isFold",
            },
          ],
          staticClass: "minus_icon",
        }),
        _vm._v(" "),
        _c("PlusIcon", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isFold,
              expression: "!isFold",
            },
          ],
          staticClass: "plus_icon",
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "foldContent",
        staticClass: "fold_content",
        staticStyle: { display: "none" },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }