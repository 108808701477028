import request from '@jz/request';

export function singlePub(data) {
    return request
        .post('/ajax/msgBoard_h.jsp?cmd=setWafCk_singlePub', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function delMsgBoard(data) {
    return request
        .post('/ajax/msgBoard_h.jsp?cmd=delWafCk_del', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function addMsgBoardComment(data) {
    return request
        .post('/ajax/msgBoard_h.jsp?cmd=addWafCk_add', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getCommentList(data) {
    return request
        .get('/rajax/msgBoard_h.jsp?cmd=getWafNotCk_getCommentList', {
            params: data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}
