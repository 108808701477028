export default {
    computed: {
        showTitle() {
            if (this.isAllMaskStyle3) {
                return this.module.commProp.pa.pf.y !== 1;
            }

            let isShow = this.module.privatePattern.pc.cmdd !== 0;
            if (this.isMobi) {
                isShow = this.module.privatePattern.mobi.cmdd !== 0;
                if (this.module.privatePattern.mobi.cmdd == 1) {
                    isShow = this.module.privatePattern.pc.cmdd !== 0;
                }
            }
            return isShow;
        },
        showDesc() {
            let isShow = this.module.privatePattern.pc.d2f.y !== 1;
            if (this.isMobi) {
                isShow = this.module.privatePattern.mobi.d2f.y !== 1;
                if (this.module.privatePattern.mobi.d2f.y === 0) {
                    isShow = this.module.privatePattern.pc.d2f.y !== 1;
                }
            }
            return isShow;
        },
    },
};
