var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audio_controller" },
    [
      _c("Slider", {
        staticClass: "progress_slider",
        on: { change: _vm.onDurationChange },
        model: {
          value: _vm.durationProgressValue,
          callback: function ($$v) {
            _vm.durationProgressValue = $$v
          },
          expression: "durationProgressValue",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "time_container" }, [
        _c("div", { staticClass: "current_time" }, [
          _vm._v(_vm._s(_vm.currentTime)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "audio_duration" }, [
          _vm._v(_vm._s(_vm.duration)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "controller" }, [
        _c("div", { staticClass: "controller_left" }, [
          !_vm.isMobi
            ? _c("div", { staticClass: "volume_wrap" }, [
                _c("svg", { staticClass: "volume" }, [
                  _c("use", { attrs: { "xlink:href": "#icon_volume" } }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "volume_manage" }, [
                  _c(
                    "div",
                    { staticClass: "volume_slider" },
                    [
                      _c("Slider", {
                        attrs: { vertical: "" },
                        on: { change: _vm.onVolumeChange },
                        model: {
                          value: _vm.volumeValue,
                          callback: function ($$v) {
                            _vm.volumeValue = $$v
                          },
                          expression: "volumeValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "volume_value" }, [
                    _vm._v(_vm._s(_vm.volumeValue + "%")),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "controller_center" }, [
          _c(
            "svg",
            {
              staticClass: "back_15",
              on: {
                click: function ($event) {
                  return _vm.jumpSecond("back")
                },
              },
            },
            [_c("use", { attrs: { "xlink:href": "#icon_back15" } })]
          ),
          _vm._v(" "),
          _c(
            "svg",
            { staticClass: "status", on: { click: _vm.onStatusChange } },
            [
              _c("use", {
                attrs: {
                  "xlink:href": !_vm.pause ? "#icon_pause" : "#icon_play",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "go_15",
              on: {
                click: function ($event) {
                  return _vm.jumpSecond("forward")
                },
              },
            },
            [_c("use", { attrs: { "xlink:href": "#icon_go15" } })]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "controller_right" }, [
          _c(
            "div",
            { staticClass: "speed" },
            [
              _vm.isMobi
                ? [
                    _c("div", { on: { click: _vm.toggleSpeed } }, [
                      _vm._v(_vm._s(_vm.mobiSpeed)),
                    ]),
                  ]
                : [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.speed === "1" ? "倍数" : _vm.speed + "X")
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "speed_manage" }, [
                      _c(
                        "div",
                        { staticClass: "speed_list" },
                        _vm._l(_vm.speedList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item,
                              staticClass: "speed_item",
                              class: {
                                "speed_item--active": _vm.speed === item,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onSpeedChange(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item) +
                                  "X\n                            "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.audioUrl
        ? _c("audio", {
            ref: "audio",
            attrs: { src: _vm.audioUrl },
            on: {
              canplay: _vm.onCanplay,
              timeupdate: _vm.onTimeUpdate,
              ended: _vm.onEnded,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }