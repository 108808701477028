import { computed, onMounted } from 'vue';

export const useCheckLogin = (module) => {
    const isLogin = computed(() => module.memberId > 0);

    onMounted(() => {
        if (module.memberId === 0) {
            window.location.href = module.loginUrl;
        }
    });

    return { isLogin };
};
