var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { class: _vm.descClass, style: _vm.descWrapStyle }, [
        _vm.showTitleNode
          ? _c("div", {
              staticClass: "list_photo_item_title",
              style: _vm.titleStyle,
              domProps: { innerHTML: _vm._s(_vm.titleText) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showDescNode
          ? _c("div", {
              staticClass: "list_photo_item_desc",
              style: _vm.titleDesc,
              domProps: { innerHTML: _vm._s(_vm.descText) },
            })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }