var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "member_bought_wrap" },
    [
      _c(
        "div",
        { staticClass: "member_bought_list" },
        _vm._l(_vm.boughtList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "b_item",
              on: {
                click: function ($event) {
                  return _vm.goProduct(item)
                },
              },
            },
            [
              _c("div", { staticClass: "b_content" }, [
                _c("div", { staticClass: "b_left" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "lazyload2",
                          rawName: "v-lazyload2",
                          value: {
                            src: item.picPath,
                          },
                          expression:
                            "{\n                            src: item.picPath,\n                        }",
                        },
                      ],
                      staticClass: "b_image",
                    },
                    [
                      _c("div", { staticClass: "b_tag" }, [
                        _vm._v(_vm._s(_vm.getTag(item))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "b_title" }, [
                    _vm._v(_vm._s(item.productName)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "b_right" }, [
                  _c("span", { staticClass: "b_price_s b_price_text" }, [
                    _vm._v("实付款"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "b_price_s b_price_red" }, [
                    _vm._v("￥"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "b_price_red b_price" }, [
                    _vm._v(_vm._s(item.price)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "b_bottom" }, [
                _c("span", { staticClass: "b_order_id" }, [
                  _vm._v("订单编号：" + _vm._s(item.code)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "b_time" }, [
                  _vm._v("下单时间：" + _vm._s(_vm.formatTime(item.payTime))),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("pagination-component", {
        key: _vm.paginationPageCount,
        attrs: {
          "module-id": 8,
          "page-count": _vm.paginationPageCount,
          "current-page": _vm.currentPage,
          "page-size": _vm.pageSize,
          "style-index": 1,
        },
        on: {
          "update:currentPage": function ($event) {
            _vm.currentPage = $event
          },
          "update:current-page": function ($event) {
            _vm.currentPage = $event
          },
          currentChange: _vm.handlePaginationPageChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }