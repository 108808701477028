
const regA = /<i>/g;
const regB = /<\/i>/g;
const regC = /<!---->/g;

export default {
    name: 'DynamicCSS',
    destroyed() {
        this.refreshCSS();
    },
    updated() {
        this.refreshCSS();
    },
    mounted() {
        this.refreshCSS();
    },
    methods: {
        /* 刷新CSS */
        async refreshCSS() {
            // 合并刷新请求，返回需要等待的promise
            if (this.isRefresh) return this.refreshPromise;
            // 标记进入刷新状态
            this.isRefresh = true;

            // 通过等待微任务合并刷新请求
            this.refreshPromise = this.$nextTick();
            if (this.init) {
                // 初始化时不用等待
                // 要马上出效果
                await this.refreshPromise;
            }
            this.init = true;

            // 不处于刷新状态但是进来了
            if (!this.isRefresh) return;
            // 标置已进入刷新状态
            this.isRefresh = false;

            let result = this.$el.innerText;
            result = result.replace(regA, '');
            result = result.replace(regB, '');
            result = result.replace(regC, '');

            // 样式没有更新
            if (result == this.resultCache) return;

            // 缓存结果
            this.resultCache = result;
            // 触发事件，请求更新真实样式表
            this.$emit('on-update', this.resultCache);
        },
        /* 获取当前结果 */
        async getResult() {
            // 计算样式
            await this.refreshCSS();
            // 返回结果
            return this.resultCache;
        },
    },
    render(h) {
        if (this.$isServer) {
            return h('i', this.$slots.default);
        } else {
            return h('style', this.$slots.default);
        }
    },
};
