import { mapState } from 'vuex';
import { setLinkForm } from '@/api/form/index.js';
import { store } from '@/store/store.js';
import { ImageComponent } from '@jz/biz-shared';
/**
 * 创建个弹窗
 * @param {String} content 提示语内容
 * @param {Boolean} autoHide 是否自动关闭
 * @returns {any} createPopup的返回结果
 */
class _PopupWin {
    constructor(opts) {
        this.id = 'p_popup_win';
        this.width = opts.width || 900;
        this.height = opts.height || 588;
        this.$ele = '';
        this.isPc = Comm.getDevice() == 'pc';
        this.myComponent = opts.component || '';
        this.module = opts.module || {};
        this.propsData = opts.props || {};
        this.options = opts.options || {};

        this.init();
    }

    init() {
        this.createPopupRoot();
    }

    // 创建根节点
    createPopupRoot() {
        if ($('.p_popup_win').length != 0) {
            $('.p_popup_win').remove();
        }
        let html = `<div class='p_popup_win popup_in_${Comm.getDevice()}'><div id='${this.id}'></div></div>`;
        if (window._store.state.manageMode && !this.isPc) {
            $(html).css({ position: 'absolute', left: 0, right: 0 }).insertAfter($('.jz_preview_content'));
        } else if (!window._store.state.manageMode && !this.isPc) {
            $(html).appendTo($('body'));
        } else {
            $(html).prependTo($('body'));
        }

        this.$ele = $(html);

        this.newVue = this.buildVue();
    }

    buildVue() {
        let vm, that;

        that = this;
        vm = new Vue({
            el: '#' + this.id,
            data() {
                return {
                    width: that.width,
                    height: that.height,
                    isPc: that.isPc,
                    showPopup: true,
                    module: that.module,
                    myComponent: that.component,
                    manageMode: window._store.state.manageMode,
                    panelData: Fai.top.linkFormList[that.module.prop2],
                    showEditToolBtn: true,
                    showSaveBtn: false,
                    canSavePopup: false,
                    clonePanelDate: { submitName: '', popupName: '' },
                    editPanelConfig: {},
                };
            },
            mounted() {
                let $popupWin = $('.p_popup_container'),
                    panelOffset = $popupWin.offset(),
                    panelTop,
                    panelLeft,
                    panelWidth;

                panelTop = panelOffset.top;
                panelLeft = panelOffset.left;
                panelWidth = $popupWin.width();
                this.editPanelConfig = {
                    type: 'linkForm',
                    active: true,
                    top: panelTop - $(document).scrollTop() - 58,
                    left: panelLeft + panelWidth + 30,
                    height: 740,
                    width: 380,
                    data: this.panelData,
                };
            },
            methods: {
                stopOutSideScroll(e) {
                    if (this.isMultiPdInquiry && !this.isPc) {
                        return;
                    }
                    let innerHeight = $('.popup_inner_content').height(),
                        outsideHeiht = $('.popup_content').height(),
                        scrollRange = innerHeight - outsideHeiht,
                        scrollTop = $('.popup_content').scrollTop(),
                        isUp = e.wheelDelta > 0;

                    if (scrollTop == 0 && isUp) {
                        // 往上滚动 到顶
                        e.preventDefault();
                    } else if (scrollTop == scrollRange && !isUp) {
                        // 往下滚动 ， 到底
                        e.preventDefault();
                    } else if (innerHeight < outsideHeiht) {
                        // 内部区域低于容器高度，禁用滚动事件。
                        e.preventDefault();
                    }
                },
                closePopup() {
                    this.showPopup = false;
                    this.exitEdit();
                    $('.p_popup_win').remove();
                    $('.select_list_wrap').remove();
                    this.$destroy();
                },
                editFormPanel() {
                    // 进入编辑态
                    this.enterEdit();
                    window._store.commit('manage/editPanel/editPanelChange', this.editPanelConfig);
                    this.promoteEditPanelIndex(true);
                    this.cloneOldData();
                },
                manageForm() {
                    if (!this.manageMode) {
                        return;
                    }
                    if (!window._store.state.manage.auth.isFormManage) {
                        Vue?.$famessage?.warning('没有权限操作', 0, null, true);
                        return;
                    }

                    showTopManageFrame('manageFrameFormManage', `formid=${this.module.prop2}`);
                },
                promoteEditPanelIndex(isPromote) {
                    // 提升编辑弹窗的层级
                    if (!this.manageMode) {
                        return;
                    }
                    let $toolContainer = $('.jz_tool_container');
                    if (isPromote) {
                        // 提高层级会导致4个按钮也会一并提升。所以将它们隐藏掉
                        $toolContainer.css('z-index', 9032).find('#funcPanel').hide();
                    } else {
                        $toolContainer.css('z-index', '');
                        setTimeout(() => {
                            // 动画结束后，在显示
                            $toolContainer.find('#funcPanel').show();
                        }, 301);
                    }
                    //isPromote ? $toolContainer.css("z-index", 9032) : $toolContainer.css("z-index", "");
                },
                cloneOldData() {
                    // 备份数据
                    this.clonePanelDate.submitName = this.panelData.prop1;
                    this.clonePanelDate.popupName = this.panelData.other.p.n;
                    this.clonePanelDate.btnPattern = JSON.parse(JSON.stringify(this.panelData.other.btnPattern));
                },
                revertSaveData() {
                    // 还原数据
                    this.panelData.prop1 = this.clonePanelDate.submitName;
                    this.panelData.other.p.n = this.clonePanelDate.popupName;
                    this.panelData.other.btnPattern = this.clonePanelDate.btnPattern;
                },
                popupSaveEdit() {
                    if (!this.canSavePopup) {
                        return;
                    }
                    let formId = this.module.prop2,
                        popupName = this.panelData.other.p.n.trim(),
                        submitName = this.panelData.prop1.trim(),
                        linkIde = this.panelData.other.p.li,
                        _that = this;
                    let btnPattern = this.panelData.other.btnPattern;

                    if (!popupName) {
                        Vue?.$famessage?.warning('请输入弹窗名称', 3, null, true);
                        _that.canSavePopup = false;
                        return;
                    }
                    if (!submitName) {
                        Vue?.$famessage?.warning('请输入提交表单按钮', 3, null, true);
                        _that.canSavePopup = false;
                        return;
                    }
                    setLinkForm({
                        formId: formId,
                        popupName: popupName,
                        submitName: submitName,
                        linkIde: linkIde,
                        btnPattern: JSON.stringify(btnPattern),
                        isBlank: this.panelData.other.p.ib,
                    }).then((response) => {
                        if (response.success) {
                            Vue?.$famessage?.success('表单修改成功', 3, null, true);
                            _that.canSavePopup = false;
                            _that.panelData.buttonName = submitName;
                            _that.exitEdit();
                        }
                    });
                },
                popupCanel() {
                    // 取消 -- 还原数据
                    this.revertSaveData();
                    this.exitEdit();
                },
                exitEdit() {
                    this.dealWithEdit(true);
                    this.promoteEditPanelIndex(false);
                },
                enterEdit() {
                    this.dealWithEdit(false);
                },
                dealWithEdit(isExit = true) {
                    this.showEditToolBtn = isExit;
                    this.showSaveBtn = !isExit;
                    this.editPanelConfig.active = !isExit;
                    window._store.commit('manage/editPanel/editPanelChange', this.editPanelConfig);
                },
                enterEditByFormSubmitBtn() {
                    this.showSaveBtn = true;
                    this.cloneOldData();
                },
                deleteInquiryProduct(index) {
                    this.inquiryProductList.splice(index, 1);
                    localStorage.setItem(
                        'inquiry_product_list',
                        JSON.stringify(this.inquiryProductList.map((inquiryProduct) => inquiryProduct.id))
                    );
                },
            },
            template: `
                <div class='p_popup_wrap' v-if="showPopup" :style="{ zIndex: manageMode ? 9031 : null }" :class="{ 'inquiry_product_link_popup': isMultiPdInquiry }">
                    <div class='p_popup_layout' @mousewheel.prevent></div>
                    <div class='p_popup_container' :style="containerStyle">
                        <div class='popup_tool_tab' v-if="manageMode && showEditToolBtn">
                            <div class='tool_btn eidt_tool' @click="editFormPanel"><i class='btn_icon'></i><span class='btn_text'>编辑</span></div>
                            <div class='tool_btn_split'></div>
                            <div class='tool_btn manage_tool' @click="manageForm"><i class='btn_icon'></i></div>
                        </div>
                        <div class='popup_title' @mousewheel.stop.prevent>
                            <span class='title_text'>{{panelData.other.p.n}}</span>
                            <i class="popup_close i_close faisco-icons-S000118" @click="closePopup"></i>
                        </div>
                        <div class='link_popup_content_wrap' >
                            <div class='popup_content link_popup'>
                                <div class='popup_inner_content'>
                                    <cusComponent></cusComponent>
                                </div>
                            </div>
                            <div v-if="isMultiPdInquiry && !isPc" style="height: 10px;flex-shrink: 0;background-color: #f8f8f8;"> </div>
                            <div v-if="isMultiPdInquiry" class="inquiry_product_area">
                                <div class="inquiry_product_list_title">${LS.module_product}</div>
                                <div class="inquiry_product_list">
                                    <div v-for="(inquiryProduct,index) in inquiryProductList" class="inquiry_product_item">
                                        <ImageComponent
                                            v-lazyload2="{
                                            id: module.id,
                                            src: inquiryProduct.picPath,
                                        }"
                                            :src="inquiryProduct.picPath"
                                            :webp="webpOptOpen"
                                            class="inquiry_product_img"
                                        >
                                        </ImageComponent>
                                        <div class="inquiry_product_info">
                                            <span class="inquiry_product_name">{{inquiryProduct.name}}</span>
                                            <span class="inquiry_product_delete" v-if="inquiryProductList.length > 1" @click="deleteInquiryProduct(index)">${LS.g_del}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='popup_save_wrap' v-if="manageMode && showSaveBtn">
                            <button-component class='popup_button' global-oper :active="canSavePopup" :disabled="!canSavePopup"  @click='popupSaveEdit'>保 存</button-component>
                            <button-component class='popup_button' global-oper @click='popupCanel'>取 消</button-component>
                        </div>
                    </div>
                </div>
            `,
            store,
            ...mapState(['device']),
            computed: {
                containerStyle() {
                    let width = this.isPc ? this.width + 'px' : '100%;';
                    let height = this.isPc ? this.height + 'px' : '100%;';
                    return {
                        width,
                        height,
                    };
                },
                inquiryProductList() {
                    return this.module.inquiryProductList || [];
                },
                isMultiPdInquiry() {
                    return window._store.state.openMultiPdInquiry && this.module.fromBtn;
                },
                webpOptOpen() {
                    return window._store.state.webpOptOpen;
                },
            },
            components: {
                cusComponent: {
                    render(createElement) {
                        return createElement(that.myComponent, {
                            props: that.propsData,
                        });
                    },
                    provide() {
                        return {
                            isMaterialPreview: false,
                        };
                    },
                },
                ImageComponent,
            },
            watch: {
                panelData: {
                    deep: true,
                    handler() {
                        this.canSavePopup = true;
                    },
                },
            },
            destroyed() {
                PopupWin.newPopup = null;
            },
        });

        return vm;
    }
}
export const PopupWin = function (...args) {
    let newPopupWin = new _PopupWin(...args);
    PopupWin.newPopup = newPopupWin;
    return newPopupWin;
};
