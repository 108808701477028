import request from '@jz/request';

export function createOrder(data) {
    return request.post('/api/guest/jzEcommerce/settleOrder', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function memberSetOrder(data) {
    return request.post('/api/guest/jzEcommerce/memberSetOrder', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function checkOrderFinPay(params) {
    return request.get('/api/guest/jzEcommerce/orderFinPay', { params }).then(({ data = {} }) => {
        return data;
    });
}
