import { DomMutationObserver } from './MutationObserver';
import { context } from '@jz/utils';
if (!context._DomMutationObserverMap) {
    context._DomMutationObserverMap = new Map();
}
export const DomMutationObserverMap = context._DomMutationObserverMap;
export const initDomMutationObserver = (config) => {
    try {
        if (DomMutationObserverMap.get(config.id)) {
            return DomMutationObserverMap.get(config.id);
        }
        DomMutationObserverMap.set(config.id, new DomMutationObserver(config));
    } catch (error) {
        console.log(error);
    }
};
