var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "column_content" },
    [
      !_vm.isBought
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.tipsHtml) } })
        : _c("ColumnProductList", {
            attrs: { list: _vm.productList },
            on: { "last-item-show": _vm.getNextPageData },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }