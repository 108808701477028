export const urlMixin = {
    methods: {
        /**
         *
         * @param {string} url
         */
        getUrl(href, groupId) {
            const STYLE_PRODUCT_NAV = 18;
            if (this.manageMode && this.module.style === STYLE_PRODUCT_NAV) {
                return (
                    Site.preprocessUrl({ path: '', oldPath: '' }) +
                    `pr.jsp?groupId=${groupId}&libId=${this.module.prop4}`
                );
            }
            return href;
        },
    },
};
