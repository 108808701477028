import request from '@jz/request';

export function checkFaiDomain(data) {
    return request.post('/ajax/siteDomain_h.jsp?cmd=wafNotCk_checkFaiDomain', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getLimitPreview(data) {
    return request.post('/ajax/site_h.jsp?cmd=getWafNotCk_getLimitPreview', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function timeDataNoAuth(data) {
    return request.post('/ajax/statistics_h.jsp?cmd=getWafNotCk_timeDataNoAuth', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function visited(data) {
    return request.post('/ajax/statistics_h.jsp?cmd=wafNotCk_visited', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function visitTime(data) {
    return request.post('/ajax/statistics_h.jsp?cmd=wafNotCk_visitTime', { data }).then(({ data = {} }) => {
        return data;
    });
}
