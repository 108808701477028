import { browser } from '@jz/utils';
import { JzFdpLog } from '@/site/shared/log/index.js';
import { getWeixinAppScheme, getWeiXinShareData } from '@api/wx/index.js';
import { warningMessage } from '@/site/shared/dialog/index.js';
import { asyncLoad } from '@jz/utils';

/**
 * 初始化微信分享
 */
const wxShare = function () {
    let _isWechat = browser.isWeChat();

    if (!_isWechat) {
        // 允许微信分享且是微信，才放行
        return;
    }
    const { shareDes, shareTit, shareIconPath } = window._store.state.wx.wxShareInfo ?? {};
    const wxShareUrl = window.location.href;
    const desc = shareDes?.split?.('<br/>')?.join?.('\r\n');
    const wxShareInfo = {
        title: shareTit,
        desc,
        link: wxShareUrl,
        imgUrl: shareIconPath,
        success() {
            bizShared?.Clue?.logClueShareNewsAction();
        },
    };
    initWxConfig()
        .then(() => {
            if (!window._store.state.wx.allowedWXShare) {
                const info = {
                    success() {
                        bizShared?.Clue?.logClueShareNewsAction();
                    },
                };
                if (window._store.state.colId == 7) {
                    if (window.wx.updateAppMessageShareData) {
                        //分享给朋友
                        window.wx.updateAppMessageShareData(info);
                    }
                    //分享给朋友
                    window.wx.onMenuShareAppMessage(info);
                    if (window.wx.updateTimelineShareData) {
                        //分享到朋友圈
                        window.wx.updateTimelineShareData(info);
                    }
                    //分享到朋友圈
                    window.wx.onMenuShareTimeline(info);
                    //分享到QQ
                    window.wx.onMenuShareQQ(info);
                    //分享到微博
                    window.wx.onMenuShareWeibo(info);
                }
            } else {
                if (window.wx.updateAppMessageShareData) {
                    //分享给朋友
                    window.wx.updateAppMessageShareData(wxShareInfo);
                }
                //分享给朋友
                window.wx.onMenuShareAppMessage(wxShareInfo);
                if (window.wx.updateTimelineShareData) {
                    //分享到朋友圈
                    window.wx.updateTimelineShareData(wxShareInfo);
                }
                //分享到朋友圈
                window.wx.onMenuShareTimeline(wxShareInfo);
                //分享到QQ
                window.wx.onMenuShareQQ(wxShareInfo);
                //分享到微博
                window.wx.onMenuShareWeibo(wxShareInfo);
            }
        })
        .catch((err) => {
            if (err && err.type != -1) {
                console.log(err);
            }
        });
};

/**
 * 跳转小程序的埋点
 */
const jumpWxAppFdpLog = ({ type = 0, isErrorInWx = false }) => {
    const isWeixin = browser.isWeChat();
    const isWeixinPc = browser.isWindowsWechat();
    const isPc = browser.isPc();
    const isInMiniProgram = browser.isWechatMiniProgram();
    const isInMobiWechat = isWeixin && !isWeixinPc;
    let environment = '';
    if (isPc) {
        environment = 'pc';
    } else if (isInMiniProgram) {
        environment = '微信小程序';
    } else if (isInMobiWechat) {
        environment = '微信';
    } else {
        environment = '移动端外网';
    }
    JzFdpLog.logJzFdp('jz_fktzxcx', {
        jz_free_text1: type === 0 ? '外部小程序' : '轻站小程序',
        jz_content_terminal: '自适应',
        jz_free_bool1: isErrorInWx,
        jz_version: Fai.top.window._store.state.siteVer || 0,
        jz_domain: window.location.href,
        jz_free_text0: environment,
    });
};
/**
 * 获取认证微信的签名等等信息
 * @param {Function} callBack - 回调
 */
function getWxShareData(callBack) {
    var wxShareData = {};
    getWeiXinShareData({
        url: Fai.encodeUrl(document.location.href?.split('#')?.[0] ?? document.location.href),
    }).then((data) => {
        if (data.success) {
            wxShareData.noncestr = data.noncestr;
            wxShareData.timestamp = data.timestamp;
            wxShareData.signature = data.signature;
            callBack(wxShareData);
        } else {
            console.error('getWxShareData request error');
        }
    });
}
/**
 * 初始化微信功能
 * @return { Promise } - 初始化是否完成
 */
const initWxConfig = function () {
    return (
        initWxConfig.p ||
        (initWxConfig.p = new Promise((resolve, reject) => {
            const isWeixin = browser.isWeChat();
            if (!isWeixin) {
                reject({ type: -1, msg: '不是微信浏览器' });
                return;
            }
            if (typeof window._store.state.wx.wxShareInfo !== 'object') {
                reject({
                    type: -2,
                    msg: '缺失window._store.state.wx.wxShareInfo',
                });
                return;
            }
            asyncLoad.loadJs('//res.wx.qq.com/open/js/jweixin-1.6.0.js', () => {
                const { appId, openWxDebug } = window._store.state.wx.wxShareInfo;
                getWxShareData(({ timestamp, noncestr, signature }) => {
                    window.wx.config({
                        debug: openWxDebug,
                        appId,
                        timestamp,
                        nonceStr: noncestr,
                        signature,
                        jsApiList: [
                            'onMenuShareTimeline',
                            'onMenuShareAppMessage',
                            'onMenuShareQQ',
                            'onMenuShareWeibo',
                            'updateAppMessageShareData',
                            'updateTimelineShareData',
                        ],
                        openTagList: ['wx-open-launch-weapp'],
                    });
                    window.wx.ready(function () {
                        resolve();
                    });
                    window.wx.error(function (...args) {
                        console.error('wx.error: ', ...args);
                        reject({ type: -3, msg: 'wxVerifyError' });
                    });
                });
            });
        }))
    );
};
/**
 * 初始化跳转小程序
 * @param {Number} moduleId - 模块id
 * @param {Object} jumpWxAppData - 初始化跳转小程序的数据
 */
const initJumpWxApp = function (moduleId, jumpWxAppData) {
    if (window._store.state.manageMode) {
        return;
    }

    let jumpAppPath = '';
    let jumpAppId = '';
    let { type = 0, qzOriginalId = '', qzWxAppPath = '', id, path = '', qzAppId = '' } = jumpWxAppData;

    if (type === 0) {
        jumpAppId = id;
        jumpAppPath = path;
    } else if (type === 1) {
        jumpAppId = qzOriginalId;
        jumpAppPath = qzWxAppPath;
    }

    if (jumpAppPath !== '' && !jumpAppPath.includes('.html')) {
        if (jumpAppPath.includes('?')) {
            jumpAppPath = jumpAppPath.replace(/(.*?)(\?)/g, '$1.html$2');
        } else {
            jumpAppPath += '.html';
        }
    }

    const $module = $(`#module${moduleId}`);

    if ($module.length == 0) {
        console.error('initJumpWxApp: 找不到模块');
        return;
    }

    const addEmptyWrap = ({ clickNoEvent = false } = {}) => {
        if (browser.isPc()) {
            $module.on('click', 'a, .J_jumpWxApp', function (e) {
                e.preventDefault();
                e.stopPropagation();
                if (clickNoEvent) {
                    return;
                }
                warningMessage('请在手机微信端打开链接');
            });
            return;
        }
        let ajaxLoading = false;
        $('<div class="wxapp_launch_btn_wrap"></div>')
            .appendTo($module)
            .click(function (e) {
                jumpWxAppFdpLog({
                    type,
                    isErrorInWx: false,
                });
                e.stopPropagation();

                if (clickNoEvent) {
                    return;
                }

                // 不在移动端微信并且不在pc端中，弹出提示
                if (type === 1) {
                    // 轻站小程序，请求后端是否能直接跳转微信并打开小程序
                    if (ajaxLoading) {
                        return;
                    }

                    ajaxLoading = true;
                    getWeixinAppScheme({
                        appid: qzAppId,
                        pageUrl: qzWxAppPath,
                    })
                        .then((data) => {
                            const { success = false, openlink = '' } = data.data;

                            if (success && openlink) {
                                window.location.href = openlink;
                            } else {
                                warningMessage('请在手机微信端打开链接');
                            }

                            ajaxLoading = false;
                        })
                        .catch(() => {
                            warningMessage('请在手机微信端打开链接');
                            ajaxLoading = false;
                        });
                } else {
                    warningMessage('请在手机微信端打开链接');
                }
            });
    };

    if (!window._store.state.wx.isAllowJumpWxApp) {
        // 不够版本不提示
        addEmptyWrap({
            clickNoEvent: true,
        });
        return;
    }

    initWxConfig()
        .then(() => {
            // 电脑pc端微信也暂时不支持跳转小程序，给出提示
            if (browser.isWindowsWechat()) {
                addEmptyWrap({
                    clickNoEvent: false,
                });
                return;
            }

            const wrapId = `launchBtnWrap${moduleId}`;
            const wxAppBtnHtml = `
                <div id="${wrapId}" class="wxapp_launch_btn_wrap">
                    <wx-open-launch-weapp
                        class="wxapp_launch_btn"
                        id="launchBtn${moduleId}"
                        username="${jumpAppId}"
                        path="${jumpAppPath}"
                    >
                        <template>
                            <div style="width: 9999px; height: 9999px;">&nbsp;</div>
                        </template>
                    </wx-open-launch-weapp>
                </div>
            `;
            $(wxAppBtnHtml).appendTo($module);
            $(`#${wrapId}`).click(function (e) {
                e.stopPropagation();
            });
            var btn = document.getElementById(`launchBtn${moduleId}`);
            btn.addEventListener('launch', () => {
                jumpWxAppFdpLog({
                    type,
                    isErrorInWx: false,
                });
            });
            btn.addEventListener('error', () => {
                jumpWxAppFdpLog({
                    type,
                    isErrorInWx: true,
                });
            });
        })
        .catch((err) => {
            // 如果不是微信浏览器给出提示
            let isNotInWechatPc = typeof err === 'object' && err.type === -1;

            if (!isNotInWechatPc) {
                console.log(err);
            }

            addEmptyWrap({
                clickNoEvent: !isNotInWechatPc,
            });
        });
};

export { wxShare, initJumpWxApp, initWxConfig };
