var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file_upload_container" },
    [
      _c(
        "div",
        {
          ref: "uploadBtn2",
          staticClass: "uploadify-button2",
          class: _vm.uploadBtnClass,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.uploadClick.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.uploadFileBtnText) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.uploadPreviewFileList.length,
              expression: "uploadPreviewFileList.length",
            },
          ],
          staticClass: "preview_file_container",
        },
        [
          _c(
            "ul",
            {
              staticClass: "preview_file_list",
              class: _vm.previewFileListClass,
            },
            _vm._l(_vm.uploadPreviewFileList, function (fileItem) {
              return _c(
                "li",
                {
                  key: fileItem.key,
                  staticClass: "preview_file_item",
                  attrs: { id: _vm.fileItemId(fileItem) },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "preview_file_item_box preview_file_item_content",
                    },
                    [
                      fileItem.fileType == "image"
                        ? _c("img", {
                            staticClass:
                              "preview_file_item_box--img preview_file_item_box--icon",
                            attrs: { src: fileItem.src, alt: fileItem.title },
                            on: {
                              click: function ($event) {
                                return _vm.openPhotoSlidesshow(fileItem.key)
                              },
                            },
                          })
                        : _c(
                            "i",
                            {
                              staticClass:
                                "preview_file_item_box--icon preview_file_item_box--other",
                              class: _vm.fileIconClass(fileItem.fileType),
                            },
                            [
                              _c("svg", { staticClass: "file_icon" }, [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": _vm.currentFileIcon(
                                      fileItem.fileType
                                    ),
                                  },
                                }),
                              ]),
                            ]
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "preview_file_item--container preview_file_item_content",
                    },
                    [
                      !_vm.isError(fileItem.status)
                        ? _c("p", { staticClass: "preview_file_item_title" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(fileItem.title || "") +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isLoading(fileItem.status),
                              expression: "isLoading(fileItem.status)",
                            },
                          ],
                          staticClass: "preview_file_item--progress",
                        },
                        [
                          _c("div", { staticClass: "jz_progress_line" }, [
                            _c("div", { staticClass: "jz_progress_outer" }, [
                              _c("div", { staticClass: "jz_progress_inner" }, [
                                _c("div", {
                                  staticClass: "jz_progress_bg",
                                  style: _vm.progressBgStyle(fileItem.percent),
                                }),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isSuccess(fileItem.status),
                              expression: "isSuccess(fileItem.status)",
                            },
                          ],
                          staticClass: "preview_file_item_des",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.previewSize(fileItem.size)) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isError(fileItem.status),
                              expression: "isError(fileItem.status)",
                            },
                          ],
                          staticClass: "preview_file_item_error",
                        },
                        [_vm._v("上传错误！")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "preview_file_item--action preview_file_item_content",
                    },
                    [
                      _c(
                        "svg",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isError(fileItem.status),
                              expression: "isError(fileItem.status)",
                            },
                          ],
                          staticClass: "preview_file_item_refresh action_icon",
                          on: {
                            click: function ($event) {
                              return _vm.retryUpload(fileItem.key)
                            },
                          },
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon_refresh" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "svg",
                        {
                          staticClass: "preview_file_item_del action_icon",
                          on: {
                            click: function ($event) {
                              return _vm.delUploadFile(fileItem.key)
                            },
                          },
                        },
                        [_c("use", { attrs: { "xlink:href": "#icon_delete" } })]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "file_size_tips" }, [
        _vm._v(_vm._s(_vm.noteTips) + _vm._s(_vm.fileLimitTips)),
      ]),
      _vm._v(" "),
      _c("image-main-viewer", {
        attrs: {
          "show-mobi-viewer": _vm.showMobiViewer,
          "show-pc-viewer": _vm.showPcViewer,
          "z-index": _vm.zIndex,
          "initial-index": _vm.imageIndex,
          "on-close": _vm.closeViewer,
          "url-list": _vm.previewSrcList,
          "is-mobi": _vm.isMobi,
          "manage-mode": _vm.manageMode,
          "log-event-config": _vm.logEventConfig,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }