var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mall_order_detail_content" }, [
    _setup.orderNotFound
      ? _c("div", [_vm._v("order not found")])
      : _c("div", { staticClass: "mall_order_detail_wrap" }, [
          _c(
            "div",
            {
              staticClass: "mall_order_detail_info",
              class: {
                change_order_in_mobi: _setup.isChangeElementOrderInMobi,
              },
            },
            [
              _c(
                _setup.OrderDetailSection,
                [
                  _c(_setup.statusInfo.component, {
                    tag: "component",
                    attrs: { module: _vm.module },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _setup.isMobi
                ? _c(
                    _setup.OrderDetailSection,
                    { attrs: { title: _setup.LS.receivingInfo } },
                    [
                      _c(
                        "div",
                        { staticClass: "mall_prop_info_list_mobi" },
                        _vm._l(_setup.receiveGoodsValues, function (v, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "mall_prop_value_mobi" },
                            [_vm._v(_vm._s(v))]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                _setup.OrderDetailSection,
                {
                  staticClass: "mall_order_detail_order_info",
                  attrs: { title: _setup.LS.orderInfo },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mall_order_info_list" },
                    [
                      _vm._l(_setup.orderDisplayInfo, function (item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "mall_order_info_item" },
                          [
                            _c(
                              "div",
                              { staticClass: "mall_order_info_title" },
                              [_vm._v(_vm._s(item.title) + "：")]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "mall_order_info_value",
                              domProps: {
                                innerHTML: _vm._s(
                                  _setup.encodeHtml(item.value)
                                ),
                              },
                            }),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _setup.showParcelInfo
                        ? [
                            _c("div", {
                              staticClass: "mall_order_detail_split_line m0",
                            }),
                            _vm._v(" "),
                            _vm._l(_setup.parcelInfo, function (item, i) {
                              return _c(
                                "div",
                                {
                                  key: "p" + i,
                                  staticClass: "mall_order_info_item",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mall_order_info_title" },
                                    [_vm._v(_vm._s(item.title) + "：")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "mall_order_info_value",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _setup.encodeHtml(item.value)
                                      ),
                                    },
                                  }),
                                ]
                              )
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                _setup.OrderDetailSection,
                { attrs: { title: _setup.LS.orderDetailListTitle } },
                [
                  _c(_setup.ShoppingList, {
                    attrs: {
                      list: _setup.productList,
                      "can-modify": false,
                      "unit-info": _setup.unitInfo,
                    },
                  }),
                  _vm._v(" "),
                  !_setup.showTotal
                    ? [
                        _c("div", {
                          staticClass: "mall_order_detail_split_line",
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "mall_order_detail_footer_price_wrap",
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_setup.payText) +
                                  " "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_setup.addUnit(_setup.orderInfo.price))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _setup.showConfirmReceipt,
                                      expression: "showConfirmReceipt",
                                    },
                                  ],
                                  staticClass:
                                    "mall_order_detail_confirm_receipt",
                                  on: { click: _setup.confirmReceipt },
                                },
                                [_vm._v(_vm._s(_setup.LS.orderSureRpt))]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _setup.showTotal
            ? _c(
                "div",
                { staticClass: "mall_order_detail_total_wrap" },
                [
                  _c(
                    _setup.OrderDetailSection,
                    {
                      staticClass: "mall_order_detail_total",
                      attrs: { title: _setup.LS.total },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mall_order_detail_total_item" },
                        [
                          _c("span", [_vm._v(_vm._s(_setup.LS.productSum))]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_setup.addUnit(_setup.orderInfo.price))
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "mall_order_detail_split_line",
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mall_order_detail_total_item" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_setup.LS.shouldPayPrice)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "mall_order_detail_price" },
                            [
                              _vm._v(
                                _vm._s(_setup.addUnit(_setup.orderInfo.price))
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mall_order_detail_submit",
                          on: { click: _setup.payNow },
                        },
                        [_vm._v(_vm._s(_setup.LS.orderDetailSubmit))]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _setup.isMobi
            ? _c(
                "div",
                {
                  staticClass: "pay_success_back_mobi",
                  on: { click: _setup.goIndex },
                },
                [_vm._v(_vm._s(_setup.LS.rebackIndex))]
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }