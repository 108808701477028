
import { mapGetters } from 'vuex';

import FullScreenRow from './FullScreenRow.vue';

export default {
    name: 'jz-web-row-entry',
    components: {
        FullScreenRow,
    },
    props: [
        'row',
        'hideToolBar', //工具条中使用，误删
        'index',
        'cloneModuleId',
        'rowsLength',
    ],
    computed: {
        ...mapGetters(['isFullScreen']),
        wrapComponent() {
            return this.isFullScreen ? 'FullScreenRow' : 'jz-web-row';
        },
    },
    render(h) {
        const rowComponent = (props = {}) => {
            return h(this.wrapComponent, {
                //动态组件
                props: {
                    key: this.row.id,
                    row: this.row,
                    index: this.index,
                    'rows-length': this.rowsLength,
                },
                class: {
                    [props.class]: this.manageMode,
                },
            });
        };
        return this.manageMode ? (
            <DndDraggable
                renderless={true}
                {...{
                    scopedSlots: {
                        //slot 传参
                        default: rowComponent,
                    },
                }}
            ></DndDraggable>
        ) : (
            rowComponent()
        );
    },
};
