var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search_result_product" },
    [
      _vm.other.showResultType
        ? _c(
            "div",
            { staticClass: "search_result_title jz_theme_font_color" },
            [_vm._v(_vm._s(_vm.LS.searchResultProduct))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.list, function (item) {
        return _c(
          "div",
          {
            key: _vm.uniqueId(item.pid),
            staticClass: "exhibition_show_product result_calc_item",
            class: { exhibition_show_product_nopic: _vm.noPicStyle },
            style: _vm.productStyle,
          },
          [
            _c(
              "a",
              {
                staticClass:
                  "exhibition_show_product_wrap jz_hover jz_theme_font_hover_color jz_theme_border_hover_color",
                class: { border_bottom_transition: _vm.noPicStyle },
                attrs: {
                  href: _vm.setQueryString(item.url, "fromColId", _vm.colId),
                  target: "_blank",
                  onclick: _vm.createOnclickStr(item),
                },
              },
              [
                !_vm.noPicStyle
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "exhibition_product_pic_wrap result_pic_item_padding--bottom",
                          style: _vm.productSizeStyle,
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "lazyload2",
                                rawName: "v-lazyload2",
                                value: { id: _vm.module.id, src: item.picPath },
                                expression:
                                  "{ id: module.id, src: item.picPath }",
                              },
                            ],
                            staticClass:
                              "exhibition_product_pic_item result_pic_item--scale result_pic_background_default",
                            style: _vm.genPicItemStyle(item),
                          }),
                          _vm._v(" "),
                          _vm.getIsShowPayKnowTag(item)
                            ? _c("div", { staticClass: "pay_know_tag" }, [
                                _vm._v(_vm._s(_vm.getPayKnowTag(item))),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "exhibition_prop" }, [
                        _c(
                          "div",
                          { staticClass: "exhibition_product_name_wrap" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "result_highlight_node exhibition_product_name jz_hover jz_theme_font_hover_color",
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.name) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "result_highlight_node exhibition_product_name",
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      _vm._v(" "),
                      _vm.other.showClassification && _vm.someProductHasCategory
                        ? _c(
                            "div",
                            { staticClass: "category-wrapper" },
                            _vm._l(item.groups, function (groupItem) {
                              return groupItem
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "category-item",
                                      attrs: {
                                        href: _vm.setQueryString(
                                          groupItem.url,
                                          "fromColId",
                                          _vm.colId
                                        ),
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(groupItem.name) +
                                          "\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e()
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
              ]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }