var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search_result_photogroup module_photo_group_content",
      attrs: { id: "module" + _vm.moduleId },
    },
    [
      _vm.other.showResultType
        ? _c(
            "div",
            { staticClass: "search_result_title jz_theme_font_color" },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.LS.searchResultPhotoGroup) + "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "jz_scroll_wrap" }, [
        _c(
          "ul",
          { staticClass: "photo_display_area jz_scroll jz_scroll_only_x" },
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "li",
              {
                key: item.id,
                staticClass: "search_result_photogroup--item",
                class: { result_calc_item: !_vm.noPicStyle },
                style: _vm.photoItemStyle,
                attrs: { "data-id": item.id },
                on: {
                  click: function ($event) {
                    return _vm.photoSlide(index, item.id)
                  },
                },
              },
              [
                _vm.noPicStyle
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "search_result_photogroup--nopic" },
                        [
                          _c("div", { staticClass: "photogroup-content" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "photogroup_name result_highlight_node jz_hover jz_theme_font_hover_color",
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.name) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            item.groupId > 0
                              ? _c("div", { staticClass: "category-wrapper" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "photogroup-category category-item",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            item.groupInfo
                                              ? item.groupInfo.name
                                              : ""
                                          ) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ])
                  : _c(
                      "div",
                      {
                        staticClass:
                          "photo_group_item jz_hover jz_theme_font_hover_color",
                      },
                      [
                        _c("div", { staticClass: "photoWrapper" }, [
                          _c("div", { staticClass: "photo_small_layer" }),
                          _vm._v(" "),
                          _c("div", {
                            directives: [
                              {
                                name: "lazyload2",
                                rawName: "v-lazyload2",
                                value: { id: _vm.module.id, src: item.picPath },
                                expression:
                                  "{ id: module.id, src: item.picPath }",
                              },
                            ],
                            staticClass:
                              "photo result_pic_item_padding--bottom result_pic_item--scale",
                            style: _vm.genPicItemStyle(item),
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "num" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  item.photoList ? item.photoList.length : 0
                                )
                              ),
                            ]),
                            _c("i"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "result_highlight_node name jz_theme_font_hover_color jz_hover",
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }