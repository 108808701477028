var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "fk-circle" },
        _vm._l(_vm.circles, function (circle) {
          return _c("div", { class: _vm.getClassName(circle) })
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }