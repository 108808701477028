import smoothscroll from 'smoothscroll-polyfill';
import { gotoFullScreenIndex } from '@/components/grid/row/pureFullScreen/index.js';

export function scrollToTop() {
    let { manageMode, device } = window._store.state;

    if (window._store.getters.isFullScreen) {
        gotoFullScreenIndex(0);
        return;
    }

    let $jzPreviewContent = $('#jzPreviewContent');
    let $html = $('html'); //  电脑视图下滚动的是html
    let $body = manageMode ? $jzPreviewContent : $('body');
    // 如果当前已经是顶部了，就不需要再滚动了， 直接隐藏掉
    if ($html.scrollTop() === 0 && $body.scrollTop() === 0 && $jzPreviewContent.scrollTop() === 0) {
        return;
    }
    // kick off the polyfill!
    // fix ios 没有平滑滚动效果
    smoothscroll.polyfill();
    if (window.scrollTo) {
        //平滑滚动
        if (manageMode && device === 'mobi') {
            $body[0].scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
        return;
    }

    $('.edit_tool_shortcut').hide();
}
