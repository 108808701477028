import MultiLanguage from '../components/system/multiLanguage/menus/index.vue';
// pattern里的语言数据列表需要section字段里的补充
function fillLanguageData(sourceList, patternLanguage) {
    if (sourceList.length === 0) {
        return patternLanguage;
    }

    return sourceList.map((s) => {
        const matched = patternLanguage.find((p) => p.lcid === s.lcid);
        if (matched) {
            return {
                url: s.url,
                lanCode: s.lanCode,
                lanVerDesc: s.lanVerDesc,
                name: matched.name,
                lcid: s.lcid,
            };
        }
        return s;
    });
}

const initMultiLanguage = function (opts) {
    if (opts.multiLanguageHidden) {
        return;
    }
    let hasEl = $(opts.selector).length > 0;
    if (hasEl) {
        // 判断有没有被挂载过这个组件，如果这个id还存在的话，就说明还没被挂载到页面上，需要new Vue然后挂载
        new Vue({
            el: opts.selector,
            components: {
                MultiLanguage,
            },
            data() {
                return {
                    opts,
                };
            },
            computed: {
                list() {
                    if (opts.renderDefaultText === 0) {
                        return opts.openLanInfoList;
                    } else {
                        return fillLanguageData(opts.openLanInfoList, opts.renderTextList);
                    }
                },
                currentLan() {
                    if (opts.renderDefaultText === 0) {
                        return opts.currentLan;
                    } else {
                        return this.list.find((item) => item.lcid === opts.currentLan.lcid);
                    }
                },
            },
            template: `
                <multiLanguage
                    :list="list"
                    :currentLan="currentLan"
                    :renderType="opts.renderType"
                    :renderStyle="opts.renderStyle"
                    :isCore="opts.isCore"
                    :renderArea="opts.renderArea"
                    :fontSetting="opts.fontSetting"
                />
            `,
        });
    }
};

export { initMultiLanguage };
