var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass: "cate_group_list",
      class: [
        `cate_group_rowwrap${_vm.rowCount}`,
        `cate_group_rowwrap${_vm.rowCount}_style${_vm.style}`,
        `${
          _vm.rowCount == 2
            ? `J_categroup__hoveritem J_categroup__hoveritem-${_vm.parentId}`
            : ""
        } `,
        `cate_group_rowwrap${_vm.module.id}`,
      ],
      attrs: { "data-id": String(_vm.module.id) + String(_vm.parentId) },
    },
    _vm._l(_vm.groupList, function (item, index) {
      return _c(
        "li",
        {
          directives: [
            {
              name: "animate",
              rawName: "v-animate",
              value: _vm.module,
              expression: "module",
            },
          ],
          key: item.id,
          staticClass: "m_categroup_itemwrap",
          class: [
            `m_categroup_itemwrap${_vm.rowCount}`,
            { is_only_one_level: _vm.isOnlyOneLevel && _vm.isApplyAlgin },
          ],
          style: _vm.itemStyle(index),
          attrs: { "data-id": String(_vm.module.id) + String(item.id) },
          on: {
            mouseover: function ($event) {
              return _vm.showFixedGroup($event, _vm.rowCount)
            },
            mouseleave: function ($event) {
              return _vm.hideFixedGroup($event, item.id, _vm.rowCount)
            },
            click: function ($event) {
              return _vm.showPanel(item)
            },
          },
        },
        [
          _c(
            "div",
            {
              ref: "mCategroupLinkwraps",
              refInFor: true,
              staticClass: "m_categroup_linkwrap",
              class: _vm.getSelectClass(item),
              on: {
                click: function ($event) {
                  return _vm.showSubGroup($event, item)
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "cate_group_item jz_theme_font_hover_color jz_hover",
                  class: [
                    `m_categroup_item${_vm.rowCount}`,
                    `${
                      item.children && item.children.length
                        ? ""
                        : "cate_group_item-nochild"
                    }`,
                    {
                      is_only_one_level: _vm.isOnlyOneLevel && _vm.isApplyAlgin,
                    },
                  ],
                  style: _vm.fontStyle,
                  attrs: {
                    href: _vm.getUrl(item.href, item.id),
                    target: _vm.getLinkTarget(item),
                    onclick: `${
                      item.children && item.children.length
                        ? "event.stopPropagation()"
                        : ""
                    }`,
                  },
                },
                [_vm._v(_vm._s(item.name))]
              ),
              _vm._v(" "),
              item.children &&
              item.children.length &&
              (_vm.isMobi || (_vm.rowCount < 3 && !_vm.style))
                ? _c("div", {
                    staticClass: "m_categroup__switchicon faisco-icons-S000112",
                    class: _vm.isMobi ? "m_categroup__switchicon-right" : "",
                  })
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          item.children && item.children.length && !_vm.isMobi
            ? _c("group-item", {
                key: _vm.style,
                attrs: {
                  module: _vm.module,
                  "row-count": _vm.rowCount + 1,
                  "group-list": item.children,
                  "parent-id": item.id,
                  "parent-index": index,
                  "is-only-one-level": _vm.isOnlyOneLevel,
                },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }