var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.value,
          expression: "value",
        },
      ],
      staticClass: "hls_video_wrap",
    },
    [
      _c(
        "svg",
        {
          staticClass: "cancel",
          attrs: { title: "关闭视频" },
          on: { click: _vm.closeKnowProductVideo },
        },
        [_c("use", { attrs: { "xlink:href": "#icon_material_close" } })]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "jz_know_play_wrap", attrs: { id: "media_player" } },
        [
          _vm.manageMode || !_vm.isMobi
            ? _c("video", {
                ref: "videoPlayer",
                staticClass: "video-js vjs-default-skin vjs-big-play-centered",
                attrs: {
                  controls: "",
                  controlsList: "nodownload",
                  preload: "auto",
                  "x5-video-player-fullscreen": "true",
                  "x5-playsinline": "false",
                  playsinline: "false",
                  "x5-video-player-type": "h5",
                  "data-setup":
                    '{"controlBar":{"volumeMenuButton":{"inline": false},"currentTimeDisplay":true,"timeDivider":true,"durationDisplay":true,"remainingTimeDisplay":false}}',
                  oncontextmenu: "return false",
                },
              })
            : _c("video", {
                ref: "videoPlayer",
                staticClass: "video-js vjs-default-skin vjs-big-play-centered",
                attrs: {
                  controls: "",
                  "webkit-playsinline": "true",
                  playsinline: "true",
                  controlsList: "nodownload",
                  preload: "auto",
                },
              }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }