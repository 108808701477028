export const SERVICE_INTERFACE = 'drag';

export class DragService {
    constructor(dragData = {}) {
        this.dragData = dragData;
    }

    validate() {
        throw new Error('validate method not implement');
    }

    onDragOver() {
        console.info('onDragOver not implement');
    }

    beforeAdd() {
        console.info('beforeAdd not implement');
    }
}
