var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "jz_root", class: _vm.rootClass, attrs: { id: "jzRoot" } },
    [
      !_vm.manageMode ? _c("welcome-page") : _vm._e(),
      _vm._v(" "),
      !_vm.safeMode && _vm.manageMode
        ? _c(
            "div",
            {
              staticClass: "topbar-loading",
              staticStyle: { display: "none" },
              attrs: { id: "fk-topbar-loading" },
            },
            [_vm._v("网站加载异常，建议重新刷新加载")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.manageMode
        ? _c(
            "div",
            {
              staticClass: "jz_toolbar",
              style: _vm.toolBarStyle,
              attrs: { id: "jzToolbar" },
            },
            [_c("jz-toolbar", { ref: "toolbar" })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "jz_edit_container",
          class: { jz_setBar__hide: _vm.manageMode && !_vm.settingBarStatus },
          style: _vm.editContainerStyle,
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.manageMode || !_vm.visitorPreviewing,
                  expression: "!manageMode || !visitorPreviewing",
                },
              ],
              staticClass: "jz_preview_wrap",
              attrs: { id: "jzPreviewWrap" },
            },
            [
              _vm.manageMode
                ? _c(
                    "alert-component",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.device == "mobi",
                          expression: "device == 'mobi'",
                        },
                      ],
                      staticClass: "mobi_setting_tips",
                      staticStyle: { display: "none" },
                    },
                    [_vm._v("手机视图下修改为独立设置，不影响到电脑视图")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { ref: "jzPreviewArea", staticClass: "jz_preview_area" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "jz_preview_content",
                      attrs: { id: "jzPreviewContent" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "jz_web",
                          class: _vm.jzWebClass,
                          style: _vm.jzWebStyle,
                          attrs: { id: "jz_web" },
                        },
                        [
                          _c("JzBackTop", { attrs: { id: "jzBackTopCompon" } }),
                          _vm._v(" "),
                          !_vm.isMobi &&
                          _vm.info.pattern.header.display == 0 &&
                          _vm.isFullScreen &&
                          _vm.info.pattern.rollingScreen.fixedTop
                            ? _c("grid-header", {
                                ref: "header",
                                attrs: { info: _vm.info },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isMobi &&
                          !_vm.info.pattern.header.display &&
                          !_vm.info.pattern.header.mobiHide &&
                          _vm.isFullScreen &&
                          _vm.info.pattern.rollingScreen.fixedTop
                            ? _c("grid-mobi-header", {
                                attrs: { info: _vm.info },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isFullScreen
                            ? _c("FullScreenPagination", {
                                staticClass: "jz_full_screen_pagination",
                                attrs: { id: "jzFullScreenPagination" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "jz_web_container",
                              class: ["jz_col_" + _vm.colId],
                              style: _vm.siteStyle,
                              attrs: { id: "jzWebContainer" },
                            },
                            [
                              (_vm.isMobi &&
                                !_vm.info.pattern.header.display &&
                                !_vm.info.pattern.header.mobiHide &&
                                !_vm.isFullScreen) ||
                              (_vm.isMobi &&
                                _vm.isFullScreen &&
                                _vm.info.pattern.header.display == 0 &&
                                !_vm.info.pattern.rollingScreen.fixedTop)
                                ? _c("grid-mobi-header", {
                                    attrs: { info: _vm.info },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              (!_vm.isMobi &&
                                _vm.info.pattern.header.display == 0 &&
                                !_vm.isFullScreen) ||
                              (!_vm.isMobi &&
                                _vm.isFullScreen &&
                                _vm.info.pattern.header.display == 0 &&
                                !_vm.info.pattern.rollingScreen.fixedTop)
                                ? _c("grid-header", {
                                    ref: "header",
                                    attrs: { info: _vm.info },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "jz_web_banner" },
                                [
                                  _vm.showBanner
                                    ? _c("jz-banner", { ref: "jz_banner" })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "jz_web_content",
                                  class: _vm.fullScreenRowClass,
                                  style: _vm.fullScreenRowStyle,
                                },
                                [
                                  _c("jz-web-rows", {
                                    ref: "webContent",
                                    attrs: {
                                      ids: _vm.info.rowIds,
                                      content: true,
                                      name: "webContent",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.info.pattern.footer.display == 0
                                ? _c("grid-footer", {
                                    ref: "footer",
                                    attrs: { info: _vm.info },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isFullScreen
                                ? _c("div", {
                                    attrs: { id: "jzBackTopCompon" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.manageMode
                                ? _c("jz-svg-animation")
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isMobi &&
                              !_vm.info.pattern.footNav.h &&
                              (!_vm.isFullScreen ||
                                (_vm.isFullScreen &&
                                  !_vm.info.pattern.rollingScreen.isMobiSync))
                                ? _c("foot-nav", { ref: "footnav" })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("suspend-serv", {
                            ref: "suspendserv",
                            attrs: { module: _vm.info },
                          }),
                          _vm._v(" "),
                          _vm.manageMode
                            ? _c(
                                "DndContainer",
                                {
                                  staticClass: "fixedModules",
                                  style: _vm.draggingStyle,
                                  attrs: {
                                    behaviour: "drop-zone",
                                    "group-name": "drop-zone",
                                    snappable: true,
                                    "should-accept-drop": _vm.shouldAcceptDrop,
                                    "get-child-payload": _vm.getChildPayload,
                                    "auto-scroll-enabled": false,
                                    "use-document-scroll": false,
                                    "drag-on-point": false,
                                  },
                                  on: {
                                    "drag-start": _vm.onDragStart,
                                    "drag-end": _vm.onDragEnd,
                                    "drag-enter": _vm.onDragEnter,
                                    "drag-leave": _vm.onDragLeave,
                                    drop: _vm.handleDrop,
                                  },
                                },
                                _vm._l(
                                  _vm.layoutStatusModuleIds,
                                  function (moduleId) {
                                    return !_vm.isMobi
                                      ? _c("project", {
                                          key: moduleId,
                                          attrs: { id: moduleId },
                                        })
                                      : _vm._e()
                                  }
                                ),
                                1
                              )
                            : _vm._l(
                                _vm.layoutStatusModuleIds,
                                function (moduleId) {
                                  return !_vm.isMobi
                                    ? _c("project", {
                                        key: moduleId,
                                        attrs: { id: moduleId },
                                      })
                                    : _vm._e()
                                }
                              ),
                          _vm._v(" "),
                          _vm.isMobi &&
                          !_vm.info.pattern.footNav.h &&
                          _vm.isFullScreen &&
                          _vm.info.pattern.rollingScreen.isMobiSync
                            ? _c("foot-nav", { ref: "footnav" })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showFullNav
                            ? _c(
                                "div",
                                {
                                  key: _vm.updateFullNavKeyInManageMode,
                                  staticClass:
                                    "jz_full_nav_wrap jz_full_nav_wrap_expend_0",
                                },
                                [
                                  _c("jz-web-rows", {
                                    attrs: {
                                      ids: _vm.fullNavRowIds,
                                      name: "navFullScreen",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.manageMode
                            ? _c(
                                "tooltip-component",
                                {
                                  attrs: {
                                    manual: true,
                                    value:
                                      _vm.isShowFullNavExitTips &&
                                      !_vm.isMobi &&
                                      _vm.isEnterFullNavFromPanel,
                                    effect: "light",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                点击可退出展开内容编辑\n                                "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "full_nav_exit_tips",
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: _vm.closeFullNavExitTips,
                                          },
                                        },
                                        [_vm._v("我知道了")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "jz_full_nav_menu full_nav_close",
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "jz_full_nav_menu_item_top jz_full_nav_menu_item",
                                      }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "jz_full_nav_menu_item_center jz_full_nav_menu_item",
                                      }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "jz_full_nav_menu_item_bottom jz_full_nav_menu_item",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "jz_full_nav_menu full_nav_close",
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "jz_full_nav_menu_item_top jz_full_nav_menu_item",
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "jz_full_nav_menu_item_center jz_full_nav_menu_item",
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "jz_full_nav_menu_item_bottom jz_full_nav_menu_item",
                                  }),
                                ]
                              ),
                          _vm._v(" "),
                          _c("popup-zone"),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.manageMode ? _c("jz-mobi-designer-btn") : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.manageMode ? _c("manage-panel") : _vm._e(),
      _vm._v(" "),
      _vm.manageMode && _vm.isEditMode
        ? _c(_vm.panelName, { tag: "component" })
        : _vm._e(),
      _vm._v(" "),
      _c("popupZoneToolbar"),
      _vm._v(" "),
      _vm.manageMode ? _c("ManageImageClip") : _vm._e(),
      _vm._v(" "),
      _vm.manageMode ? _c("manage-hide-set-bar") : _vm._e(),
      _vm._v(" "),
      _vm.manageMode ? _c("jz-visitor-preview") : _vm._e(),
      _vm._v(" "),
      _c("client-only", [!_vm.manageMode ? _c("demo-preview") : _vm._e()], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }