import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

const INDEX_ALL = -1;

const [INDEX_PRODUCT, INDEX_NEWS, INDEX_PHOTO_GROUPS, INDEX_FILE_DOWNLOAD, INDEX_COL] = [0, 1, 2, 3, 4];

const SEARCH_TABS = [
    {
        index: INDEX_ALL,
        name: LS.searchResultAll,
    },
    {
        index: INDEX_PRODUCT,
        name: LS.searchResultProduct,
        logSrcId: 1,
    },
    {
        index: INDEX_NEWS,
        name: LS.searchResultArticle,
        logSrcId: 2,
    },
    {
        index: INDEX_PHOTO_GROUPS,
        name: LS.searchResultPhotoGroup,
        logSrcId: 3,
    },
    {
        index: INDEX_FILE_DOWNLOAD,
        name: LS.searchResultFile,
        logSrcId: 4,
    },
    {
        index: INDEX_COL,
        name: LS.searchResultCol,
        logSrcId: 5,
    },
];

export { SEARCH_TABS, INDEX_PRODUCT, INDEX_ALL, INDEX_NEWS, INDEX_PHOTO_GROUPS, INDEX_FILE_DOWNLOAD, INDEX_COL };
