import { setAccordionItemStatus } from './utils';
import { computed } from 'vue';
import { useMapGetters } from '@jz/utils';

export const useHoverMenu = ({ module, props }) => {
    const { isMobi } = useMapGetters(['isMobi']);
    const hovermenu = computed(() => (args) => {
        const isActive = props.isActive;
        if (isMobi.value) {
            return null;
        }
        return {
            type: 'shortcut',
            styleMode: 3,
            posMode: 14,
            list: [
                {
                    text: '初始',
                    style: !isActive ? 'icon_tab_active' : 'icon_tab',
                    svgIcon: !isActive ? 'tab_active' : 'tab',
                    click() {
                        if (!isActive) {
                            return;
                        }
                        setAccordionItemStatus({
                            moduleId: module.id,
                            id: args.item.id,
                            type: 'initial',
                        });
                    },
                },
                {
                    text: '悬停',
                    style: isActive ? 'icon_tab_active' : 'icon_tab',
                    svgIcon: isActive ? 'tab_active' : 'tab',
                    click() {
                        if (isActive) {
                            return;
                        }
                        setAccordionItemStatus({
                            moduleId: module.id,
                            id: args.item.id,
                            type: 'hover',
                        });
                    },
                },
            ],
        };
    });

    return hovermenu;
};
