export default {
    namespaced: true,
    state: () => ({
        isEdit: false,
        rowId: null,
        panelName: '',
        popupList: [],
        isShowToolTab: false,
        isHoverTool: false,
        popupToolTabPosition: {
            left: 0,
            top: 0,
        },
        isIndexPopup: false,
    }),
    mutations: {
        setRowId(state, payload) {
            state.rowId = payload;
        },
        setPopupList(state, payload) {
            state.popupList = payload;
        },
    },
    getters: {
        // eslint-disable-next-line no-unused-vars
        isVisitMode: (state, getters, rootState, rootGetters) => {
            return getters.isOpen && !state.isEdit;
        },
        // eslint-disable-next-line no-unused-vars
        isEditMode: (state, getters, rootState, rootGetters) => {
            return getters.isOpen && state.isEdit;
        },
        // eslint-disable-next-line no-unused-vars
        isOpen: (state, getters, rootState, rootGetters) => {
            return !!state.rowId;
        },
    },
};
