var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "pay_status_wrap" }, [
    _c("div", { staticClass: "pay_status_icon pay_wait_icon" }, [
      _c("svg", [_c("use", { attrs: { "xlink:href": "#icon_cargo" } })]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pay_status_info" }, [
      _c("div", { staticClass: "pay_status_title" }, [
        _vm._v(_vm._s(_setup.LS.jzOrderFinPay)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }