/**
 *
 * init header
 *
 * 常规栏目: responsiveInfo.pattern.header
 * 滚屏栏目: responsiveInfo.pattern.rollingScreen
 *
 */
export const initHeader = (state) => {
    if (!state || !state.responsiveInfo) {
        return;
    }

    const header = state.responsiveInfo.pattern.header;
    const rollingScreen = state.responsiveInfo.pattern.rollingScreen;

    if (state.fullScreen && rollingScreen.st === true) {
        // 滚屏栏目若开启了顶部同步，则把顶部的设置同步到滚屏栏目
        assignHeader(rollingScreen, header);
    }
};

/**
 *
 * @param {Object} target
 * @param {Object} source
 */
export const assignHeader = (target, source) => {
    const assignKeys = [
        'background', // 顶部背景
        'fixedTop', // 顶部类型
        'fixedHeight', // 固定高度
        'fixedBackground', // 固定背景
        'ps', // 预设样式
        'bd', // 下边框
        'fsd', // 固定阴影
        'se', // 鼠标悬停
        'hftt', // 顶部位置
    ];

    recursiveAssign(target, source, assignKeys);
};

/**
 * 递归 assign
 * @param {Object} target   目标对象
 * @param {Object} source   源对象
 * @param {Array} keys    需要递归的 key 数组
 */
function recursiveAssign(target, source, keys) {
    for (let key in source) {
        if (keys && keys.indexOf(key) === -1) {
            continue;
        }
        if (Object.prototype.hasOwnProperty.call(source, key)) {
            if (typeof source[key] === 'object') {
                if (typeof target[key] !== 'object') {
                    target[key] = {};
                }
                recursiveAssign(target[key], source[key]);
            } else {
                target[key] = source[key];
            }
        }
    }
}
