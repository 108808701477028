import { getPageScrollTarget } from './utils.js';
import { bindScrollForFixTopStyle, FIX_TOP_GRID_HEADER_CLASS } from '@/site/shared/fixTopStyle/index.js';

const initHeader = (header = {}) => {
    initHeaderFixedTop(header);
};

const FIXED_TOP_OPEN = 1;
const FIXED_HEIGHT_OPEN = 1;
const SYS_HEADER = 3;
let isRightPosition = false; // 控制变化固定高度 固定背景的变量抽出来
let isRightPositionOld = isRightPosition;
let headerPaddingTop = '';
let headerTopStr = '';
let pageScrollTarget = null;
let timer = null;
let isOpenFixedTop = false, // 固定顶部
    isOpenFixedHeight = false;
let isUnfixedTopFloatStyle = false; // 非固定顶部是悬浮
let isTopFloatStyle = 0;

/**
 * 循环执行
 * @param {Function} fn
 * @author Jy
 * @date 2019.06.04
 */
function tick(fn) {
    let times = 0;
    const tickInterval = setInterval(() => {
        fn();
    }, 1000 / 60);
    const clearTick = () => clearInterval(tickInterval);

    return {
        clearTick,
        times,
    };
}

function isPc() {
    return $('html').hasClass('jz_screen_pc');
}

/**
 * 初始化固定顶部
 * @param {Object} header 顶部的样式数据
 */
function initHeaderFixedTop(header = {}) {
    const { fixedTop, fixedHeight, hftt = 0 } = header;
    isOpenFixedTop = fixedTop == FIXED_TOP_OPEN; // 固定顶部
    isOpenFixedHeight = fixedHeight == FIXED_HEIGHT_OPEN; // 固定导航所在通栏
    isTopFloatStyle = hftt;
    window.isFixedTopFloatStyle = isTopFloatStyle && isOpenFixedTop;
    isUnfixedTopFloatStyle = isTopFloatStyle && !isOpenFixedTop;
    window._store.commit('header/setIsOpenFixedHeight', isOpenFixedHeight);
    window._store.commit('header/setIsOpenFixedTop', isOpenFixedTop);

    _checkIsRightPosition();
    changeHeaderTop();

    if (!window._store.state.fullScreen) {
        setHeaderPaddingTop();
    }

    if (!window._store.state.fullScreen && isOpenFixedTop && isPc()) {
        bindScrollForFixTopStyle();
    }

    setOpenFixedTopEvent();
    flush();

    if (isOpenFixedTop && !window._store.state.fullScreen) {
        // 考虑到固定顶部后要计算padding(img加载在后会影响顶部高度),
        // 故循环获取paddingTop,若改动了顶部paddingTop或者循环次数大于60次(相当于1s)则停止
        // @author Jy @date 2019.06.04
        let maxCallTimes = 60;
        let t = tick(() => {
            t.times++;
            if (t.times >= maxCallTimes) {
                t.clearTick();
                t = null;
            }
            setHeaderPaddingTop();
            flush();
        });
    }
}
/**
 * 判断是否达到转变样式的位置
 */
function _checkIsRightPosition() {
    if (!$('#gridHeader').length) {
        return;
    }

    const { top, height } = $('#gridHeader')[0].getBoundingClientRect();
    // 根据是否开启固定导航高度走不同的判断条件
    if (isOpenFixedHeight ? Math.abs(top) > height : top < 0) {
        isRightPosition = true;
    } else {
        isRightPosition = false;
    }

    if (isRightPosition !== isRightPositionOld) {
        flush();
        isRightPositionOld = isRightPosition;
    }
}

/**
 * 若开启了固定顶部，给与顶部节点一个跟顶部内容区一样高的上内间距，作为占位
 */
function setHeaderPaddingTop() {
    const $headrContent = $('#gridHeader').find('.J_headerContent');
    if (!$headrContent.length || window._store.state.fullScreen) {
        return;
    }
    headerPaddingTop = isOpenFixedTop ? window.getComputedStyle($headrContent[0]).height : '';
}

/**
 * 设置开启了固定顶部后的事件
 * @param {Boolean} destory 是否销毁
 */

function setOpenFixedTopEvent(destory = false) {
    pageScrollTarget = pageScrollTarget || getPageScrollTarget();
    if (!pageScrollTarget) {
        return;
    }

    if (!isOpenFixedTop || destory) {
        // 添加滚动事件
        $(pageScrollTarget).off('scroll.isRight', _checkIsRightPosition);
        pageScrollTarget = null;
        $(window).off('resize.fixedTop');
    } else {
        $(pageScrollTarget).off('scroll.isRight').on('scroll.isRight', _checkIsRightPosition);

        $(window).off('resize.fixedTop').on('resize.fixedTop', resizeGridHeaderHandler);
    }
}

/**
 * 优化体验: 当缩放浏览器时修复顶部占位区的高度
 */
function resizeGridHeaderHandler() {
    setHeaderPaddingTop();
    flush();
}
function headerStyle() {
    return {
        'padding-top': headerPaddingTop,
    };
}

function isFixedBackgroundCurrent() {
    let rollingScreen = window._store.state.responsiveInfo.pattern.rollingScreen;
    return (
        ((isOpenFixedTop && !rollingScreen.type) || (!!rollingScreen.type && rollingScreen.fixedTop)) && isRightPosition
    );
}

function isFixedHeightCurrent() {
    let rollingScreen = window._store.state.responsiveInfo.pattern.rollingScreen;
    return (
        ((isOpenFixedTop && !rollingScreen.type) || (!!rollingScreen.type && rollingScreen.fixedTop)) &&
        isRightPosition &&
        isOpenFixedHeight
    );
}
function headerContentStyle() {
    return Object.assign({}, { top: headerTopStr });
}
/**
 * 根据当前是否固定导航高度改变顶部的top值
 */
function changeHeaderTop() {
    let rollingScreen = window._store.state.responsiveInfo.pattern.rollingScreen;
    const _isFullScreen = window._store.state.fullScreen && rollingScreen.hftt == 1 && rollingScreen.fixedTop;
    if (isFixedHeightCurrent()) {
        const $sysHeader = $('#row' + SYS_HEADER);
        if ($sysHeader.length) {
            //增加判断免费版预览
            /// 在很快滚动时计算top会有问题。
            const $headrContent = $('#gridHeader').find('.J_headerContent');
            rollingScreen.hftt == 0 &&
                $headrContent.height() !== $sysHeader.outerHeight() &&
                $('#gridHeader').css('height', $sysHeader.outerHeight() + Comm.getPageTop());
            let sysHeaderTop = $headrContent.height() - $sysHeader.outerHeight();
            headerTopStr = '-' + (sysHeaderTop - Comm.getPageTop()) + 'px';
            return;
        }
    } else {
        if (_isFullScreen) {
            headerTopStr = Comm.getPageTop() + 'px';
            return;
        }
        $('#gridHeader').css('height', '');
    }
    if (_isFullScreen) {
        headerTopStr = Comm.getPageTop() + 'px';
        return;
    }
    headerTopStr = '';
}

function flush() {
    if (timer) {
        return;
    }

    timer = setTimeout(() => {
        const $gridHeader = $('#gridHeader');
        !window._store.state.fullScreen && !isTopFloatStyle && $gridHeader.css(headerStyle());
        changeHeaderTop();
        if (isOpenFixedTop && !window._store.state.fullScreen) {
            $gridHeader.find('.J_headerContent').addClass('g_header_content__fixed');
        }
        if (isOpenFixedTop || window._store.state.fullScreenOpenFixed) {
            $gridHeader.find('.J_headerContent').addClass(FIX_TOP_GRID_HEADER_CLASS);
        }

        if (isUnfixedTopFloatStyle) {
            $gridHeader.find('.J_headerContent').removeClass('g_header_content__fixed');
            if (!window._store.state.fullScreen) {
                $gridHeader.addClass('g_header_content__unfixed_float');
            }
        }
        $gridHeader.find('.J_headerContent').css(headerContentStyle());
        if (isFixedBackgroundCurrent()) {
            $('#gridHeader').addClass('g_header__fixed').removeClass('g_header__static');
        } else {
            $('#gridHeader').removeClass('g_header__fixed').addClass('g_header__static');
        }
        timer = null;
    }, 1000 / 60);
}

const checkIsRightPosition = function (status) {
    isRightPosition = status;
    if (isRightPosition !== isRightPositionOld) {
        flush();
        isRightPositionOld = isRightPosition;
    }
};
// 修复顶部尺寸变化的影响， 依赖JZ.initHeader函数
const resizeHeader = function () {
    $(window).trigger('resize.fixedTop');
};

export { initHeader, checkIsRightPosition, resizeHeader };
