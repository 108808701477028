var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mall_order_detail_section" }, [
    _vm.title
      ? _c("div", { staticClass: "mall_order_detail_section_title" }, [
          _vm._v(_vm._s(_vm.title)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mall_order_detail_section_content" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }