
import { mapGetters, mapState, mapActions } from 'vuex';
import { JzFdpLog } from '@/site/shared/log/index.js';

export default {
    name: 'pack',
    components: {
    },
    props: {
        moduleIds: {
            type: Array,
            default: () => [],
        },
        moduleIndex: {
            type: Number,
            default: 0,
        },
        rowId: {
            type: Number,
            default: 0,
        },
        name: {
            type: String,
            default: 'tabpack',
        },
        parentId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            dragHandleSelector: '',
        };
    },
    render() {
        const projectsTemplate = () => {
            return this.finalModuleIds.map((id) => {
                return (
                    <Project
                        id={id}
                        key={id}
                        module-index={this.moduleIndex}
                        rowid={this.rowId}
                        projectid={this.id}
                    ></Project>
                );
            });
        };
        const manageTemplate = () => {
            return (
                <DndContainer
                    ref="content"
                    class={{
                        web_col_content_empty: this.finalModuleIds.length == 0 && this.manageMode,
                        web_col_content: this.manageMode,
                    }}
                    should-accept-drop={this.shouldAcceptDrop}
                    should-not-accept-drop-but-tips={this.shouldNotAcceptDropButTips}
                    get-child-payload={this.getChildPayload}
                    drag-handle-selector={this.dragHandleSelector}
                    drag-disabled={this.dragDisabled}
                    empty={this.isEmpty}
                    non-drag-area-selector={this.nonDragAreaSelector}
                    on={{
                        drop: this.onDrop,
                    }}
                >
                    <template slot="default">{projectsTemplate()}</template>
                    <template slot="empty">
                        <div class="web_col_content_empty_tips">
                            <div class="web_col_content_empty_tips_btn" on={{ click: this.addModule }}>
                                <i class="web_col_content_empty_tips_icon"></i>
                                <div class="web_col_content_empty_tips_text">添加模块</div>
                            </div>
                            <div class="web_col_content_empty_tips_label">可拖动模块进来</div>
                        </div>
                    </template>
                </DndContainer>
            );
        };

        return this.manageMode ? manageTemplate() : <div>{projectsTemplate()}</div>;
    },
    computed: {
        ...mapState(['device', 'manageMode', 'modules']),
        ...mapGetters(['isMobi']),
        ...mapState('manage/auth', ['designAuth']),
        isEmpty() {
            return this.manageMode && !this.finalModuleIds.length && !this.isMobi && this.designAuth;
        },
        dragDisabled() {
            return !this.manageMode || this.device === 'mobi';
        },
        nonDragAreaSelector() {
            return this.isNestTabpack() ? '.m_tabpack_picture .m_tabpack_tablist' : '';
        },
        finalModuleIds() {
            return this.moduleIds.filter((moduleId) => moduleId !== this.parentId);
        },
    },
    methods: {
        findComponentsUpward(context, componentName, componentNames) {
            var components = [];

            if (typeof componentName === 'string') {
                componentNames = [componentName];
            } else {
                componentNames = componentName;
            }

            var parent = context.$parent;
            // eslint-disable-next-line no-unused-vars
            var name = parent.$options.name;
            while (parent) {
                if (componentNames.indexOf(parent.$options.name) > -1) {
                    components.push(parent);
                }
                parent = parent.$parent;
            }
            return components;
        },

        isNestTabpack() {
            return this.findComponentsUpward(this, 'Tabpack').length >= 2;
        },
    },
    mounted() {
        if (this.isNestTabpack() && !this.manageMode) {
            setTimeout(() => {
                JzFdpLog.logJzFdp('jz_zsy_tagnesting_expo', {
                    jz_free_text1: ['横向标签', '纵向标签', '图片标签', '折叠标签'][
                        this.findComponentsUpward(this, 'Tabpack')[1].module.prop0
                    ],
                    jz_free_text2: ['横向标签', '纵向标签', '图片标签', '折叠标签'][
                        this.findComponentsUpward(this, 'Tabpack')[0].module.prop0
                    ],
                    jz_free_text3: '访客态',
                    jz_version: bizShared.context.jzVersion,
                    jz_zsy_page: window._store.state.fullScreen ? '滚屏栏目' : '常规栏目',
                    jz_zsy_view: window._store.state.device === 'mobi' ? '手机视图' : '电脑视图',
                });
            }, 0);
        }
    },
};
