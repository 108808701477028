import { throttle, debounce, deepExtendClone } from '@jz/utils';
import { DEFAULT_OPTIONS, FILTER_CLASSES } from './constant';
const getClassList = (target) => {
    return Array.from(target.classList || []);
};

export class DomMutationObserver {
    constructor(options) {
        this.options = deepExtendClone({}, DEFAULT_OPTIONS, options);
        this.filterClassSet = this._handleFilterClasses();
        this.cb = this._handleCallback(this.options.callback);
        this.fnCalling = false;
        this._observer = null;
        this._moduleHeightMap = {};
        this._collectModuleHeight();
        this._init();
    }

    _init() {
        if (this._observer !== null) return;
        this._observer = new MutationObserver(this._mutationObserverCb.bind(this));
        this.observe();
    }

    // mutationObserver回调
    _mutationObserverCb(mutationsList) {
        // 拖拽中，不触发回调
        if (this.isFilterDrag()) return;
        // 大规模回调，直接调用，防止浏览器运算卡死
        if (mutationsList.length > 200) {
            this.cb(mutationsList);
            return;
        }
        // 防止函数调用过程中，再次触发回调
        if (this.fnCalling) return;

        for (let mutation of mutationsList) {
            // 通过类名过滤
            const classList = getClassList(mutation.target);
            const hasFilterClass = classList.some((classItem) => {
                return this.filterClassSet.has(classItem);
            });
            if (hasFilterClass) continue;

            // 过滤动画中的元素
            if (this._isAnimation(mutation.target)) break;

            // 记录容器内模块的位置高度信息 top height
            const $target = $(mutation.target);
            if ($target === null) continue;

            const height = $target.height();
            const top = parseInt($target.css('top'));
            if (
                typeof this._moduleHeightMap['height'] === 'undefined' ||
                typeof this._moduleHeightMap['top'] === 'undefined'
            ) {
                this._moduleHeightMap['height'] = height;
                this._moduleHeightMap['top'] = top;
                this.cb(mutationsList);
                break;
            }
            // 对比位置高度信息，如果有改变，则触发回调
            if (this._moduleHeightMap.height == height && this._moduleHeightMap.top == top) {
                continue;
            } else {
                this._moduleHeightMap.height = height;
                this._moduleHeightMap.top = top;
            }
            this.cb(mutationsList);
            break;
        }
    }

    // 收集容器内模块的top height值
    _collectModuleHeight() {
        setTimeout(() => {
            const $node = $(this.options.node);
            this._moduleHeightMap.height = $node.height();
            this._moduleHeightMap.top = parseInt($node.css('top'));
            !window._store.state.manageMode && this.cb();
        }, 500);
    }

    observe(node = this.options.node, config = this.options.config) {
        if (!node) return;
        if (this._observer === null) return;
        if (node instanceof NodeList) {
            [...node].forEach((n) => this._observer.observe(n, config));
        } else {
            this._observer.observe(node, config);
        }
    }

    disconnect() {
        if (this._observer === null) return;
        this._observer.disconnect();
    }

    _handleCallback(callback) {
        const fn = function () {
            // 调用函数时候触发的变化，不触发callback
            this.fnCalling = true;
            callback(...arguments);
            this._delay(() => {
                this.fnCalling = false;
            });
        };
        return window._store.state.manageMode ? throttle(fn, this.options.throttleTime) : debounce(fn, 16);
    }

    // 过滤拖拽时触发的回调
    isFilterDrag() {
        const sortableHelper = document.querySelector('.moduleSortableHelperWrap');
        const $sortPlaceHolder = $(document.querySelector('.jz_placeholder'));
        if ($sortPlaceHolder.length > 0 && $sortPlaceHolder.css('display') === 'block') return true;
        if (this.options.dragingCall) {
            // 不过滤拖拽中的回调，但是要过滤拖出容器后的操作
            if (sortableHelper && sortableHelper.style.display === 'block') return true;
        } else {
            // 过滤拖拽中触发的回调和脱出容器后的操作
            if (sortableHelper) return true;
        }
        return false;
    }

    _delay(cb) {
        if (typeof Promise === 'undefined') {
            setTimeout(cb, 0);
            return;
        }
        Promise.resolve().then(cb);
    }

    _isAnimation(node) {
        return node.style.animation && node.style.animationName != 'none';
    }

    _handleFilterClasses() {
        const filterClasses = new Set(this.options.filterClassList.concat(FILTER_CLASSES));
        return filterClasses;
    }
}
