var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module_content_detail module_dynamicnumber" },
    [
      _c(
        "a",
        {
          staticClass: "number-link",
          style: _vm.alginStyle,
          attrs: {
            href: _vm.link,
            target: _vm.linkTarget,
            rel: _vm.linkNofollow,
          },
        },
        [
          _c("div", { staticClass: "number-desc-wrapper" }, [
            _c(
              "div",
              {
                ref: "linkRef",
                staticClass: "number-count module_text_content",
              },
              [
                _vm.manageMode
                  ? _c(
                      "span",
                      {
                        ref: "dynamicNumberCountEl",
                        staticClass: "el-editable J-number-count-text",
                        style: _vm.numberCountTextStyle,
                      },
                      [_vm._v(_vm._s(_vm.startValFormatted))]
                    )
                  : _c(
                      "span",
                      {
                        ref: "dynamicNumberCountEl",
                        style: _vm.numberCountTextStyle,
                      },
                      [_vm._v(_vm._s(_vm.startValFormatted))]
                    ),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "number-unit", style: _vm.numberUnitTextStyle },
              [_vm._v(_vm._s(_vm.unitText))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "number-bottom-text",
              style: _vm.numberBottomTextStyle,
            },
            [_vm._v(_vm._s(_vm.bottomText))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }