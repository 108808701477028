import { addMsgBoardComment } from '@api/msgBoard/index.js';
import { warningMessage } from '@/site/shared/dialog/index.js';
import { logDog } from '@/site/shared/log/index.js';
import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

export function submitForm(module, form, contentList, openValidateCode, validateCode) {
    return new Promise(function (resolve, reject) {
        const msgBdData = {};

        for (const item of contentList) {
            const key = item.key;

            //这里是验证代码
            switch (item.type) {
                case 0: //单行文本
                    {
                        const val = $.trim(item.value);
                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }
                        if (val.length > 100) {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputMaxLength, Fai.encodeHtml(item.name), 100)
                            );
                        }
                        msgBdData[key] = val;
                    }
                    break;
                case 1: //多行文本
                    {
                        const val = $.trim(item.value);
                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }
                        if (val.length > 1000) {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputMaxLength, Fai.encodeHtml(item.name), 1000)
                            );
                        }
                        msgBdData[key] = val;
                    }
                    break;
                case 9: //邮箱验证
                    {
                        const val = $.trim(item.value);
                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }
                        if (val && !Fai.isEmail(val)) {
                            return createValidError(
                                Fai.format(LS.memberSignupUserAddItemCorrect, Fai.encodeHtml(item.name))
                            );
                        }
                        msgBdData[key] = val;
                    }
                    break;
                case 8: //手机号码
                    {
                        const val = item.value || '';

                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }

                        if (!!val && !Fai.isPhone(val)) {
                            return createValidError(Fai.format(LS.msgBoardInputCorrect, Fai.encodeHtml(item.name)));
                        }

                        msgBdData[key] = val;
                    }
                    break;
            }
            //eslint-disable-next-line no-inner-declarations
            function createValidError(msg) {
                return createSubmitError({
                    msg: msg,
                    itemId: item.id,
                });
            }
        }

        // 【7月3上线】免费版削弱
        if (window._store.state.isJuly3FreeUser) {
            logDog(200964, 5);
            return createSubmitError('免费版暂不支持此功能');
        }

        // 开启校验验证码的
        if (openValidateCode && validateCode == '') {
            return createSubmitError(LS.msgBoardInputValidateCode);
        }
        msgBdData['memberId'] = module.mId === undefined ? 0 : module.mId;

        if (module.isReCommit) {
            return;
        }
        module.isReCommit = true;
        addMsgBoardComment({
            msgBdData: $.toJSON(msgBdData),
            validateCode,
            vCodeId: module.id,
            moduleId: module.id,
        })
            .then((result) => {
                module.isReCommit = false;
                if (result.success) {
                    for (var i = contentList.length - 1; i >= 0; i--) {
                        contentList[i].value = '';
                    }
                    resolve(result);
                } else {
                    if (result.isRealDemo) {
                        Fai.ing(result.msg);
                        return;
                    }
                    if (result.errno == 1) {
                        warningMessage(LS.js_captchaError);
                    } else if (result.errno == 2) {
                        warningMessage(LS.js_argsError);
                    } else if (result.errno == -4) {
                        warningMessage(LS.msgBoardAddCountLimit);
                    } else if (result.hasFW) {
                        warningMessage(result.msg);
                    } else if (result.needCode) {
                        return createSubmitError({
                            msg: result.msg,
                            errCode: 'needCode',
                        });
                    } else {
                        warningMessage(LS.js_systemError);
                    }
                }
                !!JZ.msgBoardInfo && JZ.msgBoardInfo.getComments(false);
            })
            .catch(() => {
                module.isReCommit = false;
                return createSubmitError(Fai.format(LS.siteFormSubmitError));
            });

        function createSubmitError(msg) {
            if (typeof msg === 'string') {
                return reject({ msg });
            }
            return reject(msg);
        }
    });
}
